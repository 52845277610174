import { Chat as ChatIcon, Edit as EditIcon } from '@mui/icons-material';
import { Badge, Box, Button, IconButton } from '@mui/material';
import { uniqueId } from 'lodash';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useIsHQ } from 'hooks/useIsHQ';
import { useAddComment } from 'queries';
import { useCheckForDuplicateRecipeName } from 'queries/recipes/hooks';
import { useNotifications } from 'services/snackbar';
import { SaveOptions } from 'types/common.types';
import { RecipeFormValues, RecipeStatus } from 'types/recipes.types';

import { ValidationDialog } from 'components/@form/ValidationDialog';
import {
  RecipeApprovalState,
  RecipeCommentDialog,
} from 'components/@recipe-form/RecipeForm/RecipeApprovalState';
import { SaveButton } from 'components/@recipe-form/RecipeForm/RecipeFormMainInfo/RecipeFormMainInfo.style';

import { useRecipeValidation } from './hooks';
import RecipeActionsMenu from './RecipeActionsMenu';

interface Props {
  isReadOnly: boolean;
  isSaving: boolean;
  setIsReadOnly: (isReadOnly: boolean) => void;
  saveRecipe: (options: SaveOptions) => void;
  calculateOnBrandness: (successCallback?: () => void) => void;
}

// @TODO don't set buttons to loading when autosaving
const RecipeActions = ({
  isReadOnly,
  isSaving,
  setIsReadOnly,
  saveRecipe,
  calculateOnBrandness,
}: Props) => {
  const isHQ = useIsHQ();
  const notifications = useNotifications();
  const intl = useIntl();
  const { reset, getValues, setValue } = useFormContext<RecipeFormValues>();
  const status = useWatch<RecipeFormValues>({ name: 'status' }) as RecipeFormValues['status'];
  const comments = useWatch<RecipeFormValues>({ name: 'comments' }) as RecipeFormValues['comments'];
  const [isShowingComments, setIsShowingComments] = useState(false);
  const { isPending: isAddingComment, addComment } = useAddComment();
  const getValidationErrors = useRecipeValidation();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const checkError = useCheckForDuplicateRecipeName();

  const isDraft = [RecipeStatus.Draft, RecipeStatus.Rejected].includes(status);

  const handleAddComment = (message: string) => {
    addComment(
      { recipeId: getValues('id'), message },
      {
        onSuccess: () => {
          setIsShowingComments(false);
          setValue('comments', [
            { id: Number(uniqueId()), message, createdAt: new Date().toString() },
            ...comments,
          ]);
        },
      },
    );
  };

  const handleSave = () => {
    const validationErrors = getValidationErrors();
    if (
      ![RecipeStatus.Draft, RecipeStatus.Rejected].includes(getValues('status')) &&
      validationErrors.length
    ) {
      setValidationErrors(validationErrors);
      return;
    }
    saveRecipe({
      silent: false,
      onSuccess: () => {
        !isDraft && setIsReadOnly(true);
        calculateOnBrandness();
      },
    });
  };

  const handleCancel = () => {
    reset();
    setIsReadOnly(true);
  };

  const handleStatusChange = async ({
    successCallback,
    skipValidation,
  }: {
    successCallback?: () => void;
    skipValidation?: boolean;
  }) => {
    const validationErrors = getValidationErrors();
    if (!skipValidation && validationErrors.length) {
      return setValidationErrors(validationErrors);
    }

    saveRecipe({
      silent: true,
      onSuccess: () => calculateOnBrandness(() => successCallback?.()),
      onError: (error) => {
        const isDuplicate = checkError(error);
        if (!isDuplicate) {
          notifications.error({ message: intl.formatMessage({ id: 'error.general.text' }) });
        }
      },
    });
  };

  return (
    <Box display="flex" gap={1} alignItems="center" className="no-print">
      <RecipeActionsMenu />

      {!isReadOnly && (
        <>
          {![RecipeStatus.Draft, RecipeStatus.Rejected].includes(status) && (
            <Button
              variant="text"
              sx={(theme) => ({ color: theme.palette.black[80], px: 1 })}
              onClick={handleCancel}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
          )}

          <SaveButton loading={isSaving} onClick={handleSave} data-testid="save-recipe-button">
            <FormattedMessage id="general.actions.save" />
          </SaveButton>
        </>
      )}

      {isReadOnly &&
        isHQ &&
        ![
          RecipeStatus.Archived,
          RecipeStatus.Published,
          RecipeStatus.Draft,
          RecipeStatus.Rejected,
        ].includes(status) && (
          <IconButton onClick={() => setIsReadOnly(false)}>
            <EditIcon />
          </IconButton>
        )}

      {!!comments.length && (
        <Box>
          <IconButton onClick={() => setIsShowingComments(true)}>
            <Badge badgeContent={comments.length} color="error">
              <ChatIcon />
            </Badge>
          </IconButton>
        </Box>
      )}

      <RecipeCommentDialog
        title="recipes.creator.reject.dialog.read.title"
        isLoading={isAddingComment}
        addComment={handleAddComment}
        open={isShowingComments}
        onClose={() => setIsShowingComments(false)}
      />

      <RecipeApprovalState
        isSavingRecipe={isSaving}
        setIsReadOnly={setIsReadOnly}
        handleStatusChange={handleStatusChange}
      />

      <ValidationDialog
        title="recipes.creator.validation_dialog.title"
        open={!!validationErrors.length}
        validationErrors={validationErrors}
        onClose={() => setValidationErrors([])}
      />
    </Box>
  );
};

export default RecipeActions;
