import type { IntlShape, MessageDescriptor } from 'react-intl';

interface MessageForProps {
  type: 'required' | 'min_length' | 'mismatch' | 'max_length' | 'uppercase_lowercase';
  label: string;
  [key: string]: string | number;
}

export const errorMessageFor = (intl: IntlShape, { type, label, ...rest }: MessageForProps) =>
  intl.formatMessage(
    {
      id: `form.validation.${type}` as MessageDescriptor['id'],
    },
    {
      label: intl.formatMessage({
        id: `form.label.${label}` as MessageDescriptor['id'],
      }),
      ...rest,
    },
  );

export const hasLowercase = (string: string) => {
  return /[a-z]/.test(string);
};
export const hasUppercase = (string: string) => {
  return /[A-Z]/.test(string);
};
