import { Components, Theme } from '@mui/material';

export const MuiContainer: Components<Theme>['MuiContainer'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      paddingBlock: theme.spacing(8),
      height: '100%',
      [theme.breakpoints.down('lg')]: {
        width: '80%',
      },
    }),
  },
};
