import { useQuery } from '@tanstack/react-query';
import { getCurrentUser } from 'aws-amplify/auth';

import { QueryKeys } from 'queries';
import { Duration } from 'queries/constants';

export const useCognitoUser = () => {
  const {
    data: user,
    status,
    isLoading,
    refetch,
  } = useQuery({
    queryFn: () => getCurrentUser(),
    queryKey: QueryKeys.auth.currentUser(),
    staleTime: Duration.FIVE_MIN,
  });

  return {
    user,
    isLoading,
    status,
    isLoggedIn: !!user,
    refetch,
  };
};
