import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useNotifications } from 'services/snackbar';

export default function useIsOffline() {
  const intl = useIntl();
  const notifications = useNotifications();
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    const onOffline = () => {
      notifications.error(
        { message: intl.formatMessage({ id: 'general.connection.offline' }) },
        null,
      );
      setIsOffline(true);
    };

    const onOnline = () => {
      notifications.success(
        { message: intl.formatMessage({ id: 'general.connection.back_online' }) },
        5000,
      );
      setIsOffline(false);
    };
    window.addEventListener('offline', onOffline);
    window.addEventListener('online', onOnline);

    return () => {
      window.removeEventListener('offline', onOffline);
      window.removeEventListener('online', onOnline);
    };
  }, [intl, notifications]);

  return isOffline;
}
