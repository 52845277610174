import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isEqual } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { RESULTS_PER_PAGE } from 'constants/common.constants';
import { Country } from 'constants/countries.constants';
import { EMPTY_MENU_FILTERS } from 'constants/menus.constants';
import { Routes } from 'constants/routes.constants';
import { useMedia, useSearchState, useTableSort } from 'hooks';
import { useIsHQ } from 'hooks/useIsHQ';
import { useMenus, useUser } from 'queries';
import { useSearchAndSortStore } from 'store';
import { ActiveFilters, Season } from 'types/common.types';
import { MenuFilters, MenuStatus, MenuTableColumns } from 'types/menus.types';

import { MenuFiltersList, MenuTable } from 'components/@menu-overview';

const MenuOverview = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const isHQ = useIsHQ();
  const { user, isLoading: isLoadingUser } = useUser();
  const { sm } = useMedia();

  const {
    sortBy: storedSortBy,
    setSortBy,
    sortDirection: storedSortDirection,
    setSortDirection,
    search: storedSearch,
    setSearch,
  } = useSearchAndSortStore({ name: 'menu' })();

  const { searchValue, debouncedSearchValue, handleSearchChange } = useSearchState({
    initialValue: storedSearch,
  });
  const [filters, setFilters] = useState<ActiveFilters<MenuFilters>>(EMPTY_MENU_FILTERS);
  const { sort, sortBy, sortDirection, handleTableSort } = useTableSort({
    initialSortBy: storedSortBy || MenuTableColumns.UpdatedAt,
    initialSortDirection: storedSortDirection || 'desc',
  });

  useEffect(() => {
    setSearch(debouncedSearchValue);
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  }, [setSortBy, setSortDirection, setSearch, sortBy, sortDirection, debouncedSearchValue]);

  const onSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleSearchChange(e.currentTarget.value);
  };
  const {
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    data: menus,
    total,
    isLoading,
  } = useMenus(
    {
      search: debouncedSearchValue,
      page_size: RESULTS_PER_PAGE,
      season: filters[MenuFilters.season] as Season[],
      country: filters[MenuFilters.country] as Country[],
      year: filters[MenuFilters.year] as number[],
      status: filters[MenuFilters.status] as MenuStatus[],
      sort,
    },
    { enabled: !!user },
  );
  const getNext = useCallback(() => {
    if (!isFetching && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching, isFetchingNextPage]);

  const showResetFilter = useMemo(() => !isEqual(filters, EMPTY_MENU_FILTERS), [filters]);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'alain_ai.label' })} -{' '}
          {intl.formatMessage({ id: 'menus.overview.label' })}
        </title>
      </Helmet>

      <Stack direction="column" gap={3} width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">
            <FormattedMessage id="menus.label" />
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(Routes.MenuUpload)}
            startIcon={<AddIcon fontSize="small" />}
          >
            <FormattedMessage id="menus.create.label" />
          </Button>
        </Box>

        <TextField
          variant="standard"
          placeholder={intl.formatMessage({
            id: isHQ ? 'menus.search' : 'menus.search.franchisee',
          })}
          value={searchValue}
          onChange={onSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ width: 24 }} />
              </InputAdornment>
            ),
          }}
        />

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography>
            <FormattedMessage
              id="menus.amount"
              values={{
                amount: isLoading ? (
                  <CircularProgress size={16} color="inherit" sx={{ mr: 0.5 }} />
                ) : (
                  total
                ),
              }}
            />
          </Typography>
        </Stack>

        <Divider sx={{ height: 2 }} />

        <Stack
          direction={sm ? 'column-reverse' : 'row'}
          gap={1}
          justifyContent={sm ? 'flex-start' : 'space-between'}
          alignItems={sm ? 'flex-start' : 'center'}
        >
          <MenuFiltersList filters={filters} setFilters={setFilters} />
          {showResetFilter && (
            <Button color="inherit" onClick={() => setFilters(EMPTY_MENU_FILTERS)}>
              <FormattedMessage id="common.filters.reset" />
            </Button>
          )}
        </Stack>
      </Stack>

      <MenuTable
        menus={menus}
        menusTotal={total}
        isLoading={isLoading || isLoadingUser}
        isFetchingNextPage={isFetchingNextPage}
        getNext={getNext}
        sortDirection={sortDirection}
        sortBy={sortBy as MenuTableColumns}
        onSort={handleTableSort}
      />
    </>
  );
};

export default MenuOverview;
