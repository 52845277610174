import { useTheme } from '@mui/material';
import { uniq } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useLocale } from 'store';
import {
  Recipe,
  RecipeFormIngredient,
  RecipeFormValues,
  RecipeIngredient,
  RecipeStatus,
} from 'types/recipes.types';

export const useGetOnBrandnessColor = () => {
  const theme = useTheme();
  const useGetOnBrandnessColor = (onBrand: number) => {
    if (onBrand > 70) return theme.palette.success.main;
    if (onBrand > 50) return theme.palette.success.light;
    if (onBrand > 30) return theme.palette.warning.main;
    return theme.palette.error.main;
  };
  return useGetOnBrandnessColor;
};

export const useGetRecipeStatusColor = () => {
  const theme = useTheme();
  const getRecipeStatusColor = (status: RecipeStatus) => {
    switch (status) {
      case RecipeStatus.Submitted:
        return {
          bgColor: theme.palette.info.main,
          textColor: theme.palette.text.secondary,
        };
      case RecipeStatus.Rejected:
        return {
          bgColor: theme.palette.warning.main,
          textColor: theme.palette.text.primary,
        };
      case RecipeStatus.Approved:
        return {
          bgColor: theme.palette.success.main,
          textColor: theme.palette.text.secondary,
        };
      case RecipeStatus.Archived:
        return {
          bgColor: theme.palette.error.main,
          textColor: theme.palette.text.secondary,
        };
      case RecipeStatus.Published:
        return {
          bgColor: theme.palette.success.main,
          textColor: theme.palette.text.secondary,
        };
      case RecipeStatus.Draft:
      default:
        return {
          bgColor: theme.palette.grey[400],
          textColor: theme.palette.text.primary,
        };
    }
  };
  return getRecipeStatusColor;
};

export const useTransformIngredients = () => {
  const transformApiIngredientsToForm = useCallback(
    (ingredients: RecipeIngredient[], isNew?: boolean): RecipeFormIngredient[] => {
      const transformedIngredients: RecipeFormIngredient[] = [];
      let alreadyTransformed: number[] = [];

      ingredients
        .sort((a, b) => (a.order || 0) - (b.order || 0))
        .forEach((ingredient) => {
          if (!ingredient) return null;
          if (alreadyTransformed.includes(ingredient.id || 0)) return null;

          const peerIngredient = ingredients.find(
            (i) => i.order === ingredient.order && i.id !== ingredient.id,
          );
          // if (!peerIngredient) return null;

          transformedIngredients.push({
            refId:
              (ingredient.isIngredientRecipe ? ingredient.recipe?.id : ingredient.ingredient?.id) ||
              null,
            isIngredientRecipe: ingredient.isIngredientRecipe || false,
            status:
              !!ingredient.isIngredientRecipe && ingredient.recipe?.status
                ? ingredient.recipe?.status
                : undefined,
            country:
              !!ingredient.isIngredientRecipe && ingredient.recipe?.country
                ? ingredient.recipe?.country
                : undefined,
            name: !!ingredient.isIngredientRecipe
              ? ingredient.recipe?.name || ''
              : ingredient.ingredient?.name || '',
            measurements: [
              {
                id: ingredient.measurement.id,
                quantity: ingredient.quantity,
                unit: ingredient.measurement.unit || '',
                recipeIngredientId: isNew ? null : ingredient.id || null,
              },
              {
                id: peerIngredient?.measurement.id || null,
                quantity: peerIngredient?.quantity || '',
                unit: peerIngredient?.measurement.unit || '',
                recipeIngredientId: isNew ? null : peerIngredient?.id || null,
              },
            ],
            notes: ingredient.notes,
          });
          alreadyTransformed = [...alreadyTransformed, ingredient.id || 0, peerIngredient?.id || 0];
          return;
        });

      return transformedIngredients.filter(Boolean);
    },
    [],
  );

  const transformFormIngredientsToApi = useCallback(
    (ingredients: RecipeFormIngredient[]): RecipeIngredient[] => {
      const transformedIngredients: RecipeIngredient[] = [];

      uniq(ingredients).forEach((ingredient, index) => {
        const transformedIngredient = {
          order: index + 1,
          name: ingredient.name,
          isIngredientRecipe: !!ingredient.isIngredientRecipe,
          notes: ingredient.notes,
          ingredient: !!ingredient.isIngredientRecipe
            ? undefined
            : ingredient.refId
              ? { id: ingredient.refId }
              : null,
          recipe: !!ingredient.isIngredientRecipe ? { id: ingredient.refId || null } : null,
        };
        const ingredientIds = ingredient.measurements.map((m) => m.recipeIngredientId);
        // Push 1 ingredient for each measurement (even if the measurement is empty)
        transformedIngredients.push(
          ...([
            {
              ...transformedIngredient,
              id: ingredientIds[0] || null,
              measurement: {
                unit: ingredient.measurements[0]?.unit || '',
                id: ingredient.measurements[0]?.id || null,
              },
              quantity: ingredient.measurements[0]?.quantity || '',
            },
            {
              ...transformedIngredient,
              id: ingredientIds[1] || null,
              measurement: {
                unit: ingredient.measurements[1]?.unit || '',
                id: ingredient.measurements[1]?.id || null,
              },
              quantity: ingredient.measurements[1]?.quantity || '',
            },
          ] as RecipeIngredient[]),
        );
      });

      return transformedIngredients;
    },
    [],
  );

  const returnValue = useMemo(
    () => ({ transformApiIngredientsToForm, transformFormIngredientsToApi }),
    [transformApiIngredientsToForm, transformFormIngredientsToApi],
  );
  return returnValue;
};

export const useTransformRecipe = () => {
  const intl = useIntl();
  const { transformApiIngredientsToForm, transformFormIngredientsToApi } =
    useTransformIngredients();

  const transformApiRecipeToForm = useCallback(
    (recipe?: Recipe, isNew?: boolean): RecipeFormValues => {
      if (!recipe) return {} as RecipeFormValues;
      return {
        id: Number(recipe.id),
        name: recipe.name || intl.formatMessage({ id: 'recipes.label.untitled' }),
        image: recipe.image,
        batchImage: recipe.batchImage,
        category: recipe.category || null,
        comments: recipe.comments || [],
        ingredients: transformApiIngredientsToForm(recipe.ingredients, isNew || false),
        preparationSteps: isNew
          ? recipe.preparationSteps.map((step) => ({ ...step, id: undefined }))
          : recipe.preparationSteps,
        seasons: recipe.seasons || [],
        country: recipe.country,
        type: recipe.type,
        requirementType: recipe.requirementType,
        notes: recipe.notes || [],
        status: recipe.status,
        onBrandScore: recipe.onBrand?.score ?? null,
        performanceData: recipe.performanceData,
      };
    },
    [intl, transformApiIngredientsToForm],
  );

  const transformFormRecipeToApi = useCallback(
    (recipe: RecipeFormValues): Recipe => ({
      id: Number(recipe.id),
      name: recipe.name,
      image: recipe.image,
      batchImage: recipe.batchImage,
      category: recipe.category || null,
      comments: recipe.comments || [],
      ingredients: transformFormIngredientsToApi(recipe?.ingredients),
      preparationSteps: recipe.preparationSteps.map((step, index) => ({
        ...step,
        order: index + 1,
      })),
      seasons: recipe.seasons || [],
      country: recipe.country,
      updatedAt: '',
      type: recipe.type || null,
      requirementType: recipe.requirementType || null,
      notes: recipe.notes || [],
      status: recipe.status || RecipeStatus.Draft,
      onBrand: { score: recipe.onBrandScore },
      performanceData: recipe.performanceData,
    }),
    [transformFormIngredientsToApi],
  );

  const returnValue = useMemo(
    () => ({ transformApiRecipeToForm, transformFormRecipeToApi }),
    [transformApiRecipeToForm, transformFormRecipeToApi],
  );

  return returnValue;
};

export const useCountryName = () => {
  const locale = useLocale((state) => state.locale);

  const getCountryName = useCallback(
    (countryCode: string) => {
      try {
        const regionNames = new Intl.DisplayNames([locale], { type: 'region' });
        return regionNames.of(countryCode) || countryCode;
      } catch (error) {
        return countryCode;
      }
    },
    [locale],
  );

  return getCountryName;
};
