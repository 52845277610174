// Inspired by Chatbot-UI and modified to fit the needs of this project
// @see https://github.com/mckaywrigley/chatbot-ui/blob/main/components/Chat/ChatMessage.tsx

import { Button } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import { memo, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useChatHandler } from 'hooks';
import { ChatbotUIContext } from 'services/ai-chat';
import { CREATE_RECIPE_TOKEN } from 'services/ai-chat/constants';
import { Message } from 'types/chat.types';

import { BotCard, BotMessage, SpinnerMessage, SystemMessage, UserMessage } from '../stock-messages';

export interface ChatMessageProps {
  message: Message;
  secondLastMessage: Message | undefined;
  isLoading?: boolean;
}

const ChatMessage = ({ message, secondLastMessage, isLoading }: ChatMessageProps) => {
  const intl = useIntl();
  const posthog = usePostHog();
  const [hasSentMessage, setHasSentMessage] = useState(false);
  const { handleSendMessage } = useChatHandler();
  const { chatMessages } = useContext(ChatbotUIContext);

  if (isLoading) {
    const isCreatingRecipe = secondLastMessage?.content.includes(CREATE_RECIPE_TOKEN);

    return (
      <SpinnerMessage>
        {isCreatingRecipe && <FormattedMessage id="chat.response.create_recipe.loading" />}
      </SpinnerMessage>
    );
  }
  if (message.role === 'user') {
    return <UserMessage>{message.content}</UserMessage>;
  }
  if (message.role === 'assistant') {
    let content = message.content;
    const hasCreateRecipeToken = message.content.includes(CREATE_RECIPE_TOKEN);
    if (hasCreateRecipeToken) {
      content = message.content.replace(CREATE_RECIPE_TOKEN, '');
    }

    const handleSendConfirm = () => {
      posthog.capture('create_recipe', { type: 'new', source: 'chat' });
      handleSendMessage(intl.formatMessage({ id: 'chat.response.create_recipe' }), chatMessages);
      setHasSentMessage(true);
    };

    return (
      <>
        <BotMessage content={content}>
          {!hasSentMessage && hasCreateRecipeToken && (
            <Button variant="contained" onClick={handleSendConfirm}>
              <FormattedMessage id="recipes.create.label" />
            </Button>
          )}
        </BotMessage>
      </>
    );
  }
  if (message.role === 'system') {
    return <SystemMessage>{message.content}</SystemMessage>;
  }

  if (message.role === 'function') {
    return <BotCard>{message.content}</BotCard>;
  }
};

export default memo(ChatMessage);
