import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { RecipeCommentParams } from 'types/recipes.types';

const rejectRecipe = (params: RecipeCommentParams) => {
  return apiService.rejectRecipe(params);
};

export const useRejectRecipe = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const notifications = useNotifications();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: rejectRecipe,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.recipes.all() });
      notifications.success({
        message: intl.formatMessage({ id: 'recipes.creator.rejected.success' }),
      });
    },
    onError: () => {
      notifications.error({ message: intl.formatMessage({ id: 'error.general.text' }) });
    },
  });

  return {
    rejectRecipe: mutateAsync,
    isPending,
  };
};
