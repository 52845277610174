import { Menu } from 'types/menus.types';
import { Recipe } from 'types/recipes.types';

enum Keys {
  User = 'user',
  Recipes = 'recipes',
  Measurements = 'measurements',
  Ingredients = 'ingredients',
  Menus = 'menus',
  MenuItems = 'menuItems',
  Status = 'status',
  Recommended = 'recommended',
  ComplianceRules = 'complianceRules',
  Chat = 'chat',
  Insights = 'insights',
}

export const QueryKeys = {
  users: {
    me: () => [Keys.User, 'me'],
  },
  auth: {
    currentUser: () => [Keys.User, 'current'],
  },
  recipes: {
    all: (...params: unknown[]) => [Keys.Recipes, ...params],
    byId: (id: Recipe['id']) => [Keys.Recipes, id],
    complianceRules: () => [Keys.Recipes, Keys.ComplianceRules],
  },
  measurements: {
    all: (...params: unknown[]) => [Keys.Measurements, ...params],
  },
  ingredients: {
    all: (...params: unknown[]) => [Keys.Ingredients, ...params],
  },
  menus: {
    all: (...params: unknown[]) => [Keys.Menus, ...params],
    byId: (id: Menu['id']) => [Keys.Menus, id],
    status: (id: Menu['id']) => [Keys.Menus, id, Keys.Status],
    recommendedRecipes: (menuId: Menu['id'], menuItemId: Menu['id']) => [
      Keys.Menus,
      menuId,
      Keys.MenuItems,
      menuItemId,
      Keys.Recommended,
    ],
    complianceRules: () => [Keys.Menus, Keys.ComplianceRules],
  },
  chat: {
    byId: (id: string) => [Keys.Chat, id],
  },
  insights: {
    all: () => [Keys.Insights],
  },
};
