import {
  Box,
  filledInputClasses,
  InputAdornment,
  inputBaseClasses,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useTransformRecipe } from 'utils/recipe.utils';

import { Country } from 'constants/countries.constants';
import { COUNTRIES_ALL, MEP_PREFIX } from 'constants/recipes.constants';
import { useSearchState } from 'hooks';
import { useIsHQ } from 'hooks/useIsHQ';
import { useRecipeById, useRecipes, useUser } from 'queries';
import {
  Recipe,
  RecipeAutocompleteOption,
  RecipeFormValues,
  RecipeRequirement,
  RecipeStatus,
  RecipeType,
} from 'types/recipes.types';

import { ConfirmationDialog, CountryFlag } from 'components/@common';
import { CountrySelect } from 'components/@form';
import { useIsInMenuItemDrawer } from 'components/@menu-form';

import { AutocompleteOption } from './AutocompleteOption';

type Props = {
  isReadOnly: boolean;
  onSave: (options: { silent: boolean }) => void;
};

const removeMepPrefix = (name: string) =>
  name.substring(0, MEP_PREFIX.length) === MEP_PREFIX ? name.replace(MEP_PREFIX, '') : name;

const RecipeNameAutocomplete = ({ isReadOnly, onSave }: Props) => {
  const isInMenuItemDrawer = useIsInMenuItemDrawer();

  const intl = useIntl();
  const { user } = useUser();
  const isHQ = useIsHQ();
  const { setValue } = useFormContext<RecipeFormValues>();

  const name = useWatch<RecipeFormValues>({ name: 'name' }) as string;
  const requirementType = useWatch<RecipeFormValues>({
    name: 'requirementType',
  }) as RecipeRequirement;
  const type = useWatch<RecipeFormValues>({ name: 'type' }) as RecipeType;
  const isMEP = type === RecipeType.MiseEnPlace;

  const { searchValue, debouncedSearchValue, handleSearchChange } = useSearchState({
    initialValue: name,
  });
  const country = useWatch<RecipeFormValues>({ name: 'country' }) as Country;

  useEffect(() => {
    if (isReadOnly) return;

    setValue('name', `${isMEP ? MEP_PREFIX : ''}${removeMepPrefix(searchValue)}`, {
      shouldDirty: true,
    });
  }, [isReadOnly, isMEP, searchValue, setValue]);

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data: recipes,
    isFetching,
  } = useRecipes({ search: debouncedSearchValue || '' }, { enabled: !isReadOnly });

  const options: RecipeAutocompleteOption[] = recipes
    .filter(({ status }) => status === RecipeStatus.Published)
    .map(({ id, ...rest }) => ({ id, ...rest }));

  const getNext = (inView: boolean) => {
    if (inView && hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const { transformApiRecipeToForm } = useTransformRecipe();

  const [recipeOverwriterId, setRecipeOverwriterId] = useState<Recipe['id'] | null>(null);

  const { data: recipeOverwriter } = useRecipeById(recipeOverwriterId || 0, {
    retry: 0,
    enabled: !!recipeOverwriterId,
  });

  const handleOverwriteRecipe = () => {
    if (!recipeOverwriterId) return;
    const formattedRecipe = transformApiRecipeToForm(recipeOverwriter, true);
    handleSearchChange(formattedRecipe.name);
    setValue('name', formattedRecipe.name);
    setValue('description', formattedRecipe.description);
    setValue('category', formattedRecipe.category);
    setValue('type', formattedRecipe.type);
    setValue('seasons', formattedRecipe.seasons);
    setValue('image', formattedRecipe.image);
    setValue('batchImage', formattedRecipe.batchImage);
    setValue('ingredients', formattedRecipe.ingredients);
    setValue('preparationSteps', formattedRecipe.preparationSteps);
    setValue('notes', formattedRecipe.notes);

    // HQ users can create recipes in any country and requirement type, franchisees can only create in their country and no CORE recipes
    setValue(
      'requirementType',
      isHQ
        ? formattedRecipe.requirementType
        : formattedRecipe.requirementType === RecipeRequirement.Core
          ? null
          : formattedRecipe.requirementType,
    );
    setValue('country', isHQ ? formattedRecipe.country : user?.group.country || '');
    onSave({ silent: false });
  };

  if (isReadOnly) {
    return (
      <Stack pr={1} direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="h4">{name}</Typography>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={1}>
          <Typography className="print-only">
            <FormattedMessage id="general.country.label" />:
          </Typography>
          {!isInMenuItemDrawer && (
            <CountryFlag
              country={requirementType === RecipeRequirement.Core ? COUNTRIES_ALL : country}
              size={32}
            />
          )}
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      <Autocomplete
        value={removeMepPrefix(searchValue)}
        options={options}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
        ListboxProps={{ role: 'list-box' }} // needed to stop scroll from resetting
        loading={isFetching}
        clearOnEscape
        freeSolo
        selectOnFocus
        handleHomeEndKeys
        inputValue={removeMepPrefix(searchValue)}
        onInputChange={(_, value) => handleSearchChange(value)}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        loadingText={<OptionSkeleton />}
        sx={{ width: '100%' }}
        renderOption={(_, option, state) => (
          <AutocompleteOption
            key={option.id}
            allOptions={options}
            option={option}
            inputValue={state.inputValue}
            index={state.index}
            onChange={(recipe) => handleSearchChange(recipe.name)}
            onOverwriteFromRecipe={() => setRecipeOverwriterId(option.id || null)}
            onGetNextOptions={getNext}
          />
        )}
        disableClearable
        renderInput={({ InputProps, ...props }) => (
          <TextField
            className="recipe-title"
            variant="filled"
            {...props}
            inputProps={{
              ...props.inputProps,
              maxLength: 255,
              style: { textTransform: 'uppercase', padding: 0 },
            }}
            sx={{
              [`& .${inputBaseClasses.root}`]: {
                paddingTop: 0,
                ':hover': { backgroundColor: 'black.4' },
              },
              '@media print': {
                [`& .${filledInputClasses.root}`]: { p: 0 },
                [`& .${autocompleteClasses.input}`]: {
                  textOverflow: 'unset',
                  whiteSpace: 'normal',
                },
              },
            }}
            InputProps={{
              ...InputProps,
              startAdornment: isMEP && (
                <InputAdornment position="start" sx={{ marginTop: '0 !important' }}>
                  <Typography fontSize={32}>{MEP_PREFIX}</Typography>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {isHQ && requirementType !== RecipeRequirement.Core ? (
                    <CountrySelect />
                  ) : (
                    <Box px={1}>
                      <CountryFlag
                        country={
                          requirementType === RecipeRequirement.Core ? COUNTRIES_ALL : country
                        }
                        size={32}
                      />
                    </Box>
                  )}
                </InputAdornment>
              ),
            }}
            placeholder={intl.formatMessage({ id: 'recipes.creator.input.title.label' })}
          />
        )}
      />

      <ConfirmationDialog
        title={<FormattedMessage id="recipes.actions.overwrite_values.confirm.title" />}
        message={<FormattedMessage id="recipes.actions.overwrite_values.confirm.text" />}
        open={!!recipeOverwriterId}
        onConfirm={handleOverwriteRecipe}
        onClose={() => setRecipeOverwriterId(null)}
      />
    </>
  );
};

export default RecipeNameAutocomplete;

const OptionSkeleton = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height={48}
      px={2}
      py={1.5}
    >
      <Skeleton height={24} width="30%" />
      <Skeleton height={24} width={40} variant="circular" />
    </Stack>
  );
};
