import { Stack } from '@mui/material';
import { memo, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useMedia } from 'hooks';
import { useUpdateMenu } from 'queries/menus/useUpdateMenu';
import { useNotifications } from 'services/snackbar';
import { SaveOptions } from 'types/common.types';
import {
  Menu,
  MenuFormData,
  MenuItemStatus,
  MenuProcessingStatus,
  MenuStatus,
} from 'types/menus.types';
import { RecipeType } from 'types/recipes.types';

import ErrorBoundary from 'components/@boundaries/ErrorBoundary';
import { BackButton, ConfirmationDialog } from 'components/@common';
import { useMenuProcessingStatus } from 'components/@menu-form/MenuForm/hooks';
import { CoverageValues } from 'components/@menu-form/MenuForm/types';
import { MenuProcessing } from 'components/@menu-form/MenuProcessing';
import { AutosaveState } from 'components/@states';

import { MenuItems } from '../MenuItems';
import { MenuMainInfo } from './MenuMainInfo';

interface Props {
  menu: Menu;
  disabled: boolean;
}

const MenuForm = ({ menu, disabled }: Props) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const notifications = useNotifications();

  const menuProcessingStatus = useMenuProcessingStatus({
    menuId: menu.id,
    initialStatus: menu.processingStatus,
  });

  const hasProcessingError = [MenuProcessingStatus.Error, MenuProcessingStatus.PdfError].includes(
    menuProcessingStatus,
  );

  const [isOverridingErrorState, setIsOverridingErrorState] = useState(() => {
    return hasProcessingError && menu.menuItems.length > 0;
  });
  const isProcessing = menuProcessingStatus === MenuProcessingStatus.Processing;

  const initialValues = useMemo<MenuFormData>(
    () => ({
      id: menu.id,
      name: menu.name,
      country: menu.country,
      seasons: menu.seasons,
      year: menu.year,
      status: menu.status,
    }),
    [menu],
  );

  const methods = useForm<MenuFormData>({
    defaultValues: initialValues,
    progressive: true,
    mode: 'all',
  });

  const { updateMenu, isPending: isSavingMenu, isError } = useUpdateMenu();

  const [lastSavedAt, setLastSavedAt] = useState(new Date(menu.updatedAt));

  const [isReadOnly, setIsReadOnly] = useState(disabled || menu.status !== MenuStatus.Draft);
  useEffect(
    () => setIsReadOnly(disabled || menu.status !== MenuStatus.Draft),
    [menu.status, disabled],
  );

  const [supported, setSupported] = useState(true);
  const { sm } = useMedia();
  useEffect(() => setSupported((!sm && !isReadOnly) || isReadOnly), [sm, isReadOnly]);

  const saveMenu = ({ silent, onSuccess, onError }: SaveOptions) => {
    if (isSavingMenu) return;

    updateMenu(
      { ...methods.getValues(), silent },
      {
        onSuccess: () => {
          setLastSavedAt(new Date());
          onSuccess?.();
        },
        onError: (error) => {
          onError?.(error);
        },
      },
    );
  };

  const handleSubmit = ({ silent, onSuccess, onError }: SaveOptions = { silent: false }) => {
    methods.handleSubmit(
      () => saveMenu({ silent, onSuccess, onError }),
      () =>
        notifications.error({
          message: intl.formatMessage(
            { id: 'general.actions.save.error' },
            { type: intl.formatMessage({ id: 'menus.label.singular' }) },
          ),
        }),
    )();
  };

  const isPurchasedItem = (item: Menu['menuItems'][0]) => {
    return (
      item.isPurchasedItem ||
      (item.recipe && 'type' in item.recipe && item.recipe.type === RecipeType.PurchasedItem)
    );
  };

  const coverageValues = useMemo<CoverageValues>(() => {
    const matchedAmount = menu.menuItems.filter(
      (item) =>
        [MenuItemStatus.Approved, MenuItemStatus.Matched, MenuItemStatus.ManualOverride].includes(
          item.status,
        ) && !isPurchasedItem(item),
    ).length;
    const unmatchedAmount = menu.menuItems.filter(
      (item) => item.status === MenuItemStatus.Unmatched && !isPurchasedItem(item),
    ).length;
    const purchasedAmount = menu.menuItems.filter(isPurchasedItem).length;
    const coveragePercent = Math.round((matchedAmount / (matchedAmount + unmatchedAmount)) * 100);

    return { matchedAmount, unmatchedAmount, purchasedAmount, coveragePercent };
  }, [menu.menuItems]);

  const showProcessingState = (isProcessing || hasProcessingError) && !isOverridingErrorState;

  return (
    <ErrorBoundary boundary="menu-form">
      <Stack>
        <FormProvider {...methods}>
          <Stack gap={3} height="100%" width="100%">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="no-print"
              height="50px"
            >
              <BackButton to={Routes.MenuOverview} />

              {!isReadOnly && initialValues.status === MenuStatus.Draft && (
                <AutosaveState
                  isError={isError}
                  isPending={isSavingMenu}
                  savedAt={lastSavedAt}
                  handleSave={(callback) => handleSubmit({ silent: true, onSuccess: callback })}
                />
              )}
            </Stack>

            <Stack component="form" gap={3}>
              <MenuMainInfo
                menu={menu}
                isSaving={isSavingMenu}
                isReadOnly={isReadOnly}
                setIsReadOnly={setIsReadOnly}
                onSave={handleSubmit}
                isProcessing={showProcessingState}
                isError={hasProcessingError}
                disabled={disabled}
                coverageValues={coverageValues}
              />
            </Stack>
          </Stack>
        </FormProvider>
        {showProcessingState ? (
          <MenuProcessing
            hasProcessingError={hasProcessingError}
            processingStatus={menuProcessingStatus}
            menuId={menu.id}
            overrideErrorState={() => setIsOverridingErrorState(true)}
          />
        ) : (
          <MenuItems
            menuId={menu.id as number}
            menuItems={menu.menuItems}
            coverageValues={coverageValues}
            disabled={disabled}
            recalculateCompliance={() => saveMenu({ silent: true })}
          />
        )}

        <ConfirmationDialog
          open={!supported}
          title={<FormattedMessage id="error.mobile.not_supported.title" />}
          message={<FormattedMessage id="error.mobile.not_supported.text" />}
          onConfirm={() => navigate(-1)}
          onClose={() => setSupported(true)}
          closeText={<FormattedMessage id="error.mobile.not_supported.proceed" />}
          confirmText={<FormattedMessage id="error.mobile.not_supported.go_back" />}
        />
      </Stack>
    </ErrorBoundary>
  );
};

export default memo(MenuForm);
