import { Close as CloseIcon, Edit as EditIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import alain_avatar from 'assets/images/avatar_alain.png';

import { Routes } from 'constants/routes.constants';
import { useDeleteMenu } from 'queries';
import { Menu, MenuProcessingStatus } from 'types/menus.types';

interface Props {
  hasProcessingError: boolean;
  processingStatus: MenuProcessingStatus;
  menuId: Menu['id'];
  overrideErrorState: () => void;
}

const MenuProcessing = ({
  hasProcessingError,
  processingStatus,
  menuId,
  overrideErrorState,
}: Props) => {
  const navigate = useNavigate();
  const { deleteMenu, isPending: isDeletingMenu } = useDeleteMenu();

  const loadingMessages = useMemo(() => {
    let loadingMessages: I18nKey[] = Array.from(
      { length: 10 },
      (_, i) => `menus.processing.state.${i + 1}` as I18nKey,
    );

    loadingMessages = [
      'menus.processing.state.1',
      'menus.processing.state.2',
      'menus.processing.state.3',
      ...loadingMessages,
    ];
    return loadingMessages;
  }, []);

  // loop through loadingMessages and show a different one every couple of seconds
  const [loadingMessagesIndex, setLoadingMessagesIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingMessagesIndex((prev) => (prev + 1) % loadingMessages.length);
    }, 7_500);

    return () => clearInterval(interval);
  }, [loadingMessages]);

  const onTryAgain = () => {
    deleteMenu(menuId, { onSuccess: () => navigate(Routes.MenuUpload) });
  };

  return (
    <Box borderRadius={2} bgcolor="bg.paper" p={{ xs: 4, sm: 8 }}>
      <Stack>
        <Avatar src={alain_avatar} sx={{ height: 85, width: 85 }} />

        {hasProcessingError ? (
          <Stack gap={3}>
            <Stack
              gap={1}
              direction="row"
              mt={4}
              alignItems="center"
              border={2}
              p={1}
              borderRadius={2}
              maxWidth={700}
              color="error.dark"
            >
              <CloseIcon sx={{ height: 24, width: 24, color: 'error.dark' }} />
              <Typography variant="body1" sx={{ color: (theme) => theme.palette.error.dark }}>
                <FormattedMessage
                  id={
                    processingStatus === MenuProcessingStatus.PdfError
                      ? 'menus.processing.state.pdf_error'
                      : 'menus.processing.state.error'
                  }
                />
              </Typography>
            </Stack>

            <Stack direction="row" gap={2} flexWrap="wrap">
              <LoadingButton
                loading={isDeletingMenu}
                variant="contained"
                startIcon={<RefreshIcon />}
                onClick={onTryAgain}
              >
                <FormattedMessage id="menus.processing.state.error.try_again" />
              </LoadingButton>

              <Button startIcon={<EditIcon />} variant="contained" onClick={overrideErrorState}>
                <FormattedMessage id="menus.processing.state.error.manual" />
              </Button>
            </Stack>
          </Stack>
        ) : (
          <>
            <Typography variant="h5" fontStyle="italic" fontWeight={300} mt={2}>
              <FormattedMessage id="menus.processing.title" />
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={500}
              mt={1}
              sx={{ color: (theme) => theme.palette.bluegrey[500] }}
            >
              <FormattedMessage id="menus.processing.description" />
            </Typography>

            <Stack gap={1} direction="row" mt={4} alignItems="center">
              <CircularProgress size={20} sx={{ color: 'grey.500' }} />
              <Typography variant="body1" sx={{ color: (theme) => theme.palette.grey[800] }}>
                <FormattedMessage id={loadingMessages[loadingMessagesIndex]} />
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default MenuProcessing;
