import { confirmResetPassword } from '@aws-amplify/auth';
import { useMutation } from '@tanstack/react-query';

import { CognitoError, ResetPasswordParams } from 'types/auth.types';

export const useResetPassword = () => {
  const { mutate, isPending, error } = useMutation<void, CognitoError, ResetPasswordParams>({
    mutationFn: (params) => confirmResetPassword({ ...params, username: params.email.trim() }),
  });

  return {
    resetPassword: mutate,
    isPending,
    error,
  };
};
