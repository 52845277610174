import { Avatar, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import alain_avatar from 'assets/images/avatar_alain.png';

const EmptyScreen = () => (
  <Stack color="bluegrey.50" justifySelf="flex-start">
    <Stack px={5} py={4} direction="row" gap={3} alignItems="center" bgcolor="bluegrey.900">
      <Avatar src={alain_avatar} sx={{ height: 72, width: 72 }} />
      <Stack>
        <Typography variant="h6">
          <FormattedMessage id="chat.header.label" />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id="chat.header.description" />
        </Typography>
      </Stack>
    </Stack>
    <Typography p={5} variant="body1" fontStyle="italic" fontSize="1.5rem" fontWeight={300}>
      <FormattedMessage id="chat.message.initial" />
    </Typography>
  </Stack>
);
export default EmptyScreen;
