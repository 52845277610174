import { ArrowForwardRounded as ArrowForwardRoundedIcon } from '@mui/icons-material';
import { Box, Button, Rating, Stack } from '@mui/material';
import { memo } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { Country } from 'constants/countries.constants';
import { COUNTRIES_ALL } from 'constants/recipes.constants';
import { Routes } from 'constants/routes.constants';
import { SaveOptions } from 'types/common.types';
import { RecipeFormValues, RecipeRequirement } from 'types/recipes.types';

import { CountryFlag } from 'components/@common';
import { useIsInMenuItemDrawer } from 'components/@menu-form';
import { RecipeNameAutocomplete } from 'components/@recipe-form/RecipeNameAutocomplete';

import {
  RecipeActions,
  RecipeCategory,
  RecipeRequirementType,
  RecipeSeasons,
  RecipeType,
} from './components';
import { TitleBox } from './RecipeFormMainInfo.style';

type Props = {
  isReadOnly: boolean;
  isSaving: boolean;
  disableActions: boolean | undefined;
  onSave: (options: SaveOptions) => void;
  setIsReadOnly: (isReadOnly: boolean) => void;
  calculateOnBrandness: (successCallback?: () => void) => void;
};

const RecipeFormMainInfo = ({
  isSaving,
  isReadOnly,
  disableActions,
  onSave,
  setIsReadOnly,
  calculateOnBrandness,
}: Props) => {
  const isInMenuItemDrawer = useIsInMenuItemDrawer();
  const recipeId = useWatch<RecipeFormValues>({ name: 'id' }) as RecipeFormValues['id'];
  const country = useWatch<RecipeFormValues>({ name: 'country' }) as Country;
  const requirementType = useWatch<RecipeFormValues>({
    name: 'requirementType',
  }) as RecipeRequirement;

  const openRecipe = () => {
    window.open(`${generatePath(Routes.RecipeDetail, { recipeId: String(recipeId) })}`, '_blank');
  };

  return (
    <Stack width="100%" gap={0} height="fit-content">
      {isInMenuItemDrawer && (
        <Stack mb={3} direction="row" alignItems="center" justifyContent="space-between">
          <Rating />
          <CountryFlag
            country={requirementType === RecipeRequirement.Core ? COUNTRIES_ALL : country}
            size={32}
          />
        </Stack>
      )}

      <RecipeRequirementType isReadOnly={isReadOnly} />
      <TitleBox mt={1}>
        <RecipeNameAutocomplete isReadOnly={isReadOnly} onSave={onSave} />

        {!disableActions && (
          <RecipeActions
            isReadOnly={isReadOnly}
            isSaving={isSaving}
            setIsReadOnly={setIsReadOnly}
            saveRecipe={onSave}
            calculateOnBrandness={calculateOnBrandness}
          />
        )}
      </TitleBox>

      <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
        <RecipeSeasons isReadOnly={isReadOnly} />
        <RecipeCategory isReadOnly={isReadOnly} />
        <RecipeType isReadOnly={isReadOnly} />

        {isInMenuItemDrawer && (
          <Box ml="auto">
            <Button
              endIcon={<ArrowForwardRoundedIcon />}
              variant="text"
              sx={(theme) => ({ color: theme.palette.global01.main })}
              onClick={openRecipe}
            >
              <FormattedMessage id="recipes.view_full" />
            </Button>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default memo(RecipeFormMainInfo);
