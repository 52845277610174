import { KeysMap, Season } from 'types/common.types';

export const RESULTS_PER_PAGE = 25;

export const seasonsMap: KeysMap<Season> = {
  [Season.Winter]: 'general.seasons.winter',
  [Season.Spring]: 'general.seasons.spring',
  [Season.Summer]: 'general.seasons.summer',
  [Season.Autumn]: 'general.seasons.autumn',
  ALL: 'general.seasons.all',
};

export const NOTIFICATION_DURATION = 7000;
