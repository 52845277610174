import { useMutation, useQueryClient } from '@tanstack/react-query';
import { signOut } from 'aws-amplify/auth';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries';
import { useNotifications } from 'services/snackbar';

export const useDeleteLogin = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isPending, error } = useMutation({
    mutationFn: () => signOut(),
    onSettled: () => {
      queryClient.removeQueries({ queryKey: QueryKeys.auth.currentUser() });
    },
    onError: () => {
      notifications.error({ message: intl.formatMessage({ id: 'common.error_messages.error' }) });
    },
  });

  return {
    signOut: mutate,
    isPending,
    error,
  };
};
