import {
  autocompleteClasses,
  Box,
  outlinedInputClasses,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { memo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { lineClamp } from 'utils/text.utils';

import { RecipeFormMeasurement, RecipeFormValues } from 'types/recipes.types';

import { InputSelectCombo } from 'components/@form';
import { IngredientAutocomplete } from 'components/@recipe-form/IngredientAutocomplete';

type Props = {
  index: number;
  isReadOnly: boolean;
};

const IngredientItem = ({ index, isReadOnly }: Props) => {
  const intl = useIntl();
  const { control } = useFormContext<RecipeFormValues>();

  const firstMeasurement = useWatch<RecipeFormValues>({
    name: `ingredients.${index}.measurements.0`,
  }) as RecipeFormMeasurement;
  const secondMeasurement = useWatch<RecipeFormValues>({
    name: `ingredients.${index}.measurements.1`,
  }) as RecipeFormMeasurement;

  const hasFirstMeasurement = firstMeasurement?.quantity || firstMeasurement?.quantity;
  const hasSecondMeasurement = secondMeasurement?.quantity || secondMeasurement?.quantity;

  return (
    <Box display="flex" gap={2}>
      <Box flex={1} display="flex" alignItems="center">
        <Controller
          control={control}
          name={`ingredients.${index}`}
          render={({ field: { onChange, value } }) => (
            <IngredientAutocomplete
              index={index + 1}
              onChange={(newValue) =>
                onChange({
                  ...value,
                  name: newValue?.name || '',
                  refId: newValue?.refId || null,
                  isIngredientRecipe: newValue?.isIngredientRecipe || false,
                  status: newValue?.status || undefined,
                  country: newValue?.country || undefined,
                })
              }
              value={value}
              isReadOnly={isReadOnly}
            />
          )}
        />
      </Box>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        maxWidth="50%"
        gap={!isReadOnly || (hasFirstMeasurement && hasSecondMeasurement) ? 1 : 0}
        flex={2}
        sx={(theme) => ({ [theme.breakpoints.down('lg')]: { flex: 2 } })}
      >
        <Box flex={isReadOnly ? undefined : 1}>
          <Controller
            control={control}
            name={`ingredients.${index}.measurements.0`}
            render={({ field }) => {
              return (
                <InputSelectCombo
                  index={index + 1}
                  order={1}
                  {...field}
                  label={intl.formatMessage({ id: 'recipes.ingredients.quantity.label' })}
                  isReadOnly={isReadOnly}
                />
              );
            }}
          />
        </Box>
        {isReadOnly && !!hasSecondMeasurement && !!hasFirstMeasurement ? (
          <Typography variant="body2">/</Typography>
        ) : null}
        <Box flex={isReadOnly ? undefined : 1}>
          <Controller
            control={control}
            name={`ingredients.${index}.measurements.1`}
            render={({ field }) => (
              <InputSelectCombo
                index={index + 1}
                order={2}
                {...field}
                label={intl.formatMessage({ id: 'recipes.ingredients.quantity.label' })}
                isReadOnly={isReadOnly}
              />
            )}
          />
        </Box>
      </Stack>

      <Box flex={1} display="flex" alignItems="center">
        <Controller
          control={control}
          name={`ingredients.${index}.notes`}
          render={({ field }) =>
            isReadOnly ? (
              <Typography
                variant="body2"
                sx={{ maxWidth: 200, wordBreak: 'break-word', ...lineClamp(2) }}
              >
                {field.value}
              </Typography>
            ) : (
              <TextField
                {...field}
                placeholder={intl.formatMessage({ id: 'recipes.ingredients.add.remarks' })}
                size="small"
                inputProps={{
                  maxLength: 255,
                  'data-testid': `ingredient${index + 1}-notes`,
                }}
                sx={{
                  '@media print': {
                    [`& .${autocompleteClasses.input}`]: { textOverflow: 'unset' },
                    [`& .${outlinedInputClasses.notchedOutline}`]: { border: 'none' },
                  },
                }}
              />
            )
          }
        />
      </Box>
    </Box>
  );
};

export default memo(IngredientItem);
