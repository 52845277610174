import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import Login from './Login';

export default createRoute({
  element: <Login />,
  path: Routes.Login,
});
