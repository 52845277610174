import { SortDirection } from '@mui/material';
import { ReactNode } from 'react';

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export enum Season {
  Winter = 'WINTER',
  Spring = 'SPRING',
  Summer = 'SUMMER',
  Autumn = 'AUTUMN',
}

/* Filters */
export type FilterOptions = { [key: string]: ReactNode };
export type FilterObject = {
  label: ReactNode;
  options: FilterOptions;
  amountSelectedLabel?: I18nKey;
};
export type FiltersObject<T extends string> = {
  [key in T]?: FilterObject;
};
export type ActiveFilters<T extends string> = {
  [key in T]: (string | number)[];
};

export type ToggleFilterFn<T> = (filterType: T, filterOption: string) => void;

// Keys mapping type
export type KeysMap<T> = {
  [key in T as string]: I18nKey;
};

/* Tables */
export type TableHeader<T> = {
  id: T;
  label: I18nKey;
  width?: string;
  sortable?: boolean;
  initialSort?: SortDirection;
};
export type FilterParams = {
  [key: string]: string[];
};

export type SaveOptions = {
  silent: boolean;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};
