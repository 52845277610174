import { List, ListItem } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { RecipeOnBrandnessRules, RecipeOnBrandnessRuleSet } from 'types/recipes.types';

import { InfoAlert } from 'components/@common';

type Props = {
  onBrandnessErrors: RecipeOnBrandnessRuleSet[];
  setShowExplanation: (show: boolean) => void;
};

const RecipePerformanceAlerts = ({ onBrandnessErrors, setShowExplanation }: Props) => {
  const [showOnBrandnessErrors, setShowOnBrandnessErrors] = useState(!!onBrandnessErrors?.length);

  useEffect(
    () => setShowOnBrandnessErrors(!!onBrandnessErrors?.length),
    [onBrandnessErrors?.length],
  );

  return (
    <InfoAlert
      isOpen={showOnBrandnessErrors}
      onClose={() => setShowOnBrandnessErrors(false)}
      onAction={() => setShowExplanation(true)}
      actionLabel={<FormattedMessage id="general.calculation.how.label" />}
    >
      <List sx={{ py: 0, display: 'flex', flexDirection: 'column', gap: 1 }}>
        {!!onBrandnessErrors &&
          onBrandnessErrors.map(({ rule, properties }) => {
            switch (rule) {
              case RecipeOnBrandnessRules.IngredientCount:
                return (
                  <ListItem key={rule} sx={{ display: 'list-item', py: 0 }}>
                    <FormattedMessage
                      id="recipes.props.onbrand.explanation.content.ingredient_amount.alert"
                      values={{
                        amount: Number(properties.ingredientRule?.rangeMin) - 1,
                        deduction: properties.ingredientRule?.deduction,
                        strong: (message: ReactNode) => <strong>{message}</strong>,
                      }}
                    />
                  </ListItem>
                );
              case RecipeOnBrandnessRules.MaterialCount:
                return (
                  <ListItem key={rule} sx={{ display: 'list-item', py: 0 }}>
                    <FormattedMessage
                      id="recipes.props.onbrand.explanation.content.materials.alert"
                      values={{
                        maxAmount: properties.allowedMaterialCount,
                        materials: properties.forbiddenMaterials?.join(', '),
                        amountToRemove:
                          Number(properties.forbiddenMaterials?.length) -
                          Number(properties.allowedMaterialCount),
                        strong: (message: ReactNode) => <strong>{message}</strong>,
                      }}
                    />
                  </ListItem>
                );
              case RecipeOnBrandnessRules.MepCount:
                return (
                  <ListItem key={rule} sx={{ display: 'list-item', py: 0 }}>
                    <FormattedMessage
                      id="recipes.props.onbrand.explanation.content.mep.alert"
                      values={{
                        amount: properties.mepRule?.rangeMin,
                        deduction: properties.mepRule?.deduction,
                        strong: (message: ReactNode) => <strong>{message}</strong>,
                      }}
                    />
                  </ListItem>
                );
              case RecipeOnBrandnessRules.ForbiddenWords:
                return (
                  <ListItem key={rule} sx={{ display: 'list-item', py: 0 }}>
                    <FormattedMessage
                      id="recipes.props.onbrand.words.blacklist.alert"
                      values={{
                        forbiddenAmount: properties.forbiddenWords?.length,
                        deduction: properties.keywordDeduction,
                        words: properties.forbiddenWords?.join(', '),
                        amount: properties.forbiddenWords?.length,
                        amountToRemove:
                          Number(properties.forbiddenMaterials?.length) -
                          Number(properties.allowedMaterialCount),
                        strong: (message: ReactNode) => <strong>{message}</strong>,
                      }}
                    />
                  </ListItem>
                );
            }
          })}
      </List>
    </InfoAlert>
  );
};

export default RecipePerformanceAlerts;
