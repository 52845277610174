import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { ApiResponse } from 'services/api/api.types';
import { useNotifications } from 'services/snackbar';
import { DeleteMenuItemParams, Menu } from 'types/menus.types';

const deleteMenuItem = async (params: DeleteMenuItemParams) => {
  const res = await apiService.deleteMenuItem(params);
  return res.data;
};

export const useDeleteMenuItem = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: deleteMenuItem,
    onMutate: async ({ menuId, id: menuItemId }) => {
      // Cancel any outgoing refetches so they don't overwrite our optimistic update
      await queryClient.cancelQueries({ queryKey: QueryKeys.menus.byId(menuId) });

      const queryData = queryClient.getQueriesData<ApiResponse<Menu>>({
        queryKey: QueryKeys.menus.byId(menuId),
        exact: true,
      });
      queryData.forEach(([key, data]) => {
        if (!!data) {
          queryClient.setQueryData<ApiResponse<Menu>>(key, {
            ...data,
            data: {
              ...data.data,
              menuItems: data.data.menuItems?.filter((menuItem) => menuItem.id !== menuItemId),
            },
          });
        }
      });

      return { previousData: queryData };
    },
    onSuccess: async () => {
      notifications.success({
        message: intl.formatMessage(
          { id: 'general.actions.delete.success' },
          {
            type: intl.formatMessage({ id: 'menus.items.label.singular' }),
          },
        ),
      });
    },
    onError: (err, _, context) => {
      if (context?.previousData) {
        // Optimistically update to the new value
        context.previousData.forEach(([key, data]) => {
          if (!!data) {
            queryClient.setQueryData(key, data);
          }
        });
      }

      console.error(err);
      notifications.error({
        message: intl.formatMessage(
          { id: 'general.actions.delete.error' },
          {
            type: intl.formatMessage({ id: 'menus.items.label.singular' }),
          },
        ),
      });
    },
  });

  return {
    deleteMenuItem: mutate,
    isPending,
  };
};
