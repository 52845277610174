import { useQuery } from '@tanstack/react-query';

import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { CommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { ApiResponse } from 'services/api/api.types';
import { MenuComplianceRuleSet } from 'types/menus.types';

const fetchMenuComplianceRules = async () => {
  const { data: complianceRules } = await apiService.getMenuComplianceRules();
  return complianceRules;
};

export const useMenuComplianceRules = (
  config: CommonQueryOptions<
    ApiResponse<MenuComplianceRuleSet>,
    ReturnType<typeof QueryKeys.menus.complianceRules>
  > = {},
) => {
  const { data, status, isLoading, error, isError } = useQuery({
    queryKey: QueryKeys.menus.complianceRules(),
    queryFn: fetchMenuComplianceRules,
    ...config,
    staleTime: config?.staleTime ?? Duration.FIVE_MIN,
  });

  return {
    data: data?.data,
    isError,
    status,
    isLoading,
    error,
  };
};
