import { Outlet } from 'react-router-dom';

import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import menuDetail from 'pages/menus/menu-detail';
import menuOverview from 'pages/menus/menu-overview';
import menuUpload from 'pages/menus/menu-upload';

export default createRoute({
  path: Routes.MenuOverview,
  Component: Outlet,
  children: [menuOverview, menuDetail, menuUpload],
});
