import { useQuery } from '@tanstack/react-query';

import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { CommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { ApiResponse } from 'services/api/api.types';
import { Recipe } from 'types/recipes.types';

const fetchRecipe = async (recipeId: Recipe['id']) => {
  const { data: recipe } = await apiService.getRecipe(recipeId);
  return recipe;
};

export const useRecipeById = (
  recipeId: Recipe['id'],
  config: CommonQueryOptions<ApiResponse<Recipe>, ReturnType<typeof QueryKeys.recipes.byId>> = {},
) => {
  const { data, status, isLoading, isFetching, error, isError, refetch } = useQuery({
    queryKey: QueryKeys.recipes.byId(recipeId),
    queryFn: () => fetchRecipe(recipeId),
    ...config,
    staleTime: config?.staleTime ?? Duration.FIVE_MIN,
  });

  return {
    data: data?.data,
    status,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  };
};
