import { Box, LinearProgress, Typography } from '@mui/material';

import { useGetOnBrandnessColor } from 'utils/recipe.utils';

interface Props {
  value: number;
}

const ActiveMenuCoverage = ({ value }: Props) => {
  const getOnBrandnessColor = useGetOnBrandnessColor();

  return (
    <Box color={getOnBrandnessColor(value)} position="relative">
      <LinearProgress
        value={value}
        variant="determinate"
        color="inherit"
        sx={{
          height: 28,
          borderRadius: 4,
          '&::before': { backgroundColor: 'grey.300', opacity: 1 },
        }}
      />
      <Box position="absolute" left={10} top={0} bottom={0} display="flex" alignItems="center">
        <Typography variant="chip" sx={{ color: (theme) => theme.palette.black[90] }}>
          {value}%
        </Typography>
      </Box>
    </Box>
  );
};

export default ActiveMenuCoverage;
