import { RecipeTypesWithCount } from 'types/insights.types';

export const getTotalExperimental = (experimental: RecipeTypesWithCount) => {
  return (
    experimental.rejected + experimental.draft + experimental.submitted + experimental.approved
  );
};

export const getExperimentalCounts = (
  experimental: RecipeTypesWithCount,
): { color: string; label: I18nKey; value: number }[] => {
  return [
    { color: 'warning.darker', label: 'recipes.status.draft', value: experimental.draft },
    { color: 'tertiary.main', label: 'recipes.status.submitted', value: experimental.submitted },
    { color: 'green.500', label: 'recipes.status.approved', value: experimental.approved },
    { color: '#FFC008', label: 'recipes.status.rejected', value: experimental.rejected },
  ];
};
