export const removeEmptyValuesFromParams = <T extends object>(params?: T) => {
  if (!params) return {};

  return (Object.keys(params) as (keyof T)[]).reduce<Record<string, string | boolean | number>>(
    (cleanParams, property) => {
      // Filter out: undefined, null and ''
      // this is because `0` will also be falsy, but, it is a valid value for a query param, for example: count=0
      if (
        params[property] !== '' &&
        params[property] !== null &&
        typeof params[property] !== 'undefined'
      ) {
        // @ts-expect-error property should be only strings, numbers or booleans
        cleanParams[property] = params[property] as string | boolean | number;
      }

      return cleanParams;
    },
    {},
  );
};
