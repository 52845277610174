import { AnimationProps } from 'framer-motion';

export const listItemAnimations: AnimationProps = {
  initial: { opacity: 1, height: 0 },
  animate: { opacity: 1, height: 'auto' },
  exit: { opacity: 1, height: 0 },
  transition: {
    type: 'spring',
    stiffness: 1000,
    mass: 1,
    damping: 100,
    staggerDirection: -1,
    duration: 200,
  },
};
