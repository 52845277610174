import { useInfiniteQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';
import { CommonInfiniteQueryOptions } from 'queries/types';
import { getInfiniteData } from 'queries/utils';
import { apiService } from 'services';
import { ApiResponse } from 'services/api/api.types';
import { GetMeasurementOptions, Measurement } from 'types/recipes.types';

const fetchMeasurements = async (options: GetMeasurementOptions) => {
  const { data: measurements } = await apiService.getMeasurements(options);
  return measurements;
};

export const useMeasurements = (
  params: GetMeasurementOptions,
  config?: CommonInfiniteQueryOptions<
    ApiResponse<Measurement[]>,
    ReturnType<typeof QueryKeys.measurements.all>,
    number
  >,
) => {
  const { search, page_size, sort } = params;
  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    isError,
    data,
    error,
  } = useInfiniteQuery({
    ...config,
    initialPageParam: 1,
    queryKey: QueryKeys.measurements.all(Object.values(params)),
    queryFn: ({ pageParam }) => {
      return fetchMeasurements({
        page: pageParam,
        page_size,
        search,
        sort,
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      return !!lastPage.links.next ? allPages.length + 1 : undefined;
    },
  });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    isError,
    error,
    ...getInfiniteData(data),
  };
};
