import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { StorageMediaContext } from 'types/media.types';

import { DropZone } from 'components/@form';
import { useIsInMenuItemDrawer } from 'components/@menu-form';

type Props = {
  title: ReactNode;
  name: string;
  storageContext: StorageMediaContext;
  recipeId: string;
  isReadOnly: boolean;
};
const RecipeFormDropzone = ({ title, name, storageContext, recipeId, isReadOnly }: Props) => {
  const isInMenuItemDrawer = useIsInMenuItemDrawer();

  const value = useWatch({ name });

  return (
    <Stack direction="column" flex={1} gap={1}>
      <Typography>{title}</Typography>

      {!value && isReadOnly ? (
        <Typography variant="body2" fontStyle="italic">
          <FormattedMessage id="recipes.image.empty" />
        </Typography>
      ) : (
        <Box
          width="full"
          bgcolor="bg.paper"
          borderRadius={(theme) => theme.mixins.borderRadius.base}
          overflow="hidden"
        >
          <DropZone
            name={name}
            testId={`recipe-form-dropzone-${name}`}
            storageContext={storageContext}
            storageFolder={recipeId}
            height={isInMenuItemDrawer ? 200 : 340}
            disabled={isReadOnly}
          />
        </Box>
      )}
    </Stack>
  );
};

export default RecipeFormDropzone;
