import { useMutation, useQueryClient } from '@tanstack/react-query';
import { capitalize } from 'lodash';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { UpdateMenuParams } from 'types/menus.types';

const putMenu = async (data: UpdateMenuParams & { silent: boolean }) => {
  const { data: menu } = await apiService.putMenu({
    ...data,
    year: Number(data.year),
  });
  return menu;
};

export const useUpdateMenu = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: putMenu,
    onSuccess: (_, { silent }) => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.menus.all() });

      if (!silent) {
        notifications.success({
          message: intl.formatMessage(
            { id: 'general.actions.save.success' },
            {
              type: capitalize(intl.formatMessage({ id: 'menus.label.singular' })),
            },
          ),
        });
      }
    },
    onError: (error, { silent }) => {
      console.error(error);
      if (!silent) {
        notifications.error({
          message: intl.formatMessage(
            { id: 'general.actions.save.error' },
            {
              type: capitalize(intl.formatMessage({ id: 'menus.label.singular' })),
            },
          ),
        });
      }
    },
  });

  return {
    updateMenu: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
