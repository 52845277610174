import type { User } from 'types/user.types';

import RestApiService from './';
import { ApiResponse } from './api.types';

export default {
  getUser(this: RestApiService) {
    return this.api.get<ApiResponse<User>>(`/users/me`);
  },
};
