import { Chip, chipClasses, styled, Typography } from '@mui/material';

import { useGetOnBrandnessColor } from 'utils/recipe.utils';

export const GridContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
  display: 'grid',
  gap: theme.spacing(3),
  gridTemplateColumns: 'repeat(4, 1fr)',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));
export const CountryChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  border: `none`,
  height: '1.5rem',
  ...theme.typography.buttonSmall,
  [`& .${chipClasses.label}`]: {
    paddingInline: theme.spacing(1),
  },
}));

export const OnBrandnessBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'imageHeight' && prop !== 'score',
})<{ imageHeight: number; score: number }>(({ imageHeight, score, theme }) => {
  const getOnBrandnessColor = useGetOnBrandnessColor();
  const BOX_HEIGHT = 55;
  return {
    backgroundColor: getOnBrandnessColor(score),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: BOX_HEIGHT,
    height: BOX_HEIGHT,
    borderRadius: theme.mixins.borderRadius.full,
    border: '4px solid',
    borderColor: theme.palette.white[100],
    position: 'absolute',
    right: theme.spacing(2.5),
    top: imageHeight - BOX_HEIGHT * (2 / 3),
    paddingBottom: theme.spacing(0.625),
    paddingTop: theme.spacing(0.5),
  };
});
export const OnBrandnessLabel = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(-0.25),
  fontWeight: 400,
  fontSize: '0.4375rem',
  lineHeight: '0.5125rem',
  color: theme.palette.black[60],
}));
