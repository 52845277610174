import { Box, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { memo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { seasonsMap } from 'constants/common.constants';
import { Country } from 'constants/countries.constants';
import { useIsHQ } from 'hooks/useIsHQ';
import { SaveOptions, Season } from 'types/common.types';
import { Menu } from 'types/menus.types';

import { CountryFlag } from 'components/@common';
import { ChipSelect, CountrySelect } from 'components/@form';
import { CoverageValues } from 'components/@menu-form/MenuForm/types';
import { TitleBox } from 'components/@recipe-form';

import { MenuActions, MenuCompliance, MenuCoverage } from './components';
import MenuComplianceAlerts from './components/MenuComplianceAlerts';
import MenuComplianceDialog from './components/MenuComplianceDialog';

const YEARS_SHOWN = 9;
const YEARS_AHEAD = 3;

type Props = {
  menu: Menu;
  isReadOnly: boolean;
  isSaving: boolean;
  isProcessing: boolean;
  isError: boolean;
  coverageValues: CoverageValues;
  disabled: boolean;
  onSave: (options: SaveOptions) => void;
  setIsReadOnly: (isReadOnly: boolean) => void;
};

const MenuMainInfo = ({
  menu,
  isSaving,
  isReadOnly,
  isProcessing,
  isError,
  coverageValues,
  disabled,
  onSave,
  setIsReadOnly,
}: Props) => {
  const intl = useIntl();
  const isHQ = useIsHQ();
  const { control } = useFormContext<Menu>();
  const country = useWatch<Menu>({ name: 'country' }) as Country;
  const name = useWatch<Menu>({ name: 'name' }) as string;

  const [showHowWeCalculateDialog, setShowHowWeCalculateDialog] = useState(false);

  return (
    <Stack width="100%" gap={0} height="fit-content">
      <TitleBox>
        {isReadOnly ? (
          <Stack pr={1} gap={1.5} direction="row" alignItems="center" width="100%" mt="auto">
            <Typography variant="h4">{name}</Typography>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={1}>
              <Typography className="print-only">
                <FormattedMessage id="general.country.label" />:
              </Typography>
              <CountryFlag country={country} size={32} />
            </Stack>
          </Stack>
        ) : (
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField
                className="recipe-title"
                variant="filled"
                inputProps={{
                  maxLength: 255,
                  onFocus: (event) => event.target.select(),
                }}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isHQ && !isReadOnly ? (
                        <CountrySelect />
                      ) : (
                        <Box px={1}>
                          <CountryFlag country={country} size={32} />
                        </Box>
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder={intl.formatMessage({ id: 'menus.input.name' })}
              />
            )}
          />
        )}
        <MenuActions
          menu={menu}
          isReadOnly={isReadOnly}
          isSaving={isSaving}
          isProcessing={isProcessing}
          disabled={disabled}
          setIsReadOnly={setIsReadOnly}
          saveMenu={onSave}
        />
      </TitleBox>

      <Stack direction="row" alignItems="center" gap={1}>
        <Controller
          control={control}
          name="year"
          render={({ field: { onChange, value } }) => (
            <ChipSelect
              label={<FormattedMessage id="menus.year.label" />}
              readOnly={isReadOnly}
              options={Array.from({ length: YEARS_SHOWN }).map((_, index) => {
                const yearBefore = new Date().getFullYear() + YEARS_AHEAD - index;
                return {
                  value: String(yearBefore),
                  label: String(yearBefore),
                };
              })}
              selected={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="seasons"
          render={({ field: { onChange, value } }) => (
            <ChipSelect
              label={<FormattedMessage id="general.seasons.label" />}
              readOnly={isReadOnly}
              options={Object.values(Season).map((value) => ({
                value: value,
                label: intl.formatMessage({ id: seasonsMap[value] || value }),
              }))}
              selected={value ?? ([] as Season[])}
              keyMap={seasonsMap}
              onChange={onChange}
            />
          )}
        />
      </Stack>

      <Stack gap={1} mt={3} flex={1} maxWidth="100%">
        <Stack
          gap={3}
          direction="row"
          sx={(theme) => ({ [theme.breakpoints.down('md')]: { flexDirection: 'column-reverse' } })}
        >
          <MenuCompliance
            menu={menu}
            isProcessing={isProcessing}
            compliancePercent={menu.complianceScore}
            setShowExplanation={setShowHowWeCalculateDialog}
            showExplanation={showHowWeCalculateDialog}
            isCalculating={isSaving}
            isError={isError}
          />
          <MenuCoverage isProcessing={isProcessing} coverageValues={coverageValues} />
        </Stack>
        <MenuComplianceAlerts
          menu={menu}
          isProcessing={isProcessing}
          setShowHowWeCalculateDialog={setShowHowWeCalculateDialog}
        />
      </Stack>

      <MenuComplianceDialog
        isOpen={showHowWeCalculateDialog}
        onClose={() => setShowHowWeCalculateDialog(false)}
      />
    </Stack>
  );
};

export default memo(MenuMainInfo);
