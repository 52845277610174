import {
  Components,
  filledInputClasses,
  formHelperTextClasses,
  inputBaseClasses,
  Theme,
} from '@mui/material';

export const MuiTextField: Components<Theme>['MuiTextField'] = {
  defaultProps: {
    variant: 'outlined',
    fullWidth: true,
    InputLabelProps: {
      color: 'primary',
      sx: (theme) => ({
        color: theme.palette.text.primary,
        transform: `translate(0, 0)`,
        position: 'relative',
        ...theme.typography.body2,
        ['&.Mui-error']: {
          ['&.Mui-focused']: {
            color: theme.palette.error.dark,
          },
        },
        ['&.Mui-focused']: {
          color: theme.palette.text.primary,
        },
      }),
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.bg.paper,
      borderRadius: theme.mixins.borderRadius.lg,
      gap: theme.spacing(0.5),
      '& legend': {
        display: 'none',
      },
      '& fieldset': {
        top: 0,
      },
      [`& .${inputBaseClasses.multiline}`]: {
        padding: 0,
      },
      [`& .${formHelperTextClasses.root}`]: {
        ['&.Mui-error']: {
          color: theme.palette.error.dark,
        },
        marginInline: 0,
      },
      multiline: {},
    }),
  },
  variants: [
    {
      props: { variant: 'standard' },
      style: ({ theme }) => ({
        boxShadow: theme.shadows[2],
      }),
    },
    {
      props: {
        variant: 'filled',
      },
      style: ({ theme }) => ({
        backgroundColor: 'transparent',
        boxShadow: 'none',
        [`& .${filledInputClasses.root}`]: {
          paddingInline: '0 !important',
          backgroundColor: 'transparent',
          ...theme.typography.h4,
        },
        [`& .${filledInputClasses.input}`]: {
          paddingTop: theme.spacing(2),
        },
      }),
    },
  ],
};
