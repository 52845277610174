import { Box, Collapse } from '@mui/material';
import { useIntl } from 'react-intl';

import { useIsHQ } from 'hooks/useIsHQ';
import { useRecipes } from 'queries';
import Notification from 'services/snackbar/Notification';
import { NotificationTypes } from 'services/snackbar/snackbar.types';
import { RecipeStatus } from 'types/recipes.types';

type Props = {
  onClick: () => void;
};

const RecipeAttentionBanner = ({ onClick }: Props) => {
  const intl = useIntl();
  const isHQ = useIsHQ();

  const { total } = useRecipes({ status: [RecipeStatus.Submitted] }, { enabled: isHQ });

  if (!isHQ) return null;

  return (
    <Collapse in={total > 0}>
      <Notification
        message={intl.formatMessage({ id: 'recipes.attention_needed.title' })}
        cta={intl.formatMessage({ id: 'recipes.attention_needed.cta' })}
        customIcon={
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="warning.darker"
            borderRadius="100%"
            width="24px"
            height="24px"
            color="text.secondary"
            fontSize="12px"
          >
            {total}
          </Box>
        }
        type={NotificationTypes.Warning}
        onClick={onClick}
        withClose={false}
      />
    </Collapse>
  );
};

export default RecipeAttentionBanner;
