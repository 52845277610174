import '/node_modules/flag-icons/css/flag-icons.min.css';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { runInDev, runInProd } from 'utils/env.utils';
import registerErrorOverlay from 'utils/vite-error-overlay';

import { initSentry } from 'services/sentry';

import App from './App';

runInProd(() => initSentry());

runInDev(() => {
  registerErrorOverlay();

  // Enable to start mocking!
  // const { worker } = require('./mocks/browser');
  // worker.start({ onUnhandledRequest: 'bypass' });
});
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
