import { Box, Typography } from '@mui/material';
import { maxBy } from 'lodash';
import { memo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { PreparationStep, RecipeFormValues } from 'types/recipes.types';

import ErrorBoundary from 'components/@boundaries/ErrorBoundary';
import { SortableGrid } from 'components/@common';

import { PreparationItem } from './PreparationItem';

type Props = {
  recipeId: number;
  isReadOnly: boolean;
};

const RecipeFormPreparation = ({ recipeId, isReadOnly }: Props) => {
  const {
    fields: items,
    append,
    remove,
    move,
  } = useFieldArray<RecipeFormValues>({
    name: 'preparationSteps',
  });

  const isEmpty =
    isReadOnly && (items as PreparationStep[]).every((item) => !item.description && !item.image);

  return (
    <ErrorBoundary boundary="recipe-preparation-steps">
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        sx={{ '@media print': { pageBreakInside: 'avoid' } }}
      >
        <Typography>
          <FormattedMessage id="recipes.preparation.label" />
        </Typography>

        <Box
          width="full"
          bgcolor="bg.paper"
          overflow="hidden"
          borderRadius={(theme) => theme.mixins.borderRadius.base}
          px={4}
          pt={!isEmpty ? 4 : 0}
          pb={!isEmpty ? 3.5 : 0}
          sx={{ '@media print': { p: 0 } }}
        >
          <SortableGrid
            items={items}
            addButtonTestId="add-preparation-step-button"
            placeholder="recipes.preparation.steps.empty"
            isEmpty={isEmpty}
            isReadOnly={isReadOnly}
            renderItem={(index) => (
              <PreparationItem
                recipeId={recipeId}
                key={index}
                index={index}
                isReadOnly={isReadOnly}
              />
            )}
            buttonText={<FormattedMessage id="recipes.preparation.steps.add" />}
            onAddItem={() =>
              append({
                order: (maxBy(items as PreparationStep[], 'order')?.order || items.length) + 1,
                image: '',
                description: '',
              })
            }
            onRemoveItem={remove}
            onMoveItem={move}
          />
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default memo(RecipeFormPreparation);
