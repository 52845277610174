import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';

import useIsOffline from 'hooks/useIsOffline';
import { sentryCreateBrowserRouter } from 'services/sentry';

import { routes } from './routes';

const router = sentryCreateBrowserRouter([routes]);

const Pages = () => {
  useIsOffline();

  return (
    <Suspense>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Pages;
