import { Box, Button, Chip, MenuItem, Stack, Typography } from '@mui/material';
import { InView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';

import { highlightText } from 'utils/text.utils';

import { Recipe, RecipeAutocompleteOption, RecipeRequirement } from 'types/recipes.types';

import { CountryFlag } from 'components/@common';

type Props = {
  allOptions: RecipeAutocompleteOption[];
  option: RecipeAutocompleteOption;
  inputValue: string;
  index: number;
  onChange: (recipe: RecipeAutocompleteOption) => void;
  onOverwriteFromRecipe: (recipeId: Recipe['id']) => void;
  onGetNextOptions: (inView: boolean) => void;
};

const AutocompleteOption = ({
  allOptions,
  option,
  inputValue,
  index,
  onChange,
  onOverwriteFromRecipe,
  onGetNextOptions,
}: Props) => {
  if (!option.name.toLowerCase().includes(inputValue.toLowerCase())) return;

  return (
    <>
      <MenuItem
        key={option.id || option.name}
        sx={{
          px: 2,
          py: 1.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '100%',
        }}
        onClick={() =>
          onChange(allOptions.find((value) => value.id === option.id) as RecipeAutocompleteOption)
        }
      >
        <Typography overflow="wrap">{highlightText(inputValue, option.name)}</Typography>
        <Stack direction="row" justifyItems="flex-end" gap={2}>
          <Button
            variant="text"
            color="inherit"
            sx={(theme) => ({
              textTransform: 'uppercase',
              ...theme.typography.buttonSmall,
              px: 1,
            })}
            onClick={(e) => {
              e.stopPropagation();
              onOverwriteFromRecipe(option.id || 0);
            }}
          >
            <FormattedMessage id="recipes.create.copy" />
          </Button>

          <Box width="42px" display="flex" justifyContent="center" alignItems="center">
            {option.requirementType === RecipeRequirement.Core ? (
              <Chip variant="labelDark" label={<FormattedMessage id="base.all" />} />
            ) : (
              option.country && <CountryFlag country={option.country} />
            )}
          </Box>
        </Stack>
      </MenuItem>
      {index === allOptions.length - 1 && (
        <InView as="div" onChange={(inView) => onGetNextOptions(inView)} />
      )}
    </>
  );
};

export default AutocompleteOption;
