import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import MenuUpload from './MenuUpload';

export default createRoute({
  element: <MenuUpload />,
  path: Routes.MenuUpload,
  authenticated: true,
});
