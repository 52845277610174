import {
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  HighlightOff as HighlightOffIcon,
  MoreHoriz as MoreHorizIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { cloneDeep, isEqual } from 'lodash';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { RelativeTime } from 'utils/datetime.utils';

type Props = {
  isError: boolean;
  isPending: boolean;
  savedAt: Date;
  handleSave: (successCallback?: () => void) => void;
};

const AUTOSAVE_INTERVAL = 2_000;

const AutosaveState = ({ isError, isPending, savedAt, handleSave }: Props) => {
  const { formState, getValues } = useFormContext();

  const lastFormValues = useRef(cloneDeep(getValues()));

  useEffect(() => {
    const interval = setInterval(() => {
      // if the focused element is an input or textarea, we won't save
      const focusedElement = document.activeElement;

      if (focusedElement && ['INPUT', 'TEXTAREA'].includes(focusedElement.tagName)) return;

      const isDirty = !isEqual(lastFormValues.current, getValues());
      // if there are any dirty fields, we trigger a save
      if (isDirty) {
        handleSave(() => (lastFormValues.current = cloneDeep(getValues())));
      }
    }, AUTOSAVE_INTERVAL);

    return () => clearInterval(interval);
  }, [handleSave, getValues, formState.isDirty]);

  const AutosaveIcon = () => {
    if (isPending) return <MoreHorizIcon fontSize="small" />;
    if (isError) return <HighlightOffIcon fontSize="small" sx={{ color: 'error.main' }} />;

    return <CheckCircleOutlinedIcon fontSize="small" sx={{ color: 'text.disabled' }} />;
  };

  const AutosaveLabel = () => {
    if (isPending) return <FormattedMessage id="general.autosave.saving" />;
    if (isError) return <FormattedMessage id="general.autosave.failed" />;
    return <FormattedMessage id="general.autosave.saved" />;
  };

  return (
    <Box display="flex" gap={1} alignItems="center">
      <AutosaveIcon />
      <Box display="flex" flexDirection="column" justifyContent="center" height="50px">
        <Typography variant="caption">
          <AutosaveLabel />
        </Typography>

        <AutoSaveText
          isError={isError}
          isPending={isPending}
          handleSave={handleSave}
          savedAt={savedAt}
        />
      </Box>
    </Box>
  );
};

type AutoSaveTextProps = {
  isError: boolean;
  isPending: boolean;
  savedAt: Date;
  handleSave: () => void;
};

const AutoSaveText = ({ isError, isPending, savedAt, handleSave }: AutoSaveTextProps) => {
  if (isPending) {
    return (
      <Typography variant="tooltip" mt={-0.5}>
        <FormattedMessage id="general.autosave.saving.text" />
      </Typography>
    );
  }

  if (isError) {
    return (
      <LoadingButton variant="contained" sx={{ py: 0, px: 0.5, gap: 1 }} onClick={handleSave}>
        <RefreshIcon sx={{ width: 18, mr: '-2px' }} />
        <Typography variant="buttonSmall" textTransform="none" mb={0.2}>
          <FormattedMessage id="general.autosave.failed.text" />
        </Typography>
      </LoadingButton>
    );
  }

  return (
    <Typography variant="tooltip" mt={-0.5}>
      <RelativeTime>{savedAt}</RelativeTime>
    </Typography>
  );
};

export default AutosaveState;
