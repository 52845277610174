import { useQuery } from '@tanstack/react-query';

import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { CommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { ApiResponse } from 'services/api/api.types';
import { Menu, MenuProcessingStatus } from 'types/menus.types';

const fetchMenuStatus = async (menuId: Menu['id']) => {
  const { data: menuStatus } = await apiService.getMenuStatus(menuId);
  return menuStatus;
};

export const useMenuStatus = (
  menuId: Menu['id'],
  config: CommonQueryOptions<
    ApiResponse<MenuProcessingStatus>,
    ReturnType<typeof QueryKeys.menus.status>
  > = {},
) => {
  const { data, isLoading, error } = useQuery({
    queryKey: QueryKeys.menus.status(menuId),
    queryFn: () => fetchMenuStatus(menuId),
    ...config,
    staleTime: config?.staleTime ?? Duration.FIVE_MIN,
  });

  return {
    menuStatus: data?.data,
    isLoading,
    error,
  };
};
