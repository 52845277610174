import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Amplify } from 'aws-amplify';
import { HelmetProvider } from 'react-helmet-async';

import config from 'config/env';

import { ChatProvider } from 'services/ai-chat';
import IntlProvider from 'services/i18n';
import { queryClient } from 'services/react-query';
import { SnackbarProvider } from 'services/snackbar';
import theme from 'theme';

import Pages from 'pages/Pages';

import { RootBoundary } from 'components/@boundaries';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: config.COGNITO_USER_POOL_ID,
      userPoolClientId: config.COGNITO_CLIENT_ID,
    },
  },
});

const App = () => (
  <RootBoundary>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <IntlProvider>
          <ChatProvider>
            <SnackbarProvider>
              <HelmetProvider>
                <Pages />
              </HelmetProvider>
            </SnackbarProvider>
          </ChatProvider>
        </IntlProvider>
      </ThemeProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </RootBoundary>
);

export default App;
