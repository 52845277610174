import { Components, Theme } from '@mui/material';

import { fonts } from '../fonts';
import printStyles from './print.styles';

export const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: `
  ${fonts}

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    -webkit-overflow-scrolling: touch;
  }

  html {
    height: 100svh;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
    font-family: "Roboto", sans-serif;
    color: #222;
    font-weight: 100;
    font-size: 16px;
    line-height: 1.5;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  body {
    overflow-x: clip;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #FBF5EE;
    padding: 0 !important;
  }
  #root {
    background-color: #FBF5EE;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
  }

  a {
    color: #293735;
    text-decoration: none;
  }

  em {
    font-style: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: inherit;
  }

  button {
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    border-radius: 12px;
    box-shadow: inset 0 0 10px 10px transparent;
    border: solid 3px transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    box-shadow: inset 0 0 10px 10px #dbdbdb;
    border: solid 3px transparent;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  /*
  * Disable webkit input type search styles
  */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  /*
  * Remove text-shadow in selection highlight:
  * https://twitter.com/miketaylr/status/12228805301
  *
  * These selection rule sets have to be separate.
  * Customize the background color to match your design.
  */

  ::-moz-selection {
    color: #000000;
    background: #FDC40030;
    text-shadow: none;
  }

  ::selection {
    color: #000000;
    background: #FDC40030;
    text-shadow: none;
  }

  /*
  * A better looking default horizontal rule
  */

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
  }

  /*
  * Remove the gap between audio, canvas, iframes,
  * images, videos and the bottom of their containers:
  * https://github.com/h5bp/html5-boilerplate/issues/440
  */

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  /*
  * Remove default fieldset styles.
  */

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  /*
  * Allow only vertical resizing of textareas.
  */

  textarea {
    resize: vertical;
  }

  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  /*
  * Browser upgrade prompt
  * ========================================================================== */

  :global(.browserupgrade) {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
  }
  ${printStyles}
  `,
};
