import { Stack } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { Outlet, ScrollRestoration, useLocation, useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useMedia } from 'hooks';
import PostHogProvider from 'services/posthog/posthog';

import ErrorBoundary from 'components/@boundaries/ErrorBoundary';
import { ChatPopper } from 'components/@chatbox';
import Sidebar from 'components/@navigations/Sidebar';

const PAGES_WITHOUT_LAYOUT = [Routes.Login];
const PAGES_WITHOUT_ALAIN = [Routes.Login, Routes.Root];
const PAGES_WITH_SCROLL_RESTORATION = [
  Routes.MenuOverview,
  Routes.Cookbook,
  Routes.ExperimentalKitchen,
];

const Layout = () => {
  const { sm } = useMedia();
  const posthog = usePostHog();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/') {
      return navigate(Routes.Root, { replace: true });
    }

    // capture pageview posthog event on page change
    posthog.capture('$pageview');
  }, [navigate, pathname, posthog]);

  useEffect(() => {
    if (state?.resetScroll) {
      window.scrollTo(0, 0);
    }
  }, [pathname, state?.resetScroll]);

  if (PAGES_WITHOUT_LAYOUT.includes(pathname as Routes)) {
    return <Outlet />;
  }

  const hideAlain = PAGES_WITHOUT_ALAIN.includes(pathname as Routes);

  const horizontalPadding = sm ? 1.5 : 3;

  return (
    <ErrorBoundary boundary="layout">
      <ScrollRestoration
        getKey={(location) =>
          PAGES_WITH_SCROLL_RESTORATION.includes(location.pathname as Routes)
            ? location.pathname
            : location.key
        }
        storageKey="session-storage"
      />
      <PostHogProvider>
        <Stack direction="row" width="100%" height="100%" maxWidth="100svw">
          <Sidebar />

          <Stack
            component="main"
            mx="auto"
            flex={1}
            my={8}
            pl={(theme) =>
              `calc(${theme.mixins.sidebarWidth}px + ${theme.spacing(horizontalPadding)})`
            }
            pr={horizontalPadding}
            width="100%"
            maxWidth={(theme) => theme.breakpoints.values.lg}
          >
            <Outlet />
          </Stack>

          {!hideAlain && <ChatPopper />}
        </Stack>
      </PostHogProvider>
    </ErrorBoundary>
  );
};

export default Layout;
