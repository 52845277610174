import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { ChatbotUIContext } from 'services/ai-chat';

export const useChatScrollAnchor = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const visibilityRef = useRef<HTMLDivElement>(null);
  const [isAtBottom, setIsAtBottom] = useState(true);

  const { isGenerating, firstTokenReceived } = useContext(ChatbotUIContext);

  const scrollToBottom = useCallback(() => {
    visibilityRef.current &&
      visibilityRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, [visibilityRef]);

  useEffect(() => {
    (firstTokenReceived || isGenerating) && scrollToBottom();
  }, [firstTokenReceived, isGenerating, scrollToBottom, visibilityRef]);

  useEffect(() => {
    const { current } = scrollRef;
    if (current) {
      const handleScroll = (event: Event) => {
        const target = event.target as HTMLDivElement;
        const offset = 25;
        const isAtBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - offset;

        setIsAtBottom(isAtBottom);
      };

      current.addEventListener('scroll', handleScroll, { passive: true });
      return () => current.removeEventListener('scroll', handleScroll);
    }
  }, [scrollRef]);

  return {
    scrollToBottom,
    isAtBottom,
    scrollRef,
    visibilityRef,
  };
};
