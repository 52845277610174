import {
  CheckCircleRounded as CheckCircleRoundedIcon,
  LinkOff as LinkOffIcon,
  SentimentDissatisfied as SadIcon,
  SwapHoriz as SwapHorizIcon,
} from '@mui/icons-material';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { useRecipeById } from 'queries';
import { MenuItem } from 'types/menus.types';

import { Image } from 'components/@common';
import {
  CreateRecipeButton,
  PickRecipeButton,
  PurchasedItemButton,
} from 'components/@menu-form/MenuItemDrawer/ActionButtons';
import { RecipeForm, RecipeSkeleton } from 'components/@recipe-form';

interface Props {
  menuItem: MenuItem;
  disabled: boolean;
  isUpdatingMenuItem: boolean;
  isCreatingRecipe: boolean;
  isCreatingPurchasedItem: boolean;
  setIsPickingRecipe: (value: boolean) => void;
  handleUnlinkRecipe: () => void;
  handleSearchRecipe: () => void;
  handleCreateRecipe: () => void;
  handlePurchasedItem: () => void;
}

const MenuItemRecipe = ({
  menuItem,
  disabled,
  isUpdatingMenuItem,
  isCreatingRecipe,
  isCreatingPurchasedItem,
  handleSearchRecipe,
  handleCreateRecipe,
  handlePurchasedItem,
  setIsPickingRecipe,
  handleUnlinkRecipe,
}: Props) => {
  const {
    data: recipe,
    isLoading,
    isError,
  } = useRecipeById(Number(menuItem.recipe?.id), {
    enabled: !!menuItem.recipe?.id,
  });

  // if we couldn't fetch the recipe or the user doesn't have access to it, we shouldn't block them from doing actions on the menu item
  if (!menuItem.recipe || isError)
    return (
      <>
        <SadIcon sx={{ height: '56px', width: '56px', color: 'bluegrey.200' }} />
        <Stack mt={3} gap={1.5} alignItems="center">
          <Typography variant="body1">
            <FormattedMessage id="menus.matcher.recipe_error.title" />
          </Typography>
          <Typography variant="caption" sx={{ color: (theme) => theme.palette.bluegrey[500] }}>
            <FormattedMessage id="menus.matcher.recipe_error.description" />
          </Typography>
        </Stack>

        <Stack direction="row" gap={2} mt={6}>
          <PickRecipeButton disabled={isLoading} onClick={handleSearchRecipe} />
          <CreateRecipeButton
            loading={isCreatingRecipe}
            disabled={isLoading}
            onClick={handleCreateRecipe}
          />
          <PurchasedItemButton
            loading={isCreatingPurchasedItem}
            disabled={isLoading}
            onClick={handlePurchasedItem}
          />
        </Stack>
      </>
    );

  return (
    <>
      {isLoading && (
        <Box>
          <Skeleton height={312} width="100%" sx={{ mb: 3 }} />
          <Box p={3} sx={{ transform: 'scaleX(0.75)' }}>
            <RecipeSkeleton />
          </Box>
        </Box>
      )}
      {!isLoading && !!recipe && (
        <Stack width="100%">
          <Box width="100%" height="312px" position="relative">
            <Image src={recipe.batchImage} objectFit="cover" />
            <Stack
              position="absolute"
              py={1}
              pl={2}
              pr={1}
              bottom={0}
              width="100%"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              bgcolor="black.50"
              sx={{ backdropFilter: 'blur(8.5px)' }}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <CheckCircleRoundedIcon color="success" />
                <Typography variant="body1" sx={{ color: (theme) => theme.palette.white[100] }}>
                  <FormattedMessage id="menus.items.linked_recipe" />
                </Typography>
              </Stack>

              {!disabled && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Button
                    variant="contained"
                    disabled={isUpdatingMenuItem}
                    sx={{
                      color: 'white.100',
                      borderRadius: 1,
                      bgcolor: 'black.60',
                      '&:hover': { bgcolor: 'black.40' },
                    }}
                    startIcon={<SwapHorizIcon sx={{ color: 'white.100' }} />}
                    onClick={() => setIsPickingRecipe(true)}
                  >
                    <FormattedMessage id="menus.items.swap" />
                  </Button>
                  <Button
                    variant="contained"
                    disabled={isUpdatingMenuItem}
                    sx={{
                      color: 'white.100',
                      borderRadius: 1,
                      bgcolor: 'black.60',
                      '&:hover': { bgcolor: 'black.40' },
                    }}
                    startIcon={<LinkOffIcon sx={{ color: 'white.100' }} />}
                    onClick={handleUnlinkRecipe}
                  >
                    <FormattedMessage id="menus.items.detach" />
                  </Button>
                </Stack>
              )}
            </Stack>
          </Box>

          <Box p={3}>
            <RecipeForm initialRecipe={recipe} disableActions />
          </Box>
        </Stack>
      )}
    </>
  );
};

export default MenuItemRecipe;
