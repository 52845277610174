import { Box } from '@mui/material';
import { ReactNode } from 'react';

/**
 * Clamp lines of text with ellipsis on overflow (as style mixin)
 *
 * @param {number} lines Number of lines to clamp
 * @returns {object} Style object
 */
export const lineClamp = (lines: number) => ({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: lines,
});

/**
 * Checks for matching text and highlights it
 *
 * @param {string} textToHighlight Text to highlight
 * @param {string} text Text to search for
 * @returns {ReactNode} ReactNode
 */
export const highlightText = (textToHighlight: string, text: string): ReactNode => {
  if (!text.toLowerCase().includes(textToHighlight.toLowerCase())) return text;

  const textBefore = text.substring(0, text.toLowerCase().indexOf(textToHighlight.toLowerCase()));
  const textHighlight = text.substring(
    textBefore.length,
    textBefore.length + textToHighlight.length,
  );
  const textAfter = text.substring(textBefore.length + textToHighlight.length);
  return (
    <>
      <Box component="span">{textBefore}</Box>
      <Box component="span" fontWeight="bold">
        {textHighlight}
      </Box>
      <Box component="span">{textAfter}</Box>
    </>
  );
};
