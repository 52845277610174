import { FormattedMessage } from 'react-intl';
import { TransitionGroup } from 'react-transition-group';

import { useTableFilters } from 'hooks/useTableFilters';
import { ActiveFilters, Season } from 'types/common.types';
import { MenuFilters, MenuFiltersObject, MenuStatus } from 'types/menus.types';

import { FilterSelect } from 'components/@form';
import { useCountryFilterOptions } from 'components/@form/FilterSelect/hooks';

type Props = {
  filters: ActiveFilters<MenuFilters>;
  enableCountryFilter?: boolean;
  setFilters: (newFilters: ActiveFilters<MenuFilters>) => void;
};

const MenuFiltersList = ({ filters, setFilters }: Props) => {
  const countryFilterOptions = useCountryFilterOptions({ withAllCountries: false });
  const { activeFilters, toggleFilter } = useTableFilters({
    name: 'menu',
    filters,
    setFilters,
    save: true,
  });

  const baseFilters: MenuFiltersObject = {
    [MenuFilters.year]: {
      label: <FormattedMessage id="menus.year.label" />,
      amountSelectedLabel: 'menus.year.amount_selected',
      options: [
        new Date().getFullYear().toString(),
        (new Date().getFullYear() - 1).toString(),
        (new Date().getFullYear() - 2).toString(),
      ].reduce((acc, key) => ({ ...acc, [key]: key }), {}),
    },
    [MenuFilters.season]: {
      label: <FormattedMessage id="general.seasons.label" />,
      amountSelectedLabel: 'general.seasons.amount_selected',
      options: {
        [Season.Winter]: <FormattedMessage id="general.seasons.winter" />,
        [Season.Spring]: <FormattedMessage id="general.seasons.spring" />,
        [Season.Summer]: <FormattedMessage id="general.seasons.summer" />,
        [Season.Autumn]: <FormattedMessage id="general.seasons.autumn" />,
      },
    },
    [MenuFilters.status]: {
      label: <FormattedMessage id="general.status.label" />,
      amountSelectedLabel: 'general.status.amount_selected',
      options: {
        [MenuStatus.Draft]: <FormattedMessage id="menus.status.draft" />,
        [MenuStatus.Active]: <FormattedMessage id="menus.status.active" />,
        [MenuStatus.Archived]: <FormattedMessage id="menus.status.archived" />,
      },
    },
    [MenuFilters.country]: {
      label: <FormattedMessage id="general.country.label" />,
      amountSelectedLabel: 'countries.amount_selected',
      options: countryFilterOptions,
    },
  };

  return (
    <TransitionGroup style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
      {Object.keys(baseFilters).map((filterKey) => {
        const filter = baseFilters[filterKey as MenuFilters];
        return (
          <FilterSelect
            activeFilters={activeFilters}
            filter={filterKey as MenuFilters}
            key={filterKey}
            label={filter?.label}
            options={filter?.options || {}}
            amountSelectedKey={filter?.amountSelectedLabel}
            updateFilters={toggleFilter}
          />
        );
      })}
    </TransitionGroup>
  );
};

export default MenuFiltersList;
