import {
  autocompleteClasses,
  FormControl,
  InputAdornment,
  inputAdornmentClasses,
  inputBaseClasses,
  OutlinedInput,
  outlinedInputClasses,
  Stack,
  Typography,
} from '@mui/material';

import { lineClamp } from 'utils/text.utils';

import { RecipeFormMeasurement } from 'types/recipes.types';

import { MeasurementAutocomplete } from 'components/@recipe-form';

type Props = {
  label: string;
  name: string;
  value: RecipeFormMeasurement;
  onChange: (newValue: RecipeFormMeasurement) => void;
  onBlur?: () => void;
  isReadOnly?: boolean;
  index: number;
  order?: number;
};

const InputSelectCombo = ({
  label,
  name,
  value = { quantity: '', unit: '', id: 0, recipeIngredientId: 0 },
  onChange,
  onBlur,
  isReadOnly,
  index,
  order,
}: Props) => {
  if (isReadOnly) {
    if (!value) return null;

    return (
      <Stack
        direction="row"
        gap={0.5}
        height="100%"
        alignItems="center"
        minWidth={80}
        maxWidth={200}
        sx={lineClamp(2)}
      >
        <Typography variant="body2">
          {value.quantity} {value.unit}
        </Typography>
      </Stack>
    );
  }

  return (
    <FormControl
      size="small"
      sx={{ p: 0, minWidth: 'fit-content', maxWidth: 60 }}
      variant="outlined"
    >
      <OutlinedInput
        inputProps={{
          maxLength: 255,
          'data-testid': `ingredient${index}-quantity${order}`,
        }}
        name={name}
        label={label}
        value={value.quantity}
        onBlur={isReadOnly ? undefined : onBlur}
        onChange={(e) => onChange({ ...value, quantity: e.target.value })}
        sx={{
          p: 0,
          [`& .${outlinedInputClasses.notchedOutline} legend`]: { width: 0 },
          [`& .${inputAdornmentClasses.root}`]: { height: 32, mx: 0.5 },
          [`& .${inputBaseClasses.root}`]: { p: 0.5 },
          '@media print': {
            [`& .${autocompleteClasses.input}`]: { textOverflow: 'unset' },
            [`& .${outlinedInputClasses.notchedOutline}`]: { border: 'none' },
          },
        }}
        placeholder={label}
        onFocus={(event) => event.target.select()}
        endAdornment={
          <InputAdornment position="end">
            <MeasurementAutocomplete
              testId={`ingredient${index}-unit${order}`}
              value={{ unit: value.unit, id: value.id }}
              onChange={(newValue) => onChange({ ...value, ...newValue })}
            />
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default InputSelectCombo;
