import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { errorMessageFor } from 'utils/form.utils';

import { useSubmitMfa } from 'queries';
import { CognitoError, CognitoErrors, LoginState, MFASetupValues } from 'types/auth.types';

interface Props {
  onLoginStateChange: (state: LoginState) => void;
}

const MessageByErrorCode = ({ error }: { error: CognitoError }) => {
  switch (error.name) {
    case CognitoErrors.CodeMismatchException:
    case CognitoErrors.InvalidParameterException:
    case CognitoErrors.EnableSoftwareTokenMFAException: {
      return <FormattedMessage id="error.mfa.expired" />;
    }
    case CognitoErrors.ExpiredCodeException: {
      return <FormattedMessage id="error.mfa.other" />;
    }
  }
  return <FormattedMessage id="error.mfa.other" />;
};

export function MFAForm({ onLoginStateChange }: Props) {
  const intl = useIntl();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: '',
    },
  });

  const { submitMFA, isPending, error } = useSubmitMfa();

  const submitMfa = (values: MFASetupValues) => {
    submitMFA(
      { code: values.code },
      {
        onSuccess: () => {
          onLoginStateChange(LoginState.Success);
        },
      },
    );
  };

  return (
    <>
      <Typography variant="h4">
        <FormattedMessage id="form.label.mfa" />
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 5 }}>
        <FormattedMessage id="form.mfa.description" />
      </Typography>
      <form onSubmit={handleSubmit(submitMfa)}>
        <TextField
          label={<FormattedMessage id="form.mfa_setup.code" />}
          id="code"
          autoFocus
          type="number"
          {...register('code', {
            required: errorMessageFor(intl, {
              type: 'required',
              label: 'mfa_code',
            }),
          })}
          error={!!errors?.code}
          helperText={errors?.code?.message}
          fullWidth
        />
        <Box mt={5} display="flex" justifyContent="flex-end">
          <LoadingButton variant="contained" loading={isPending} type="submit">
            <FormattedMessage id="form.button.submit" />
          </LoadingButton>
        </Box>
        {error && (
          <Box mt={2} px={2} py={1} borderRadius={1} bgcolor="error.light">
            <Typography variant="body2">
              <MessageByErrorCode error={error} />
            </Typography>
          </Box>
        )}
      </form>
    </>
  );
}
