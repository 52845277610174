import { Info, PictureAsPdfRounded as PictureAsPdfRoundedIcon } from '@mui/icons-material';
import { Box, Button, Card, CircularProgress, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import MenuPreviewImg from 'assets/images/lpq-preview-menu-low.jpg';

import { getMenuCompliance } from 'utils/menu.utils';

import { Menu, MenuItemStatus } from 'types/menus.types';

import { Image } from 'components/@common';

type Props = {
  menu: Menu;
  compliancePercent: number | null;
  showExplanation: boolean;
  isProcessing: boolean;
  isCalculating: boolean;
  isError: boolean;
  setShowExplanation: (showExplanation: boolean) => void;
};

const MenuCompliance = ({
  menu,
  compliancePercent,
  showExplanation,
  isProcessing,
  isCalculating,
  isError,
  setShowExplanation,
}: Props) => {
  const blockComplianceKey: I18nKey | null = useMemo(() => {
    if (!menu.menuItems.length) {
      return 'menus.compliance.blocked.empty';
    }

    // // if less than 40% of the menu items are matched, we also don't display compliance
    const matchedItems = menu.menuItems.filter((item) => item.status !== MenuItemStatus.Unmatched);
    if (matchedItems.length / menu.menuItems.length < 0.4) {
      return 'menus.compliance.blocked.unmatched';
    }

    // if more than 40% of the menu items with a linked recipes are in draft, we don't display compliance
    const menuItemsWithLinkedRecipe = menu.menuItems.filter(
      (item) => item.recipe && 'status' in item.recipe,
    );
    const publishedRecipes = menuItemsWithLinkedRecipe.filter(
      (item) => item.recipe && 'status' in item.recipe && item.recipe.status === 'PUBLISHED',
    );
    if (publishedRecipes.length / menuItemsWithLinkedRecipe.length < 0.4) {
      return 'menus.compliance.blocked.unpublished';
    }

    return null;
  }, [menu.menuItems]);

  if (isProcessing || typeof compliancePercent === null) {
    return (
      <Card
        variant="outlined"
        sx={(theme) => ({
          p: 2,
          gap: 2.5,
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          bgcolor: 'transparent',
          borderRadius: theme.mixins.borderRadius.base,
          minHeight: 116,
          height: 'fit-content',
        })}
      >
        <Card
          sx={(theme) => ({
            height: 84,
            width: '33%',
            bgcolor: 'transparent',
            borderRadius: theme.mixins.borderRadius.base,
          })}
          variant="outlined"
        />
        <Stack justifyContent="center" flex={1} gap={1}>
          <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.grey[600] }}>
            <FormattedMessage id="menus.compliance.label" />
          </Typography>
          <ComplianceBar isError={isError} isProcessing={isProcessing} />
        </Stack>
      </Card>
    );
  }

  const openPdf = () => {
    window.open(menu.pdfFile, '_blank');
  };

  return (
    <Card
      elevation={0}
      sx={{
        overflow: 'hidden',
        position: 'relative',
        flex: 1,
      }}
    >
      <Box
        sx={{
          filter: blockComplianceKey ? 'blur(2px)' : undefined,
          p: 2,
          gap: 2.5,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {isProcessing ? (
          <Card sx={{ width: '33%' }} variant="outlined" />
        ) : (
          <Box
            width="33%"
            height={84}
            position="relative"
            sx={{ cursor: 'pointer' }}
            onClick={openPdf}
          >
            <Image
              src={MenuPreviewImg}
              alt="Menu Preview"
              objectFit="cover"
              sx={{
                objectPosition: 'top',
                border: '1px solid',
                borderColor: 'grey.400',
                '&:hover': {
                  borderColor: 'primary.main',
                },
              }}
            />
            <PictureAsPdfRoundedIcon
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'black.60',
              }}
            />
          </Box>
        )}
        <Stack justifyContent="center" flex={1}>
          <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.grey[600] }}>
            <FormattedMessage id="menus.compliance.label" />
          </Typography>
          <ComplianceBar
            compliancePercent={compliancePercent || 0}
            isCalculating={isCalculating}
            isProcessing={isProcessing}
          />
          {!isProcessing && (
            <Button
              variant="link"
              onClick={() => setShowExplanation(!showExplanation)}
              sx={{ fontSize: '0.8125rem', fontWeight: 500, color: 'global01.main' }}
            >
              <FormattedMessage id="general.calculation.how.label" />
            </Button>
          )}
        </Stack>
      </Box>

      {blockComplianceKey && (
        <Box position="absolute" sx={{ inset: 0 }}>
          <Box position="absolute" sx={{ inset: 0, opacity: 0.8 }} bgcolor="bg.default" />
          <Box
            position="absolute"
            sx={{ inset: 0 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={3}
            gap={2}
          >
            <Info color="info" />
            <Typography variant="body2">
              <FormattedMessage
                id={blockComplianceKey}
                values={{
                  strong: (message: React.ReactNode) => <strong>{message}</strong>,
                }}
              />
            </Typography>
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default MenuCompliance;

type ComplianceBarProps = {
  compliancePercent?: number;
  isCalculating?: boolean;
  isProcessing: boolean;
  isError?: boolean;
};

const ComplianceBar = ({
  compliancePercent,
  isCalculating,
  isProcessing,
  isError,
}: ComplianceBarProps) => {
  const bgGradient =
    isCalculating || isError || isProcessing
      ? 'linear-gradient(90deg, #B6B6B6 0%, #EBEBEB 100%)'
      : 'linear-gradient(90deg, #FFDC21 0%, #C2FB20 30%, #70F101 60%, #148B00 100%)';

  const complianceKey = getMenuCompliance(compliancePercent, isCalculating);

  return (
    <Stack flex={1} direction="row" alignItems="center" gap={2}>
      <Box
        position="relative"
        height={3}
        minHeight={3}
        flex={1}
        width="100%"
        borderRadius={(theme) => theme.mixins.borderRadius.full}
        sx={{ background: bgGradient }}
      >
        {!isProcessing && !isError && typeof compliancePercent !== null && (
          <Box
            position="absolute"
            bottom="-100%"
            left={`${compliancePercent}%`}
            sx={{ transform: 'translateX(-50%)' }}
          >
            <svg
              viewBox="0 0 12 11"
              xmlns="http://www.w3.org/2000/svg"
              style={{ width: 12, height: 11 }}
            >
              <path
                d="M10.6537 3.54941L7.26923 8.92302C6.68089 9.85714 5.31911 9.85714 4.73077 8.92302L1.34627 3.54941C0.717142 2.55053 1.43501 1.25 2.6155 1.25H9.3845C10.565 1.25 11.2829 2.55053 10.6537 3.54941Z"
                style={{ fill: 'black', fillOpacity: '0.87' }}
              />
            </svg>
          </Box>
        )}
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={1}>
        {isCalculating && <CircularProgress color="inherit" size={14} />}
        {complianceKey && (
          <Typography
            variant="tableHeader"
            sx={{
              color: (theme) =>
                isCalculating ? theme.palette.black[60] : theme.palette.black[100],
            }}
          >
            <FormattedMessage id={complianceKey} />
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
