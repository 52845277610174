import { useMutation } from '@tanstack/react-query';
import { confirmSignIn, ConfirmSignInOutput, updateMFAPreference } from 'aws-amplify/auth';

import { CognitoError } from 'types/auth.types';

interface VerifyVariables {
  code: string;
}

export const useCreateMfaSetup = () => {
  const {
    mutate: verifyMFA,
    isPending,
    error,
  } = useMutation<ConfirmSignInOutput, CognitoError, VerifyVariables>({
    mutationFn: ({ code }: VerifyVariables) => confirmSignIn({ challengeResponse: code }),
    onSuccess: () => {
      updateMFAPreference({ totp: 'PREFERRED' });
    },
  });

  return {
    verifyMFA,
    isPending,
    error,
  };
};
