import axios, { AxiosRequestConfig } from 'axios';
import humps from 'humps';
import qs from 'query-string';

import config from 'config';

import { createAmplifyAuthorizationInterceptor } from 'services/api/axios/auth.interceptor';

import { removeEmptyValuesFromParams } from '../utils';

const DEFAULT_CONFIG: AxiosRequestConfig = {
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: (params) =>
    qs.stringify(
      removeEmptyValuesFromParams<Record<string, unknown>>({
        ...params,
      } as Record<string, unknown>),
      {
        arrayFormat: 'comma',
      },
    ),
  transformRequest: [
    (data) => {
      if (!(data instanceof FormData)) {
        // convert all body keys to snake_case
        data = humps.decamelizeKeys(data);

        // convert all body objects to strings
        if (typeof data === 'object') {
          data = JSON.stringify(data);
        }
      }

      return data;
    },
  ],
  transformResponse: [
    (data) => {
      try {
        // convert all body keys to camelCase
        if (typeof data !== 'object' && data) {
          data = JSON.parse(data);
        }
        if (!(data instanceof Blob)) {
          data = humps.camelizeKeys(data);
        }
        return data;
      } catch (error) {
        return humps.camelizeKeys(data);
      }
    },
  ],
};

const createManager = (requestConfig?: AxiosRequestConfig) => {
  const manager = axios.create({ ...DEFAULT_CONFIG, ...requestConfig });

  manager.interceptors.request.use(
    async (axiosConfig) => {
      if (axiosConfig.headers) {
        axiosConfig.headers['Accept-Language'] = config.DEFAULT_LOCALE.toUpperCase();
      }

      return axiosConfig;
    },
    (error) => Promise.reject(error),
  );

  createAmplifyAuthorizationInterceptor(manager);

  return manager;
};

export { createManager };
