import { createTheme } from '@mui/material/styles';

import breakpoints from './breakpoints';
import mixins from './mixins';
import * as components from './overrides';
import palette from './palette';
import typography from './typography';

const theme = createTheme({ typography, palette, mixins, components, breakpoints });

export default theme;
