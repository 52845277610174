import { Box, Container } from '@mui/material';
import { fetchAuthSession, SignInOutput } from 'aws-amplify/auth';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import img_alain_ai_kitchen from 'assets/images/alain_ai_kitchen.jpg';

import { Routes } from 'constants/routes.constants';
import { LoginState } from 'types/auth.types';

import {
  CompleteSignupForm,
  ForgotPasswordForm,
  LoginForm,
  MFAForm,
  MFASetupForm,
  ResetPasswordForm,
} from 'components/@auth';
import { Image } from 'components/@common';
import { CollapseFade } from 'components/@utils/CollapseFade';

const Login = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const initialEmail = new URLSearchParams(location.search).get('email');
  const initialResetToken = new URLSearchParams(location.search).get('resetToken');

  const [email, setEmail] = useState(initialEmail || '');
  const intl = useIntl();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname ?? Routes.Root;

  const [loginState, setLoginState] = useState<{
    state: LoginState;
    output?: SignInOutput;
  }>({
    state: initialResetToken ? LoginState.ResetPassword : LoginState.Login,
    output: undefined,
  });

  useEffect(() => {
    const fetchAuth = async () => {
      fetchAuthSession()
        .then((authSession) => {
          if (
            !!authSession.userSub &&
            !!authSession.tokens &&
            authSession.userSub === authSession.tokens?.accessToken.payload.sub
          ) {
            navigate(Routes.Root, { replace: true });
          }
        })
        .finally(() => setIsLoading(false));
    };

    fetchAuth();
  }, [navigate]);

  if (isLoading) {
    return null;
  }

  const handleLoginStateChange = (loginState: LoginState, output?: SignInOutput) => {
    if (loginState === LoginState.Success) {
      navigate(from, { replace: true });
      return;
    }
    setLoginState({ state: loginState, output });
  };

  return (
    <Container
      sx={{
        maxWidth: '100% !important',
        width: '100% !important',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100svh',
        position: 'relative',
        padding: '0 !important',
        mx: 0,
      }}
    >
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'alain_ai.label' })} -{' '}
          {intl.formatMessage({ id: 'login.label' })}
        </title>
      </Helmet>
      <Box
        zIndex={1}
        flex={1}
        bgcolor="bg.paper"
        width="fit-content"
        px={3.5}
        py={4}
        my={2}
        mx={5.25}
        sx={(theme) => ({
          boxShadow: theme.shadows[8],
          [theme.breakpoints.down('md')]: {
            maxWidth: theme.breakpoints.values.sm,
          },
        })}
      >
        <CollapseFade in={loginState.state === LoginState.Login}>
          <LoginForm
            initialEmail={email}
            setEmail={setEmail}
            onLoginStateChange={handleLoginStateChange}
          />
        </CollapseFade>
        <CollapseFade in={loginState.state === LoginState.ForgotPassword}>
          <ForgotPasswordForm
            initialEmail={email}
            setEmail={setEmail}
            onLoginStateChange={handleLoginStateChange}
          />
        </CollapseFade>
        <CollapseFade in={loginState.state === LoginState.ResetPassword}>
          <ResetPasswordForm
            initialEmail={email}
            initialResetToken={initialResetToken}
            onLoginStateChange={handleLoginStateChange}
          />
        </CollapseFade>
        <CollapseFade in={loginState.state === LoginState.CompleteSignup}>
          <CompleteSignupForm onLoginStateChange={handleLoginStateChange} />
        </CollapseFade>
        <CollapseFade in={loginState.state === LoginState.MFAStetup}>
          <MFASetupForm
            onLoginStateChange={handleLoginStateChange}
            signInOutput={loginState.output}
          />
        </CollapseFade>
        <CollapseFade in={loginState.state === LoginState.MFA}>
          <MFAForm onLoginStateChange={handleLoginStateChange} />
        </CollapseFade>
      </Box>

      <Box
        flex={1}
        height="100%"
        width="100%"
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
      >
        <Image
          src={img_alain_ai_kitchen}
          flex={1}
          height="100%"
          width="100%"
          sx={{
            borderRadius: 0,
            objectPosition: 'center top',
            objectFit: 'cover !important',
            display: 'flex',
          }}
        />
      </Box>
    </Container>
  );
};

export default Login;
