import { ActiveFilters, KeysMap } from 'types/common.types';
import {
  BaseRecipeFilters,
  OptionalRecipeFilters,
  RecipeCategory,
  RecipeFilters,
  RecipeRequirement,
  RecipeStatus,
  RecipeType,
} from 'types/recipes.types';

export const recipeRequirementsMap: KeysMap<RecipeRequirement> = {
  [RecipeRequirement.Local]: 'recipes.requirements.local',
  [RecipeRequirement.Required]: 'recipes.requirements.required',
  [RecipeRequirement.Core]: 'recipes.requirements.core',
};

export const recipeTypesMap: KeysMap<RecipeType> = {
  [RecipeType.Recipe]: 'recipes.types.recipe',
  [RecipeType.MiseEnPlace]: 'recipes.types.mep',
  [RecipeType.Formula]: 'recipes.types.formula',
  [RecipeType.PurchasedItem]: 'recipes.types.purchased_item',
};

export const recipeStatusesMap: KeysMap<RecipeStatus> = {
  [RecipeStatus.Draft]: 'recipes.status.draft',
  [RecipeStatus.Submitted]: 'recipes.status.submitted',
  [RecipeStatus.Rejected]: 'recipes.status.rejected',
  [RecipeStatus.Approved]: 'recipes.status.approved',
  [RecipeStatus.Archived]: 'recipes.status.archived',
  [RecipeStatus.Published]: 'recipes.status.published',
};

export const recipeCategoriesMap: KeysMap<RecipeCategory> = {
  [RecipeCategory.BreakfastBrunch]: 'recipes.categories.breakfast_brunch',
  [RecipeCategory.Tartines]: 'recipes.categories.tartines',
  [RecipeCategory.Salads]: 'recipes.categories.salads',
  [RecipeCategory.HotDishesOther]: 'recipes.categories.hot_dish_other',
  [RecipeCategory.SupplementsExtra]: 'recipes.categories.supplements_extra',
  [RecipeCategory.DessertsPastry]: 'recipes.categories.desserts_pastry',
  [RecipeCategory.Beverages]: 'recipes.categories.beverages',
  [RecipeCategory.Catering]: 'recipes.categories.catering',
  [RecipeCategory.ExpressOffer]: 'recipes.categories.express_offer',
};

export const EMPTY_RECIPE_FILTERS: ActiveFilters<RecipeFilters> = {
  [BaseRecipeFilters.category]: [],
  [BaseRecipeFilters.requirement]: [],
  [BaseRecipeFilters.type]: [],
  [OptionalRecipeFilters.country]: [],
  [OptionalRecipeFilters.season]: [],
  [OptionalRecipeFilters.status]: [],
};

export const RECIPE_GRID_IMAGE_HEIGHT = 195;

export const RECIPE_TABLE_IMAGE_HEIGHT = 67;
export const RECIPE_TABLE_IMAGE_WIDTH = 62;

export const MEASUREMENTS_PER_PAGE = 10;

export const COUNTRIES_ALL = 'ALL';

export const MEP_PREFIX = 'MEP ';
