import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { FormattedMessage } from 'react-intl';

import { useRecipeCreator } from 'hooks';

interface Props {
  source: 'dashboard' | 'experimental-kitchen';
}

const CreateRecipeButton = ({ source }: Props) => {
  const { handleCreateRecipe, isCreateRecipePending } = useRecipeCreator(source);

  return (
    <LoadingButton
      variant="contained"
      color="primary"
      onClick={handleCreateRecipe}
      loading={isCreateRecipePending}
      loadingPosition="start"
      startIcon={<AddIcon fontSize="small" />}
    >
      {isCreateRecipePending ? (
        <FormattedMessage id="recipes.create.creating" />
      ) : (
        <FormattedMessage id="recipes.create.label" />
      )}
    </LoadingButton>
  );
};

export default CreateRecipeButton;
