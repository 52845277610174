import { fetchAuthSession } from '@aws-amplify/auth';
import { AxiosInstance } from 'axios';

// Try catch so we don't end up with a console error
export const fetchAuthenticatedUserSession = async () => {
  try {
    const data = await fetchAuthSession();
    return data;
  } catch {
    return undefined;
  }
};

export const createAmplifyAuthorizationInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.request.use(async (request) => {
    const session = await fetchAuthenticatedUserSession();

    if (session) {
      request.headers = request.headers ?? {};
      request.headers.Authorization = session?.tokens?.accessToken.toString();
    }

    return request;
  });
};
