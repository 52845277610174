import { useTheme } from '@mui/material';
import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';

const TRANSITION_DURATION = 500;
const OVERLAY_DURATION = 200;

type Props = {
  sections: string[];
  parentRef: RefObject<HTMLDivElement>;
  onScrollEnd?: (activeSection: string) => void;
};

/**
 * Hook to handle scroll navigation between sections
 * @param {string[]} sections - Array of section ids
 * @param {RefObject} parentRef - Ref of the parent container
 */
export const useScrollNavigation = ({ sections, parentRef, onScrollEnd }: Props) => {
  const theme = useTheme();
  const [activeSection, setActiveSection] = useState<string | null>(null);

  // Create overlay element
  const generateOverlay = useCallback(() => {
    const overlay = document.createElement('div');
    overlay.style.position = 'absolute';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.transition = `opacity ${TRANSITION_DURATION}ms ease-out`;
    overlay.style.border = `3px solid ${theme.palette.black[20]}`;
    overlay.style.backgroundColor = theme.palette.black[10];
    overlay.style.zIndex = '100';
    return overlay;
  }, [theme.palette.black]);

  const [scrollRatio, setScrollRatio] = useState(0);
  const [sectionOffsets, setSectionOffsets] = useState<{ id: string; offset: number }[]>([]);
  const [screenThreshold, setScreenThreshold] = useState(0);
  const [overlay, setOverlay] = useState<HTMLElement | null>(generateOverlay());

  const setSizes = useCallback(() => {
    if (!parentRef.current) return;
    // Calculate the scroll ratio based on the height of the parent and the max scroll height
    const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
    const parentHeight = parentRef?.current?.scrollHeight || 0;
    setScreenThreshold(window.innerHeight - (parentHeight - maxScroll));
    setScrollRatio(parentHeight / maxScroll);

    // Calculate the offsets of each section according to the parent height
    const sectionsWithOffsets = sections.map((section) => {
      const sectionElement = document.getElementById(section);
      return {
        id: section,
        offset: (sectionElement?.offsetTop || 0) - (screenThreshold || 0),
      };
    });
    setSectionOffsets(sectionsWithOffsets);
  }, [parentRef, screenThreshold, sections]);

  useEffect(() => {
    setSizes();
    const resizeObserver = new ResizeObserver(setSizes);
    parentRef.current && resizeObserver.observe(parentRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [parentRef, setSizes]);

  // Add overlay effect when scrolling to a section and remove it after transition
  const toggleOverlay = useCallback(
    (sectionRef: HTMLElement) => {
      sectionRef.style.setProperty('position', 'relative');
      setOverlay(generateOverlay());
      if (!overlay) return;
      sectionRef.appendChild(overlay);
      // Remove overlay after transition
      setTimeout(() => {
        overlay.style.opacity = '0';
      }, OVERLAY_DURATION);
      setTimeout(() => {
        sectionRef.style.removeProperty('position');
        if (sectionRef.contains(overlay)) sectionRef.removeChild(overlay);
        overlay.remove();
      }, TRANSITION_DURATION + OVERLAY_DURATION);
    },
    [generateOverlay, overlay],
  );

  // Scroll to a section and add overlay effect;
  const scrollToSection = useCallback(
    (section: string): void => {
      if (section === activeSection) return;
      const sectionElement = document.getElementById(section);
      if (!sectionElement) return;

      sectionElement?.scrollIntoView({ behavior: 'smooth' });

      const handleScrollEnd = () => {
        document.removeEventListener('scrollend', handleScrollEnd);
        toggleOverlay(sectionElement);
        onScrollEnd?.(section);
        setActiveSection(section);
      };
      document.addEventListener('scrollend', handleScrollEnd);
    },
    [activeSection, onScrollEnd, toggleOverlay],
  );

  // Handle scroll event and set the active section
  const handleScroll = useCallback(() => {
    const scrollY = document.documentElement.scrollTop;
    if (!scrollY) return;
    const scrollWithRatio = scrollY * scrollRatio;

    const active = sectionOffsets.find((section, index) => {
      const nextSection = sectionOffsets[index + 1];
      if (scrollWithRatio >= section.offset) {
        if (index === sectionOffsets.length - 1 || scrollWithRatio < nextSection?.offset) {
          return section;
        }
      }
    });
    if (!!active) setActiveSection(active.id);
  }, [scrollRatio, sectionOffsets]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll, scrollRatio, sectionOffsets]);

  return useMemo(() => ({ activeSection, scrollToSection }), [activeSection, scrollToSection]);
};
