import { COUNTRIES_ALL } from 'constants/recipes.constants';

export const COUNTRIES: Record<string, string> = {
  AR: 'ar', // Argentina
  AZ: 'az', // Azerbaijan
  BE: 'be', // Belgium
  BR: 'br', // Brazil
  CO: 'co', // Colombia
  CD: 'cd', // Congo
  ES: 'es', // Spain
  FR: 'fr', // France
  GR: 'gr', // Greece
  HK: 'hk', // Hong Kong
  IN: 'in', // India
  JP: 'jp', // Japan
  KZ: 'kz', // Kazakhstan
  LU: 'lu', // Luxembourg
  MX: 'mx', // Mexico
  MA: 'ma', // Morocco
  NL: 'nl', // Netherlands
  PE: 'pe', // Peru
  CH: 'ch', // Switzerland
  TR: 'tr', // Turkey
  AE: 'ae', // United Arab Emirates
  GB: 'gb', // United Kingdom
  US: 'us', // United States
  UY: 'uy', // Uruguay
  UZ: 'uz', // Uzbekistan
};

export const COUNTRIES_WITH_ALL: { [key: string]: string } = {
  ALL: COUNTRIES_ALL,
  ...COUNTRIES,
};

export type Country = keyof typeof COUNTRIES;
