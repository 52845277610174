import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, Menu } from '@mui/material';
import { useState } from 'react';

interface Props {
  children: React.ReactNode;
}

const ActionMenu = ({ children }: Props) => {
  const [optionsAnchorEl, setOptionsAnchorEl] = useState<null | HTMLElement>(null);
  const optionsOpen = Boolean(optionsAnchorEl);
  const handleToggleOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOptionsAnchorEl(event.currentTarget);
  };
  const handleCloseOptions = () => setOptionsAnchorEl(null);

  return (
    <>
      <IconButton
        onClick={handleToggleOptions}
        sx={(theme) => ({
          '&:focus, :hover': {
            color: `${theme.palette.primary['dark']}`,
          },
        })}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={optionsAnchorEl}
        open={optionsOpen}
        onClose={handleCloseOptions}
      >
        {children}
      </Menu>
    </>
  );
};

export default ActionMenu;
