import { useCallback, useState } from 'react';

import { useDebouncedState } from './useDebouncedState';

type Options = {
  initialValue?: string;
  debounceTime?: number;
};

const defaultOptions = { initialValue: '', debounceTime: 300 };

export const useSearchState = ({
  initialValue = defaultOptions.initialValue,
  debounceTime = defaultOptions.debounceTime,
}: Options = defaultOptions) => {
  const [searchValue, setSearchValue] = useState(() => initialValue);
  const debouncedSearchValue = useDebouncedState(searchValue, debounceTime);

  const handleSearchChange = useCallback((newValue: string) => setSearchValue(newValue), []);

  const clearSearchValue = useCallback(() => setSearchValue(''), []);

  return {
    searchValue,
    debouncedSearchValue,
    handleSearchChange,
    clearSearchValue,
  };
};
