const ONE_MINUTE = 60000;

export enum Duration {
  MINUTE = ONE_MINUTE,
  FIVE_MIN = 5 * ONE_MINUTE,
  TEN_MIN = 10 * ONE_MINUTE,
  FIFTEEN_MIN = 15 * ONE_MINUTE,
  THIRTY_MIN = 30 * ONE_MINUTE,
  FORTY_FIVE_MIN = 45 * ONE_MINUTE,
  ONE_HOUR = 60 * ONE_MINUTE,
  DAY = 24 * ONE_MINUTE * 60,
  WEEK = 7 * 24 * ONE_MINUTE * 60,
  FOREVER = Infinity,
}

export const VALIDATIONS = {
  email:
    /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
  phone: /^[+][(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
};
