import { Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1),
      width: 'fit-content',
      paddingBlock: theme.spacing(1),
      paddingInline: theme.spacing(2.5),
      whiteSpace: 'nowrap',
      minWidth: 0,
      ...theme.typography.button,
    }),
    startIcon: {
      marginRight: 0,
    },
  },
  variants: [
    {
      props: { variant: 'link' },
      style: ({ theme }) => ({
        ...theme.typography.body1,
        textDecoration: 'underline',
        padding: 0,
        '&:hover': {
          textDecoration: 'underline',
        },
        '&:active': {
          textDecoration: 'none',
        },
      }),
    },
    {
      props: { variant: 'text' },
      style: {
        padding: '0 12px',
      },
    },
  ],
};
