import { Skeleton, TableCell, tableCellClasses, TableRow } from '@mui/material';

const MenuTableSkeleton = () => {
  return Array.from({ length: 12 }).map((_, index) => (
    <TableRow
      key={index}
      hover
      sx={{
        [`& .${tableCellClasses.root}`]: { py: 3 },
        backgroundColor: 'bg.paper',
      }}
    >
      <TableCell>
        <Skeleton height={20} width={180} sx={{ mb: 0.5 }} />
      </TableCell>
      <TableCell align="center">
        <Skeleton variant="circular" height={32} width={32} />
      </TableCell>
      <TableCell>
        <Skeleton height={20} width={32} />
      </TableCell>
      <TableCell>
        <Skeleton height={20} width={56} />
      </TableCell>
      <TableCell>
        <Skeleton height={20} width={36} />
      </TableCell>
      <TableCell>
        <Skeleton height={20} width={64} />
      </TableCell>
    </TableRow>
  ));
};

export default MenuTableSkeleton;
