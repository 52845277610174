import {
  Star as StarIcon,
  StarHalf as StarHalfIcon,
  StarOutline as StarOutlineIcon,
} from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { MouseEvent, useMemo, useState } from 'react';

const STAR_SIZE = 22;

type Props = {
  value: number;
  onChange: (value: number) => void;
  readOnly?: boolean;
};

const RatingSelect = ({ value, onChange, readOnly }: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hoverValue, setHoverValue] = useState<number | null>(null);

  const starValues: number[] = useMemo(
    () =>
      Array.from({ length: 5 }, (_, i) => {
        const order = i + 1;
        if (isHovering ? hoverValue && order < hoverValue : order < value) return 1;
        if (
          isHovering
            ? hoverValue && order > hoverValue && order < hoverValue + 1
            : order > value && order < value + 1
        )
          return 0.5;
        if (isHovering ? hoverValue && order === hoverValue : order === value) return 1;
        return 0;
      }),
    [hoverValue, isHovering, value],
  );

  const handleMouseOver = (e: MouseEvent, index: number) => {
    setIsHovering(true);
    if (e.clientX < (e.target as HTMLElement)?.getBoundingClientRect().x + STAR_SIZE / 2) {
      setHoverValue(index - 0.5);
      return;
    }
    setHoverValue(index);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
    setHoverValue(null);
  };

  return (
    <Stack direction="row">
      {starValues.map((starValue, index) => (
        <Star
          key={index}
          value={starValue}
          onClick={() => onChange(Number(hoverValue))}
          onMouseEnter={(e) => handleMouseOver(e, index + 1)}
          onMouseLeave={handleMouseOut}
          readOnly={readOnly}
        />
      ))}
    </Stack>
  );
};

export default RatingSelect;

type StarProps = {
  value: number;
  onMouseEnter: (event: MouseEvent) => void;
  onMouseLeave: () => void;
  onClick: (e: MouseEvent) => void;
  readOnly?: boolean;
};
const Star = ({ value, onMouseEnter, onMouseLeave, onClick, readOnly }: StarProps) => {
  const Icon = value === 0 ? StarOutlineIcon : value === 0.5 ? StarHalfIcon : StarIcon;
  return (
    <Button
      onMouseDown={(e) => !readOnly && onClick(e)}
      onMouseEnter={(e) => !readOnly && onMouseEnter(e)}
      onMouseMove={(e) => !readOnly && onMouseEnter(e)}
      onMouseLeave={() => !readOnly && onMouseLeave()}
      disabled={readOnly}
    >
      <Icon color="primary" sx={{ width: STAR_SIZE }} />
    </Button>
  );
};
