import { Box, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useTransformRecipe } from 'utils/recipe.utils';

import { Routes } from 'constants/routes.constants';
import { useMedia } from 'hooks';
import { useCalculateOnBrandness, useUpdateRecipe } from 'queries/recipes';
import { useNotifications } from 'services/snackbar';
import { SaveOptions } from 'types/common.types';
import { StorageMediaContext } from 'types/media.types';
import {
  Recipe,
  RecipeFormValues,
  RecipeOnBrandnessRuleSet,
  RecipeStatus,
} from 'types/recipes.types';

import ErrorBoundary from 'components/@boundaries/ErrorBoundary';
import { BackButton, ConfirmationDialog } from 'components/@common';
import { RecipeActions } from 'components/@recipe-form/RecipeForm/RecipeFormMainInfo/components';
import { AutosaveState } from 'components/@states';
import { CollapseFade } from 'components/@utils';

import { RecipeFormDropzone } from './RecipeFormDropzone';
import { RecipeFormIngredients } from './RecipeFormIngredients';
import { RecipeFormMainInfo } from './RecipeFormMainInfo';
import { RecipeFormPerformance } from './RecipeFormPerformance';
import { RecipeFormPreparation } from './RecipeFormPreparation';
import { RecipeFormRemarks } from './RecipeFormRemarks';

type Props = {
  initialRecipe: Recipe;
  disableActions?: boolean;
};

const RecipeForm = ({ initialRecipe, disableActions }: Props) => {
  const intl = useIntl();
  const notifications = useNotifications();

  const { transformFormRecipeToApi, transformApiRecipeToForm } = useTransformRecipe();
  const initialValues: RecipeFormValues = useMemo(() => {
    return transformApiRecipeToForm(initialRecipe);
  }, [transformApiRecipeToForm, initialRecipe]);

  const [isReadOnly, setIsReadOnly] = useState(
    disableActions || ![RecipeStatus.Draft, RecipeStatus.Rejected].includes(initialValues.status),
  );

  const methods = useForm<RecipeFormValues>({
    defaultValues: initialValues,
    progressive: true,
    mode: 'all',
  });

  const status = useWatch<RecipeFormValues>({
    name: 'status',
    control: methods.control,
  }) as RecipeFormValues['status'];

  useEffect(() => {
    if (isReadOnly) {
      methods.reset(initialValues);
    }
  }, [isReadOnly, methods, initialValues]);

  const { updateRecipe, isPending: isSavingRecipe, isError } = useUpdateRecipe();

  // we're not putting this in the form values because we don't want to set a form dirty state based on the `updatedAt` value
  const [lastSavedAt, setLastSavedAt] = useState(
    initialRecipe.updatedAt ? new Date(initialRecipe.updatedAt) : new Date(),
  );

  const [supported, setSupported] = useState(true);
  const { sm } = useMedia();
  useEffect(() => setSupported((!sm && !isReadOnly) || isReadOnly), [sm, isReadOnly]);

  useEffect(() => {
    if (
      !disableActions &&
      [RecipeStatus.Draft, RecipeStatus.Rejected].includes(initialValues.status)
    ) {
      setIsReadOnly(false);
    }
  }, [disableActions, initialValues.status]);

  const overviewRoute =
    status === RecipeStatus.Published ? Routes.Cookbook : Routes.ExperimentalKitchen;

  const { calculateOnBrandness, isPending: isCalculating } = useCalculateOnBrandness();

  const handleCalculateOnBrandness = useCallback(
    (successCallback?: () => void) => {
      calculateOnBrandness(initialRecipe.id, {
        onSuccess: ({ data }) => {
          methods.setValue('onBrandScore', Number(data.data.onBrand));
          successCallback?.();
        },
      });
    },
    [calculateOnBrandness, initialRecipe.id, methods],
  );

  const saveRecipe = useCallback(
    ({ silent, onSuccess, onError }: SaveOptions) => {
      if (isSavingRecipe) return;

      updateRecipe(
        { ...transformFormRecipeToApi(methods.getValues()), silent },
        {
          onSuccess: () => {
            setLastSavedAt(new Date());
            onSuccess?.();
          },
          onError: (error) => {
            onError?.(error);
          },
        },
      );
    },
    [isSavingRecipe, methods, transformFormRecipeToApi, updateRecipe],
  );

  // it can happen that while a new ingredient is being created, the autosave triggers. In this case we stop the autosave and try again in a couple of seconds
  const ingredients = useWatch<RecipeFormValues>({
    name: 'ingredients',
    control: methods.control,
  }) as RecipeFormValues['ingredients'];
  const blockSave = useMemo(
    () => ingredients.some((ingredient) => !ingredient.refId),
    [ingredients],
  );

  const handleSubmit = useCallback(
    ({ silent, onSuccess, onError }: SaveOptions = { silent: false }) => {
      if (blockSave) return;

      methods.handleSubmit(
        () => saveRecipe({ silent, onSuccess, onError }),
        () =>
          notifications.error({
            message: intl.formatMessage(
              { id: 'general.actions.save.error' },
              { type: intl.formatMessage({ id: 'recipes.label.singular' }) },
            ),
          }),
      )();
    },
    [intl, methods, notifications, blockSave, saveRecipe],
  );

  const saveAndCalculate = () => {
    saveRecipe({ silent: true, onSuccess: handleCalculateOnBrandness });
  };

  return (
    <ErrorBoundary boundary="recipe-form">
      <FormProvider {...methods}>
        <CollapseFade in sx={{ bgcolor: 'transparent', m: 0 }} timeout={600}>
          <Box display="flex" flexDirection="column" gap={3} height="100%" width="100%">
            {!disableActions && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className="no-print"
              >
                <BackButton to={overviewRoute} />

                {!isReadOnly && [RecipeStatus.Draft, RecipeStatus.Rejected].includes(status) && (
                  <AutosaveState
                    isError={isError}
                    isPending={isSavingRecipe}
                    savedAt={lastSavedAt}
                    handleSave={(callback) => handleSubmit({ silent: true, onSuccess: callback })}
                  />
                )}
              </Box>
            )}

            <Stack component="form" gap={3}>
              <RecipeFormMainInfo
                isSaving={isSavingRecipe || isCalculating}
                isReadOnly={isReadOnly}
                disableActions={disableActions}
                setIsReadOnly={setIsReadOnly}
                onSave={handleSubmit}
                calculateOnBrandness={handleCalculateOnBrandness}
              />
              <Box sx={{ m: 0, bgcolor: 'transparent' }}>
                <Stack gap={3}>
                  <RecipeFormPerformance
                    isCalculating={isCalculating}
                    disableActions={disableActions}
                    onBrandnessErrors={initialRecipe.onBrandErrors as RecipeOnBrandnessRuleSet[]}
                    isReadOnly={isReadOnly}
                    onCalculate={saveAndCalculate}
                  />
                  <RecipeFormIngredients isReadOnly={isReadOnly} />

                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    sx={{ '@media print': { flexDirection: 'row', pageBreakInside: 'avoid' } }}
                    gap={3}
                  >
                    <RecipeFormDropzone
                      title={<FormattedMessage id="recipes.batch.label" />}
                      name="image"
                      storageContext={StorageMediaContext.RecipeStep}
                      recipeId={`${initialValues.id}`}
                      isReadOnly={isReadOnly}
                    />

                    <RecipeFormDropzone
                      title={<FormattedMessage id="recipes.plating.label" />}
                      name="batchImage"
                      storageContext={StorageMediaContext.RecipeStep}
                      recipeId={`${initialValues.id}`}
                      isReadOnly={isReadOnly}
                    />
                  </Stack>

                  <RecipeFormPreparation recipeId={initialValues.id} isReadOnly={isReadOnly} />
                  <RecipeFormRemarks isReadOnly={isReadOnly} />

                  {!disableActions && (
                    <Box my={9} display="flex" justifyContent="flex-end" gap={3}>
                      <RecipeActions
                        isReadOnly={isReadOnly}
                        isSaving={isSavingRecipe || isCalculating}
                        setIsReadOnly={setIsReadOnly}
                        saveRecipe={handleSubmit}
                        calculateOnBrandness={handleCalculateOnBrandness}
                      />
                    </Box>
                  )}
                </Stack>
              </Box>
            </Stack>
          </Box>
        </CollapseFade>
      </FormProvider>

      <ConfirmationDialog
        open={!supported}
        title={<FormattedMessage id="error.mobile.not_supported.title" />}
        message={<FormattedMessage id="error.mobile.not_supported.text" />}
        onConfirm={() => history.back()}
        onClose={() => setSupported(true)}
        closeText={<FormattedMessage id="error.mobile.not_supported.proceed" />}
        confirmText={<FormattedMessage id="error.mobile.not_supported.go_back" />}
      />
    </ErrorBoundary>
  );
};

export default RecipeForm;
