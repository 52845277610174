import {
  Close as CloseIcon,
  PlaylistAddRounded as PlaylistAddRoundedIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  autocompleteClasses,
  buttonBaseClasses,
  inputBaseClasses,
  inputClasses,
  MenuItem,
  outlinedInputClasses,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { noop } from 'lodash';
import { Fragment, memo, useMemo, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { FormattedMessage, useIntl } from 'react-intl';

import { highlightText } from 'utils/text.utils';

import { MEASUREMENTS_PER_PAGE } from 'constants/recipes.constants';
import { useSearchState } from 'hooks';
import { useMeasurements } from 'queries';
import { useCreateMeasurement } from 'queries/measurements/useCreateMeasurement';
import { Measurement } from 'types/recipes.types';

type Props = {
  value: Measurement | null;
  onChange: (newValue: Measurement | null) => void;
  testId?: string;
};

const MeasurementAutocomplete = ({ value, testId, onChange }: Props) => {
  const intl = useIntl();
  const { createMeasurement } = useCreateMeasurement();

  const { searchValue, debouncedSearchValue, handleSearchChange } = useSearchState();
  const [fetchEnabled, setFetchEnabled] = useState(false);

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    data: measurements,
  } = useMeasurements(
    { search: debouncedSearchValue.trim(), page_size: MEASUREMENTS_PER_PAGE, sort: 'unit' },
    {
      _optimisticResults: 'optimistic',
      enabled: fetchEnabled,
    },
  );

  const options = useMemo(() => {
    let filtered = measurements;
    if (
      searchValue.trim() !== '' &&
      !filtered.find((i) => i.unit?.toLowerCase().trim() === searchValue.toLowerCase().trim())
    ) {
      filtered = [{ unit: searchValue.trim(), id: 0 }, ...filtered];
    }
    return filtered
      .filter((item, index, self) => self.findIndex((i) => i.unit === item.unit) === index)
      .filter((item) => !!item.unit);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurements, searchValue, onChange]);

  const getNext = (inView: boolean) => {
    if (inView && hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const handleOnChange = (selectedOption: Measurement | string | null) => {
    // when clearing value
    if (selectedOption === null) {
      return onChange({ unit: '', id: 0 });
    }
    // if creating new option
    if (selectedOption && (typeof selectedOption === 'string' || selectedOption.id === 0)) {
      const newUnit =
        typeof selectedOption === 'string' ? selectedOption : selectedOption.unit || '';
      handleSearchChange(newUnit);
      onChange({ unit: newUnit, id: 0 });

      return createMeasurement({ unit: newUnit }, { onSuccess: (data) => onChange(data.data[0]) });
    }

    // if existing option
    if (typeof selectedOption !== 'string' && selectedOption.id) {
      return onChange(selectedOption);
    }
  };

  const searchInputMatchesOption = measurements.find(
    (measurement) => measurement.unit === searchValue,
  );

  return (
    <Autocomplete
      value={value}
      options={options}
      inputValue={searchValue}
      onChange={(_, val) => handleOnChange(val)}
      onOpen={() => setFetchEnabled(true)}
      onClose={() => setFetchEnabled(false)}
      freeSolo
      onInputChange={(e, value) => handleSearchChange(value === null ? '' : value)}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.unit || '')}
      clearOnEscape
      clearOnBlur={!searchInputMatchesOption}
      onBlur={searchInputMatchesOption ? () => handleOnChange(searchInputMatchesOption) : noop}
      blurOnSelect
      selectOnFocus
      handleHomeEndKeys
      loading={isFetching}
      clearIcon={<CloseIcon sx={{ width: 16, height: 16 }} />}
      componentsProps={{
        clearIndicator: {
          sx: {
            mr: 0.25,
            mb: 0.25,
            height: 20,
            width: 20,
            alignItems: 'center',
            display: value?.unit || debouncedSearchValue ? 'flex' : 'none',
          },
          onClick: () => {
            handleSearchChange('');
            handleOnChange(null);
          },
        },
        paper: { sx: { width: 320 } },
        popper: { popperOptions: { placement: 'bottom-start' } },
      }}
      ListboxProps={{
        role: 'list-box',
        sx: { [`& .${buttonBaseClasses.root}`]: { pl: 2, pr: 1, py: 1 } },
      }}
      loadingText={Array.from({ length: 10 }).map((_, index) => (
        <MeasurementItemSkeleton key={index} />
      ))}
      renderOption={(props, option, state) =>
        !!searchValue &&
        !measurements.find(
          (measurement) =>
            measurement.unit?.toLowerCase().trim() === option.unit?.toLowerCase().trim(),
        ) ? (
          isFetching || debouncedSearchValue !== searchValue ? (
            Array.from({ length: 5 }).map((_, index) => <MeasurementItemSkeleton key={index} />)
          ) : (
            // new item
            <MenuItem
              {...props}
              data-testid={option.unit}
              sx={{ px: 2, py: 1.5, gap: 2, display: 'flex', alignItems: 'center' }}
              key={option.unit}
            >
              <PlaylistAddRoundedIcon sx={{ color: 'black.60', width: 24 }} />
              <Stack>
                <Typography variant="body1">
                  <FormattedMessage id="recipes.ingredients.unit.add" />
                </Typography>
                <Typography
                  variant="body2"
                  overflow="clip"
                  textOverflow="ellipsis"
                  width="100%"
                  sx={{ color: (theme) => theme.palette.black[60] }}
                >
                  {option.unit}
                </Typography>
              </Stack>
            </MenuItem>
          )
        ) : (
          // existing item
          <Fragment key={option.unit}>
            <MenuItem
              {...props}
              data-testid={option.unit}
              sx={{
                px: 2,
                py: 1.5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                gap: 1,
              }}
              onClick={() => handleOnChange(option)}
            >
              <Typography overflow="clip" textOverflow="ellipsis" width="100%">
                {highlightText(state.inputValue, option?.unit || '')}
              </Typography>
            </MenuItem>
            {state.index === options.length - 1 && <InView as="div" onChange={getNext} />}
          </Fragment>
        )
      }
      renderInput={({ inputProps, ...props }) => (
        <TextField
          inputProps={{
            maxLength: 255,
            'data-testid': testId,
            ...inputProps,
          }}
          data-testid={testId}
          variant="standard"
          sx={{
            boxShadow: 'none',
            width: { xs: '50px', sm: '70px', md: '120px', lg: '160px' },
            [`& .${autocompleteClasses.input}`]: { width: '50px !important' },
            [`& .${autocompleteClasses.inputRoot}`]: { pr: '20px !important' },
            [`& .${outlinedInputClasses.root}`]: { p: 0 },
            [`& .${outlinedInputClasses.notchedOutline}`]: { border: 'none' },
            [`& .${outlinedInputClasses.notchedOutline} legend`]: { width: 0 },
            [`& .${buttonBaseClasses.root}`]: { p: 0.25 },
            [`& .${inputBaseClasses.root}`]: { bgcolor: 'bluegrey.50' },
            [`& .${inputClasses.root}`]: { px: 1, py: 0 },
          }}
          {...props}
          placeholder={intl.formatMessage({ id: 'recipes.ingredients.unit.label' })}
        />
      )}
    />
  );
};

export default memo(MeasurementAutocomplete);

const MeasurementItemSkeleton = () => (
  <Stack direction="row" px={2} py={1.5} width="100%" alignItems="center">
    <Skeleton height={14} width={160} />
  </Stack>
);
