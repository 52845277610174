import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  title: ReactNode;
  message?: ReactNode;
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmText?: ReactNode;
  closeText?: ReactNode;
};

const ConfirmationDialog = ({
  title,
  message,
  onConfirm,
  open,
  loading,
  onClose,
  confirmText = <FormattedMessage id="form.button.confirm" />,
  closeText = <FormattedMessage id="common.cancel" />,
}: Props) => {
  const handleConfirm = async () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} className="no-print">
      <DialogTitle>{title}</DialogTitle>
      {message && (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onClose}>
          {closeText}
        </Button>
        <LoadingButton loading={loading} variant="contained" onClick={handleConfirm} autoFocus>
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
