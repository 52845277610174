import { LinkOff as LinkOffIcon } from '@mui/icons-material';

import { ActionButton } from 'components/@common';

interface Props {
  disabled?: boolean;
  loading?: boolean;

  onClick: () => void;
}

const UnlinkButton = ({ loading, disabled, onClick }: Props) => {
  return (
    <ActionButton
      icon={<LinkOffIcon sx={{ height: 40, width: 40 }} />}
      text="menus.matcher.unlink"
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    />
  );
};

export default UnlinkButton;
