import { Box, CircularProgress, Stack, SxProps, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Props {
  icon: React.ReactNode;
  text: I18nKey;
  disabled?: boolean;
  loading?: boolean;
  wide?: boolean;
  onClick: () => void;
  sx?: SxProps;
}

const ActionButton = ({ icon, text, disabled, loading, wide, onClick, sx }: Props) => {
  return (
    <Stack
      width={wide ? '225px' : '140px'}
      height="100%"
      bgcolor={disabled && !loading ? 'grey.200' : 'bg.default'}
      color={disabled && !loading ? 'grey.700' : 'global01.main'}
      borderRadius={1}
      boxShadow={1}
      role="button"
      gap={1}
      py={2}
      px={3.5}
      alignItems="center"
      textAlign="center"
      sx={{
        transition: 'background-color 0.2s',
        cursor: disabled && !loading ? undefined : 'pointer',
        '&:hover': disabled && !loading ? undefined : { bgcolor: '#fff0e0' },
        ...sx,
      }}
      onClick={disabled || loading ? () => {} : onClick}
    >
      {loading ? (
        <Box height="40px">
          <CircularProgress size={30} sx={{ mt: '4px', color: 'global01.main' }} />
        </Box>
      ) : (
        icon
      )}
      <Typography fontSize="11px" lineHeight="15px" fontWeight={500}>
        <FormattedMessage id={text} />
      </Typography>
    </Stack>
  );
};

export default ActionButton;
