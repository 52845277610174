import { defaultDropAnimationSideEffects, DragOverlay } from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import { Delete as DeleteIcon, Reorder as ReorderIcon } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { memo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const ListItemOverlay = ({ children }: Props) => {
  return (
    <DragOverlay
      adjustScale={true}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges, restrictToParentElement]}
      dropAnimation={{
        duration: 150,
        easing: 'ease-in',
        ...defaultDropAnimationSideEffects,
      }}
    >
      <Stack
        flex={1}
        direction="row"
        gap={2}
        px={3}
        py={0.5}
        alignItems="center"
        bgcolor="bg.paper"
        boxShadow={(theme) => theme.shadows[4]}
      >
        <ReorderIcon sx={{ width: 24, height: 24, opacity: 0.6 }} />
        <Box flex={1} sx={{ opacity: 0.6 }}>
          {children}
        </Box>
        <DeleteIcon sx={{ width: 24, height: 24, opacity: 0.6 }} />
      </Stack>
    </DragOverlay>
  );
};

export default memo(ListItemOverlay);
