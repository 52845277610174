import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';

import { useInsights } from 'queries';

import FranchiseePerformance from 'components/@insights/FranchiseePerformance/FranchiseePerformance';
import GeneralPerformance from 'components/@insights/GeneralPerformance/GeneralPerformance';

const Insights = () => {
  const intl = useIntl();
  const { data: insights, isLoading } = useInsights();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'alain_ai.label' })} -{' '}
          {intl.formatMessage({ id: 'insights.title' })}
        </title>
      </Helmet>

      <Stack gap={5}>
        <Typography variant="h1">
          <FormattedMessage id="insights.title" />
        </Typography>

        {isLoading ? (
          <Box height="50vh" width="100%" display="flex" p={4}>
            <CircularProgress size={60} />
          </Box>
        ) : (
          insights && (
            <>
              <GeneralPerformance insights={insights} />
              <FranchiseePerformance insights={insights.data} />
            </>
          )
        )}
      </Stack>
    </>
  );
};

export default Insights;
