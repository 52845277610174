import {
  DeleteMenuItemParams,
  ExportMenuParams,
  GetMenuItemRecommendedRecipeParams,
  GetMenuParams,
  Menu,
  MenuComplianceRuleSet,
  MenuItem,
  MenuProcessingStatus,
  PostMenuItemParams,
  PostMenuParams,
  UpdateMenuParams,
} from 'types/menus.types';
import { Recipe } from 'types/recipes.types';

import RestApiService from '.';
import { ApiResponse } from './api.types';

export default {
  getMenus(this: RestApiService, params: GetMenuParams) {
    return this.api.get<ApiResponse<Menu[]>>('/menus', {
      params,
    });
  },
  getMenu(this: RestApiService, menuId: Menu['id']) {
    return this.api.get<ApiResponse<Menu>>(`/menus/${menuId}`);
  },
  postMenu(this: RestApiService, data: PostMenuParams) {
    return this.api.post<ApiResponse<Menu>>(`/menus`, { data });
  },
  getMenuStatus(this: RestApiService, menuId: Menu['id']) {
    return this.api.get<ApiResponse<MenuProcessingStatus>>(`/menus/${menuId}/status`);
  },
  putMenu(this: RestApiService, data: UpdateMenuParams) {
    return this.api.put<ApiResponse<Menu>>(`/menus/${data.id}`, { data });
  },
  deleteMenu(this: RestApiService, menuId: Menu['id']) {
    return this.api.delete<void>(`/menus/${menuId}`);
  },
  exportMenu(this: RestApiService, { menuId, emails }: ExportMenuParams) {
    return this.api.post(`/menus/${menuId}/export`, { data: { emails } });
  },
  // PERFORMANCE & COMPLIANCE
  getMenuComplianceRules(this: RestApiService) {
    return this.api.get<ApiResponse<MenuComplianceRuleSet>>(`/menus/compliance-rules`);
  },
  // MENU ITEMS
  postMenuItem(this: RestApiService, data: PostMenuItemParams) {
    return this.api.post<ApiResponse<MenuItem>>(`/menus/${data.menuId}/menu-items`, {
      data: data.menuItem || null,
    });
  },
  putMenuItem(this: RestApiService, data: MenuItem) {
    return this.api.put<ApiResponse<MenuItem>>(`/menus/${data.menuId}/menu-items/${data.id}`, {
      data,
    });
  },
  putMenuItems(this: RestApiService, data: MenuItem[]) {
    return this.api.put<ApiResponse<MenuItem>>(`/menus/${data[0]?.menuId}/menu-items`, {
      data,
    });
  },
  deleteMenuItem(this: RestApiService, params: DeleteMenuItemParams) {
    return this.api.delete<void>(`/menus/${params.menuId}/menu-items/${params.id}`);
  },
  recheckMenuItemMatches(this: RestApiService, menuId: Menu['id']) {
    return this.api.patch<void>(`/menus/${menuId}/menu-items/match`);
  },
  getRecommendedRecipes(
    this: RestApiService,
    { menuId, menuItemId }: GetMenuItemRecommendedRecipeParams,
  ) {
    return this.api.get<ApiResponse<Recipe[]>>(
      `/menus/${menuId}/menu-items/${menuItemId}/recipes/recommended`,
    );
  },
};
