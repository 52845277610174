import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { PostIngredientOptions } from 'types/recipes.types';

const postIngredient = async (options: PostIngredientOptions) => {
  const { data: ingredient } = await apiService.postIngredient(options);
  return ingredient;
};

export const useCreateIngredient = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (options: PostIngredientOptions) => postIngredient(options),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.ingredients.all() });

      notifications.success({
        message: intl.formatMessage({ id: 'recipes.ingredients.added' }),
      });
    },
    onError: () => {
      notifications.error({ message: intl.formatMessage({ id: 'common.error_messages.error' }) });
    },
  });

  return {
    createIngredient: mutateAsync,
    isPending,
  };
};
