import { LoadingButton } from '@mui/lab';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSendForgotPasswordMail } from 'queries';
import { useNotifications } from 'services/snackbar';
import { LoginState, ResetPasswordFormValues } from 'types/auth.types';

interface Props {
  initialEmail: string;
  setEmail: (email: string) => void;
  onLoginStateChange: (state: LoginState) => void;
}

export function ForgotPasswordForm({ initialEmail, setEmail, onLoginStateChange }: Props) {
  const intl = useIntl();
  const notifications = useNotifications();
  const { sendForgotPasswordMail, isPending } = useSendForgotPasswordMail();
  const [hasError, setHasError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormValues>({ defaultValues: { email: initialEmail } });

  const handleResetPassword = (values: ResetPasswordFormValues) => {
    sendForgotPasswordMail(values, {
      onSuccess: () => {
        setEmail(values.email);
        onLoginStateChange(LoginState.ResetPassword);
      },
      onError: (error) => {
        notifications.error({ message: intl.formatMessage({ id: 'common.error_messages.error' }) });

        console.error(error);
        setHasError(true);
      },
    });
  };

  return (
    <>
      <Typography variant="h4">
        <FormattedMessage id="login.password.forgot.title" />
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 5 }}>
        <FormattedMessage id="login.password.forgot.description" />
      </Typography>

      <form onSubmit={handleSubmit(handleResetPassword)}>
        <TextField
          {...register('email', { required: true })}
          type="email"
          label={<FormattedMessage id="form.label.email" />}
          variant="outlined"
          InputProps={{ autoFocus: true }}
          error={!!errors.email || hasError}
          helperText={<FormattedMessage id="login.password.forgot.error" />}
        />
        <Box gap={2} display="flex" alignItems="center" justifyContent="flex-end" mt={5}>
          <Button
            onClick={() => onLoginStateChange(LoginState.Login)}
            variant="link"
            color="inherit"
            tabIndex={1}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <LoadingButton loading={isPending} type="submit" variant="contained">
            <FormattedMessage id="login.password.forgot.submit" />
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}
