import { Star } from '@mui/icons-material';
import { Components, Theme } from '@mui/material';

export const MuiRating: Components<Theme>['MuiRating'] = {
  defaultProps: {
    precision: 0.1,
    readOnly: true,
    emptyIcon: (
      <Star
        sx={{
          color: 'grey.100',
          zIndex: 0,
        }}
        fontSize="inherit"
      />
    ),
    icon: (
      <Star
        sx={{
          color: 'primary.main',
          zIndex: 1,
        }}
        fontSize="inherit"
      />
    ),
  },
};
