import { Box, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';

import { useMedia } from 'hooks';
import { useIsHQ } from 'hooks/useIsHQ';
import {
  RecipeStatusFilterOptions,
  RecipeTableColumns,
  RecipeTableHeader,
} from 'types/recipes.types';

import { CreateRecipeButton } from 'components/@recipe-form';
import RecipeViewer from 'components/@recipe-overview/RecipeViewer/RecipeViewer';

export const EXPERIMENTAL_KITCHEN_TABLE_HEADERS: RecipeTableHeader[] = [
  { id: RecipeTableColumns.Image, label: 'recipes.props.image', width: '8%' },
  {
    id: RecipeTableColumns.Name,
    label: 'general.title.label',
    width: '21%',
    sortable: true,
    initialSort: 'asc',
  },
  // { id: RecipeTableColumns.OnBrand, label: 'recipes.props.onbrand' },
  { id: RecipeTableColumns.Type, label: 'recipes.props.type', width: '13%' },
  { id: RecipeTableColumns.Season, label: 'general.seasons.label', width: '15%' },
  {
    id: RecipeTableColumns.UpdatedAt,
    label: 'common.updated_at',
    width: '15%',
    sortable: true,
    initialSort: 'desc',
  },
  { id: RecipeTableColumns.Countries, label: 'recipes.props.countries', width: '9%' },
  { id: RecipeTableColumns.Status, label: 'general.status.label', width: '19%' },
];

const ExperimentalKitchen = () => {
  const intl = useIntl();
  const { sm } = useMedia();
  const isHQ = useIsHQ();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'alain_ai.label' })} -{' '}
          {intl.formatMessage({ id: 'experimental.label' })}
        </title>
      </Helmet>

      <RecipeViewer
        name="experimental-kitchen"
        statusFilter={RecipeStatusFilterOptions.AllButPublished}
        headers={EXPERIMENTAL_KITCHEN_TABLE_HEADERS}
        enableCountryFilter={isHQ}
        titleComponent={
          <Stack gap={2}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h4">
                <FormattedMessage id="experimental.label" />
              </Typography>
              {!sm && <CreateRecipeButton source="experimental-kitchen" />}
            </Box>
          </Stack>
        }
        searchLabel={intl.formatMessage({ id: 'experimental.search' })}
      />
    </>
  );
};

export default ExperimentalKitchen;
