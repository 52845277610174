import {
  Close as CloseIcon,
  Link as LinkIcon,
  SentimentDissatisfied as SadIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogContent, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { useRecipeById } from 'queries';
import { Recipe } from 'types/recipes.types';

import { RecipeForm, RecipeSkeleton } from 'components/@recipe-form';

interface Props {
  recipe: Recipe | null;
  isOpen: boolean;
  isLinking: boolean;
  onClose: () => void;
  handleLinkRecipe: () => void;
}

const PreviewRecipeDialog = ({
  recipe: minimalRecipe,
  isLinking,
  isOpen,
  onClose,
  handleLinkRecipe,
}: Props) => {
  const {
    data: recipe,
    isLoading,
    isError,
  } = useRecipeById(Number(minimalRecipe?.id), { enabled: !!minimalRecipe?.id });

  if (!minimalRecipe) return null;

  return (
    <Dialog open={isOpen} onClose={onClose} className="no-print">
      <Stack zIndex={5}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          px={3}
          py={2}
          gap={2}
        >
          <LoadingButton
            variant="contained"
            disabled={isLoading}
            loading={isLinking}
            startIcon={<LinkIcon />}
            onClick={handleLinkRecipe}
          >
            <FormattedMessage id="menus.items.linked_recipe.link" />
          </LoadingButton>

          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </Stack>

      <DialogContent>
        <Box py={2}>
          {isError && (
            <Stack alignItems="center">
              <SadIcon sx={{ height: '56px', width: '56px', color: 'bluegrey.200' }} />
              <Stack mt={3} gap={1.5} alignItems="center">
                <Typography variant="body1">
                  <FormattedMessage id="menus.matcher.recipe_error.title" />
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ color: (theme) => theme.palette.bluegrey[500] }}
                >
                  <FormattedMessage id="menus.matcher.recipe_error.description" />
                </Typography>
              </Stack>
            </Stack>
          )}

          {isLoading && (
            <Box>
              <Skeleton height={312} width="100%" sx={{ mb: 3 }} />
              <Box p={3} sx={{ transform: 'scaleX(0.75)' }}>
                <RecipeSkeleton />
              </Box>
            </Box>
          )}

          {recipe && <RecipeForm initialRecipe={recipe} disableActions />}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewRecipeDialog;
