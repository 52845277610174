import { SvgIconComponent } from '@mui/icons-material';
import { CircularProgress, MenuItem, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  onClick: () => void;
  Icon: SvgIconComponent;
  label: ReactNode;
  isLoading?: boolean;
};

const ActionMenuItem = ({ onClick, Icon, label, isLoading }: Props) => {
  return (
    <MenuItem
      onClick={onClick}
      disabled={isLoading}
      sx={{ display: 'flex', alignItems: 'center', gap: 1.5, px: 2, py: 1.5 }}
    >
      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <Icon sx={{ color: 'black.60', width: 24, height: 24 }} />
      )}
      <Typography variant="body1" sx={{ color: (theme) => theme.palette.black[90] }}>
        {label}
      </Typography>
    </MenuItem>
  );
};

export default ActionMenuItem;
