import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';

const approveRecipe = (recipeId: number) => {
  return apiService.approveRecipe(recipeId);
};

export const useApproveRecipe = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ recipeId }: { recipeId: number; isUnpublish?: boolean }) =>
      approveRecipe(recipeId),
    onSuccess: (_, { isUnpublish }) => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.recipes.all() });

      notifications.success({
        message: intl.formatMessage({
          id: isUnpublish
            ? 'recipes.creator.unpublish.success'
            : 'recipes.creator.approved.success',
        }),
      });
    },
    onError: () => {
      notifications.error({ message: intl.formatMessage({ id: 'error.general.text' }) });
    },
  });

  return {
    approveRecipe: mutate,
    isPending,
  };
};
