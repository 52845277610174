import { CircularProgress, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';

import alain_ai_kitchen from 'assets/images/alain_ai_kitchen.jpg';
import img_dashboard_cookbook from 'assets/images/img_dashboard_cookbook.png';

import { Routes } from 'constants/routes.constants';
import { useMedia } from 'hooks';
import { useRecipes } from 'queries';

import { DashboardCard } from 'components/@cards';
import { DashboardLayout } from 'components/@common';
import { CreateRecipeButton } from 'components/@recipe-form';

const RecipeDashboard = () => {
  const theme = useTheme();
  const intl = useIntl();
  const { total, isLoading } = useRecipes({});
  const { sm } = useMedia();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'alain_ai.label' })} -{' '}
          {intl.formatMessage({ id: 'dashboard.label' })}
        </title>
      </Helmet>

      <DashboardLayout
        title={<FormattedMessage id="dashboard.title" />}
        createButton={!sm && <CreateRecipeButton source="dashboard" />}
      >
        <DashboardCard
          href={Routes.Cookbook}
          title={intl.formatMessage({ id: 'cookbook.label' })}
          buttonText={<FormattedMessage id="cookbook.cta" />}
          description={intl.formatMessage(
            { id: 'cookbook.description' },
            {
              amount: isLoading ? (
                <CircularProgress size={14} color="inherit" sx={{ mr: 1 }} />
              ) : (
                total
              ),
              strong: (message: ReactNode) => <strong>{message}</strong>,
            },
          )}
          image={img_dashboard_cookbook}
          objectPosition="right top"
          gradient={{
            direction: 'top',
            color: theme.palette.global01.main,
          }}
          sx={{ flexGrow: 1 }}
        />
        <DashboardCard
          href={Routes.ExperimentalKitchen}
          title={intl.formatMessage({ id: 'experimental.label' })}
          buttonText={<FormattedMessage id="experimental.cta" />}
          description={intl.formatMessage({
            id: 'experimental.description',
          })}
          image={alain_ai_kitchen}
          objectPosition="center"
          gradient={{
            direction: 'right',
            color: theme.palette.global02[700],
          }}
        />
      </DashboardLayout>
    </>
  );
};

export default RecipeDashboard;
