import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { ApiResponse } from 'services/api/api.types';
import { useNotifications } from 'services/snackbar';
import { Menu, MenuItem } from 'types/menus.types';

const putMenuItem = async (data: MenuItem & { silent?: boolean }) => {
  const { data: menuItem } = await apiService.putMenuItem(data);
  return menuItem;
};

export const useUpdateMenuItem = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: putMenuItem,
    onMutate: async ({ menuId, id, ...newMenuItem }) => {
      // Cancel any outgoing refetches so they don't overwrite our optimistic update
      await queryClient.cancelQueries({ queryKey: QueryKeys.menus.byId(menuId) });

      const queryData = queryClient.getQueriesData<ApiResponse<Menu>>({
        queryKey: QueryKeys.menus.byId(menuId),
        exact: true,
      });
      queryData.forEach(([key, data]) => {
        if (!!data) {
          queryClient.setQueryData<ApiResponse<Menu>>(key, {
            ...data,
            data: {
              ...data.data,
              menuItems: data.data.menuItems?.map((menuItem) => {
                return menuItem.id === id
                  ? {
                      ...menuItem,
                      ...newMenuItem,
                      recipe: { ...menuItem.recipe, ...newMenuItem.recipe },
                    }
                  : menuItem;
              }),
            },
          });
        }
      });
      return { previousData: queryData, id };
    },
    onSuccess: async (_, { menuId, id: menuItemId, silent }) => {
      queryClient.removeQueries({
        queryKey: QueryKeys.menus.recommendedRecipes(menuId, menuItemId),
      });

      if (!silent) {
        notifications.success({
          message: intl.formatMessage(
            { id: 'general.actions.save.success' },
            { type: intl.formatMessage({ id: 'menus.items.label.singular' }) },
          ),
        });
      }
    },
    onError: (error, _, context) => {
      if (context?.id) {
        context.previousData.forEach(([key, data]) => {
          if (!!data) {
            queryClient.setQueryData(key, data);
          }
        });
      }

      console.error(error);
      notifications.error({
        message: intl.formatMessage(
          { id: 'general.actions.save.error' },
          { type: intl.formatMessage({ id: 'menus.items.label.singular' }) },
        ),
      });
    },
  });

  return {
    updateMenuItem: mutate,
    isPending,
  };
};
