import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { Recipe } from 'types/recipes.types';

const calculateOnBrandness = (id: Recipe['id']) => {
  return apiService.calculateOnBrandness(id);
};

export const useCalculateOnBrandness = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const notifications = useNotifications();

  const { mutate, isPending } = useMutation({
    mutationFn: calculateOnBrandness,
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.recipes.byId(id) });
    },
    onError: () => {
      notifications.error({ message: intl.formatMessage({ id: 'error.general.text' }) });
    },
  });

  return {
    calculateOnBrandness: mutate,
    isPending,
  };
};
