import { useMutation } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { ExportMenuParams } from 'types/menus.types';

const exportMenu = async (data: ExportMenuParams) => {
  await apiService.exportMenu(data);
};

export const useExportMenu = () => {
  const intl = useIntl();
  const notifications = useNotifications();

  const { mutate, isPending } = useMutation({
    mutationFn: exportMenu,
    onSuccess: () => {
      notifications.success({
        message: intl.formatMessage({ id: 'menus.export.success' }),
      });
    },
    onError: (error) => {
      console.error(error);
      notifications.error({
        message: intl.formatMessage({ id: 'common.error_messages.error' }),
      });
    },
  });

  return {
    exportMenu: mutate,
    isPending,
  };
};
