import { ClickAwayListener } from '@mui/base';
import { Grow, Paper, Popper as MuiPopper } from '@mui/material';
import { cloneElement, MouseEvent, useState } from 'react';

interface Props {
  trigger: React.ReactElement;
  children: React.ReactNode;
  closeOnClick?: boolean;
}

const Popper = ({ trigger, children, closeOnClick }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onOpenPopper = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const onClosePopper = () => setAnchorEl(null);

  return (
    <>
      {cloneElement(trigger, { onClick: onOpenPopper })}
      <ClickAwayListener onClickAway={onClosePopper} mouseEvent="onMouseDown">
        <MuiPopper
          anchorEl={anchorEl}
          open={open}
          sx={{ zIndex: 15 }}
          transition
          placement="bottom-start"
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={200}>
              <Paper sx={{ bgcolor: 'white' }} onClick={closeOnClick ? onClosePopper : undefined}>
                {children}
              </Paper>
            </Grow>
          )}
        </MuiPopper>
      </ClickAwayListener>
    </>
  );
};

export default Popper;
