import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { CommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { ApiResponse } from 'services/api/api.types';
import { GetMessagesResponse } from 'types/chat.types';

const fetchChat = async (id: string) => {
  const { data: chat } = await apiService.getChat(id);
  return chat;
};

export const useChatById = (
  id: string,
  config: CommonQueryOptions<
    ApiResponse<GetMessagesResponse>,
    ReturnType<typeof QueryKeys.chat.byId>
  > = {},
) => {
  const { data, status, isLoading, isFetching, error, isError, refetch } = useQuery({
    queryKey: QueryKeys.chat.byId(id),
    queryFn: () => fetchChat(id),
    ...config,
    staleTime: config?.staleTime ?? Duration.FOREVER,
  });

  const reversedMessages = useMemo(() => data?.data?.messages?.toReversed(), [data]);

  return {
    data: reversedMessages,
    status,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  };
};
