import { Box, SortDirection, TableCell, TableRow } from '@mui/material';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { TableVirtuoso } from 'react-virtuoso';

import { getOrderIcon } from 'utils/table.utils';

import { Routes } from 'constants/routes.constants';
import { Recipe, RecipeTableColumns, RecipeTableHeader } from 'types/recipes.types';

import ErrorBoundary from 'components/@boundaries/ErrorBoundary';

import NoRecipesRow from './NoRecipesRow';
import { SortingHeader } from './RecipeTable.style';
import RecipeTableRow from './RecipeTableRow';
import RecipeTableSkeleton from './RecipeTableSkeleton';

type Props = {
  recipes: Recipe[];
  recipeTotal: number;
  tableHeaders: RecipeTableHeader[];
  isLoading: boolean;
  isFetchingNextPage: boolean;
  sortDirection: SortDirection;
  sortBy: RecipeTableColumns;
  getNext: () => void;
  onSort: (property: RecipeTableColumns, initialSort?: SortDirection) => void;
};

const RecipeTable = ({
  recipes,
  recipeTotal,
  tableHeaders,
  isLoading,
  isFetchingNextPage,
  getNext,
  sortDirection,
  sortBy,
  onSort,
}: Props) => {
  const navigate = useNavigate();

  return (
    <ErrorBoundary boundary="recipe-table">
      <Box
        mt={3}
        sx={{
          overflowX: 'auto',
          overflowY: 'hidden',
          table: { tableLayout: 'fixed', maxWidth: '1188px', minWidth: '850px', width: '100%' },
          borderRadius: 2,
          height: '100%',
        }}
      >
        <TableVirtuoso
          useWindowScroll
          totalCount={recipeTotal}
          data={recipes}
          overscan={500}
          increaseViewportBy={500}
          endReached={getNext}
          computeItemKey={(_index, data) => Number(data.id)}
          fixedHeaderContent={() => (
            <TableRow sx={{ backgroundColor: 'bg.paper' }}>
              {tableHeaders.map(({ id, label, sortable, initialSort, width }) => (
                <TableCell
                  key={id}
                  width={width}
                  sortDirection={sortable ? (sortBy === id ? sortDirection : false) : undefined}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    p: 2,
                    borderLeft: '1px solid',
                    borderColor: 'black.20',
                    '&:first-of-type': { borderLeft: 'none ' },
                  }}
                >
                  {sortable ? (
                    <SortingHeader
                      active={sortBy === id}
                      direction={sortBy === id ? sortDirection || undefined : 'desc'}
                      onClick={() => onSort(id, initialSort)}
                      IconComponent={() => getOrderIcon({ id, sortBy, sortDirection })}
                    >
                      <FormattedMessage id={label} />
                    </SortingHeader>
                  ) : (
                    <FormattedMessage id={label} />
                  )}
                </TableCell>
              ))}
            </TableRow>
          )}
          itemContent={(_index, data) => (
            <RecipeTableRow key={data.id} recipe={data} tableHeaders={tableHeaders} />
          )}
          fixedFooterContent={() =>
            (isFetchingNextPage || isLoading) && <RecipeTableSkeleton tableHeaders={tableHeaders} />
          }
          components={{
            EmptyPlaceholder: () => !isLoading && <NoRecipesRow tableHeaders={tableHeaders} />,
            TableRow: ({ item, ...props }) => (
              <TableRow
                hover
                onClick={() =>
                  navigate(generatePath(Routes.RecipeDetail, { recipeId: `${item.id}` }))
                }
                sx={{ cursor: 'pointer', backgroundColor: 'bg.paper', overflow: 'hidden' }}
                {...props}
              />
            ),
            ScrollSeekPlaceholder: () => <RecipeTableSkeleton tableHeaders={tableHeaders} />,
          }}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default memo(RecipeTable);
