import { Card, styled } from '@mui/material';

export const GridItem = styled(Card)(({ theme }) => ({
  overflow: 'visible',
  position: 'relative',
  width: '100%',
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  '@media print': {
    width: 'fit-content',
    flex: 1,
    minWidth: '30%',
    maxWidth: '50%',
    paddingInline: theme.spacing(1),
  },
}));

export const IndexNumber = styled('div')(({ theme }) => ({
  zIndex: 10,
  top: -20,
  left: -20,
  height: 40,
  width: 40,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  backgroundColor: theme.palette.global01.main,
  color: theme.palette.white[100],
}));
