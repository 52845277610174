import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Rating,
  Skeleton,
  Typography,
} from '@mui/material';
import { InView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { lineClamp } from 'utils/text.utils';

import { FALLBACK_IMAGE } from 'constants/media.constants';
import { RECIPE_GRID_IMAGE_HEIGHT } from 'constants/recipes.constants';
import { recipeRequirementsMap, recipeTypesMap } from 'constants/recipes.constants';
import { Routes } from 'constants/routes.constants';
import { Recipe, RecipeRequirement, RecipeStatus } from 'types/recipes.types';

import { CountryFlag } from 'components/@common';
import { RecipeSeasonsChips } from 'components/@recipe-overview';

import { CountryChip, GridContainer } from './RecipeGrid.style';

type Props = {
  recipes: Recipe[];
  isLoading: boolean;
  isFetchingNextPage: boolean;
  getNext: (inView: boolean) => void;
};

const RecipeGrid = ({ recipes = [], isLoading, isFetchingNextPage, getNext }: Props) => {
  const navigate = useNavigate();

  if (recipes.length === 0) {
    return (
      <GridContainer>
        <Typography gridColumn="1 / -1" align="center">
          <FormattedMessage id="recipes.none" />
        </Typography>
      </GridContainer>
    );
  }

  if (isLoading) {
    return (
      <GridContainer>
        {Array.from({ length: 8 }).map((_, index) => (
          <SkeletonState key={index} />
        ))}
      </GridContainer>
    );
  }

  return (
    <GridContainer>
      {recipes.map(
        ({
          id,
          name,
          batchImage: image,
          type,
          requirementType,
          // onBrand: { score: onBrandScore },
          seasons,
          country,
          status: recipeStatus,
        }) => {
          return (
            <Card
              key={`card-${id}`}
              onClick={() => navigate(generatePath(Routes.RecipeDetail, { recipeId: `${id}` }))}
            >
              <CardActionArea
                sx={{
                  height: '100%',
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}
              >
                <CardMedia
                  component="img"
                  height={RECIPE_GRID_IMAGE_HEIGHT}
                  image={image || FALLBACK_IMAGE}
                  alt={name}
                />
                {/* <OnBrandnessBox score={0.5} imageHeight={RECIPE_GRID_IMAGE_HEIGHT}>
                  <Typography variant="body1">50%</Typography>
                  <OnBrandnessLabel>
                    <FormattedMessage id="recipes.props.onbrand.label" />
                  </OnBrandnessLabel>
                </OnBrandnessBox> */}
                <Box
                  position="absolute"
                  top={(theme) => theme.spacing(1.5)}
                  right={(theme) => theme.spacing(1.5)}
                >
                  {requirementType === RecipeRequirement.Core ? (
                    <CountryChip label={<FormattedMessage id="countries.ALL" />} />
                  ) : (
                    <CountryFlag
                      country={country}
                      size={24}
                      border="2px solid"
                      borderColor="white.100"
                    />
                  )}
                </Box>
                <CardContent
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant="caption" sx={{ color: (theme) => theme.palette.grey[500] }}>
                    <FormattedMessage
                      id={
                        !!requirementType
                          ? recipeRequirementsMap[requirementType]
                          : 'recipes.requirements.none'
                      }
                    />
                  </Typography>
                  <Typography gutterBottom variant="body1" component="h3" sx={lineClamp(1)}>
                    {!name && [RecipeStatus.Draft, RecipeStatus.Rejected].includes(recipeStatus) ? (
                      <FormattedMessage id="recipes.label.untitled" />
                    ) : (
                      name
                    )}
                  </Typography>
                  {/* <Rating sx={{ mt: 4 }} value={onBrandScore ? onBrandScore * 100 : 2.5} /> */}
                  <Box mt={3} display="flex" flexWrap="wrap" gap={1}>
                    <Chip
                      variant="label"
                      label={
                        <FormattedMessage
                          id={!!type ? recipeTypesMap[type] : 'recipes.types.none'}
                        />
                      }
                    />
                    {!seasons?.length ? (
                      <Chip
                        variant="label"
                        label={<FormattedMessage id="general.seasons.none" />}
                      />
                    ) : (
                      <RecipeSeasonsChips seasons={seasons} />
                    )}
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          );
        },
      )}
      {isFetchingNextPage &&
        Array.from({ length: 8 }).map((_, index) => <SkeletonState key={index} />)}
      <InView as="div" onChange={getNext} />
    </GridContainer>
  );
};

export default RecipeGrid;

const SkeletonState = () => (
  <Card>
    <Skeleton height={RECIPE_GRID_IMAGE_HEIGHT} width="100%" />
    <CardContent>
      <Skeleton height={14} width={32} />
      <Skeleton height={16} width={64} sx={{ mt: 0.5 }} />
      <Rating sx={{ justifySelf: 'flex-end', mt: 4 }} value={0} />
      <Box mt={3} display="flex" flexWrap="wrap" gap={1}>
        <Skeleton variant="circular" height={32} width={68} />
        <Skeleton variant="circular" height={32} width={68} />
      </Box>
    </CardContent>
  </Card>
);
