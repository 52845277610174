import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import { Avatar, Box, Button, IconButton, Popper, Zoom } from '@mui/material';
import { MouseEvent, useState } from 'react';

import alain_avatar from 'assets/images/avatar_alain.png';

import useWindowDimensions from 'hooks/useWindowDimensions';

import Chat from './Chat';

const ChatPopper = () => {
  const { windowHeight, windowWidth } = useWindowDimensions();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };
  const handleClose = () => setOpen(false);

  const showOnLeft = windowHeight < 700;
  const showFullScreen = windowWidth < 650;

  return (
    <Box position="fixed" right={24} bottom={24} className="no-print" zIndex={100}>
      <Button
        onClick={handleClick}
        sx={(theme) => ({
          px: 0,
          borderRadius: theme.mixins.borderRadius.full,
          boxShadow: theme.shadows[6],
          transition: 'all 0.2s ease-in',
          '&:hover': { boxShadow: theme.shadows[12] },
          '&:active': { transform: 'scale(0.95)', boxShadow: theme.shadows[10] },
        })}
      >
        <Avatar src={alain_avatar} sx={{ height: 72, width: 72 }} />
      </Button>

      <Popper
        placement={showFullScreen ? 'right' : showOnLeft ? 'left' : 'top-end'}
        transition
        open={open}
        anchorEl={anchorEl}
        sx={{ zIndex: 10000 }}
        popperOptions={{ strategy: 'fixed' }}
      >
        {({ TransitionProps }) => (
          <Zoom
            {...TransitionProps}
            style={{ transformOrigin: 'bottom right', position: 'relative' }}
            timeout={200}
            unmountOnExit
            mountOnEnter
          >
            <Box pb={3} mr={showFullScreen ? -9 : showOnLeft ? 3 : 0}>
              <IconButton
                onClick={handleClose}
                sx={{
                  width: 36,
                  height: 36,
                  position: 'absolute',
                  zIndex: 2,
                  top: -18,
                  right: -18,
                  bgcolor: 'bluegrey.700',
                  '&:hover': { bgcolor: 'bluegrey.800' },
                  '&:active': { bgcolor: 'bluegrey.600' },
                }}
              >
                <CloseRoundedIcon sx={{ width: 24, color: 'text.secondary' }} />
              </IconButton>
              <Chat
                boxShadow={(theme) => theme.shadows[6]}
                maxWidth="500px"
                width="90vw"
                minHeight={showFullScreen || showOnLeft ? '90svh' : '80svh'}
                height="100%"
                borderRadius={(theme) => theme.mixins.borderRadius.base}
              />
            </Box>
          </Zoom>
        )}
      </Popper>
    </Box>
  );
};

export default ChatPopper;
