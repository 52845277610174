import { enc, lib, MD5 } from 'crypto-js';

export const fileToHash = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target?.result;
      const wordArray = lib.WordArray.create(arrayBuffer as ArrayBuffer);
      const hash = MD5(wordArray).toString(enc.Base64);
      return resolve(hash);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

export const formatBytes = (bytes: number, decimals = 0) => {
  if (bytes == 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
