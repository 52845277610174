import {
  Close as CloseIcon,
  PlaylistAddRounded as PlaylistAddRoundedIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  autocompleteClasses,
  buttonBaseClasses,
  createFilterOptions,
  MenuItem,
  outlinedInputClasses,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { uniq } from 'lodash';
import { memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { highlightText } from 'utils/text.utils';

import { useSearchState } from 'hooks';

type Props = {
  options: string[];
  value: string | null;
  disabled?: boolean;
  onChange: (section: string) => void;
};

const filter = createFilterOptions<string>();

const MenuSectionAutocomplete = ({ options, value, disabled, onChange }: Props) => {
  const intl = useIntl();
  const { searchValue, debouncedSearchValue, handleSearchChange } = useSearchState();

  const handleOnChange = useCallback(
    (selectedOption: string | null) => {
      // when clearing value
      if (selectedOption === null) return onChange('');

      // if creating new option
      if (
        !!selectedOption &&
        !options.find((opt) => opt.toLowerCase().trim() === selectedOption.toLowerCase().trim())
      ) {
        handleSearchChange(selectedOption);
        onChange(selectedOption);
      }

      // if existing option
      return onChange(selectedOption);
    },
    [onChange, options, handleSearchChange],
  );

  return (
    <Autocomplete
      value={value}
      disabled={disabled}
      options={options}
      inputValue={searchValue}
      onChange={(_, val) => handleOnChange(val)}
      freeSolo
      onInputChange={(e, value) => handleSearchChange(value === null ? '' : value)}
      clearOnEscape
      clearOnBlur
      selectOnFocus
      handleHomeEndKeys
      blurOnSelect
      clearIcon={<CloseIcon sx={{ width: 16, height: 16 }} />}
      componentsProps={{
        clearIndicator: {
          sx: {
            mr: 0.5,
            height: 24,
            width: 24,
            alignItems: 'center',
            display: value || debouncedSearchValue ? 'flex' : 'none',
          },
          onClick: () => {
            handleSearchChange('');
            handleOnChange(null);
          },
        },
        paper: { sx: { width: 240 } },
        popper: { sx: { zIndex: 2000 }, popperOptions: { placement: 'bottom-end' } },
      }}
      ListboxProps={{
        role: 'list-box',
        sx: { [`& .${buttonBaseClasses.root}`]: { pl: 2, pr: 1, py: 1 } },
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (
          params.inputValue !== '' &&
          !options.find(
            (opt) => opt.toLowerCase().trim() === params.inputValue.toLowerCase().trim(),
          )
        )
          filtered.unshift(params.inputValue);
        return uniq(filtered);
      }}
      renderOption={(props, option, state) =>
        !!debouncedSearchValue &&
        !options.find((opt) => opt.toLowerCase().trim() === option.toLowerCase().trim()) ? (
          <MenuItem
            {...props}
            sx={{ px: 2, py: 1.5, gap: 2, display: 'flex', alignItems: 'center' }}
            key={option}
          >
            <PlaylistAddRoundedIcon sx={{ color: 'black.60', width: 24 }} />
            <Stack>
              <Typography variant="body1">
                <FormattedMessage id="menus.section.add" />
              </Typography>
              <Typography
                variant="body2"
                overflow="clip"
                textOverflow="ellipsis"
                width="100%"
                color="black.60"
              >
                {option}
              </Typography>
            </Stack>
          </MenuItem>
        ) : (
          // existing item
          <MenuItem
            {...props}
            sx={{
              px: 2,
              py: 1.5,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              gap: 1,
            }}
            key={option}
          >
            <Typography overflow="clip" textOverflow="ellipsis" width="100%">
              {highlightText(state.inputValue, option || '')}
            </Typography>
          </MenuItem>
        )
      }
      renderInput={(props) => (
        <TextField
          variant="outlined"
          sx={(theme) => ({
            borderRadius: theme.mixins.borderRadius.full,
            [`& .${autocompleteClasses.input}`]: { width: '132px !important' },
            [`& .${outlinedInputClasses.root}`]: { py: 0, px: 1.5, height: '100%' },
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderRadius: theme.mixins.borderRadius.full,
            },
          })}
          {...props}
          inputProps={{ ...props.inputProps, maxLength: 255 }}
          placeholder={intl.formatMessage({ id: 'menus.section.label' })}
        />
      )}
    />
  );
};

export default memo(MenuSectionAutocomplete);
