import { resetPassword, ResetPasswordOutput } from '@aws-amplify/auth';
import { useMutation } from '@tanstack/react-query';

import { CognitoError } from 'types/auth.types';

interface Variables {
  email: string;
}

export const useSendForgotPasswordMail = () => {
  const { mutate, isPending, error } = useMutation<ResetPasswordOutput, CognitoError, Variables>({
    mutationFn: ({ email }: Variables) => resetPassword({ username: email.trim() }),
  });

  return {
    sendForgotPasswordMail: mutate,
    isPending,
    error,
  };
};
