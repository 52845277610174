import { Components, Theme } from '@mui/material';

export const MuiInput: Components<Theme>['MuiInput'] = {
  defaultProps: {
    disableUnderline: true,
    fullWidth: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.mixins.borderRadius.lg,
      paddingInline: theme.spacing(1.5),
      paddingBlock: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
    }),
  },
};
