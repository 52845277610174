import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import Insights from './Insights';

export default createRoute({
  element: <Insights />,
  path: Routes.Insights,
  authenticated: true,
});
