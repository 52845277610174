import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, ButtonGroup, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { MouseEvent, useState } from 'react';

interface Props {
  loading: boolean;
  menuItems?: {
    label: React.ReactNode;
    icon?: React.ReactNode;
    onClick: () => void;
    action?: string;
  }[];
  startIcon?: React.ReactNode;
  buttonText: React.ReactNode;
  variant?: 'contained' | 'outlined';
  color?: 'primary' | 'secondary'; // will probably have to expand on this prop
  onClick?: () => void;
  testId?: string;
}

const ButtonWithMenu = ({
  loading,
  menuItems = [],
  buttonText,
  startIcon,
  variant = 'contained',
  color = 'primary',
  onClick,
  testId,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onOpenSelect = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget.parentElement);
  const onCloseSelect = () => setAnchorEl(null);

  return (
    <>
      <ButtonGroup variant={variant} color={color}>
        <LoadingButton
          data-testid={testId}
          startIcon={startIcon}
          loading={loading}
          onClick={onClick}
          sx={{ cursor: onClick ? undefined : 'default' }}
        >
          {buttonText}
        </LoadingButton>

        {menuItems.length && (
          <Button
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="show more options"
            aria-haspopup="menu"
            sx={{ px: 0 }}
            disabled={loading}
            onClick={onOpenSelect}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>

      <Menu anchorEl={anchorEl} open={open} onClose={onCloseSelect}>
        {menuItems.map(({ label, icon, onClick, action }, i) => (
          <MenuItem
            key={i}
            data-testid={`action${i + 1}-${action}`}
            onClick={() => {
              onClick();
              onCloseSelect();
            }}
          >
            {icon && <ListItemIcon>{icon}</ListItemIcon>}

            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ButtonWithMenu;
