import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { Menu } from 'types/menus.types';

const recheckMenuItemMatches = async (id: Menu['id']) => {
  const response = await apiService.recheckMenuItemMatches(id);
  return response;
};

export const useRecheckMenuItemMatches = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: recheckMenuItemMatches,
    onSuccess: (_, menuId) => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.menus.byId(menuId) });

      notifications.success({
        message: intl.formatMessage({ id: 'menus.actions.recheck_matches.success' }),
      });
    },
    onError: (err) => {
      console.error(err);
      notifications.error({
        message: intl.formatMessage({ id: 'common.error_messages.error' }),
      });
    },
  });

  return {
    recheckMenuItems: mutate,
    isPending,
  };
};
