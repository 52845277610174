import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { RecipeFormValues, RecipeNoteSections } from 'types/recipes.types';

import { SortableList } from 'components/@common';
import { useIsInMenuItemDrawer } from 'components/@menu-form';

import { RemarkItem } from './RemarkItem';

type Props = {
  isReadOnly: boolean;
};

const RecipeFormRemarks = ({ isReadOnly }: Props) => {
  const isInMenuItemDrawer = useIsInMenuItemDrawer();

  const { fields, append, remove, move } = useFieldArray<RecipeFormValues>({
    name: 'notes',
  });

  return (
    <Box display="flex" flexDirection="column" gap={1} className={!fields.length ? 'no-print' : ''}>
      <Typography>
        <FormattedMessage id="recipes.stockage.label" />
      </Typography>
      <Box
        width="100%"
        bgcolor="bg.paper"
        overflow="hidden"
        borderRadius={(theme) => theme.mixins.borderRadius.base}
      >
        <SortableList
          items={fields}
          addButtonTestId="add-remark-button"
          placeholder="recipes.remarks.empty"
          renderItem={(index) => <RemarkItem index={index} isReadOnly={isReadOnly} />}
          isReadOnly={isReadOnly}
          withBottomBorder={isInMenuItemDrawer}
          buttonText={<FormattedMessage id="recipes.remarks.add" />}
          onAddItem={() => append({ note: '', section: RecipeNoteSections.Remarks })}
          onRemoveItem={remove}
          onMoveItem={move}
        />
      </Box>
    </Box>
  );
};

export default memo(RecipeFormRemarks);
