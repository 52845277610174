import { IndexRouteObject, NonIndexRouteObject } from 'react-router-dom';

import { RouteConfig } from 'types/routes.types';

import { CheckAuth } from 'components/@auth';

/**
 * Helper function to create a route with extra authentication logic
 */
export const createRoute = (config: NonIndexRouteObject & RouteConfig) => {
  if (!config?.authenticated) return config;

  return {
    ...config,
    element: <CheckAuth>{config.element}</CheckAuth>,
  };
};

/**
 * Helper function to create an index route with extra authentication logic
 * https://reactrouter.com/en/main/start/concepts#index-routes
 */
export const createIndexRoute = (config: IndexRouteObject & RouteConfig) => {
  if (!config?.authenticated) return config;

  return {
    ...config,
    element: <CheckAuth>{config.element}</CheckAuth>,
  };
};
