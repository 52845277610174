import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { QueryKeys, useRecipeById } from 'queries';

import { RecipeForm, RecipeSkeleton } from 'components/@recipe-form';

const RecipeDetail = () => {
  const intl = useIntl();
  const { recipeId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    // clear cache when unloading page, so we always get a new version when loading this page again
    if (recipeId) {
      return () => {
        queryClient.removeQueries({ queryKey: QueryKeys.recipes.byId(Number(recipeId)) });
      };
    }
  }, [queryClient, recipeId]);

  const { data: recipe, isLoading } = useRecipeById(Number(recipeId));

  useEffect(() => {
    if (!isLoading && !recipe) {
      navigate(Routes.Root);
    }
  }, [recipe, isLoading, navigate]);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'alain_ai.label' })} -{' '}
          {intl.formatMessage({ id: 'recipes.creator.label' })}
        </title>
      </Helmet>

      {isLoading && <RecipeSkeleton />}
      {!isLoading && !!recipe && <RecipeForm initialRecipe={recipe} />}
    </>
  );
};

export default RecipeDetail;
