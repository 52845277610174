import { Components, switchClasses, Theme } from '@mui/material';

export const MuiSwitch: Components<Theme>['MuiSwitch'] = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    colorPrimary: ({ theme }) => ({
      [`&.${switchClasses.checked}`]: {
        color: theme.palette.white[100],
      },
      [`&.${switchClasses.checked} + .${switchClasses.track}`]: {
        backgroundColor: theme.palette.global01.main,
        opacity: 1,
      },
    }),
  },
};
