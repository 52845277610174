import { InsertDriveFile as InsertDriveFileIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, Collapse, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { formatBytes } from 'utils/file.utils';

import { MAX_PDF_FILESIZE } from 'constants/forms.constants';
import { Routes } from 'constants/routes.constants';
import { useUser } from 'queries';
import { useUploadMedia } from 'queries/media/useUploadMedia';
import { useCreateMenu } from 'queries/menus/useCreateMenu';
import { StorageMediaContext } from 'types/media.types';

import { BackButton } from 'components/@common/BackButton';
import { CollapseFade } from 'components/@utils';

const MenuUploadForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { user } = useUser();
  const { uploadMedia, isPending: isUploadingMenu } = useUploadMedia();
  const { createMenu, isPending: isCreatingMenu } = useCreateMenu();

  const [error, setError] = useState<string | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setError(null);

      uploadMedia(
        {
          file: acceptedFiles[0],
          options: { storageFolder: user?.group.name || '', context: StorageMediaContext.Menu },
        },
        {
          onSuccess: (res) => {
            createMenu(
              { pdf_file: res.name, seasons: [], name: null },
              {
                onSuccess: (res) =>
                  navigate(generatePath(Routes.MenuDetail, { menuId: String(res.data.id) })),
              },
            );
          },
        },
      );
    },
    [user, navigate, uploadMedia, createMenu],
  );

  const onDropRejected = useCallback(
    (rejectedFiles: FileRejection[]) => {
      if (
        rejectedFiles.some((file) =>
          file.errors.some((error) => error.code === ErrorCode.FileTooLarge),
        )
      ) {
        setError(
          intl.formatMessage(
            { id: 'form.validation.max_size' },
            { size: formatBytes(MAX_PDF_FILESIZE) },
          ),
        );
      } else if (
        rejectedFiles.some((file) =>
          file.errors.some((error) => error.code === ErrorCode.FileInvalidType),
        )
      ) {
        setError(intl.formatMessage({ id: 'menus.upload.validation.file_type' }));
      } else {
        setError(intl.formatMessage({ id: 'menus.upload.validation.generic' }));
      }
    },
    [intl, setError],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'application/pdf': ['.pdf'] },
    maxSize: MAX_PDF_FILESIZE,
    multiple: false,
    onDrop,
    onDropRejected,
  });

  const isLoading = isUploadingMenu || isCreatingMenu;

  return (
    <CollapseFade in sx={{ bgcolor: 'transparent', m: 0 }} timeout={600}>
      <Box display="flex" flexDirection="column" gap={3} height="100%" width="100%">
        <BackButton to={Routes.MenuOverview} />

        <Typography variant="h1">
          <FormattedMessage id="menus.upload.title" />
        </Typography>

        <input type="file" {...getInputProps()} />
        <Box
          bgcolor="bg.paper"
          display="flex"
          width="100%"
          p={10}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          borderRadius={2}
          position="relative"
          sx={{ cursor: 'pointer' }}
          {...getRootProps()}
        >
          <Box
            position="absolute"
            borderRadius={1.5}
            border="2px dashed"
            sx={{
              inset: 24,
              borderColor: isDragActive ? 'bluegrey.800' : 'bluegrey.100',
              transition: 'all 0.2s ease-in',
              '&:hover': {
                borderColor: 'bluegrey.800',
              },
            }}
          />
          <InsertDriveFileIcon sx={{ height: '80px', width: '80px', color: 'bluegrey.200' }} />
          <Stack gap={3} mt={10} alignItems="center" position="relative" width="80%">
            <Stack
              gap={3}
              alignItems="center"
              sx={{
                position: 'absolute',
                zIndex: 1,
                opacity: isLoading ? 1 : 0,
                transition: 'opacity 0.2s ease-in',
              }}
            >
              <CircularProgress size="50px" />
              <Typography variant="body1" textAlign="center">
                <FormattedMessage id="menus.upload.uploading" />
              </Typography>
            </Stack>

            <Stack
              sx={{ opacity: isLoading ? 0 : 1, transition: 'opacity 0.2s ease-in' }}
              gap={3}
              alignItems="center"
            >
              <Button variant="contained" startIcon={<InsertDriveFileIcon />}>
                <FormattedMessage id="menus.upload.button" />
              </Button>
              <Typography variant="body1" textAlign="center">
                <FormattedMessage id="menus.upload.button.disclaimer" />
              </Typography>

              <Collapse in={!!error}>
                <Typography
                  variant="body1"
                  sx={{ color: (theme) => theme.palette.error.main }}
                  fontWeight={800}
                >
                  {error}
                </Typography>
              </Collapse>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </CollapseFade>
  );
};

export default MenuUploadForm;
