import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
  fontSize: 16,
  fontFamily: 'Roboto',
  h1: {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontWeight: 420,
    fontSize: '2.5rem',
    lineHeight: '1.2em',
    letterSpacing: '-0.03125rem',
  },
  h2: {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontWeight: 420,
    fontSize: '2.2rem',
    lineHeight: '1.235em',
    letterSpacing: '0.015625rem',
  },
  h3: {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '1.75rem',
    letterSpacing: '0.009375rem',
  },
  h4: {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontWeight: 420,
    fontSize: '2.25rem',
    lineHeight: '1.23em',
    letterSpacing: '0.015625rem',
  },
  h5: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '1.75rem',
    letterSpacing: '0.009375rem',
  },
  h6: {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: '1.6rem',
    letterSpacing: '0.0075em',
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5em',
    letterSpacing: '0.009375rem',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.57rem',
    letterSpacing: '0.00625rem',
  },
  subtitle3: {
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: '1.6rem',
    letterSpacing: '0.01rem',
  },
  body1: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.009375rem',
  },
  body2: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.009375rem',
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
    letterSpacing: '0.025rem',
  },
  button: {
    fontWeight: 500,
    fontSize: '0.9375rem',
    lineHeight: '1.625rem',
    letterSpacing: '0.02875rem',
    textTransform: 'none',
  },
  buttonSmall: {
    fontSize: '0.8125rem',
    fontWeight: 500,
    lineHeight: '1.375rem',
    letterSpacing: '0.02875rem',
    textTransform: 'uppercase',
  },
  chip: {
    fontSize: '0.8125rem',
    fontWeight: 400,
    lineHeight: '1.125rem',
    letterSpacing: '0.01rem',
  },
  tooltip: {
    fontSize: '0.625rem',
    fontWeight: 500,
    lineHeight: '0.875rem',
  },
  tableHeader: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1rem',
    letterSpacing: '0.01rem',
  },
  label: {
    fontSize: '0.5625rem',
    fontWeight: 700,
    lineHeight: '0.659375rem',
    letterSpacing: '0.02875rem',
    textTransform: 'uppercase',
  },
  mono: {
    fontFamily: 'Roboto Mono, monospace',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
    letterSpacing: '0.01rem',
  },
};

export default typography;
