import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { MenuFormData, MenuItem } from 'types/menus.types';

export const useMenuValidation = () => {
  const intl = useIntl();
  const { getValues } = useFormContext<MenuFormData>();

  const getValidationErrors = useCallback(
    (menuItems?: MenuItem[]) => {
      const errors: string[] = [];

      const errorFields = {
        name: !getValues('name'),
        year: !getValues('year'),
        seasons: !getValues('seasons').length,
      };

      Object.entries(errorFields).forEach(([field, error]) => {
        if (error) {
          errors.push(intl.formatMessage({ id: `menus.validation_dialog.${field}` as I18nKey }));
        }
      });

      if (typeof menuItems !== 'undefined') {
        if (!menuItems.length) {
          errors.push(intl.formatMessage({ id: 'menus.items.validation.no_items' }));
        }
        const noNamesCount = menuItems.filter((item) => !item.name).length;
        const noSectionsCount = menuItems.filter((item) => !item.section).length;
        if (noNamesCount > 0) {
          if (noNamesCount === 1) {
            errors.push(intl.formatMessage({ id: 'menus.items.validation.no_names.singular' }));
          } else {
            errors.push(
              intl.formatMessage(
                { id: 'menus.items.validation.no_names' },
                { amount: noNamesCount },
              ),
            );
          }
        }
        if (noSectionsCount > 0) {
          if (noSectionsCount === 1) {
            errors.push(intl.formatMessage({ id: 'menus.items.validation.no_sections.singular' }));
          } else {
            errors.push(
              intl.formatMessage(
                { id: 'menus.items.validation.no_sections' },
                { amount: noSectionsCount },
              ),
            );
          }
        }
      }

      return errors;
    },
    [intl, getValues],
  );

  return getValidationErrors;
};
