import queryString from 'query-string';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useSearchFilterStore } from 'store';
import { ActiveFilters, ToggleFilterFn } from 'types/common.types';
import { BaseRecipeFilters, OptionalRecipeFilters } from 'types/recipes.types';

type Props<T extends string> = {
  name: string;
  filters: ActiveFilters<T>;
  setFilters: (newFilters: ActiveFilters<T>) => void;
  selectedOptionalFilters?: T[];
  setSelectedOptionalFilters?: (newFilters: T[]) => void;
  save?: boolean;
};

type FilterParams = {
  [key: string]: string[];
};

export const useTableFilters = <T extends string>({
  name,
  filters,
  setFilters,
  selectedOptionalFilters,
  setSelectedOptionalFilters,
  save,
}: Props<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterStore = useSearchFilterStore(name)((state) => state.filters);
  const setFilterStore = useSearchFilterStore(name)((state) => state.setFilters);

  useEffect(() => {
    // Set params according to filters object
    const filtersAsParams = Object.keys(filters).reduce((acc, filterKey) => {
      if (Array.isArray(filters[filterKey as T]) && !!filters[filterKey as T].length) {
        acc[filterKey] = filters[filterKey as T] as string[];
      }
      return acc;
    }, {} as FilterParams);

    if (save) {
      setSearchParams(filtersAsParams, { replace: true });
      setFilterStore(filtersAsParams);
    }
  }, [save, filters, setFilterStore, setSearchParams]);

  useEffect(() => {
    if (!save) return;

    // Extract filters from URL
    const params = queryString.parse(searchParams.toString());
    const newFilters = { ...filters } as ActiveFilters<T>;

    // Handle default filters
    Object.keys(BaseRecipeFilters).forEach((filterKey) => {
      const filterValue = params[filterKey] || filterStore[filterKey];
      if (!filterValue) return;
      newFilters[filterKey as T] = (
        Array.isArray(filterValue) ? filterValue : [filterValue]
      ) as string[];
    });
    // Handle optional filters
    Object.keys(OptionalRecipeFilters).forEach((filterKey) => {
      const filterValue = params[filterKey] || filterStore[filterKey];
      if (!filterValue) return;
      if (!selectedOptionalFilters?.includes(filterKey as T)) toggleOptionalFilter(filterKey as T);

      newFilters[filterKey as T] = (
        Array.isArray(filterValue) ? filterValue : [filterValue]
      ) as string[];
    });

    setFilters(newFilters);
    setFilterStore(newFilters as FilterParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This effect should only run on mount
  }, []);

  const toggleFilter: ToggleFilterFn<T> = useCallback(
    (filterType, filterOption) => {
      if (filters[filterType].includes(filterOption)) {
        const updatedFilters = {
          ...filters,
          [filterType]: filters[filterType].filter((item) => item !== filterOption),
        };
        setFilters(updatedFilters);
        if (save) setFilterStore(updatedFilters as FilterParams);
      } else {
        const updatedFilters = {
          ...filters,
          [filterType]: [...filters[filterType], filterOption],
        };
        setFilters(updatedFilters);
        if (save) setFilterStore(updatedFilters as FilterParams);
      }
    },
    [save, filters, setFilters, setFilterStore],
  );

  let removeOptionalFilter: (filter: T) => void = () => {};
  let toggleOptionalFilter: (filter: T) => void = () => {};

  if (selectedOptionalFilters && setSelectedOptionalFilters) {
    removeOptionalFilter = (filter: T) => {
      const updatedExtraFilters = selectedOptionalFilters.filter((item) => item !== filter);
      setSelectedOptionalFilters(updatedExtraFilters);
      const updatedFilters = { ...filters, [filter]: [] };
      setFilters(updatedFilters);
    };
    toggleOptionalFilter = (filter: T) => {
      if (selectedOptionalFilters?.includes(filter)) removeOptionalFilter(filter);
      else {
        const updatedFilters = [...selectedOptionalFilters, filter];
        setSelectedOptionalFilters(updatedFilters);
      }
    };
  }

  return {
    activeFilters: filters,
    activeOptionalFilters: selectedOptionalFilters,
    toggleFilter,
    toggleOptionalFilter,
    removeOptionalFilter,
  };
};
