import {
  Check as IconCheck,
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  SvgIconComponent,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';

import { NotificationPayload, NotificationTypes } from 'services/snackbar/snackbar.types';

const iconLookup: Record<NotificationTypes, SvgIconComponent> = {
  [NotificationTypes.Warning]: WarningIcon,
  [NotificationTypes.Error]: ErrorIcon,
  [NotificationTypes.Success]: IconCheck,
  [NotificationTypes.Info]: InfoIcon,
};

type Props = NotificationPayload & {
  customIcon?: React.ReactNode;
  notificationKey?: string;
  withClose?: boolean;
};

const Notification = ({
  customIcon,
  message,
  cta,
  type,
  notificationKey,
  withClose = true,
  onClick,
}: Props) => {
  const theme = useTheme();
  const { closeSnackbar } = useSnackbar();

  const Icon = iconLookup[type];

  const colorLookup = {
    [NotificationTypes.Success]: {
      background: theme.palette.success.lighter,
      message: theme.palette.black[100],
      action: theme.palette.green[700],
    },
    [NotificationTypes.Warning]: {
      background: theme.palette.warning.lighter,
      message: theme.palette.black[100],
      action: theme.palette.warning.darker,
    },
    [NotificationTypes.Error]: {
      background: theme.palette.error.lighter,
      message: theme.palette.black[100],
      action: theme.palette.error.dark,
    },
    [NotificationTypes.Info]: {
      background: theme.palette.info.lighter,
      message: theme.palette.black[100],
      action: theme.palette.info.main,
    },
  };
  const color = colorLookup[type];

  return (
    <Box
      bgcolor={color}
      width="100%"
      height="100%"
      display="flex"
      padding={2}
      sx={{ wordBreak: 'break-word' }}
      alignItems="center"
      borderRadius={1}
      minWidth={320}
      gap={1}
    >
      {customIcon
        ? customIcon
        : Icon && <Icon sx={{ width: 20, height: 20, fill: color.action }} />}
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Typography variant="body2" sx={{ color: color.message }} mr={8}>
          {message}
        </Typography>

        {onClick && cta && (
          <Button variant="text" color="inherit" sx={{ px: 0.5 }} onClick={onClick}>
            <Typography sx={{ color: color.action }} fontWeight={800}>
              {cta}
            </Typography>
          </Button>
        )}
      </Box>

      {withClose && (
        <IconButton
          sx={{ height: 24, width: 24 }}
          onClick={() => closeSnackbar(notificationKey)}
          aria-label="close"
        >
          <CloseIcon sx={{ fill: color.action, height: 20, width: 20 }} />
        </IconButton>
      )}
    </Box>
  );
};

export default Notification;
