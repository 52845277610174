import { ActiveFilters } from 'types/common.types';
import { MenuFilters } from 'types/menus.types';

export const EMPTY_MENU_FILTERS: ActiveFilters<MenuFilters> = {
  [MenuFilters.country]: [],
  [MenuFilters.season]: [],
  [MenuFilters.year]: [],
  [MenuFilters.status]: [],
};

export const RECIPE_GRID_IMAGE_HEIGHT = 195;
