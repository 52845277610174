import { PaletteOptions } from '@mui/material/styles/createPalette';

const palette: PaletteOptions = {
  global01: {
    main: '#293735',
    light: '#364946',
    200: 'rgba(41, 55, 53, 0.1)',
    100: 'rgba(41, 55, 53, 0.07)',
    50: 'rgba(41, 55, 53, 0.04)',
  },
  global02: {
    700: '#785900',
    main: '#FDC400',
  },
  text: {
    primary: '#000',
    secondary: '#fff',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  tonalOffset: 0.04,
  primary: {
    darker: '#A77C00',
    dark: '#c29704',
    main: '#FDC400',
    light: '#FCEBBC',
    lighter: '#FFFDE7',
    contrastText: '#000',
    '100': 'rgba(255, 253, 231, 0.5)',
    '200': '#EBE9D3',
  },
  secondary: {
    dark: '#388E3C',
    main: '#4CAF50',
    contrastText: '#fff',
  },
  tertiary: {
    main: '#0288D1',
    contrastText: '#fff',
  },
  bg: {
    paper: '#fff',
    default: '#FBF5EE',
    dark: '#424242',
  },
  grey: {
    900: '#101828',
    600: '#475467',
    500: '#616161',
    400: '#E7E1DB',
    300: '#E3E3E3',
    200: '#EEEEEE',
    100: '#F2F4F7',
    50: '#F6F7F7',
  },
  bluegrey: {
    900: '#263238',
    800: '#37474F',
    700: '#455A64',
    600: '#546E7A',
    500: '#607D8B',
    400: '#78909C',
    300: '#90A4AE',
    200: '#B0BEC5',
    100: '#CFD8DC',
    50: '#ECEFF1',
  },
  white: {
    4: 'rgba(255, 255, 255, 0.04)',
    10: 'rgba(255, 255, 255, 0.1)',
    20: 'rgba(255, 255, 255, 0.2)',
    30: 'rgba(255, 255, 255, 0.3)',
    40: 'rgba(255, 255, 255, 0.4)',
    50: 'rgba(255, 255, 255, 0.5)',
    60: 'rgba(255, 255, 255, 0.6)',
    70: 'rgba(255, 255, 255, 0.7)',
    80: 'rgba(255, 255, 255, 0.8)',
    90: 'rgba(255, 255, 255, 0.9)',
    100: '#fff',
  },
  black: {
    4: 'rgba(0, 0, 0, 0.04)',
    10: 'rgba(0, 0, 0, 0.1)',
    20: 'rgba(0, 0, 0, 0.2)',
    30: 'rgba(0, 0, 0, 0.3)',
    40: 'rgba(0, 0, 0, 0.4)',
    50: 'rgba(0, 0, 0, 0.5)',
    60: 'rgba(0, 0, 0, 0.6)',
    70: 'rgba(0, 0, 0, 0.7)',
    80: 'rgba(0, 0, 0, 0.8)',
    90: 'rgba(0, 0, 0, 0.9)',
    100: '#000',
  },
  green: {
    100: '#C8E6C9',
    200: '#A5D6A7',
    300: '#81C784',
    400: '#66BB6A',
    500: '#4CAF50',
    600: '#43A047',
    700: '#388E3C',
    800: '#2E7D32',
    900: '#1B5E20',
  },
  orange: {
    100: '#FFE0B2',
    200: '#FFCC80',
    300: '#FFB74D',
    400: '#FFA726',
    500: '#FF9800',
    600: '#FB8C00',
    700: '#F57C00',
    800: '#EF6C00',
    900: '#E65100',
  },
  red: {
    100: '#FFCDD2',
    200: '#EF9A9A',
    300: '#E57373',
    400: '#EF5350',
    500: '#F44336',
    600: '#E53935',
    700: '#D32F2F',
    800: '#C62828',
    900: '#B71C1C',
  },
  lightBlue: {
    50: '#E1F5FE',
    900: '#01579B',
  },
  success: {
    main: '#4CAF50',
    light: '#C5E1A5',
    lighter: '#C8E6C9',
  },
  warning: {
    darker: '#ED6C02',
    dark: '#E65100',
    main: '#FFA726',
    light: '#FDECE1',
    lighter: '#FDECE1',
  },
  error: {
    dark: '#D32F2F',
    main: '#EF5350',
    light: '#FF9FAD',
    lighter: '#FAE6E6',
  },
  info: {
    main: '#1976D2',
    light: '#DDE8EB',
    lighter: '#E1F2F9',
  },
};

export default palette;
