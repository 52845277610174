import { confirmSignIn, ConfirmSignInOutput } from '@aws-amplify/auth';
import { useMutation } from '@tanstack/react-query';

import { CognitoError } from 'types/auth.types';

interface Variables {
  code: string;
}

export const useSubmitMfa = () => {
  const { mutate, isPending, error } = useMutation<ConfirmSignInOutput, CognitoError, Variables>({
    mutationFn: ({ code }: Variables) => confirmSignIn({ challengeResponse: code }),
  });
  return {
    submitMFA: mutate,
    isPending,
    error,
  };
};
