import { ContentCopy as CopyIcon } from '@mui/icons-material';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import { FormattedMessage } from 'react-intl';

import { useTransformRecipe } from 'utils/recipe.utils';

import { useCreateRecipeVariant, useRecipeById } from 'queries';
import { Recipe } from 'types/recipes.types';

interface Props {
  originalRecipe: Recipe;
  isLoading: boolean;
  selectRecipe: (recipe: Recipe, params?: { silent?: boolean }) => void;
}

const CreateVariantButton = ({ originalRecipe, isLoading, selectRecipe }: Props) => {
  const posthog = usePostHog();

  const { isFetching: isFetchingOriginalRecipe, refetch } = useRecipeById(originalRecipe.id, {
    enabled: false,
  });
  const { createRecipeVariant, isPending: isCreatingVariant } = useCreateRecipeVariant();
  const { transformApiRecipeToForm } = useTransformRecipe();

  const createVariant = async () => {
    if (isCreatingVariant || isFetchingOriginalRecipe) return;

    // because we only have a minimal version of the recipe, we need to fetch the full version first before creating a variant
    const fullOriginalRecipe = (await refetch())?.data?.data;

    createRecipeVariant(transformApiRecipeToForm(fullOriginalRecipe), {
      onSuccess: ({ data }) => {
        posthog.capture('create_recipe', { type: 'variant', source: 'menu', name: data.name });
        selectRecipe(data, { silent: true });
      },
    });
  };

  const isLoadingAny = isCreatingVariant || isFetchingOriginalRecipe || isLoading;

  return (
    <Tooltip
      sx={{ ml: 'auto' }}
      title={<FormattedMessage id="menus.items.linked_recipe.variant" />}
    >
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          createVariant();
        }}
        disabled={isLoadingAny}
      >
        {isLoadingAny ? (
          <CircularProgress size="small" sx={{ height: 24, width: 24, color: 'black.50' }} />
        ) : (
          <CopyIcon sx={{ height: 24, width: 24, color: 'black.50' }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default CreateVariantButton;
