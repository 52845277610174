import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
} from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useMenuComplianceRules } from 'queries';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const MenuComplianceDialog = ({ isOpen, onClose }: Props) => {
  const { data: complianceRules, isLoading: isLoadingComplianceRules } = useMenuComplianceRules({
    enabled: isOpen,
  });

  const corePercentages = useMemo(
    () => ({
      globalPercentage: complianceRules?.globalDishRules?.[0]?.rangeMin,
      localPercentage: 100 - (complianceRules?.globalDishRules?.[0]?.rangeMin || 0),
    }),
    [complianceRules],
  );

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="menus.compliance.text.based_on" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <List dense sx={{ listStyle: 'decimal', pl: 2, py: 0 }}>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <FormattedMessage
                id="menus.compliance.text.item_1"
                values={{
                  globalPercentage: corePercentages.globalPercentage,
                  localPercentage: corePercentages.localPercentage,
                }}
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <FormattedMessage
                id="menus.compliance.text.item_2"
                values={{
                  maxItems: isLoadingComplianceRules ? (
                    <CircularProgress size={12} />
                  ) : (
                    complianceRules?.maxItems
                  ),
                }}
              />
            </ListItem>
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onClose}>
          <FormattedMessage id="common.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MenuComplianceDialog;
