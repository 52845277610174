import { SortDirection } from '@mui/material';
import { useMemo } from 'react';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface SearchFilterState {
  sortBy: string;
  setSortBy: (newSortBy: string) => void;
  sortDirection: SortDirection;
  setSortDirection: (newSortDirection: SortDirection) => void;
  search: string;
  setSearch: (newSearch: string) => void;
}

export const useSearchAndSortStore = ({ name }: { name: string }) => {
  return useMemo(
    () =>
      create<SearchFilterState>()(
        devtools(
          persist(
            (set) => ({
              sortBy: '',
              setSortBy: (newSort) => set({ sortBy: newSort }),
              sortDirection: 'desc',
              setSortDirection: (newSortDirection) => set({ sortDirection: newSortDirection }),
              search: '',
              setSearch: (newSearch) => set({ search: newSearch }),
            }),
            {
              name: `${name}-search-and-sort`,
              storage: createJSONStorage(() => sessionStorage),
            },
          ),
        ),
      ),
    [name],
  );
};
