import BrandonGrotesque from 'assets/fonts/BrandonGrotesque.woff2';
import BrandonGrotesqueBold from 'assets/fonts/BrandonGrotesque-bold.woff2';
import BrandonGrotesqueBoldItalic from 'assets/fonts/BrandonGrotesque-bold-italic.woff2';
import BrandonGrotesqueBolder from 'assets/fonts/BrandonGrotesque-bolder.woff2';
import BrandonGrotesqueBolderItalic from 'assets/fonts/BrandonGrotesque-bolder-italic.woff2';
import BrandonGrotesqueBoldest from 'assets/fonts/BrandonGrotesque-boldest.woff2';
import BrandonGrotesqueBoldestItalic from 'assets/fonts/BrandonGrotesque-boldest-italic.woff2';
import BrandonGrotesqueItalic from 'assets/fonts/BrandonGrotesque-italic.woff2';
import BrandonGrotesqueLight from 'assets/fonts/BrandonGrotesque-light.woff2';
import BrandonGrotesqueLightItalic from 'assets/fonts/BrandonGrotesque-light-italic.woff2';
import Roboto from 'assets/fonts/Roboto.woff2';
import RobotoBold from 'assets/fonts/Roboto-bold.woff2';
import RobotoBoldItalic from 'assets/fonts/Roboto-bold-italic.woff2';
import RobotoBolder from 'assets/fonts/Roboto-bolder.woff2';
import RobotoBolderItalic from 'assets/fonts/Roboto-bolder-italic.woff2';
import RobotoBoldest from 'assets/fonts/Roboto-boldest.woff2';
import RobotoBoldestItalic from 'assets/fonts/Roboto-boldest-italic.woff2';
import RobotoItalic from 'assets/fonts/Roboto-italic.woff2';
import RobotoLight from 'assets/fonts/Roboto-light.woff2';
import RobotoLightItalic from 'assets/fonts/Roboto-light-italic.woff2';
import RobotoLighter from 'assets/fonts/Roboto-lighter.woff2';
import RobotoLighterItalic from 'assets/fonts/Roboto-lighter-italic.woff2';

export const fonts = `
@font-face {
  font-family: 'brandon-grotesque';
  src: url(${BrandonGrotesque}) format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src: url(${BrandonGrotesqueItalic}) format('woff2');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src: url(${BrandonGrotesqueBolder}) format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src: url(${BrandonGrotesqueBolderItalic}) format('woff2');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src: url(${BrandonGrotesqueLight}) format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src: url(${BrandonGrotesqueLightItalic}) format('woff2');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src: url(${BrandonGrotesqueBold}) format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src: url(${BrandonGrotesqueBoldItalic}) format('woff2');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src: url(${BrandonGrotesqueBoldest}) format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: 'brandon-grotesque';
  src: url(${BrandonGrotesqueBoldestItalic}) format('woff2');
  font-display: auto;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: 'roboto';
  src: url(${RobotoBoldest}) format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: 'roboto';
  src: url(${RobotoBoldestItalic}) format('woff2');
  font-display: auto;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: 'roboto';
  src: url(${RobotoBolder}) format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'roboto';
  src: url(${RobotoBolderItalic}) format('woff2');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'roboto';
  src: url(${RobotoItalic}) format('woff2');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'roboto';
  src: url(${RobotoLight}) format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'roboto';
  src: url(${RobotoLightItalic}) format('woff2');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'roboto';
  src: url(${RobotoBold}) format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'roboto';
  src: url(${RobotoBoldItalic}) format('woff2');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'roboto';
  src: url(${Roboto}) format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'roboto';
  src: url(${RobotoLighter}) format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
}

@font-face {
  font-family: 'roboto';
  src: url(${RobotoLighterItalic}) format('woff2');
  font-display: auto;
  font-style: italic;
  font-weight: 200;
  font-stretch: normal;
}
`;
