import { useQuery } from '@tanstack/react-query';

import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { CommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { InsightsResponse } from 'types/insights.types';

const getInsights = async () => {
  const { data: insights } = await apiService.getInsights();
  return insights;
};

export const useInsights = (
  config: CommonQueryOptions<InsightsResponse, ReturnType<typeof QueryKeys.insights.all>> = {},
) => {
  const { data, status, isLoading, isFetching, error, isError, refetch } = useQuery({
    queryKey: QueryKeys.insights.all(),
    queryFn: getInsights,
    ...config,
    staleTime: config?.staleTime ?? Duration.FIVE_MIN,
  });

  return {
    data,
    status,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  };
};
