import { Box } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useCountryName } from 'utils/recipe.utils';

import { COUNTRIES, COUNTRIES_WITH_ALL } from 'constants/countries.constants';
import { COUNTRIES_ALL } from 'constants/recipes.constants';
import { FilterOptions } from 'types/common.types';

export const useCountryFilterOptions = ({
  withAllCountries,
  allCountryKey = 'countries.ALL',
}: {
  withAllCountries: boolean;
  allCountryKey?: I18nKey;
}) => {
  const getCountryName = useCountryName();

  return useMemo<FilterOptions>(
    () =>
      Object.keys(withAllCountries ? COUNTRIES_WITH_ALL : COUNTRIES).reduce(
        (acc, country) => ({
          ...acc,
          [country]: (
            <Box key={country} display="flex" alignItems="center" gap={1}>
              {country !== COUNTRIES_ALL && (
                <Box
                  className={`fib fi-${COUNTRIES[country]} fis`}
                  width={20}
                  height={20}
                  borderRadius={(theme) => theme.mixins.borderRadius.lg}
                />
              )}
              {country === COUNTRIES_ALL ? (
                <FormattedMessage id={allCountryKey} />
              ) : (
                getCountryName(country)
              )}
            </Box>
          ),
        }),
        {},
      ),
    [withAllCountries, allCountryKey, getCountryName],
  );
};
