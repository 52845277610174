import { UniqueIdentifier } from '@dnd-kit/core';
import { defaultAnimateLayoutChanges, rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  ArrowBackRounded as ArrowBackRoundedIcon,
  ArrowForwardRounded as ArrowForwardRoundedIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
} from '@mui/icons-material';
import { Box, Fade, IconButton, Stack, Typography } from '@mui/material';
import { createContext, memo, ReactNode, useMemo } from 'react';

import { useIsTouchDevice } from 'hooks';

import { DndContext } from 'components/@common/SortableList';

import { GridItem, IndexNumber } from './SortableGridItem.style';

const SortableItemContext = createContext<DndContext>({
  attributes: null,
  listeners: undefined,
  ref() {},
});

type Props = {
  id: UniqueIdentifier;
  index: number;
  totalItems: number;
  onRemove: (id: UniqueIdentifier) => void;
  children: ReactNode;
  isReadOnly?: boolean;
  onMoveItem: (oldIndex: number, newIndex: number) => void;
};

const SortableGridItem = ({
  id,
  index,
  onRemove,
  children,
  isReadOnly,
  onMoveItem,
  totalItems,
}: Props) => {
  const isTouchDevice = useIsTouchDevice();
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges: defaultAnimateLayoutChanges,
    strategy: rectSortingStrategy,
    transition: {
      duration: 250,
      easing: 'ease-out',
    },
    disabled: isReadOnly,
    resizeObserverConfig: {
      updateMeasurementsFor: ['width', 'height'],
    },
  });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );

  return (
    <SortableItemContext.Provider value={context}>
      <GridItem
        ref={setNodeRef}
        variant="outlined"
        sx={{
          transition,
          transform: CSS.Transform.toString(transform),
          opacity: isDragging ? 0.4 : undefined,
        }}
        {...attributes}
        tabIndex={-1}
      >
        <IndexNumber className="no-print">
          <Typography fontSize={20}>{index + 1}</Typography>
        </IndexNumber>

        <Stack flex={1} gap={2} width="100%" height="100%">
          {!isReadOnly && (
            <Fade in easing="ease-out" timeout={300} className="no-print">
              <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center">
                {isTouchDevice ? (
                  <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={2}>
                    {index > 0 && (
                      <IconButton
                        sx={{ width: 32, height: 32 }}
                        onClick={() => onMoveItem(index, index - 1)}
                      >
                        <ArrowBackRoundedIcon sx={{ width: 24 }} />
                      </IconButton>
                    )}
                    {index < totalItems - 1 && (
                      <IconButton
                        sx={{ width: 32, height: 32 }}
                        onClick={() => onMoveItem(index, index + 1)}
                      >
                        <ArrowForwardRoundedIcon sx={{ width: 24 }} />
                      </IconButton>
                    )}
                  </Stack>
                ) : (
                  <Stack direction="row" gap={1} alignItems="center">
                    <IconButton
                      {...attributes}
                      {...listeners}
                      sx={(theme) => ({
                        cursor: isDragging ? 'grabbing' : 'grab',
                        width: 32,
                        height: 24,
                        '&:focus, :hover': {
                          color: `${theme.palette.primary['dark']}`,
                        },
                      })}
                    >
                      <DragIndicatorIcon sx={{ width: 24 }} />
                    </IconButton>
                  </Stack>
                )}
                <IconButton
                  onClick={() => onRemove(id)}
                  sx={(theme) => ({
                    width: 24,
                    height: 24,
                    transition: 'all 0.1s ease-in-out',
                    '&:focus, :hover': {
                      color: `${theme.palette.error['main']} !important`,
                    },
                  })}
                >
                  <DeleteIcon sx={{ width: 24 }} />
                </IconButton>
              </Stack>
            </Fade>
          )}

          <Box flex={1}>{children}</Box>
        </Stack>
      </GridItem>
    </SortableItemContext.Provider>
  );
};
export default memo(SortableGridItem);
