import { Restaurant as RestaurantIcon, Search as SearchIcon } from '@mui/icons-material';

import { ActionButton } from 'components/@common';

interface Props {
  variant?: boolean;
  disabled?: boolean;
  loading?: boolean;
  wide?: boolean;
  onClick: () => void;
}

const PickRecipeButton = ({ variant, loading, disabled, wide, onClick }: Props) => {
  const Icon = variant ? RestaurantIcon : SearchIcon;

  return (
    <ActionButton
      icon={<Icon sx={{ height: 40, width: 40 }} />}
      text={variant ? 'menus.matcher.no_results.search_variant' : 'menus.matcher.no_results.search'}
      disabled={disabled}
      loading={loading}
      wide={wide}
      onClick={onClick}
    />
  );
};

export default PickRecipeButton;
