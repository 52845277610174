import { Stack, Typography } from '@mui/material';
import { m } from 'framer-motion';
import { memo, useCallback } from 'react';

import { listItemAnimations } from 'constants/animation.constants';
import { MenuItem } from 'types/menus.types';

import { MenuItemRow } from './MenuItemRow';

type Props = {
  label: string;
  menuId: number;
  menuItems: MenuItem[];
  menuItemSections: string[];
  isDrawerOpen: boolean;
  selectedMenuItem: MenuItem | undefined;
  disabled: boolean;
  selectedMenuItems: MenuItem[];
  setSelectedMenuItems: React.Dispatch<React.SetStateAction<MenuItem[]>>;
  onClick: (menuItem: MenuItem) => void;
  removeMenuItem: (menuItemId: number) => void;
};

const MenuItemSection = ({
  label,
  menuId,
  menuItems,
  menuItemSections,
  isDrawerOpen,
  selectedMenuItem,
  disabled,
  selectedMenuItems,
  setSelectedMenuItems,
  onClick,
  removeMenuItem,
}: Props) => {
  const toggleSelected = useCallback(
    (menuItem: MenuItem) => {
      setSelectedMenuItems((prev) =>
        prev.some((selectedMenuItem) => selectedMenuItem.id === menuItem.id)
          ? prev.filter((selectedMenuItem) => selectedMenuItem.id !== menuItem.id)
          : [...prev, menuItem],
      );
    },
    [setSelectedMenuItems],
  );

  return (
    <>
      <m.div
        key={label}
        style={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'relative' }}
        layout
        layoutScroll
        layoutId={label}
        layoutDependency={menuItemSections}
        {...listItemAnimations}
      >
        <Typography
          zIndex={99}
          variant="subtitle3"
          textTransform="capitalize"
          sx={{ px: 2, pb: 1.5, pt: 2.75, bgcolor: 'bluegrey.50' }}
        >
          {label}
        </Typography>
      </m.div>
      <Stack>
        {menuItems.map((menuItem) => (
          <MenuItemRow
            key={menuItem.id}
            menuId={menuId}
            menuItem={menuItem}
            isActive={isDrawerOpen && selectedMenuItem?.id === menuItem.id}
            menuItemSections={menuItemSections}
            disabled={disabled}
            isSelected={selectedMenuItems.some(
              (selectedMenuItem) => selectedMenuItem.id === menuItem.id,
            )}
            toggleSelected={toggleSelected}
            onRemove={removeMenuItem}
            onClick={onClick}
          />
        ))}
      </Stack>
    </>
  );
};

export default memo(MenuItemSection);
