import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { recipeCategoriesMap } from 'constants/recipes.constants';
import { RecipeCategory as RecipeCategoryEnum, RecipeFormValues } from 'types/recipes.types';

import { ChipSelect } from 'components/@form';

type Props = {
  isReadOnly: boolean;
};

const RecipeCategory = ({ isReadOnly }: Props) => {
  const { control } = useFormContext<RecipeFormValues>();
  const intl = useIntl();

  return (
    <Controller
      control={control}
      name="category"
      render={({ field: { onChange, value } }) => (
        <ChipSelect
          testId="recipe-category"
          label={<FormattedMessage id="recipes.props.category" />}
          readOnly={isReadOnly}
          options={Object.values(RecipeCategoryEnum).map((value) => ({
            value,
            label: intl.formatMessage({ id: recipeCategoriesMap[value] || value }),
          }))}
          selected={value}
          onChange={onChange}
        />
      )}
    />
  );
};

export default RecipeCategory;
