import { Components, listItemClasses, menuItemClasses, Theme } from '@mui/material';

export const MuiMenu: Components<Theme>['MuiMenu'] = {
  defaultProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    disableScrollLock: true,
    MenuListProps: {
      sx: (theme) => ({
        minWidth: theme.spacing(30),
      }),
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      [`& .${listItemClasses.root}, .${menuItemClasses.root}`]: {
        paddingBlock: theme.spacing(1),
      },
      [`& .Mui-selected`]: {
        color: `${theme.palette.text.primary} !important`,
        backgroundColor: `${theme.palette.primary.main} !important`,
        '&:hover': {
          backgroundColor: `${theme.palette.primary.dark} !important`,
        },
      },
    }),
  },
};
