import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { MenuUploadForm } from 'components/@menu-upload';

const MenuUpload = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'alain_ai.label' })} -{' '}
          {intl.formatMessage({ id: 'menus.new.label' })}
        </title>
      </Helmet>

      <MenuUploadForm />
    </>
  );
};

export default MenuUpload;
