import { ClickAwayListener } from '@mui/base';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  inputBaseClasses,
  MenuItem,
  outlinedInputClasses,
  popoverClasses,
  Select,
  selectClasses,
  Stack,
  svgIconClasses,
  Typography,
} from '@mui/material';
import { ReactNode, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { KeysMap } from 'types/common.types';

type Props<T> = {
  label: ReactNode;
  options: { label: string; value: string }[];
  selected: T | null;
  onChange: (newValue: T) => void;
  color?: 'primary' | 'neutral';
  readOnly?: boolean;
  keyMap?: KeysMap<T>;
  testId?: string;
};

const ChipSelect = <T,>({
  label,
  options,
  selected,
  onChange,
  color = 'primary',
  readOnly = false,
  keyMap,
  testId,
}: Props<T>) => {
  const intl = useIntl();
  const multiple = Array.isArray(selected) && !!keyMap;

  const selectedLabel = useMemo(() => {
    if (!selected || (multiple && !(selected as unknown[]).length)) return label;
    if (multiple) {
      return selected.map((value) => intl.formatMessage({ id: keyMap[value] })).join(', ');
    } else {
      return options.find((option) => String(option.value) === String(selected))?.label;
    }
  }, [selected, label, multiple, intl, keyMap, options]);

  const [isOpen, setIsOpen] = useState(false);

  const bgcolor = useMemo(() => {
    if (!selected || (multiple && !selected.length)) return 'transparent';
    if (color === 'neutral') {
      if (isOpen) return 'global01.200';
      return !!selected ? 'global01.100' : 'global01.50';
    }
    if (color === 'primary') {
      if (isOpen) return 'primary.200';
      return !!selected ? 'primary.lighter' : 'primary.100';
    }
  }, [isOpen, selected, multiple, color]);

  const border = useMemo(() => {
    if (!selected || (multiple && !selected.length)) return '1px solid';
    return 'none';
  }, [multiple, selected]);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)} mouseEvent="onMouseDown">
      <Select
        data-testid={testId || 'chip-select'}
        IconComponent={ExpandMoreIcon}
        displayEmpty
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen((open) => !open)}
        multiple={multiple}
        readOnly={readOnly}
        MenuProps={{
          disablePortal: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        renderValue={() => (
          <Stack direction="row" gap={1} pt={0.5} pb={1}>
            <Typography variant="chip" className="print-only">
              {label}:
            </Typography>
            {selectedLabel}
          </Stack>
        )}
        value={selected || ('' as T)}
        onChange={(e) => onChange(e.target.value as T)}
        label={label}
        sx={(theme) => ({
          cursor: readOnly ? 'default' : 'pointer',
          width: 'fit-content',
          px: 0,
          [`& .${selectClasses.select}`]: {
            color: 'text.primary',
            zIndex: 5,
            ...theme.typography.chip,
            py: 0,
          },
          // don't show border active state when readOnly
          [`&.Mui-readOnly.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
            { borderColor: 'black.60', borderWidth: '1px' },
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            bgcolor,
            border,
            borderColor: 'black.60',
            borderRadius: theme.mixins.borderRadius.full,
            transition: 'background-color 0.2s ease',
          },
          [`& .${outlinedInputClasses.notchedOutline} legend`]: {
            opacity: 0,
            display: 'none',
          },
          [`& .${outlinedInputClasses.input}`]: {
            cursor: readOnly ? 'default' : 'pointer',
            py: 0,
            pr: readOnly ? '16px !important' : undefined,
          },
          [`& .${inputBaseClasses.root}`]: {
            display: 'flex',
            alignItems: 'center',
          },
          [`& .${svgIconClasses.root}`]: {
            display: readOnly ? 'none' : 'block',
            height: 24,
            width: 24,
            color: 'text.primary',
            zIndex: 5,
            '@media print': {
              display: 'none',
            },
          },
          // so we can click away from the select without closing it
          [`& .${popoverClasses.root}`]: { pointerEvents: 'none' },
          [`& .${popoverClasses.paper}`]: { pointerEvents: 'auto' },
        })}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value} data-testid={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </ClickAwayListener>
  );
};

export default ChipSelect;
