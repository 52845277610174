import { MixinsOptions } from '@mui/material/styles/createMixins';

const mixins: MixinsOptions = {
  sidebarWidth: 64,
  zIndex: {
    card: {
      image: 0,
      content: 1,
    },
  },
  borderRadius: {
    sm: 1,
    base: 2,
    lg: 4,
    full: 9999,
  },
};

export default mixins;
