import { ListItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { useIsHQ } from 'hooks/useIsHQ';
import { useUser } from 'queries';

import { CountryFlag } from 'components/@common';

const UserCountry = () => {
  const { user } = useUser();
  const isHQ = useIsHQ();

  return (
    <ListItem>
      <CountryFlag
        tooltipPlacement="right"
        country={user?.group.country || ''}
        size={32}
        overrideTooltip={
          <span style={{ whiteSpace: 'pre' }}>
            <FormattedMessage id="sidebar.user.logged_in_email" values={{ email: user?.email }} />
            {isHQ && (
              <>
                {'\n'}
                <FormattedMessage id="sidebar.user.is_hq_user" />
              </>
            )}
          </span>
        }
      />
    </ListItem>
  );
};

export default UserCountry;
