import {
  Check as CheckIcon,
  ContentCopy as CopyIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  IconButton,
  inputBaseClasses,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { SignInOutput } from 'aws-amplify/auth';
import { QRCodeSVG } from 'qrcode.react';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { errorMessageFor } from 'utils/form.utils';

import { useMedia } from 'hooks';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { useCreateMfaSetup } from 'queries';
import { CognitoError, CognitoErrors, LoginState, MFASetupValues } from 'types/auth.types';

interface Props {
  onLoginStateChange: (state: LoginState) => void;
  signInOutput?: SignInOutput;
}

export function MFASetupForm({ onLoginStateChange, signInOutput }: Props) {
  const intl = useIntl();
  const { md } = useMedia();
  const { copy, isCopied } = useCopyToClipboard();
  const [qrCode, setQrCode] = useState({ url: '', code: '' });
  const [isQrCodeExpired, setIsQrCodeExpired] = useState(false);

  const generateQrCode = useCallback(() => {
    if (signInOutput?.nextStep.signInStep === 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP') {
      const url = signInOutput.nextStep.totpSetupDetails.getSetupUri('LPQ - Alain.AI');
      setQrCode({
        url: url.toString(),
        code: signInOutput.nextStep.totpSetupDetails.sharedSecret,
      });
    }
  }, [signInOutput?.nextStep]);

  useEffect(() => {
    // generate QR code on mount
    generateQrCode();

    // show expired tooltip after 3 minutes
    const timer = setTimeout(() => setIsQrCodeExpired(true), 180_000);

    return () => clearTimeout(timer);
  }, [generateQrCode]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { code: '' } });

  const { verifyMFA, error, isPending } = useCreateMfaSetup();

  const submitMfa = (values: MFASetupValues) => {
    verifyMFA({ code: values.code }, { onSuccess: () => onLoginStateChange(LoginState.Success) });
  };

  const QRCode = (
    <Box
      p={2}
      bgcolor="white.100"
      borderRadius={1.5}
      alignSelf={{ xs: 'center', md: undefined }}
      height="fit-content"
      width="fit-content"
      position="relative"
      overflow="hidden"
    >
      <QRCodeSVG value={qrCode.url} size={120} />
      {isQrCodeExpired && (
        <Tooltip title={intl.formatMessage({ id: 'form.mfa_setup.expired.tooltip' })}>
          <Box
            position="absolute"
            bgcolor="black.70"
            color="white.100"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              inset: 0,
              cursor: 'pointer',
              transition: 'background-color 0.5s',
              '&:hover': {
                bgcolor: 'black.80',
              },
            }}
            onClick={() => location.reload()}
          >
            <RefreshIcon sx={{ width: 50, height: 50 }} />
          </Box>
        </Tooltip>
      )}
    </Box>
  );

  return (
    <>
      <Typography variant="h4">
        <FormattedMessage id="form.mfa_setup.title" />
      </Typography>
      <Typography variant="body1" mt={2.5}>
        <FormattedMessage id="form.mfa_setup.description" />
      </Typography>

      <Stack gap={4} mt={4}>
        <InstructionStep number={1}>
          <Typography
            variant="body1"
            fontWeight={700}
            sx={{ color: (theme) => theme.palette.global01.main }}
          >
            <FormattedMessage id="form.mfa_setup.step_1.title" />
          </Typography>
          <Typography mt={1.5} variant="body2">
            <FormattedMessage
              id="form.mfa_setup.step_1.description"
              values={{
                google: (message: React.ReactNode) => (
                  <a
                    href="https://support.google.com/accounts/answer/1066447"
                    target="_blank"
                    style={{ textDecoration: 'underline' }}
                    rel="noreferrer"
                  >
                    {message}
                  </a>
                ),
                microsoft: (message: React.ReactNode) => (
                  <a
                    href="https://support.microsoft.com/en-us/account-billing/download-and-install-the-microsoft-authenticator-app-351498fc-850a-45da-b7b6-27e523b8702a"
                    target="_blank"
                    style={{ textDecoration: 'underline' }}
                    rel="noreferrer"
                  >
                    {message}
                  </a>
                ),
              }}
            />
          </Typography>
        </InstructionStep>

        <InstructionStep number={2}>
          <Stack
            direction={{ xs: 'column', lg: 'row' }}
            gap={2}
            alignItems={{ xs: undefined, md: 'center' }}
          >
            <Box flex={2}>
              <Typography
                variant="body1"
                fontWeight={700}
                sx={{ color: (theme) => theme.palette.global01.main }}
              >
                <FormattedMessage id="form.mfa_setup.step_2.title" />
              </Typography>
              <Typography mt={1.5} variant="body2">
                <FormattedMessage id="form.mfa_setup.step_2.description" />
              </Typography>

              {md && <Box mt={1}>{QRCode}</Box>}

              <Stack borderRadius={1.5} bgcolor="#D0D7DC" mt={2} p={1.5}>
                <Typography
                  variant="caption"
                  fontWeight={700}
                  sx={{ color: (theme) => theme.palette.global01.main }}
                >
                  <FormattedMessage id="form.mfa_setup.step_2.disclaimer" />
                </Typography>

                <Stack direction="row" gap={1} alignItems="center">
                  <Typography
                    variant="button"
                    fontWeight={400}
                    sx={{ color: (theme) => theme.palette.global01.main }}
                  >
                    {qrCode.code}
                  </Typography>

                  <IconButton
                    onClick={() => copy(qrCode.code)}
                    size="small"
                    sx={{ height: 15, width: 15 }}
                  >
                    {isCopied ? (
                      <CheckIcon sx={{ height: 15, color: 'success.main' }} />
                    ) : (
                      <CopyIcon sx={{ height: 15 }} />
                    )}
                  </IconButton>
                </Stack>
              </Stack>
            </Box>

            {!md && QRCode}
          </Stack>
        </InstructionStep>

        <InstructionStep number={3}>
          <Typography
            variant="body1"
            fontWeight={700}
            sx={{ color: (theme) => theme.palette.global01.main }}
          >
            <FormattedMessage id="form.mfa_setup.step_3.title" />
          </Typography>
          <Typography mt={1.5} variant="body2">
            <FormattedMessage id="form.mfa_setup.step_3.description" />
          </Typography>

          <form onSubmit={handleSubmit(submitMfa)}>
            <Stack direction="row" gap={2} mt={2}>
              <TextField
                {...register('code', {
                  required: errorMessageFor(intl, { type: 'required', label: 'mfa_code' }),
                })}
                placeholder={intl.formatMessage({ id: 'form.mfa_setup.step_3.placeholder' })}
                id="code"
                name="code"
                error={!!errors.code}
                helperText={errors?.code?.message}
                fullWidth
                sx={{
                  backgroundColor: 'transparent',
                  [`& .${inputBaseClasses.root}`]: {
                    backgroundColor: 'white.100',
                  },
                }}
              />

              <LoadingButton variant="contained" loading={isPending} type="submit">
                <FormattedMessage id="form.mfa_setup.step_3.activate" />
              </LoadingButton>
            </Stack>
          </form>

          {error && (
            <Box mt={2} px={2} py={1} borderRadius={1} bgcolor="error.light">
              <Typography variant="body2">
                <MessageByErrorCode error={error} />
              </Typography>
            </Box>
          )}
        </InstructionStep>
      </Stack>
    </>
  );
}

type InstructionStepProps = {
  number: number;
  children: React.ReactNode;
};

const InstructionStep = ({ number, children }: InstructionStepProps) => {
  return (
    <Box p={3} borderRadius={2} bgcolor="bluegrey.50" position="relative">
      <Box
        position="absolute"
        top={-14}
        left={-14}
        width="40px"
        height="40px"
        borderRadius="100%"
        color="white.100"
        bgcolor="global01.main"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h6" fontWeight={400}>
          {number}
        </Typography>
      </Box>

      {children}
    </Box>
  );
};

const MessageByErrorCode = ({ error }: { error: CognitoError | null }) => {
  if (!error) return null;
  switch (error.name) {
    case CognitoErrors.CodeMismatchException:
    case CognitoErrors.InvalidParameterException:
    case CognitoErrors.EnableSoftwareTokenMFAException: {
      return <FormattedMessage id="error.mfa" />;
    }
    case CognitoErrors.ExpiredCodeException: {
      return <FormattedMessage id="error.mfa.other" />;
    }
  }
  return <FormattedMessage id="error.mfa.other" />;
};
