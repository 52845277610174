import { createIndexRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import RecipeDashboard from './RecipeDashboard';

export default createIndexRoute({
  element: <RecipeDashboard />,
  path: Routes.Root,
  index: true,
  authenticated: true,
});
