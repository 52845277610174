import { usePostHog } from 'posthog-js/react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useCreateRecipe } from 'queries';

export const useRecipeCreator = (source: 'dashboard' | 'experimental-kitchen' | 'cookbook') => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { createRecipe, isPending: isCreateRecipePending } = useCreateRecipe();

  const handleCreateRecipe = () => {
    createRecipe(
      {},
      {
        onSuccess: (res) => {
          if (!isCreateRecipePending && res.data.id) {
            posthog.capture('create_recipe', { type: 'new', source });
            navigate(generatePath(Routes.RecipeDetail, { recipeId: String(res.data.id) }), {
              replace: true,
            });
          }
        },
      },
    );
  };

  return { handleCreateRecipe, isCreateRecipePending };
};
