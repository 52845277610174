import { Check as CheckIcon, Edit as EditIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Collapse, IconButton, Stack } from '@mui/material';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { SaveOptions } from 'types/common.types';
import { Menu, MenuFormData, MenuStatus } from 'types/menus.types';

import { ValidationDialog } from 'components/@form/ValidationDialog';
import MenuExportButton from 'components/@menu-form/MenuForm/MenuMainInfo/components/MenuExportButton';
import { SaveButton } from 'components/@recipe-form';

import { useMenuValidation } from './hooks';
import MenuActionsMenu from './MenuActionsMenu';

interface Props {
  menu: Menu;
  isReadOnly: boolean;
  isSaving: boolean;
  isProcessing: boolean;
  disabled: boolean;
  setIsReadOnly: (isReadOnly: boolean) => void;
  saveMenu: (options: SaveOptions) => void;
}

const MenuActions = ({
  menu,
  isReadOnly,
  isSaving,
  isProcessing,
  disabled,
  setIsReadOnly,
  saveMenu,
}: Props) => {
  const getValidationErrors = useMenuValidation();
  const { reset, setValue } = useFormContext<MenuFormData>();

  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const status = useWatch<MenuFormData>({ name: 'status' }) as MenuStatus;
  const isDraft = status === MenuStatus.Draft;

  const handleSave = () => {
    const validationErrors = getValidationErrors();
    if (!isDraft && validationErrors.length) {
      return setValidationErrors(validationErrors);
    }

    saveMenu({ silent: false, onSuccess: () => setIsReadOnly(true) });
  };

  const handleCancel = () => {
    reset();
    setIsReadOnly(true);
  };

  const toggleMenuStatus = () => {
    const validationErrors = getValidationErrors(menu.menuItems);
    if (validationErrors.length) {
      return setValidationErrors(validationErrors);
    }

    const previousStatus = status;
    const nextStatus = isDraft ? MenuStatus.Active : MenuStatus.Draft;

    setValue('status', nextStatus);
    saveMenu({
      silent: false,
      onSuccess: () => {
        setValue('status', nextStatus);
        setIsReadOnly(true);
      },
      onError: () => setValue('status', previousStatus),
    });
  };

  return (
    <Box display="flex" gap={1} alignItems="center" className="no-print">
      {!disabled && <MenuActionsMenu />}

      <Stack direction="row" alignItems="center">
        <Collapse in={!isReadOnly} orientation="horizontal">
          <Stack direction="row" gap={1} alignItems="center">
            {!isDraft && (
              <Button
                variant="text"
                sx={(theme) => ({ color: theme.palette.black[80], px: 1 })}
                onClick={handleCancel}
              >
                <FormattedMessage id="common.cancel" />
              </Button>
            )}

            <SaveButton loading={isSaving} onClick={handleSave}>
              <FormattedMessage id="general.actions.save" />
            </SaveButton>
          </Stack>
        </Collapse>

        <Collapse in={!disabled && isReadOnly} orientation="horizontal">
          <IconButton onClick={() => setIsReadOnly(false)}>
            <EditIcon />
          </IconButton>
        </Collapse>
      </Stack>

      <MenuExportButton menu={menu} isDisabled={isSaving || isProcessing} />

      {!disabled && (
        <LoadingButton
          startIcon={<CheckIcon />}
          variant={isDraft ? 'contained' : 'outlined'}
          color="success"
          disabled={isSaving}
          sx={{ color: isDraft ? 'text.secondary' : '' }}
          onClick={toggleMenuStatus}
        >
          <FormattedMessage id={isDraft ? 'menus.set_active' : 'menus.set_inactive'} />
        </LoadingButton>
      )}

      <ValidationDialog
        title="menus.validation_dialog.title"
        open={!!validationErrors.length}
        validationErrors={validationErrors}
        onClose={() => setValidationErrors([])}
      />
    </Box>
  );
};

export default MenuActions;
