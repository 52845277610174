import { ContentCopy as CopyIcon, Edit as EditIcon } from '@mui/icons-material';
import { Button, Chip, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { lineClamp } from 'utils/text.utils';

import { recipeRequirementsMap, recipeTypesMap } from 'constants/recipes.constants';
import { useIsTouchDevice } from 'hooks';
import { useCreateMenuItem } from 'queries';
import { MenuItem, MenuItemStatus } from 'types/menus.types';
import { RecipeStatus } from 'types/recipes.types';

interface Props {
  menuItem: MenuItem;
  onEdit: () => void;
  isEditing: boolean;
  disabled: boolean;
  onOpenDrawer: (menuItem: MenuItem) => void;
}

const MenuItemInfo = ({ menuItem, onEdit, isEditing, disabled, onOpenDrawer }: Props) => {
  const { name, description, recipe } = menuItem;
  const isTouchDevice = useIsTouchDevice();
  const intl = useIntl();

  const { createMenuItem, isPending: isDuplicating } = useCreateMenuItem();

  const duplicateMenuItem = () => {
    createMenuItem({
      menuId: menuItem.menuId,
      menuItem: { ...menuItem, name: `${name} (${intl.formatMessage({ id: 'common.copy' })})` },
    });
  };

  return (
    <Stack flex={1} direction="row" alignItems="center" justifyContent="space-between" gap={2}>
      <Collapse
        in={!isEditing}
        orientation="horizontal"
        unmountOnExit
        mountOnEnter
        sx={{ flex: 1, minWidth: '70%' }}
      >
        <Stack justifyContent="center" width="100%">
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography sx={lineClamp(2)} maxWidth={500}>
              {!!name ? name : <FormattedMessage id="menus.items.label.untitled" />}
            </Typography>
            {!disabled && (
              <Stack
                direction="row"
                className="edit-button"
                gap={1}
                sx={{ opacity: isTouchDevice ? 1 : 0, transition: 'opacity 0.2s' }}
                onClick={(e) => e.stopPropagation()}
              >
                <IconButton
                  disabled={isDuplicating}
                  onClick={onEdit}
                  sx={{ width: 24, height: 24 }}
                >
                  <EditIcon sx={{ width: 20 }} />
                </IconButton>
                <IconButton
                  disabled={isDuplicating}
                  onClick={duplicateMenuItem}
                  sx={{ width: 24, height: 24 }}
                >
                  <CopyIcon sx={{ width: 20 }} />
                </IconButton>
              </Stack>
            )}
          </Stack>

          {!!description && (
            <Typography
              variant="caption"
              sx={{ color: (theme) => theme.palette.grey[500], ...lineClamp(2) }}
              maxWidth={500}
            >
              {description || ''}
            </Typography>
          )}
        </Stack>
      </Collapse>

      <Collapse
        in={!isEditing && menuItem.status !== MenuItemStatus.Unmatched}
        orientation="horizontal"
        unmountOnExit
        mountOnEnter
      >
        {!!recipe && 'status' in recipe && (
          <Stack
            width="100%"
            flex={1}
            minWidth={80}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            pr={2}
            gap={2}
          >
            {recipe.status !== RecipeStatus.Published && (
              <Chip
                variant="labelDark"
                label={<FormattedMessage id="recipes.published.waiting" />}
                sx={(theme) => ({
                  ...theme.typography.caption,
                  fontWeight: 500,
                  textTransform: 'none',
                  bgcolor: 'lightBlue.50',
                  color: 'lightBlue.900',
                  whiteSpace: 'nowrap',
                  mr: 2,
                  p: 0,
                })}
              />
            )}
            {recipe.requirement && (
              <Stack width={72}>
                <Typography variant="chip" whiteSpace="nowrap">
                  <FormattedMessage id={recipeRequirementsMap[recipe.requirement]} />
                </Typography>
              </Stack>
            )}

            {recipe.type && (
              <Stack width={76}>
                <Typography variant="chip" whiteSpace="nowrap">
                  <FormattedMessage id={recipeTypesMap[recipe.type]} />
                </Typography>
              </Stack>
            )}
          </Stack>
        )}
      </Collapse>
      {!disabled && !isEditing && (
        <Stack width={150} alignItems="flex-end">
          <Button
            variant="contained"
            color="inherit"
            onClick={() => onOpenDrawer(menuItem)}
            sx={(theme) => ({
              py: 0.5,
              px: 1.25,
              ...theme.typography.buttonSmall,
              textTransform: 'none',
              boxShadow: 'none',
              color: 'global01.main',
              bgcolor: 'grey.50',
              '&:hover': { bgcolor: 'grey.200', boxShadow: 'none' },
              '&:active': { bgcolor: 'grey.300', boxShadow: 'none' },
            })}
          >
            {menuItem.status === MenuItemStatus.Unmatched ? (
              <FormattedMessage id="menus.items.linked_recipe.link" />
            ) : (
              <FormattedMessage id="menus.items.linked_recipe.change" />
            )}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default memo(MenuItemInfo);
