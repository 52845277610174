import { Card, inputBaseClasses, outlinedInputClasses, styled, TextField } from '@mui/material';

export const PreparationCard = styled(Card)(({ theme }) => ({
  overflow: 'visible',
  position: 'relative',
  width: '100%',
  height: '100%',
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

export const DescriptionTextField = styled(TextField)(({ theme }) => ({
  paddingRight: theme.spacing(1),
  whiteSpace: 'pre-wrap',
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: 'none',
    borderBottom: '1px solid',
    borderRadius: 0,
    transition: 'all 0.2s',
  },
  [`& .${inputBaseClasses.input}`]: {
    paddingInline: 0,
    paddingBlock: theme.spacing(1),
    ...theme.typography.body1,
  },
}));
