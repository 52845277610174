import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { useTransformIngredients } from 'utils/recipe.utils';

import { Routes } from 'constants/routes.constants';
import { useIsHQ } from 'hooks/useIsHQ';
import { QueryKeys } from 'queries/QueryKeys';
import { useUser } from 'queries/users';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import {
  PostRecipeParams,
  RecipeFormValues,
  RecipeRequirement,
  RecipeStatus,
} from 'types/recipes.types';

const postRecipe = async (options: PostRecipeParams) => {
  const { data: recipe } = await apiService.postRecipe(options);
  return recipe;
};

export const useCreateRecipeVariant = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { transformFormIngredientsToApi } = useTransformIngredients();
  const { user } = useUser();
  const isHQ = useIsHQ();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (initialRecipe: RecipeFormValues) =>
      postRecipe({
        ...initialRecipe,
        name: `${initialRecipe.name} (Variant)`,
        ingredients: transformFormIngredientsToApi(initialRecipe.ingredients).map((ingredient) => ({
          ...ingredient,
          id: null,
        })),
        // HQ users can create recipes in any country and requirement type, franchisees can only create in their country and no CORE recipes
        country: isHQ ? initialRecipe.country : user?.group.country,
        requirementType: isHQ
          ? initialRecipe.requirementType
          : initialRecipe.requirementType === RecipeRequirement.Core
            ? null
            : initialRecipe.requirementType,
        id: undefined,
        status: RecipeStatus.Draft,
      }),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.recipes.all() });
      notifications.success({
        message: intl.formatMessage({ id: 'recipes.actions.variant.success' }),
        cta: intl.formatMessage({ id: 'recipes.actions.variant.success.cta' }),
        onClick: () => navigate(generatePath(Routes.RecipeDetail, { recipeId: String(data.id) })),
      });
    },
    onError: () => {
      notifications.error({ message: intl.formatMessage({ id: 'common.error_messages.error' }) });
    },
  });

  return {
    createRecipeVariant: mutateAsync,
    isPending,
  };
};
