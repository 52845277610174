import type { Active, DragEndEvent, UniqueIdentifier } from '@dnd-kit/core';
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Collapse, Stack, Typography } from '@mui/material';
import { memo, ReactNode, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TransitionGroup } from 'react-transition-group';

import { useIsTouchDevice } from 'hooks';

import { useIsInMenuItemDrawer } from 'components/@menu-form';

import { GridItemOverlay, SortableGridItem } from './components';

interface BaseItem {
  id: UniqueIdentifier;
}
type Props<T extends BaseItem> = {
  items: T[];
  buttonText?: ReactNode;
  placeholder: I18nKey;
  isReadOnly?: boolean;
  isEmpty?: boolean;
  onAddItem: () => void;
  onRemoveItem: (index: number) => void;
  onMoveItem: (oldIndex: number, newIndex: number) => void;
  renderItem(index: number): ReactNode;
  addButtonTestId?: string;
};

const SortableGrid = <T extends BaseItem>({
  items,
  buttonText = <FormattedMessage id="general.list.add_item" />,
  placeholder,
  isReadOnly,
  isEmpty,
  onAddItem,
  onRemoveItem,
  onMoveItem,
  renderItem,
  addButtonTestId,
}: Props<T>) => {
  const isInMenuItemDrawer = useIsInMenuItemDrawer();
  const isTouchDevice = useIsTouchDevice();

  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(() => items.find((item) => item.id === active?.id), [active, items]);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 50,
        tolerance: 32,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleMoveItem = (oldIndex: number, newIndex: number) => {
    onMoveItem(oldIndex, newIndex);
  };

  const handleAddItem = () => {
    onAddItem();
  };

  const handleRemoveItem = (removedId: UniqueIdentifier) => {
    onRemoveItem(items.findIndex(({ id }) => id === removedId));
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over?.id) {
      const currentIndex = items.findIndex(({ id }) => id === active.id);
      const newIndex = items.findIndex(({ id }) => id === over?.id);

      onMoveItem(currentIndex, newIndex);
    }
    setActive(null);
  };

  const empty = isEmpty || !items.length;

  return (
    <Stack gap={3}>
      <Collapse in={empty} hidden={!empty} easing="ease-out" timeout={200}>
        <Typography variant="body2" width="100%" align="center" fontStyle="italic">
          <FormattedMessage id={placeholder} />
        </Typography>
      </Collapse>
      {!empty && (
        <DndContext
          sensors={sensors}
          onDragStart={({ active }) => setActive(active)}
          onDragEnd={handleDragEnd}
          onDragCancel={() => setActive(null)}
          modifiers={[restrictToWindowEdges]}
          collisionDetection={closestCorners}
          measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
          autoScroll
        >
          <Box
            component={TransitionGroup}
            display="grid"
            gap={3}
            sx={(theme) => ({
              // in the menu item drawer, we can't use mediaqueries so we just show 1 column
              ...(isInMenuItemDrawer
                ? {}
                : {
                    gridTemplateColumns: 'repeat(1, 1fr)',
                    gridTemplateRows: 'repeat(auto-fill, 1fr)',
                    [theme.breakpoints.up('sm')]: { gridTemplateColumns: 'repeat(2, 1fr)' },
                    [theme.breakpoints.up('md')]: { gridTemplateColumns: 'repeat(3, 1fr)' },
                    [theme.breakpoints.up('lg')]: { gridTemplateColumns: 'repeat(4, 1fr)' },
                    '@media print': { display: 'flex', flexDirection: 'row', flexWrap: 'wrap' },
                  }),
            })}
          >
            <SortableContext items={items} strategy={rectSortingStrategy} disabled={isTouchDevice}>
              {items.map((item, index) => (
                <SortableGridItem
                  totalItems={items.length}
                  index={index}
                  key={item.id}
                  id={item.id}
                  onRemove={handleRemoveItem}
                  isReadOnly={isReadOnly}
                  onMoveItem={handleMoveItem}
                >
                  {renderItem(index)}
                </SortableGridItem>
              ))}
            </SortableContext>
          </Box>
          <GridItemOverlay index={items.indexOf(activeItem as T)}>
            {activeItem ? renderItem(items.findIndex(({ id }) => id === activeItem.id)) : null}
          </GridItemOverlay>
        </DndContext>
      )}

      {!isReadOnly && (
        <Button
          data-testid={addButtonTestId}
          className="no-print"
          color="inherit"
          variant="contained"
          size="small"
          onClick={handleAddItem}
          sx={(theme) => ({
            alignSelf: 'flex-end',
            py: 0.5,
            px: 1.25,
            bgcolor: 'black.10',
            '&:hover, &:active': {
              bgcolor: 'black.20',
            },
            ...theme.typography.button,
          })}
        >
          <AddIcon sx={{ width: 18 }} />
          {buttonText}
        </Button>
      )}
    </Stack>
  );
};

export default memo(SortableGrid);
