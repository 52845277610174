import { useMutation } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { UploadMediaProps } from 'types/media.types';

const uploadMedia = async ({ file, options }: UploadMediaProps) => {
  const response = apiService.uploadMedia({ file, options });
  return response;
};

export const useUploadMedia = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const { mutate, isPending } = useMutation({
    mutationFn: uploadMedia,
    onError: () => {
      notifications.error({ message: intl.formatMessage({ id: 'common.error_messages.error' }) });
    },
  });

  return { uploadMedia: mutate, isPending };
};
