import {
  Check as CheckIcon,
  Clear as ClearIcon,
  Label as LabelIcon,
  LinkOff as LinkOffIcon,
  LocalDrinkRounded as DrinkIcon,
} from '@mui/icons-material';
import { Box, Button, Collapse, Fade, IconButton, Stack, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useBulkUpdateMenuItem } from 'queries';
import { MenuItem, MenuItemStatus } from 'types/menus.types';

import MenuSectionAutocomplete from 'components/@menu-form/MenuItems/MenuItemRow/MenuSectionAutocomplete';

interface Props {
  menuItemSections: string[];
  selectedMenuItems: MenuItem[];
  setSelectedMenuItems: React.Dispatch<React.SetStateAction<MenuItem[]>>;
}

const MenuItemActionBar = ({
  menuItemSections,
  selectedMenuItems,
  setSelectedMenuItems,
}: Props) => {
  const { updateMenuItems, isPending } = useBulkUpdateMenuItem();
  const [isSectionPickerOpen, setIsSectionPickerOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedMenuItems.length) {
      setIsSectionPickerOpen(false);
      setSelectedSection(null);
    }
  }, [selectedMenuItems.length]);

  const handleUnlink = () => {
    updateMenuItems(
      selectedMenuItems.map((item) => ({
        ...item,
        isPurchasedItem: false,
        recipe: { id: null },
        status: MenuItemStatus.Unmatched,
      })),
      { onSuccess: () => setSelectedMenuItems([]) },
    );
  };

  const handleSetPurchasedItem = () => {
    updateMenuItems(
      selectedMenuItems.map((item) => ({
        ...item,
        isPurchasedItem: true,
        recipe: { id: null },
        status: MenuItemStatus.ManualOverride,
      })),
      { onSuccess: () => setSelectedMenuItems([]) },
    );
  };

  const handleSetSection = () => {
    updateMenuItems(
      selectedMenuItems.map((item) => ({
        ...item,
        section: selectedSection || '',
        recipe: item.recipe || { id: null },
      })),
      { onSuccess: () => setSelectedMenuItems([]) },
    );
  };

  return (
    <Fade in={!!selectedMenuItems.length}>
      <Box
        left={0}
        right={0}
        display="flex"
        justifyContent="center"
        zIndex={2000}
        position="fixed"
        bottom={10}
      >
        <Stack
          boxShadow={3}
          maxWidth="500px"
          width="100%"
          bgcolor="primary.main"
          color="bluegrey.900"
          px={2}
          py={0.5}
          borderRadius={10}
          alignItems="center"
          direction="row"
          gap={2}
        >
          <Button color="inherit" onClick={() => setSelectedMenuItems([])} sx={{ px: 1 }}>
            <FormattedMessage id="menus.multi_actions.clear" />
          </Button>

          <Collapse in={!isSectionPickerOpen} orientation="horizontal" sx={{ ml: 'auto' }}>
            <Stack alignItems="center" direction="row" justifyContent="space-between" gap={1}>
              <Tooltip title={<FormattedMessage id="menus.multi_actions.category" />}>
                <IconButton
                  disabled={isPending}
                  color="inherit"
                  onClick={() => setIsSectionPickerOpen(true)}
                >
                  <LabelIcon sx={{ height: 24, width: 24 }} />
                </IconButton>
              </Tooltip>

              <Tooltip title={<FormattedMessage id="menus.multi_actions.unlink" />}>
                <IconButton disabled={isPending} color="inherit" onClick={handleUnlink}>
                  <LinkOffIcon sx={{ height: 24, width: 24 }} />
                </IconButton>
              </Tooltip>

              <Tooltip title={<FormattedMessage id="menus.multi_actions.purchased" />}>
                <IconButton disabled={isPending} color="inherit" onClick={handleSetPurchasedItem}>
                  <DrinkIcon sx={{ height: 24, width: 24 }} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Collapse>

          <Collapse in={isSectionPickerOpen} orientation="horizontal">
            <Stack direction="row">
              <MenuSectionAutocomplete
                options={menuItemSections}
                value={selectedSection}
                onChange={setSelectedSection}
              />
              <IconButton
                disabled={isPending}
                color="inherit"
                onClick={() => {
                  setIsSectionPickerOpen(false);
                  setSelectedSection(null);
                }}
              >
                <ClearIcon sx={{ height: 24, width: 24 }} />
              </IconButton>
              <IconButton disabled={isPending} color="inherit" onClick={handleSetSection}>
                <CheckIcon sx={{ height: 24, width: 24 }} />
              </IconButton>
            </Stack>
          </Collapse>
        </Stack>
      </Box>
    </Fade>
  );
};

export default MenuItemActionBar;
