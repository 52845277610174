import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const HelpDialog = ({ isOpen, onClose }: Props) => {
  const intl = useIntl();

  return (
    <Dialog open={isOpen} onClose={onClose} className="no-print">
      <DialogTitle>
        <FormattedMessage id="sidebar.help.title" />
      </DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ wordBreak: 'break-word' }}>
          <p>
            <FormattedMessage
              id="sidebar.help.tutorial"
              values={{
                link: (chunks) => (
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.primary.dark,
                      '&:hover': { color: (theme) => theme.palette.primary.darker },
                    }}
                    component="span"
                  >
                    <a
                      style={{ color: 'inherit' }}
                      href={intl.formatMessage({ id: 'sidebar.help.tutorial_url' })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunks}
                    </a>
                  </Typography>
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="sidebar.help.mail"
              values={{
                mail: (chunks) => (
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.primary.dark,
                      '&:hover': { color: (theme) => theme.palette.primary.darker },
                    }}
                    component="span"
                  >
                    <a style={{ color: 'inherit' }} href={`mailto:${chunks}`}>
                      {chunks}
                    </a>
                  </Typography>
                ),
              }}
            />
          </p>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="inherit" onClick={onClose}>
          <FormattedMessage id="common.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HelpDialog;
