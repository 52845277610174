import { Button, inputBaseClasses, styled, TextareaAutosize, Typography } from '@mui/material';

export const AvatarUser = styled(Typography)(({ theme }) => ({
  variant: 'body1',
  height: 46,
  width: 46,
  aspectRatio: '1/1',
  borderRadius: theme.mixins.borderRadius.full,
  backgroundColor: theme.palette.bluegrey[900],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  cursor: 'default',
  userSelect: 'none',
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.bluegrey[900],
  color: theme.palette.text.secondary,
  ...theme.typography.buttonSmall,
  textTransform: 'none',
  paddingBlock: theme.spacing(0.5),
  paddingInline: theme.spacing(1.75),
  width: 'fit-content',
  ':hover': {
    backgroundColor: theme.palette.bluegrey[600],
  },
}));

export const MessageForm = styled('form')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  height: 'fit-content',
  minHeight: 60,
  gap: theme.spacing(1),
  width: '100%',
  border: '1px solid',
  borderColor: theme.palette.white[20],
  padding: theme.spacing(1.5),
  borderRadius: theme.mixins.borderRadius.lg,
}));

export const MessageInputField = styled(TextareaAutosize)(({ theme }) => ({
  ...theme.typography.body1,
  backgroundColor: 'transparent',
  color: theme.palette.text.secondary,
  width: '100%',
  resize: 'none',
  border: 'none',
  '&:focus-within': {
    outline: 'none',
  },
  '&::placeholder': {
    color: theme.palette.white[70],
    opacity: 1,
  },
  [`& .${inputBaseClasses.root}`]: {
    backgroundColor: 'transparent',
    padding: 0.5,
  },
  [`& .${inputBaseClasses.input}`]: {
    color: theme.palette.text.secondary,
    '&::placeholder': {
      color: theme.palette.white[70],
      opacity: 1,
    },
  },
}));
