import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { QueryKeys } from 'queries';
import { useMenuStatus } from 'queries/menus/useMenuStatus';
import { Menu, MenuProcessingStatus } from 'types/menus.types';

export const useMenuProcessingStatus = ({
  menuId,
  initialStatus,
  refetchInterval = 2_500,
}: {
  menuId: Menu['id'];
  initialStatus: Menu['processingStatus'];
  refetchInterval?: number;
}) => {
  const queryClient = useQueryClient();
  const [menuProcessingStatus, setMenuProcessingStatus] = useState(initialStatus);

  const { menuStatus } = useMenuStatus(menuId, {
    refetchInterval,
    enabled: menuProcessingStatus === MenuProcessingStatus.Processing,
  });

  useEffect(() => {
    setMenuProcessingStatus(menuStatus || initialStatus);

    // if we go from not Done to Done, we want to refetch the menu so we get the menu items
    if (initialStatus !== MenuProcessingStatus.Done && menuStatus === MenuProcessingStatus.Done) {
      queryClient.invalidateQueries({ queryKey: QueryKeys.menus.byId(menuId) });
    }
  }, [menuStatus, menuId, queryClient, initialStatus]);

  return menuProcessingStatus;
};
