import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { Chat } from 'components/@chatbox';

type Props = {
  title: ReactNode;
  createButton?: ReactNode;
  children: ReactNode;
};

const DashboardLayout = ({ title, createButton, children }: Props) => {
  return (
    <Stack gap={4} height="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h1">{title}</Typography>
        {createButton}
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} gap={3} height="100%" flex={1}>
        <Stack direction={{ xs: 'column', sm: 'row', md: 'column' }} flex={1} gap={3}>
          {children}
        </Stack>
        <Chat />
      </Stack>
    </Stack>
  );
};

export default DashboardLayout;
