import type { Active, DragEndEvent, UniqueIdentifier } from '@dnd-kit/core';
import {
  AutoScrollActivator,
  closestCenter,
  DndContext,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import AddIcon from '@mui/icons-material/Add';
import { Button, Collapse, List, Typography } from '@mui/material';
import { memo, ReactNode, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TransitionGroup } from 'react-transition-group';

import { ListItemOverlay, SortableListItem } from './components';

interface BaseItem {
  id: UniqueIdentifier;
}
type Props<T extends BaseItem> = {
  items: T[];
  buttonText?: ReactNode;
  placeholder: I18nKey;
  isReadOnly?: boolean;
  withBottomBorder?: boolean;
  onAddItem: () => void;
  onRemoveItem: (index: number) => void;
  onMoveItem: (oldIndex: number, newIndex: number) => void;
  renderItem(index: number): ReactNode;
  addButtonTestId?: string;
};

const SortableList = <T extends BaseItem>({
  items,
  buttonText = <FormattedMessage id="general.list.add_item" />,
  placeholder,
  isReadOnly,
  withBottomBorder = false,
  onAddItem,
  onRemoveItem,
  onMoveItem,
  renderItem,
  addButtonTestId,
}: Props<T>) => {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(() => items.find((item) => item.id === active?.id), [active, items]);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 16,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleAddItem = () => {
    onAddItem();
  };

  const handleRemoveItem = (removedId: UniqueIdentifier) => {
    onRemoveItem(items.findIndex(({ id }) => id === removedId));
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over?.id) {
      const currentIndex = items.findIndex(({ id }) => id === active.id);
      const newIndex = items.findIndex(({ id }) => id === over?.id);

      onMoveItem(currentIndex, newIndex);
    }
    setActive(null);
  };

  return (
    <>
      <Collapse in={!items.length} easing="ease-out" timeout={200} className="no-print">
        <Typography variant="body2" width="100%" align="center" py={1} fontStyle="italic">
          <FormattedMessage id={placeholder} />
        </Typography>
      </Collapse>
      <DndContext
        sensors={sensors}
        onDragStart={({ active }) => setActive(active)}
        onDragEnd={handleDragEnd}
        onDragCancel={() => setActive(null)}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
        collisionDetection={closestCenter}
        measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
        autoScroll={{
          threshold: { x: 0, y: 0.05 },
          layoutShiftCompensation: false,
          activator: AutoScrollActivator.DraggableRect,
          enabled: true,
          acceleration: 30,
          interval: 5,
        }}
      >
        <TransitionGroup component={List} sx={{ py: 0.5 }}>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items.map((item, index) => (
              <SortableListItem
                key={item.id}
                id={item.id}
                withBottomBorder={withBottomBorder}
                onRemove={handleRemoveItem}
                isReadOnly={isReadOnly}
              >
                {renderItem(index)}
              </SortableListItem>
            ))}
          </SortableContext>
          <ListItemOverlay>
            {activeItem && renderItem(items.findIndex(({ id }) => id === activeItem.id))}
          </ListItemOverlay>
        </TransitionGroup>
      </DndContext>
      {!isReadOnly && (
        <Button
          data-testid={addButtonTestId}
          className="no-print"
          color="inherit"
          onClick={handleAddItem}
          sx={(theme) => ({
            width: '100%',
            py: 1.5,
            bgcolor: 'bluegrey.50',
            '&:focus': { bgcolor: 'bluegrey.100' },
            '&:hover': { bgcolor: 'bluegrey.100' },
            '&:active': { bgcolor: 'bluegrey.200' },
            ...theme.typography.chip,
          })}
        >
          <AddIcon />
          {buttonText}
        </Button>
      )}
    </>
  );
};

export default memo(SortableList);
