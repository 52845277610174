import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
} from '@mui/material';
import { uniq } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useExportMenu, useUser } from 'queries';
import { Menu, MenuItemStatus } from 'types/menus.types';

interface Props {
  menu: Menu;
  isDisabled: boolean;
}

const MenuExportButton = ({ menu, isDisabled }: Props) => {
  const posthog = usePostHog();
  const intl = useIntl();
  const { user } = useUser();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { exportMenu, isPending: isExportingMenu } = useExportMenu();

  const [emails, setEmails] = useState<string[]>([]);

  useEffect(() => {
    if (!user?.email) return;
    setEmails((emails) => uniq([...emails, user.email]));
  }, [user?.email]);

  const handleExport = () => {
    exportMenu(
      { menuId: menu.id, emails },
      {
        onSuccess: () => {
          posthog.capture('exported_menu', { menu_id: menu.id });
          setIsDialogOpen(false);
        },
      },
    );
  };

  const amountOfUnmatched = useMemo(
    () => menu.menuItems.filter((menuItem) => menuItem.status === MenuItemStatus.Unmatched).length,
    [menu.menuItems],
  );

  return (
    <>
      <Tooltip title={<FormattedMessage id="menus.export.tooltip" />}>
        <LoadingButton
          variant={'contained'}
          loading={isExportingMenu}
          color="info"
          disabled={isDisabled || !menu.menuItems.length}
          sx={{
            backgroundColor: 'white.100',
            color: 'info.main',
            '&:hover': {
              backgroundColor: 'grey.100',
            },
          }}
          onClick={() => setIsDialogOpen(true)}
        >
          <FormattedMessage id="menus.export" />
        </LoadingButton>
      </Tooltip>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>
          <FormattedMessage id="menus.export.dialog.title" />
          <DialogContentText mt={2}>
            <FormattedMessage id="menus.export.dialog.description" />
          </DialogContentText>
        </DialogTitle>

        <DialogContent>
          <Autocomplete
            multiple
            id="tags-filled"
            options={[]}
            value={emails}
            onChange={(_, newValue) => setEmails(newValue)}
            freeSolo
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
              ))
            }
            renderInput={(params) => {
              const clearText = () =>
                params.inputProps?.onChange?.({
                  target: { value: '' },
                } as unknown as React.ChangeEvent<HTMLInputElement>);

              const addEmail = (email: string) => {
                if (!email) return;
                setEmails((emails) => [...emails, email]);
                clearText();
              };

              const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
                params.inputProps?.onBlur?.(event);
                addEmail(event.target.value);
              };

              const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === ',' || event.key === ' ') {
                  event.preventDefault();
                  addEmail((event.target as unknown as { value: string }).value);
                }
              };

              return (
                <TextField
                  {...params}
                  onBlur={onBlur}
                  onKeyDown={onKeyDown}
                  placeholder={intl.formatMessage({ id: 'menus.export.dialog.placeholder' })}
                />
              );
            }}
          />

          {!!amountOfUnmatched && (
            <Alert color="error" sx={{ mt: 3 }}>
              <FormattedMessage
                id="menus.export.dialog.unmatched_warning"
                values={{ amount: amountOfUnmatched }}
              />
            </Alert>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="text" color="inherit" onClick={() => setIsDialogOpen(false)}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <LoadingButton variant="contained" loading={isExportingMenu} onClick={handleExport}>
            <FormattedMessage id="menus.export.dialog.generate" />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MenuExportButton;
