import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { RecipeFormValues } from 'types/recipes.types';

export const useRecipeValidation = () => {
  const intl = useIntl();
  const { getValues } = useFormContext<RecipeFormValues>();

  const getValidationErrors = useCallback(() => {
    const errors: string[] = [];

    const preparationSteps = getValues('preparationSteps');

    const errorFields = {
      name: !getValues('name'),
      image: !getValues('image'),
      batchImage: !getValues('batchImage'),
      type: !getValues('type'),
      requirement: !getValues('requirementType'),
      seasons: !getValues('seasons').length,
      preparationSteps:
        !preparationSteps.length ||
        !preparationSteps.every(
          (step) => step.description || step.image || (!step.description && !step.image),
        ),
    };

    Object.entries(errorFields).forEach(([field, error]) => {
      if (error) {
        errors.push(
          intl.formatMessage({ id: `recipes.creator.validation_dialog.${field}` as I18nKey }),
        );
      }
    });

    const ingredients = getValues('ingredients');
    if (!ingredients.length || ingredients.some((ingredient) => !ingredient.name)) {
      errors.push(
        intl.formatMessage({ id: 'recipes.creator.validation_dialog.ingredients.amount' }),
      );
    }

    return errors;
  }, [intl, getValues]);

  return getValidationErrors;
};
