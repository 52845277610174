import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { SignInOutput } from 'aws-amplify/auth';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { errorMessageFor } from 'utils/form.utils';

import { useCreateLogin } from 'queries';
import { VALIDATIONS } from 'queries/constants';
import {
  COGNITO_CHALLENGE_STATE_MAP,
  CognitoError,
  CognitoErrors,
  LoginFormValues,
  LoginState,
} from 'types/auth.types';

const MessageByErrorCode = ({ error }: { error: CognitoError }) => {
  switch (error.name) {
    case CognitoErrors.NotAuthorizedException:
    case CognitoErrors.UserNotFoundException:
      return <FormattedMessage id="error.login" />;
  }
  return <FormattedMessage id="error.login.other" />;
};

interface Props {
  initialEmail?: string;
  setEmail: (email: string) => void;
  onLoginStateChange: (state: LoginState, signinOutput?: SignInOutput) => void;
}

export function LoginForm({ initialEmail = '', setEmail, onLoginStateChange }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<LoginFormValues>({
    defaultValues: {
      email: initialEmail,
    },
  });

  const intl = useIntl();

  const { createLogin, isPending, error } = useCreateLogin();

  const handleLogin = (values: LoginFormValues) => {
    createLogin(
      { email: values.email, password: values.password },
      {
        onSuccess: (user) => {
          if (user.nextStep && COGNITO_CHALLENGE_STATE_MAP[user.nextStep.signInStep]) {
            const nextState = COGNITO_CHALLENGE_STATE_MAP[user.nextStep.signInStep];
            if (!nextState) return;

            onLoginStateChange(nextState, user);
            return;
          }

          onLoginStateChange(LoginState.Success, user);
        },
      },
    );
  };

  const email = watch('email');

  const handleForgotPasswordClick = () => {
    setEmail(email);
    onLoginStateChange(LoginState.ForgotPassword);
  };

  return (
    <>
      <Typography variant="h4">
        <FormattedMessage id="login.label" />
      </Typography>
      <form onSubmit={handleSubmit(handleLogin)}>
        <Stack mt={3} gap={3}>
          <TextField
            {...register('email', {
              required: errorMessageFor(intl, {
                type: 'required',
                label: 'email',
              }),
              pattern: {
                value: VALIDATIONS.email,
                message: intl.formatMessage({
                  id: 'form.validation.email.format',
                }),
              },
            })}
            label={<FormattedMessage id="form.label.email" />}
            variant="outlined"
            error={!!errors.email}
            helperText={errors?.email?.message}
          />

          <TextField
            {...register('password', {
              required: errorMessageFor(intl, {
                type: 'required',
                label: 'password',
              }),
            })}
            type="password"
            label={<FormattedMessage id="form.label.password" />}
            variant="outlined"
            error={!!errors.password}
            helperText={errors?.password?.message}
          />
        </Stack>
        <Button variant="link" onClick={handleForgotPasswordClick} sx={{ mt: 2 }}>
          <FormattedMessage id="login.password.forgot" />
        </Button>
        <LoadingButton sx={{ mt: 5 }} variant="contained" loading={isPending} type="submit">
          <FormattedMessage id="login.label" />
        </LoadingButton>

        {error && (
          <Box mt={2} px={2} py={1} color="error.light">
            <Typography color="currentcolor" variant="body2">
              <MessageByErrorCode error={error} />
            </Typography>
          </Box>
        )}
      </form>
    </>
  );
}
