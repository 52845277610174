import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  name: string;
  label: ReactNode;
  icon: ReactNode;
};

const AutocompleteAddItem = ({ name, label, icon }: Props) => {
  return (
    <Stack direction="row" gap={2} alignItems="center">
      {icon}
      <Stack>
        <Typography variant="body1">{label}</Typography>
        <Typography
          variant="body2"
          overflow="clip"
          textOverflow="ellipsis"
          width="100%"
          sx={{ color: (theme) => theme.palette.black[60] }}
        >
          {name}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AutocompleteAddItem;
