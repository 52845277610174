import { SortDirection } from '@mui/material';
import { useCallback, useState } from 'react';

type TableSortInput = {
  initialSortDirection: SortDirection;
  initialSortBy: string;
};
type TableSortOutput = {
  sort: string;
  sortBy: string;
  sortDirection: SortDirection;
  handleTableSort: (property: string, initialSortDirection?: SortDirection) => void;
  resetTableSort: () => void;
};

export const useTableSort = ({
  initialSortDirection,
  initialSortBy,
}: TableSortInput): TableSortOutput => {
  const [sortDirection, setSortDirection] = useState<SortDirection>(initialSortDirection);
  const [sortByColumn, setSortByColumn] = useState<string>(initialSortBy);

  const handleTableSort = useCallback(
    (property: string, initialSortDirection?: SortDirection) => {
      if (sortByColumn !== property) {
        setSortByColumn(property);
        setSortDirection(initialSortDirection || 'asc');
        return;
      } else {
        if (sortDirection === 'desc') {
          setSortDirection('asc');
          return;
        } else {
          setSortDirection('desc');
          return;
        }
      }
    },
    [sortByColumn, sortDirection],
  );

  const resetTableSort = useCallback(() => {
    setSortDirection(initialSortDirection);
    setSortByColumn(initialSortBy);
  }, [initialSortBy, initialSortDirection]);

  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortByColumn}`;

  return {
    sort,
    sortBy: sortByColumn,
    sortDirection,
    handleTableSort,
    resetTableSort,
  };
};
