import { defaultDropAnimationSideEffects, DragOverlay } from '@dnd-kit/core';
import { restrictToParentElement, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { Delete as DeleteIcon, DragIndicator as DragIndicatorIcon } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { GridItem, IndexNumber } from './SortableGridItem.style';

type Props = {
  index: number;
  children: ReactNode;
};

const GridItemOverlay = ({ index, children }: Props) => {
  return (
    <DragOverlay
      adjustScale={false}
      modifiers={[restrictToWindowEdges, restrictToParentElement]}
      dropAnimation={{
        ...defaultDropAnimationSideEffects,
      }}
    >
      <GridItem sx={(theme) => ({ boxShadow: theme.shadows[8] })}>
        <IndexNumber className="no-print">
          <Typography fontSize={20}>{index + 1}</Typography>
        </IndexNumber>

        <Stack flex={1} gap={2} width="100%" height="100%">
          <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center">
            <DragIndicatorIcon sx={{ width: 24 }} />
            <DeleteIcon sx={{ width: 24 }} />
          </Stack>

          <Box flex={1}>{children}</Box>
        </Stack>
      </GridItem>
    </DragOverlay>
  );
};

export default GridItemOverlay;
