import { SnackbarProvider as NotistackProvider } from 'notistack';

import { NOTIFICATION_DURATION } from 'constants/common.constants';

interface Props {
  children: React.ReactNode;
}

const SnackbarProvider = ({ children }: Props) => {
  return (
    <NotistackProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      maxSnack={1}
      autoHideDuration={NOTIFICATION_DURATION}
      preventDuplicate
      domRoot={document.getElementById('snackbar-root') ?? undefined}
      style={{
        boxShadow: 'none',
        backgroundColor: 'unset',
        marginRight: -24,
        marginLeft: -8,
        paddingLeft: 0,
      }}
      dense
    >
      {children}
    </NotistackProvider>
  );
};

export default SnackbarProvider;
