import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { IconButton, inputBaseClasses, MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { useCountryName } from 'utils/recipe.utils';

import { COUNTRIES } from 'constants/countries.constants';
import { RecipeFormValues } from 'types/recipes.types';

import { CountryFlag } from 'components/@common';

type Props = {
  readOnly?: boolean;
};

const CountrySelect = ({ readOnly }: Props) => {
  const getCountryName = useCountryName();
  const { control } = useFormContext<RecipeFormValues>();

  return (
    <Controller
      name="country"
      control={control}
      render={({ field: { name, value, onChange } }) => {
        return (
          <Select
            name={name}
            IconComponent={() => <></>}
            displayEmpty
            readOnly={readOnly}
            variant="standard"
            renderValue={() => (
              <>
                <KeyboardArrowDownIcon />
                <IconButton>
                  <CountryFlag country={value} size={32} />
                </IconButton>
              </>
            )}
            value={String(value)}
            onChange={(e) => onChange(e.target.value)}
            sx={{
              bgcolor: 'transparent',
              cursor: readOnly ? 'default' : 'pointer',
              width: 'fit-content',
              p: 0,
              [`& .${inputBaseClasses.input}`]: {
                p: '0 !important',
                '&:focus': {
                  bgcolor: 'transparent',
                },
              },
            }}
          >
            {Object.entries(COUNTRIES).map(([country, value]) => (
              <MenuItem key={value} value={country} sx={{ gap: 1 }}>
                <CountryFlag country={country} />
                {getCountryName(country)}
              </MenuItem>
            ))}
          </Select>
        );
      }}
    />
  );
};

export default CountrySelect;
