import { Delete as DeleteIcon, Download as DownloadIcon } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { useIntl } from 'react-intl';

import { Image } from 'components/@common';

type Props = {
  disabled?: boolean;
  handleRemove: () => void;
  url?: string;
  isDragActive: boolean;
  height: number;
};

const UploadedMedia = ({ disabled, handleRemove, url, isDragActive, height }: Props) => {
  const intl = useIntl();

  return (
    <Box
      width="100%"
      height={height}
      position="relative"
      display="flex"
      alignItems="center"
      sx={{
        cursor: disabled ? 'default' : 'pointer',
        transition: 'all 0.2s',
        '&:hover': {
          opacity: disabled ? undefined : 0.7,
        },
        '&:active': {
          opacity: disabled ? undefined : 0.5,
        },
        ...(isDragActive && {
          opacity: disabled ? undefined : 0.7,
        }),
      }}
    >
      <Image src={url || ''} flex={1} width="100%" height="100%" />

      {!disabled && (
        <Stack
          direction="row"
          gap={1}
          alignItems="flex-end"
          position="absolute"
          p={1}
          top={0}
          right={0}
        >
          <IconButton
            className="no-print"
            sx={(theme) => ({
              height: 28,
              width: 28,
              bgcolor: 'bg.paper',
              boxShadow: theme.shadows[2],
              '&:hover': { bgcolor: 'bg.paper' },
            })}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleRemove();
            }}
            aria-label={intl.formatMessage({ id: 'general.button.remove' })}
          >
            <DeleteIcon sx={{ color: 'black.60', width: 20 }} />
          </IconButton>

          <IconButton
            className="no-print"
            sx={(theme) => ({
              height: 28,
              width: 28,
              bgcolor: 'bg.paper',
              boxShadow: theme.shadows[2],
              '&:hover': { bgcolor: 'bg.paper' },
            })}
            aria-label={intl.formatMessage({ id: 'form.dropzone.preview_image' })}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              window.open(url || '', '_blank');
            }}
          >
            <DownloadIcon sx={{ color: 'black.60', width: 20 }} />
          </IconButton>
        </Stack>
      )}
    </Box>
  );
};

export default UploadedMedia;
