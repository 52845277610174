import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Image } from 'components/@common';

import { TextShade } from './DashboardCard.types';

type Props = {
  title: string;
  buttonText: ReactNode;
  href: string;
  description: ReactNode;
  textShade?: TextShade;
  image: string;
  objectPosition: React.CSSProperties['objectPosition'];
  sx?: SxProps<Theme>;
  gradient?: {
    direction: 'left' | 'right' | 'top' | 'bottom';
    color: string;
  };
  isComingSoon?: boolean;
};

const DashboardCard = ({
  title,
  buttonText,
  href,
  description,
  textShade = TextShade.LIGHT,
  image = '',
  objectPosition,
  sx,
  gradient = {
    color: 'rgba(0,0,0,0.5)',
    direction: 'top',
  },
  isComingSoon,
}: Props) => {
  const theme = useTheme();
  const textColor = textShade === TextShade.DARK ? theme.palette.bg.dark : theme.palette.white[100];

  return (
    <Box sx={sx}>
      <Link
        to={href}
        state={{ resetScroll: true }}
        style={{
          textDecoration: 'none',
          width: '100%',
          height: '100%',
          minHeight: 'fit-content',
        }}
      >
        <Box
          p={3}
          bgcolor="bg.paper"
          borderRadius={(theme) => theme.mixins.borderRadius.base}
          boxShadow={(theme) => theme.shadows[2]}
          width="100%"
          height="100%"
          position="relative"
          overflow="hidden"
          sx={{
            transition: 'all 0.4s ease',
            '&:hover': { boxShadow: theme.shadows[4] },
            '&:hover img, &:active img': {
              transform: 'scale(1.05)',
              opacity: 0.95,
            },
            [theme.breakpoints.down('md')]: {
              p: 2,
            },
          }}
        >
          <Stack
            p={{ xs: 1.5, sm: 3, md: 5 }}
            borderRadius={(theme) => theme.mixins.borderRadius.sm}
            height="100%"
            position="relative"
            overflow="hidden"
            justifyContent="flex-end"
          >
            {!!image ? (
              <Image
                src={image}
                alt={title}
                position="absolute"
                objectFit="cover"
                zIndex={(theme) => theme.mixins.zIndex.card.image}
                sx={{ inset: 0, objectPosition, bgcolor: 'black.90' }}
              />
            ) : (
              <Box
                position="absolute"
                zIndex={(theme) => theme.mixins.zIndex.card.image}
                sx={{ inset: 0, bgcolor: 'black.100' }}
              />
            )}
            <Box
              position="absolute"
              zIndex={(theme) => theme.mixins.zIndex.card.image}
              sx={{
                inset: 0,
                background: `linear-gradient(to ${gradient.direction}, ${gradient.color} 0%, rgba(0,0,0,0) 100%)`,
              }}
            />
            <Stack
              height="fit-content"
              justifyContent="flex-end"
              gap={2}
              zIndex={(theme) => theme.mixins.zIndex.card.content}
              sx={{
                isolation: 'isolate',
                [theme.breakpoints.down('md')]: {
                  gap: 1,
                },
              }}
            >
              <Typography
                variant="h2"
                sx={{ color: textColor }}
                maxWidth="70%"
                height="fit-content"
              >
                {title}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: textColor }} maxWidth="70%">
                {description}
              </Typography>
              <Button
                sx={{ color: textColor, p: 0, ':hover': { backgroundColor: 'unset' } }}
                variant="text"
              >
                {buttonText}
                <ArrowForwardIcon sx={{ color: textShade, width: 24 }} />
              </Button>
            </Stack>
          </Stack>
          {isComingSoon && (
            <Typography
              position="absolute"
              top={36}
              right={-64}
              bgcolor="primary.main"
              variant="button"
              fontWeight={800}
              textTransform="uppercase"
              sx={{ rotate: '45deg' }}
              px={8}
              py={1}
            >
              Coming soon
            </Typography>
          )}
        </Box>
      </Link>
    </Box>
  );
};

export default DashboardCard;
