import { useQuery } from '@tanstack/react-query';

import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { CommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { RecipeComplianceRuleSet } from 'types/recipes.types';

const fetchRecipe = async () => {
  const { data } = await apiService.getRecipeComplianceRules();
  return data.data;
};

export const useRecipeComplianceRules = (
  config: CommonQueryOptions<
    RecipeComplianceRuleSet,
    ReturnType<typeof QueryKeys.recipes.complianceRules>
  > = {},
) => {
  const { data, status, isLoading, isFetching, error, isError, refetch } = useQuery({
    queryKey: QueryKeys.recipes.complianceRules(),
    queryFn: fetchRecipe,
    ...config,
    staleTime: config?.staleTime ?? Duration.FIVE_MIN,
  });

  return {
    data,
    status,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  };
};
