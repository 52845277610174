import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { InsightsResponse } from 'types/insights.types';

import ActiveMenuCoverage from 'components/@insights/ActiveMenuCoverage/ActiveMenuCoverage';
import { getExperimentalCounts, getTotalExperimental } from 'components/@insights/utils';

interface Props {
  insights: InsightsResponse;
}

const GeneralPerformance = ({ insights }: Props) => {
  const globals = insights.meta.globals;
  const totalExperimental = getTotalExperimental(globals.recipes);
  const experimentalAmounts = getExperimentalCounts(globals.recipes);

  return (
    <Stack gap={3}>
      <Typography variant="h3">
        <FormattedMessage id="insights.general_performance" />
      </Typography>

      <Stack direction={{ xs: 'column', md: 'row' }} gap={3}>
        <Stack p={3} bgcolor="bg.paper" borderRadius={2} flex={1} gap={4}>
          <Stack direction="row" justifyContent="space-between" gap={4} flexWrap="wrap">
            <StatWithTitle
              label="cookbook.label"
              value={globals.recipes.published}
              subject="recipes.label"
            />
            <StatWithTitle
              label="menus.active.label.plural"
              value={globals.menus.active}
              subject="menus.active.label.plural"
            />
            <Stack gap={0.5}>
              <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.grey[600] }}>
                <FormattedMessage id="insights.general_performance.active_coverage" />
              </Typography>
              <ActiveMenuCoverage value={globals.menus.activeCoveragePercentage} />
            </Stack>
          </Stack>

          <Box>
            <Button variant="contained" onClick={() => window.open(insights.meta.posthogUrl)}>
              <FormattedMessage id="insights.general_performance.view_all" />
            </Button>
          </Box>
        </Stack>

        <Stack p={3} bgcolor="bg.paper" borderRadius={2} flex={1} direction="row">
          <StatWithTitle
            label="experimental.label"
            value={totalExperimental}
            subject="recipes.label"
          />

          <Stack flex={1}>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                  padding: 0,
                  paddingBottom: '8px',
                },
              }}
            >
              <TableBody>
                {experimentalAmounts.map(({ color, label, value }) => (
                  <TableRow key={label}>
                    <TableCell>
                      <Box
                        ml="auto"
                        mt="1px"
                        width="10px"
                        height="10px"
                        borderRadius="100%"
                        bgcolor={color}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="subtitle2"
                        fontWeight={700}
                        sx={{ color: (theme) => theme.palette.global01.main }}
                      >
                        {value}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        pl={1}
                        variant="subtitle2"
                        fontWeight={500}
                        sx={{ color: (theme) => theme.palette.black[60] }}
                      >
                        <FormattedMessage id={label} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

type StatWithTitleProps = {
  label: I18nKey;
  value: number;
  subject: I18nKey;
};

const StatWithTitle = ({ label, value, subject }: StatWithTitleProps) => {
  return (
    <Stack gap={0.5} flex={1}>
      <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.grey[600] }}>
        <FormattedMessage id={label} />
      </Typography>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="h5" sx={{ color: (theme) => theme.palette.grey[900] }}>
          {value}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ color: (theme) => theme.palette.black[60] }}
          textTransform="lowercase"
        >
          <FormattedMessage id={subject} />
        </Typography>
      </Stack>
    </Stack>
  );
};

export default GeneralPerformance;
