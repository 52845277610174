export enum Routes {
  // General
  NotFound = '*',
  Root = '/recipes',
  Login = '/login',

  // Recipes
  Cookbook = '/recipes/cookbook',
  ExperimentalKitchen = '/recipes/experimental-kitchen',
  RecipeDetail = '/recipes/:recipeId',

  // Menus
  MenuOverview = '/menus',
  MenuUpload = '/menus/new',
  MenuDetail = '/menus/:menuId',

  // Insights
  Insights = '/insights',
}
