import { useMediaQuery, useTheme } from '@mui/material';

export const useMedia = () => {
  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.up('sm') && theme.breakpoints.down('lg'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));

  return {
    lg,
    md,
    sm,
  };
};
