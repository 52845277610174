const printStyles = `
/*
  * Print styles
  * Inlined to avoid the additional HTTP request:
  * http://www.phpied.com/delay-loading-your-print-css/
  * ========================================================================== */

  .print-only {
    display: none;
  }

  @media print {
    .no-print {
      display: none !important;
    }
    .print-only {
      display: block !important;
    }
    @page {
      size: A4;
      padding: 0rem !important;
      margin: 4rem !important;
    }

    * {
      overflow: visible !important;
      margin: 0 !important,
    }

    body {
      overflow: visible;
    }
    #root {
      height: auto;
    }
    main {
      width: 100% !important;
      margin: 0 auto !important;
      padding: 0rem !important;
    }
    div {
      overflow: visible !important;
      height: auto !important;
    }

    * {
      background: transparent !important;
      color: #000 !important; /* Black prints faster: http://www.sanbeiji.com/archives/953 */
      box-shadow: none !important;
      text-shadow: none !important;
    }
    // Hide image alts, links, and other elements that are not useful in print
    *::before,
    *::after {
      display: none !important;
    }
  
    a,
    a:visited {
      text-decoration: none;
    }
    a[href]::after {
      content: ' (' attr(href) ')';
    }

    abbr[title]::after {
      content: ' (' attr(title) ')';
    }

    /* Don't show links that are fragment identifiers, or use the javascript: pseudo protocol */
    a[href^='#']::after,
    a[href^='javascript:']::after {
      content: '';
    }

    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    /* Printing Tables: http://css-discuss.incutio.com/wiki/Printing_Tables */
    thead {
      display: table-header-group;
    }
    
    img {
      color: #fff;
      background-color: #fff;
      margin: 0 !important;
      padding: 0 !important;
      max-width: 300px !important;
      max-height: 200px !important;
      object-fit: contain;
    }

    p, span, h6, blockquote, a, div {
      font-size: 0.8rem !important;
      font-weight: 400 !important;
      margin: 0 !important;
    }

    p,
    h2,
    h3,
    h4, 
    h5 {
      orphans: 3;
      widows: 3;
    }
    h4, h5 {
      width: fit-content;
      text-align: start;
    }
    h4 {
      margin-block: 0.5rem !important;
    }
    h5 {
      font-size: 1.1rem !important;
      font-weight: 500 !important;
    }

    h2,
    h3,
    h4, 
    h5 {
      page-break-after: avoid;
    }
    
    // List styles
    ul, ol {
      border-block: 1px solid black !important;
    }
    li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
    }
    li:last-child {
      border-bottom: none !important;
    }

    // specific components
    .base-Popper-root,
    .MuiPopover-paper,
    .MuiPopover-root,
    .MuiBackdrop-root,
    #snackbar-root {
      display: none !important;
    }
    .recipe-title {
      input {
        font-size: 1.8rem !important;
        font-weight: 600 !important;
        margin: 0 !important;
      }
    }
  }
`;

export default printStyles;
