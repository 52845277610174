import { useMutation, useQueryClient } from '@tanstack/react-query';
import { signIn, SignInOutput } from 'aws-amplify/auth';

import { QueryKeys } from 'queries';
import { CognitoError } from 'types/auth.types';

interface Variables {
  email: string;
  password: string;
}

export const useCreateLogin = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation<SignInOutput, CognitoError, Variables, unknown>({
    mutationFn: ({ email, password }: Variables) => signIn({ username: email.trim(), password }),
    onSuccess: (data) => queryClient.setQueryData(QueryKeys.auth.currentUser(), data),
  });

  return {
    createLogin: mutate,
    isPending,
    error,
  };
};
