import { Box, outlinedInputClasses, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { RecipeFormValues } from 'types/recipes.types';

type Props = {
  index: number;
  isReadOnly: boolean;
};

const RemarkItem = ({ index, isReadOnly }: Props) => {
  const intl = useIntl();
  const { control } = useFormContext<RecipeFormValues>();

  return (
    <Box flex={1}>
      <Controller
        control={control}
        name={`notes.${index}.note`}
        render={({ field: { onChange, value } }) =>
          isReadOnly ? (
            <Typography
              variant="body2"
              color="black.90"
              whiteSpace="pre-wrap"
              textOverflow="revert"
              maxWidth="100%"
              sx={{
                lineBreak: 'loose',
                wordBreak: 'break-word',
              }}
            >
              {value || '-'}
            </Typography>
          ) : (
            <TextField
              value={value}
              onChange={onChange}
              placeholder={intl.formatMessage({ id: 'recipes.remarks.description' })}
              size="small"
              multiline
              variant="outlined"
              sx={{
                [`& .${outlinedInputClasses.input}`]: {
                  py: 1,
                  px: 1.75,
                },
              }}
              inputProps={{
                maxLength: 255,
                'data-testid': `stockage-remark${index + 1}`,
              }}
            />
          )
        }
      />
    </Box>
  );
};

export default RemarkItem;
