import { PostHogProvider as Provider, usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';

import config from 'config';

import { useUser } from 'queries';

interface Props {
  children: React.ReactNode;
}

const PostHogProvider = ({ children }: Props) => {
  return (
    <Provider apiKey={config.POSTHOG_KEY} options={{ api_host: config.POSTHOG_HOST }}>
      <PostHogInitialise />
      {children}
    </Provider>
  );
};

const PostHogInitialise = () => {
  const posthog = usePostHog();
  const { user } = useUser();

  useEffect(() => {
    if (!user || !posthog) return;
    posthog.identify(String(user.id), {
      email: user.email,
      country: user.group.country,
      group: user.group.name,
    });
    posthog.group('country', String(user.group.country));
  }, [user, posthog]);

  return <></>;
};

export default PostHogProvider;
