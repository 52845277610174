import { Box, Collapse, List, ListItem, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { errorMessageFor, hasLowercase, hasUppercase } from 'utils/form.utils';

import {
  MAX_PASSWORD_CHARS,
  MIN_PASSWORD_CHARS,
  PASSWORD_REQUIREMENTS,
} from 'components/@auth/PasswordField/constants';

interface Props {
  apiError?: React.ReactNode;
  autoFocus?: boolean;
}

export const PasswordField = ({ apiError, autoFocus }: Props) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<{ newPassword: string; newPasswordRepeat: string }>();
  const intl = useIntl();
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  return (
    <>
      <Box>
        <TextField
          inputProps={{ autoFocus }}
          {...register('newPassword', {
            required: errorMessageFor(intl, {
              type: 'required',
              label: 'new_password',
            }),
            validate: (value) => {
              if (value.length < MIN_PASSWORD_CHARS) {
                return errorMessageFor(intl, {
                  type: 'min_length',
                  label: 'new_password',
                  value: MIN_PASSWORD_CHARS,
                });
              }
              if (value.length > MAX_PASSWORD_CHARS) {
                return errorMessageFor(intl, {
                  type: 'max_length',
                  label: 'new_password',
                  value: MAX_PASSWORD_CHARS,
                });
              }
              if (!hasLowercase(value) || !hasUppercase(value)) {
                return errorMessageFor(intl, {
                  type: 'uppercase_lowercase',
                  label: 'new_password',
                });
              }

              return true;
            },
          })}
          label={<FormattedMessage id="form.label.new_password" />}
          id="newPassword"
          type="password"
          name="newPassword"
          fullWidth
          error={!!errors.newPassword || !!apiError}
          helperText={errors?.newPassword?.message || apiError}
          onFocus={() => setIsPasswordFocused(true)}
          onBlur={() => setIsPasswordFocused(false)}
        />
        <Collapse in={isPasswordFocused || !!apiError}>
          <List sx={{ listStyleType: 'disc', color: 'grey.500' }}>
            {PASSWORD_REQUIREMENTS.map(({ key, values }) => (
              <ListItem
                key={key}
                sx={{
                  display: 'list-item',
                  listStylePosition: 'inside',
                  paddingBottom: 0,
                }}
              >
                <Typography variant="caption">
                  <FormattedMessage id={key} values={values} />
                </Typography>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </Box>

      <TextField
        {...register('newPasswordRepeat', {
          required: errorMessageFor(intl, {
            type: 'required',
            label: 'repeat_password',
          }),
          validate: (value) =>
            value === watch('newPassword') ||
            errorMessageFor(intl, {
              type: 'mismatch',
              label: 'repeat_password',
            }),
        })}
        label={<FormattedMessage id="form.label.repeat_password" />}
        id="newPasswordRepeat"
        type="password"
        name="newPasswordRepeat"
        fullWidth
        error={!!errors.newPasswordRepeat || !!apiError}
        helperText={errors?.newPasswordRepeat?.message}
      />
    </>
  );
};
