import { Stack, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { MenuItem } from 'types/menus.types';

import MenuSectionAutocomplete from './MenuSectionAutocomplete';

interface Props {
  menuItem: MenuItem;
  isDisabled: boolean;
  menuItemSections: string[];
  uniqueFormId: string;
  onSave: (data: MenuItem) => void;
}

const MenuItemForm = ({ menuItem, isDisabled, menuItemSections, uniqueFormId, onSave }: Props) => {
  const intl = useIntl();

  const { handleSubmit, register, control } = useForm<MenuItem>({
    defaultValues: menuItem,
    progressive: true,
    mode: 'onBlur',
  });

  return (
    <form
      id={uniqueFormId}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSave)();
      }}
    >
      <Stack gap={1} flex={1} width="100%" height="100%" pr={2}>
        <Stack direction="row" gap={1} flex={1} width="100%">
          <TextField
            {...register('name')}
            disabled={isDisabled}
            inputProps={{ maxLength: 255 }}
            placeholder={intl.formatMessage({ id: 'general.label.name' })}
            size="small"
            fullWidth
            autoFocus
          />
          <Controller
            name="section"
            control={control}
            render={({ field }) => (
              <MenuSectionAutocomplete
                disabled={isDisabled}
                options={menuItemSections}
                {...field}
              />
            )}
          />
        </Stack>
        <TextField
          {...register('description')}
          disabled={isDisabled}
          inputProps={{ maxLength: 255 }}
          placeholder={intl.formatMessage({ id: 'general.label.description' })}
          size="small"
        />
      </Stack>
    </form>
  );
};

export default MenuItemForm;
