import { Box, Collapse, Stack } from '@mui/material';
import { RefObject, useContext } from 'react';

import { ChatbotUIContext } from 'services/ai-chat';

import ChatMessage from './ChatMessage';
import EmptyScreen from './EmptyScreen';

type Props = {
  visibilityRef: RefObject<HTMLDivElement>;
};

export const ChatList = ({ visibilityRef }: Props) => {
  const { chatMessages } = useContext(ChatbotUIContext);

  return (
    <Stack flex={1} justifyContent="space-between">
      <Collapse in={!chatMessages.length}>
        <EmptyScreen />
      </Collapse>

      <Collapse in={!!chatMessages.length}>
        <Stack direction="column-reverse">
          <Box width="100%" height="1px" ref={visibilityRef} />
          <Stack
            height="100%"
            direction="column"
            overflow="auto"
            sx={{
              '& a': { color: 'primary.main', textDecoration: 'underline', fontWeight: 500 },
              '& h3': { mt: 3, mb: 2, fontStyle: 'italic' },
              '& h4': { fontWeight: 600 },
              '& li': { py: 0.5 },
            }}
          >
            {chatMessages.map((message, i) => (
              <ChatMessage
                key={message.id}
                message={message}
                secondLastMessage={chatMessages.length === i - 2 ? undefined : chatMessages[i - 2]}
                isLoading={!message.content}
              />
            ))}
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default ChatList;
