import { AxiosError } from 'axios';
import { useIntl } from 'react-intl';

import { useNotifications } from 'services/snackbar';

const BACKEND_DUPLICATE_ERROR_CODE = 409;

export const useCheckForDuplicateRecipeName = () => {
  const intl = useIntl();
  const notifications = useNotifications();

  const checkError = (error: Error) => {
    const errorCode = (error as AxiosError<{ data: { message: string; errorCode: number } }>)
      .response?.data?.data?.errorCode;

    if (errorCode === BACKEND_DUPLICATE_ERROR_CODE) {
      notifications.error({
        message: intl.formatMessage({
          id: 'recipes.creator.submit_for_approval.error_duplicate',
        }),
      });

      return true;
    }
  };

  return checkError;
};
