import config from 'config/env';

import { fetchAuthenticatedUserSession } from 'services/api/axios/auth.interceptor';
import { GetMessagesResponse, SendMessagePayload } from 'types/chat.types';

import RestApiService from './';
import { ApiResponse } from './api.types';

export default {
  getChat(this: RestApiService, chatId: string) {
    return this.api.get<ApiResponse<GetMessagesResponse>>(`/chat/${chatId}`);
  },
  async sendChatMessage(this: RestApiService, payload: SendMessagePayload) {
    // axios' XHR adapter doesn't properly support stream responses, so using fetch here
    const session = await fetchAuthenticatedUserSession();

    return fetch(`${config.API_BASE}/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: session?.tokens?.accessToken.toString() || '',
      },
      body: JSON.stringify(payload),
    });
  },
};
