export type StorageMedia = {
  id: string;
  url: string;
};

export enum StorageMediaContext {
  RecipeStep = 'RECIPE_STEP',
  Recipe = 'RECIPE',
  Menu = 'MENU',
}

export type UploadMediaOptions = {
  context: StorageMediaContext;
  storageFolder: string;
};

export type UploadMediaProps = {
  file: File;
  options: UploadMediaOptions;
};

export type PdfFile = string;
