import { NoMeals as NoMealsIcon } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { CreateRecipeButton, PickRecipeButton, PurchasedItemButton } from '../ActionButtons';

interface Props {
  isCreatingRecipe: boolean;
  isUpdatingMenuItem: boolean;
  isCreatingPurchasedItem: boolean;
  handleSearchRecipe: () => void;
  handleCreateRecipe: () => void;
  handlePurchasedItem: () => void;
}

const MenuItemNoResults = ({
  isCreatingRecipe,
  isUpdatingMenuItem,
  isCreatingPurchasedItem,
  handleSearchRecipe,
  handleCreateRecipe,
  handlePurchasedItem,
}: Props) => {
  const isLoading = isCreatingRecipe || isUpdatingMenuItem;

  return (
    <>
      <NoMealsIcon sx={{ height: '56px', width: '56px', color: 'bluegrey.200' }} />
      <Stack mt={3} gap={1.5} alignItems="center">
        <Typography variant="body1">
          <FormattedMessage id="menus.matcher.no_results.title" />
        </Typography>
        <Typography variant="caption" sx={{ color: (theme) => theme.palette.bluegrey[500] }}>
          <FormattedMessage id="menus.matcher.no_results.description" />
        </Typography>
      </Stack>

      <Stack direction="row" gap={2} mt={6}>
        <PickRecipeButton disabled={isLoading} onClick={handleSearchRecipe} />
        <CreateRecipeButton
          loading={isCreatingRecipe}
          disabled={isLoading}
          onClick={handleCreateRecipe}
        />
        <PurchasedItemButton
          loading={isCreatingPurchasedItem}
          disabled={isLoading}
          onClick={handlePurchasedItem}
        />
      </Stack>
    </>
  );
};

export default MenuItemNoResults;
