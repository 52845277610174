import { Box, Card, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { CoverageValues } from 'components/@menu-form/MenuForm/types';

type Props = {
  isProcessing: boolean;
  coverageValues: CoverageValues;
};

const MenuCoverage = ({ coverageValues, isProcessing }: Props) => {
  const { coveragePercent, matchedAmount, unmatchedAmount, purchasedAmount } = coverageValues;
  const totalAmount = matchedAmount + unmatchedAmount + purchasedAmount;

  return (
    <Card
      variant="outlined"
      sx={(theme) => ({
        bgcolor: 'transparent',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        py: 1.5,
        px: 4,
        borderRadius: theme.mixins.borderRadius.base,
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      })}
    >
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          value={100}
          size={90}
          thickness={3}
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            color: isProcessing ? 'black.10' : 'warning.dark',
          }}
        />
        <CircularProgress
          variant="determinate"
          value={coveragePercent || 0}
          color="success"
          size={90}
          thickness={3}
          sx={{ width: '100%', height: '100%', opacity: isProcessing ? 0 : 100 }}
        />
        <Stack
          alignItems="center"
          justifyContent="center"
          position="absolute"
          sx={{ inset: 0, opacity: isProcessing ? 0 : 100 }}
        >
          <Typography
            variant="h5"
            component="div"
            sx={{ color: (theme) => theme.palette.grey[900] }}
          >
            {`${Math.round(coveragePercent) || 0}%`}
          </Typography>
          <Typography
            variant="tooltip"
            component="div"
            sx={{ color: (theme) => theme.palette.grey[600] }}
            mt={-0.5}
          >
            <FormattedMessage id="menus.coverage.label.single" />
          </Typography>
        </Stack>
      </Box>
      <Stack
        direction="row"
        gap={5}
        px={6}
        sx={(theme) => ({
          [theme.breakpoints.between('md', 'lg')]: {
            px: 4,
            gap: 3,
          },
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        })}
      >
        <CoverageBadge
          label={<FormattedMessage id="menus.items.total" />}
          amount={isProcessing ? undefined : totalAmount}
        />
        <CoverageBadge
          label={<FormattedMessage id="menus.items.matched" />}
          amount={isProcessing ? undefined : matchedAmount}
          color={isProcessing ? undefined : 'green.500'}
        />
        <CoverageBadge
          label={<FormattedMessage id="menus.items.not_matched" />}
          amount={isProcessing ? undefined : unmatchedAmount}
          color={isProcessing ? undefined : 'warning.main'}
        />
      </Stack>

      <Divider orientation="vertical" />
      <Box pl={4}>
        <CoverageBadge
          label={<FormattedMessage id="menus.items.purchased" />}
          amount={isProcessing ? undefined : purchasedAmount}
        >
          <Typography
            variant="tooltip"
            sx={{ color: (theme) => theme.palette.black[60] }}
            width={106}
          >
            <FormattedMessage id="menus.items.purchased.text" />
          </Typography>
        </CoverageBadge>
      </Box>
    </Card>
  );
};

export default MenuCoverage;

type CoverageBadgeProps = {
  label: ReactNode;
  amount?: number;
  color?: string;
  children?: ReactNode;
};
const CoverageBadge = ({ label, amount, color, children }: CoverageBadgeProps) => (
  <Stack
    direction="row"
    gap={1}
    alignSelf="flex-end"
    sx={(theme) => ({
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'start',
      },
    })}
  >
    {color && (
      <Box
        width={10}
        height={10}
        borderRadius={(theme) => theme.mixins.borderRadius.full}
        bgcolor={color}
        mt={1}
      />
    )}
    <Stack gap={0.5}>
      <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.grey[600] }}>
        {label}
      </Typography>
      <Stack direction="row" gap={2.5}>
        <Typography variant="h5" sx={{ color: (theme) => theme.palette.grey[900] }}>
          {amount || '-'}
        </Typography>
        {children}
      </Stack>
    </Stack>
  </Stack>
);
