import { Box, Button, Collapse, Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FallbackProps } from './boundary.types';

const Fallback = ({ error, componentStack }: FallbackProps) => {
  const [displayErrorStack, setDisplayErrorStack] = useState(false);

  return (
    <Box width="100%" pt={8} px={2} pb={2} display="flex" flexDirection="column">
      <Stack mb="30px" gap={2} textAlign="center" justifyContent="center" alignItems="center">
        <Typography variant="h1">
          <FormattedMessage id="error_page.title" />
        </Typography>
        <Typography variant="h3" mb={4}>
          <FormattedMessage id="error_page.description" />
        </Typography>
        <Button variant="contained" sx={{ mb: 4 }} onClick={() => location.reload()}>
          <FormattedMessage id="error_page.refresh" />
        </Button>
      </Stack>

      <Stack mt="auto">
        <Collapse in={!displayErrorStack}>
          <Button
            variant="text"
            sx={{ color: 'black.80' }}
            onClick={() => setDisplayErrorStack(true)}
          >
            <FormattedMessage id="error_page.show_error" />
          </Button>
        </Collapse>
        <Collapse in={displayErrorStack} sx={{ color: 'black.80' }}>
          <Typography variant="h6">{(error as unknown as Error)?.message}</Typography>
          <pre style={{ marginTop: 0, whiteSpace: 'pre-line' }}>{componentStack}</pre>
        </Collapse>
      </Stack>
    </Box>
  );
};

interface Props {
  children: ReactNode;
  boundary: string;
}

const ErrorBoundary = ({ children, boundary }: Props) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => scope.setTag('boundary', boundary)}
    fallback={(props) => <Fallback {...props} />}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;
