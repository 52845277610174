'use client';

import { Avatar, Box, CircularProgress, Stack, StackProps, Typography } from '@mui/material';
import React, { ReactNode, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import alain_avatar from 'assets/images/avatar_alain.png';

import { AvatarUser } from '../components/Chat.styles';
import MarkdownMessage from './MarkdownMessage';

// Different types of message bubbles.
const IMG_SIZE = 46;

type UserMessageProps = { children: ReactNode };
export const UserMessage = ({ children }: UserMessageProps) => (
  <MessageWrap
    avatar={
      <AvatarUser>
        <FormattedMessage id="general.person.you" />
      </AvatarUser>
    }
  >
    <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.secondary }}>
      {children}
    </Typography>
  </MessageWrap>
);

type BotMessageProps = {
  content: string;
  className?: string;
  children?: React.ReactNode;
};
export const BotMessage = ({ content, children }: BotMessageProps) => (
  <MessageWrap avatar={<Avatar src={alain_avatar} sx={{ height: IMG_SIZE, width: IMG_SIZE }} />}>
    <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.secondary }} mb={2}>
      <MarkdownMessage content={content} />
    </Typography>
    {children}
  </MessageWrap>
);

type BotCardProps = {
  children: ReactNode;
  showAvatar?: boolean;
};
export const BotCard = ({ children, showAvatar = true }: BotCardProps) => (
  <MessageWrap
    avatar={showAvatar && <Avatar src={alain_avatar} sx={{ height: IMG_SIZE, width: IMG_SIZE }} />}
  >
    <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.secondary }}>
      {children}
    </Typography>
  </MessageWrap>
);

type SystemMessageProps = { children: ReactNode };
export const SystemMessage = ({ children }: SystemMessageProps) => {
  return (
    <div>
      <div>system: {children}</div>
    </div>
  );
};

type SpinnerMessageProps = {
  children?: ReactNode;
};
export const SpinnerMessage = ({ children }: SpinnerMessageProps) => {
  return (
    <MessageWrap avatar={<Avatar src={alain_avatar} sx={{ height: IMG_SIZE, width: IMG_SIZE }} />}>
      <Stack gap={2} direction="row" alignItems="center">
        <div>
          {/* don't remove wrapping div */}
          <CircularProgress />
        </div>

        {children && (
          <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.secondary }}>
            {children}
          </Typography>
        )}
      </Stack>
    </MessageWrap>
  );
};

type MessageWrapProps = StackProps & { children: ReactNode; avatar?: ReactNode };
const MessageWrap = ({ children, avatar, ...restProps }: MessageWrapProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const smallContent = contentRef.current && contentRef.current?.clientHeight < IMG_SIZE;
  return (
    <Stack
      direction="row"
      gap={1.75}
      px={{ xs: 1.5, sm: 3 }}
      py={2}
      alignItems={smallContent ? 'center' : 'flex-start'}
      sx={{ wordBreak: 'break-word' }}
      {...restProps}
    >
      {avatar}
      <Box ref={contentRef}>{children}</Box>
    </Stack>
  );
};
