import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import Cookbook from './Cookbook';

// @TODO move the CheckAuth logic to the separate index files for each route, remove the authenticated flags everywhere, and set routes to lazy loading
// --> check if this actually improves performance over what we have currently and doesn't just cause new pages to load slower
export default createRoute({
  element: <Cookbook />,
  path: Routes.Cookbook,
  authenticated: true,
});
