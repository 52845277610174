import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { CountryInsights } from 'types/insights.types';

import { getExperimentalCounts } from 'components/@insights/utils';

interface Props {
  country: CountryInsights;
}

const ExperimentalRecipesCell = ({ country }: Props) => {
  const perStatus = useMemo(() => getExperimentalCounts(country.recipes), [country]);

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Stack gap={1} width="100%">
        <Stack height="13px" width="100%" borderRadius={5} overflow="hidden" direction="row">
          {perStatus.map(({ color, label, value }) => (
            <Tooltip
              key={label}
              placement="top"
              arrow
              title={
                <span>
                  {value} <FormattedMessage id={label} />
                </span>
              }
            >
              <Box bgcolor={color} flex={value} height="100%" />
            </Tooltip>
          ))}
        </Stack>

        <Stack direction="row" gap={2} justifyContent="space-between">
          {perStatus.map(({ color, label, value }) => (
            <Tooltip key={label} title={<FormattedMessage id={label} />} arrow>
              <Stack gap={1} direction="row" alignItems="center">
                <Box
                  ml="auto"
                  mt="1px"
                  width="10px"
                  height="10px"
                  borderRadius="100%"
                  bgcolor={color}
                />

                <Typography
                  variant="subtitle2"
                  fontWeight={700}
                  sx={{ color: (theme) => theme.palette.global01.main }}
                >
                  {value}
                </Typography>
              </Stack>
            </Tooltip>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ExperimentalRecipesCell;
