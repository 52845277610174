import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';
import { Edit as EditIcon } from '@mui/icons-material';
import { Box, Fade, IconButton, List, ListItem, Stack, TextField, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

import { listItemAnimations } from 'constants/animation.constants';
import { useBulkUpdateMenuItem } from 'queries';
import { MenuItem } from 'types/menus.types';

import ErrorBoundary from 'components/@boundaries/ErrorBoundary';

type Props = {
  onSectionClick: (section: MenuItem['section']) => void;
  activeSection: MenuItem['section'] | null;
  categorizedMenuItems: { section: string; menuItems: MenuItem[] }[];
};

const MenuItemSectionsOutline = ({
  onSectionClick,
  activeSection,
  categorizedMenuItems,
}: Props) => {
  const { updateMenuItems, isPending } = useBulkUpdateMenuItem();
  const [isEditingSectionName, setIsEditingSectionName] = useState('');
  const [newSectionName, setNewSectionName] = useState('');

  const handleUpdateSectionName = () => {
    if (!newSectionName) return null;
    const section = categorizedMenuItems.find((item) => item.section === isEditingSectionName);
    if (!section) return closeEditSectionName();

    updateMenuItems(
      section.menuItems.map((item) => ({
        ...item,
        section: newSectionName || '',
        recipe: item.recipe || { id: null },
      })),
      { onSuccess: closeEditSectionName },
    );
  };

  const closeEditSectionName = () => {
    setIsEditingSectionName('');
    setNewSectionName('');
  };

  const handleEditSectionName = (sectionName: string) => {
    setIsEditingSectionName(sectionName);
    setNewSectionName(sectionName);
  };

  return (
    <ErrorBoundary boundary="menu-sections-outline">
      <Box
        position="sticky"
        top={16}
        gridArea="1/1/6/2"
        width="100%"
        height="calc(100vh - 32px)"
        maxWidth={270}
        bgcolor="bg.paper"
        borderRadius={(theme) => theme.mixins.borderRadius.base}
        sx={(theme) => ({
          overflowX: 'hidden',
          overflowY: 'auto',
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
        component={List}
      >
        <AnimatePresence initial={false} mode="popLayout">
          {categorizedMenuItems.map(({ section, menuItems }) => (
            <motion.div layout key={section} {...listItemAnimations}>
              <ListItem
                onClick={() => onSectionClick(section)}
                color="black.60"
                sx={{
                  py: 1.5,
                  pl: 1,
                  pr: 0.5,
                  cursor: 'pointer',
                  transition: 'background-color 0.15s ease',
                  overflow: 'hidden',
                  wordBreak: 'break-word',
                  bgcolor: section === activeSection ? 'bluegrey.50' : 'bg.paper',
                  '&:hover': {
                    bgcolor: section === activeSection ? 'bluegrey.100' : 'bluegrey.50',
                  },
                  '&:hover .edit-button': { opacity: 1 },
                  '&:active': {
                    bgcolor: 'bluegrey.100',
                  },
                }}
              >
                <Fade in={!isEditingSectionName || isEditingSectionName !== section}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    position="absolute"
                    sx={{ inset: '12px 16px' }}
                  >
                    <Typography textTransform="capitalize">{section}</Typography>
                    <IconButton
                      className="edit-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditSectionName(section);
                      }}
                      sx={{
                        ml: 'auto',
                        width: 24,
                        height: 24,
                        opacity: 0,
                        transition: 'opacity 0.2s',
                      }}
                    >
                      <EditIcon sx={{ width: 20 }} />
                    </IconButton>

                    <Typography variant="chip" fontWeight={500} flexShrink={0}>
                      {menuItems.length || 0}
                    </Typography>
                  </Stack>
                </Fade>

                <Fade in={isEditingSectionName === section} onClick={(e) => e.stopPropagation()}>
                  <Stack direction="row" alignItems="center" gap={0.25}>
                    <TextField
                      size="small"
                      fullWidth
                      value={newSectionName}
                      onChange={(e) => setNewSectionName(e.target.value)}
                      sx={{ '& input': { pl: 1 } }}
                    />
                    <IconButton
                      disabled={isPending}
                      color="inherit"
                      onClick={closeEditSectionName}
                      sx={{ height: 30, width: 30 }}
                    >
                      <ClearIcon sx={{ height: 18, width: 18 }} />
                    </IconButton>
                    <IconButton
                      disabled={isPending}
                      color="inherit"
                      onClick={handleUpdateSectionName}
                      sx={{ height: 30, width: 30 }}
                    >
                      <CheckIcon sx={{ height: 18, width: 18 }} />
                    </IconButton>
                  </Stack>
                </Fade>
              </ListItem>
            </motion.div>
          ))}
        </AnimatePresence>
      </Box>
    </ErrorBoundary>
  );
};

export default MenuItemSectionsOutline;
