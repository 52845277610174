import { Skeleton, Typography } from '@mui/material';
import { Children, isValidElement, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';

import MarkdownMessageMemo from './MarkdownMessageMemo';

interface Props {
  content: string;
}

const MarkdownMessage = ({ content }: Props) => {
  const navigate = useNavigate();

  return (
    <MarkdownMessageMemo
      remarkPlugins={[remarkGfm, remarkMath]}
      components={{
        a: ({ children, ...props }) => (
          <a
            {...props}
            onClick={(e) => {
              // use react-router to navigate if the link is internal

              const url = new URL(props.href || '');
              if (url.hostname !== window.location.hostname) return;
              e.preventDefault();
              navigate(url.pathname);
            }}
          >
            {children}
          </a>
        ),
        p: ({ children }) => (
          <Typography mb={1} sx={{ '&:last-of-type': { mb: 0 } }}>
            {children}
          </Typography>
        ),
        img: ({ node, ...props }) => <img {...props} />,
        code: ({ node, className, children, ...props }) => {
          const childArray = Children.toArray(children);
          const firstChild = childArray[0] as React.ReactElement;
          const firstChildAsString = isValidElement(firstChild)
            ? (firstChild as ReactElement).props.children
            : firstChild;

          if (firstChildAsString === '▍') {
            return <Skeleton height={16} sx={{ flex: 1 }} />;
          }

          if (typeof firstChildAsString === 'string') {
            childArray[0] = firstChildAsString.replace('`▍`', '▍');
          }

          <code {...props}>{childArray}</code>;
        },
      }}
    >
      {content}
    </MarkdownMessageMemo>
  );
};

export default MarkdownMessage;
