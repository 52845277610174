import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { CommonInfiniteQueryOptions } from 'queries/types';
import { getInfiniteData } from 'queries/utils';
import { apiService } from 'services';
import { ApiResponse } from 'services/api/api.types';
import { GetMenuParams, Menu } from 'types/menus.types';

const fetchMenus = async (options: GetMenuParams) => {
  const { data: menus } = await apiService.getMenus(options);
  return menus;
};

export const useMenus = (
  params: GetMenuParams,
  config?: CommonInfiniteQueryOptions<
    ApiResponse<Menu[]>,
    ReturnType<typeof QueryKeys.menus.all>,
    number
  >,
) => {
  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    isError,
    data,
    error,
  } = useInfiniteQuery({
    ...config,
    initialPageParam: 1,
    placeholderData: (previousData) => previousData, // keep previous data
    queryKey: QueryKeys.menus.all(Object.values(params)),
    queryFn: ({ pageParam }) => fetchMenus({ ...params, page: pageParam }),
    getNextPageParam: (lastPage, allPages) =>
      !!lastPage.links.next ? allPages.length + 1 : undefined,
    staleTime: config?.staleTime ?? Duration.FIVE_MIN,
  });

  const infiniteData = useMemo(() => getInfiniteData(data), [data]);

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    isError,
    error,
    ...infiniteData,
  };
};
