import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useResetPassword } from 'queries';
import { useNotifications } from 'services/snackbar';
import { CognitoError, LoginState, ResetPasswordParams } from 'types/auth.types';

import { PasswordField } from 'components/@auth/PasswordField';

interface Props {
  initialEmail: string;
  initialResetToken: string | null;
  onLoginStateChange: (state: LoginState) => void;
}

export function ResetPasswordForm({ initialEmail, initialResetToken, onLoginStateChange }: Props) {
  const intl = useIntl();
  const notifications = useNotifications();
  const { resetPassword, isPending } = useResetPassword();
  const [error, setError] = useState<CognitoError['name'] | null>(null);

  const formMethods = useForm({
    defaultValues: {
      email: initialEmail,
      newPassword: '',
      confirmationCode: initialResetToken || '',
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const handleResetPassword = (values: ResetPasswordParams) => {
    setError(null);

    resetPassword(values, {
      onSuccess: () => onLoginStateChange(LoginState.Success),
      onError: (error) => {
        notifications.error({ message: intl.formatMessage({ id: 'common.error_messages.error' }) });
        console.error(error);
        setError(error.name);
      },
    });
  };

  const hasEmailError = !!errors.email || error === 'UserNotFoundException';
  const hasCodeError =
    !!errors.confirmationCode ||
    ['CodeMismatchException', 'ExpiredCodeException'].includes(error || '');

  return (
    <>
      <Typography variant="h4">
        <FormattedMessage id="login.password.reset.title" />
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 5 }}>
        <FormattedMessage id="login.password.reset.description" />
      </Typography>

      <form onSubmit={handleSubmit(handleResetPassword)}>
        <FormProvider {...formMethods}>
          <Stack gap={3}>
            <TextField
              {...register('email', { required: true })}
              type="email"
              label={<FormattedMessage id="form.label.email" />}
              variant="outlined"
              error={hasEmailError}
            />

            <TextField
              {...register('confirmationCode', { required: true })}
              label={<FormattedMessage id="login.password.reset.code" />}
              variant="outlined"
              error={hasCodeError}
              helperText={hasCodeError && <FormattedMessage id="login.password.reset.error.code" />}
            />

            <PasswordField autoFocus />
          </Stack>

          <Box gap={2} display="flex" alignItems="center" justifyContent="flex-end" mt={5}>
            <Button
              onClick={() => onLoginStateChange(LoginState.Login)}
              variant="link"
              color="inherit"
              tabIndex={1}
            >
              <FormattedMessage id="login.password.forgot.back_to_login" />
            </Button>
            <LoadingButton loading={isPending} type="submit" variant="contained">
              <FormattedMessage id="login.password.reset.title" />
            </LoadingButton>
          </Box>
        </FormProvider>
      </form>
    </>
  );
}
