export const getMenuCompliance = (
  compliance: number = -1,
  isCalculating: boolean = false,
): I18nKey | undefined => {
  if (isCalculating) {
    return 'general.calculating.label';
  }
  if (compliance >= 0 && compliance <= 20) {
    return 'general.value.average';
  }
  if (compliance >= 20 && compliance <= 40) {
    return 'general.value.good';
  }
  if (compliance > 40 && compliance <= 60) {
    return 'general.value.excellent';
  }
  if (compliance > 60 && compliance <= 80) {
    return 'general.value.outstanding';
  }
  if (compliance > 80 && compliance <= 100) {
    return 'general.value.awesome';
  }
};
