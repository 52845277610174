import { FoodBank as FoodBankIcon } from '@mui/icons-material';

import { ActionButton } from 'components/@common';

interface Props {
  disabled?: boolean;
  loading?: boolean;
  wide?: boolean;
  onClick: () => void;
}

const CreateRecipeButton = ({ wide, loading, disabled, onClick }: Props) => {
  return (
    <ActionButton
      icon={<FoodBankIcon sx={{ height: 40, width: 40 }} />}
      text="menus.matcher.no_results.create"
      loading={loading}
      disabled={disabled}
      wide={wide}
      onClick={onClick}
    />
  );
};

export default CreateRecipeButton;
