import { Skeleton, Stack } from '@mui/material';

const MenuSkeleton = () => {
  return (
    <Stack gap={1} mt={2}>
      {/* Main info */}
      <Skeleton height={28} width={90} />
      <Stack direction="row" justifyContent="space-between" mt={4} alignItems="start">
        <Skeleton height={48} width={420} />
        <Skeleton height={42} width={80} />
      </Stack>
      <Stack direction="row" gap={1} sx={{ mt: 2 }} alignItems="center">
        <Skeleton height={36} width={96} variant="circular" />
        <Skeleton height={36} width={96} variant="circular" />
      </Stack>
      {/* Compliance / Coverage */}
      <Stack gap={3} mt={2} direction="row">
        <Skeleton height={120} width="35%" />
        <Skeleton height={120} sx={{ flex: 1 }} />
      </Stack>
      {/* Menu Items */}
      <Stack mt={3}>
        <Skeleton height={32} width={200} />
        <Stack direction="row" gap={3} mt={2} width="100%">
          <Stack width="25%" height="100%" gap={0.5}>
            {Array.from({ length: 12 }).map((_, i) => (
              <Skeleton key={i} height={40} />
            ))}
          </Stack>
          <Stack flex={1} gap={1}>
            <Stack direction="row" gap={0.5} justifyContent="space-between">
              <Stack direction="row" gap={0.5} width="66%">
                <Skeleton height={56} sx={{ flex: 1 }} />
                <Skeleton height={56} sx={{ flex: 1 }} />
                <Skeleton height={56} sx={{ flex: 1 }} />
              </Stack>
              <Skeleton height={56} sx={{ flex: 1 }} />
            </Stack>
            <Stack flex={1} gap={1}>
              {Array.from({ length: 3 }).map((_, i) => (
                <Stack key={i} gap={0.5}>
                  <Skeleton key={i} height={60} />
                  {Array.from({ length: 5 }).map((_, i) => (
                    <Skeleton key={i} height={88} />
                  ))}
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MenuSkeleton;
