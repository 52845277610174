import { Outlet } from 'react-router-dom';

import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import cookbook from 'pages/recipes/cookbook';
import experimentalKitchen from 'pages/recipes/experimental-kitchen';
import recipeDashboard from 'pages/recipes/recipe-dashboard';
import recipeDetail from 'pages/recipes/recipe-detail';

export default createRoute({
  path: Routes.Root,
  Component: Outlet,
  children: [recipeDashboard, cookbook, experimentalKitchen, recipeDetail],
});
