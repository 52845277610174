import axios from 'axios';
import { nanoid } from 'nanoid';

import config from 'config/env';

import { fileToHash } from 'utils/file.utils';

import { mediaStorageContextMap } from 'constants/media.constants';
import { StorageMedia, UploadMediaProps } from 'types/media.types';

import RestApiService from '.';

export default {
  async uploadMedia(this: RestApiService, { file: f, options }: UploadMediaProps) {
    // Add uuid to the file name to prevent naming conflicts on s3
    const file = new File([f], `${nanoid()}-${f.name}`, { type: f.type });

    const hash = ((await fileToHash(file)) || '') as string;
    const { data: storageFile } = await this.api.post<StorageMedia>(`/generate-presigned-url`, {
      data: {
        context: options.context,
        fileName: file.name,
        mimeType: file.type,
        fileHash: hash,
        folder: options.storageFolder,
      },
    });

    await postSignedFileBinary(storageFile.url, file, hash);
    const imageUrl = `${config.S3_BASE}/${options.storageFolder}/${mediaStorageContextMap[options.context]}/${file.name}`;
    return {
      name: file.name,
      imageUrl,
      storageFile,
    };
  },
};

const postSignedFileBinary = async (signedUrl: string, file: File, md5Hash: string) => {
  // Don't use axiosInstance since this request is external and the Authorization header causes a problem.
  return axios.put(signedUrl, file, {
    headers: {
      'Content-MD5': md5Hash,
      'Content-Type': file.type,
    },
  });
};
