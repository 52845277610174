/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */
import { NonIndexRouteObject } from 'react-router-dom';

import { RouteBoundary } from 'components/@boundaries';
import { Layout } from 'components/@common';

import insights from './insights';
import login from './login';
import menus from './menus';
import notFound from './not-found';
import recipes from './recipes';

export const routes: NonIndexRouteObject = {
  path: '/',
  element: <Layout />,
  errorElement: <RouteBoundary />,
  children: [recipes, menus, insights, login, notFound],
};
