import { Box, SortDirection, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { TableVirtuoso } from 'react-virtuoso';

import { getOrderIcon } from 'utils/table.utils';

import { Routes } from 'constants/routes.constants';
import { Menu, MenuTableColumns, MenuTableHeader } from 'types/menus.types';

import ErrorBoundary from 'components/@boundaries/ErrorBoundary';
import { SortingHeader } from 'components/@recipe-overview/RecipeTable/RecipeTable.style';

import MenuTableRow from './MenuTableRow';
import MenuTableSkeleton from './MenuTableSkeleton';

type Props = {
  menus: Menu[];
  menusTotal: number;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  sortDirection: SortDirection;
  sortBy: MenuTableColumns;
  getNext: () => void;
  onSort: (property: MenuTableColumns, initialSortDirection?: SortDirection | undefined) => void;
};

const tableHeaders: MenuTableHeader[] = [
  {
    id: MenuTableColumns.Title,
    label: 'general.title.label',
    width: '30%',
    sortable: true,
    initialSort: 'asc',
  },
  { id: MenuTableColumns.Country, label: 'general.country.label', width: '10%' },
  { id: MenuTableColumns.Year, label: 'menus.year.label', width: '8%' },
  { id: MenuTableColumns.Season, label: 'general.seasons.label', width: '20%' },
  {
    id: MenuTableColumns.Coverage,
    label: 'menus.coverage.label.single',
    width: '12%',
    sortable: true,
    initialSort: 'desc',
  },
  {
    id: MenuTableColumns.UpdatedAt,
    label: 'common.updated_at',
    width: '20%',
    sortable: true,
    initialSort: 'desc',
  },
];

const MenuTable = ({
  menus,
  menusTotal,
  isLoading,
  isFetchingNextPage,
  getNext,
  sortDirection,
  sortBy,
  onSort,
}: Props) => {
  const navigate = useNavigate();

  return (
    <ErrorBoundary boundary="menu-table">
      <Box
        mt={3}
        sx={{
          overflowX: 'auto',
          overflowY: 'hidden',
          table: { tableLayout: 'fixed', maxWidth: '1188px', minWidth: '950px', width: '100%' },
          borderRadius: 2,
          height: '100%',
        }}
      >
        <TableVirtuoso
          useWindowScroll
          totalCount={menusTotal}
          data={menus}
          overscan={500}
          increaseViewportBy={500}
          endReached={getNext}
          computeItemKey={(_index, data) => Number(data.id)}
          fixedHeaderContent={() => (
            <TableRow sx={{ backgroundColor: 'bg.paper' }}>
              {tableHeaders.map(({ id, label, sortable, initialSort, width }) => (
                <TableCell
                  key={id}
                  width={width}
                  sortDirection={sortable ? (sortBy === id ? sortDirection : false) : undefined}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    p: 2,
                    borderLeft: '1px solid',
                    borderColor: 'black.20',
                    '&:first-of-type': { borderLeft: 'none ' },
                  }}
                >
                  {sortable ? (
                    <SortingHeader
                      active={sortBy === id}
                      direction={sortBy === id ? sortDirection || undefined : 'desc'}
                      onClick={() => onSort(id, initialSort)}
                      IconComponent={() => getOrderIcon({ id, sortBy, sortDirection })}
                    >
                      <FormattedMessage id={label} />
                    </SortingHeader>
                  ) : (
                    <FormattedMessage id={label} />
                  )}
                </TableCell>
              ))}
            </TableRow>
          )}
          itemContent={(_index, data) => (
            <MenuTableRow key={data.id} menu={data} tableHeaders={tableHeaders} />
          )}
          fixedFooterContent={() => (isFetchingNextPage || isLoading) && <MenuTableSkeleton />}
          components={{
            EmptyPlaceholder: () =>
              !isLoading && (
                <TableBody>
                  <TableRow sx={{ backgroundColor: 'bg.paper' }}>
                    <TableCell colSpan={tableHeaders.length}>
                      <Typography align="center">
                        <FormattedMessage id="menus.none" />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ),
            TableRow: ({ item, ...props }) => (
              <TableRow
                hover
                onClick={() => navigate(generatePath(Routes.MenuDetail, { menuId: `${item.id}` }))}
                sx={{ cursor: 'pointer', backgroundColor: 'bg.paper', overflow: 'hidden' }}
                {...props}
              />
            ),
            ScrollSeekPlaceholder: () => <MenuTableSkeleton />,
          }}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default memo(MenuTable);
