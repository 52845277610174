import { InsertDriveFile as InsertDriveFileIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { highlightText } from 'utils/text.utils';

import { RecipeFormIngredient } from 'types/recipes.types';

type Props = {
  inputValue: string;
  option: RecipeFormIngredient;
};

const AutocompleteItem = ({ option, inputValue }: Props) => {
  return (
    <>
      <Typography whiteSpace="wrap">{highlightText(inputValue, option?.name)}</Typography>

      {option.isIngredientRecipe && (
        <Box display="flex" gap={0.75} alignItems="center" justifyContent="flex-end" ml="auto">
          <Typography variant="buttonSmall">
            <FormattedMessage id="recipes.mep.label.short" />
          </Typography>
          <InsertDriveFileIcon sx={{ width: 20, color: 'black.60' }} />
        </Box>
      )}
    </>
  );
};

export default AutocompleteItem;
