import { useMutation } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { RecipeCommentParams } from 'types/recipes.types';

const addComment = (params: RecipeCommentParams) => {
  return apiService.addComment(params);
};

export const useAddComment = () => {
  const intl = useIntl();
  const notifications = useNotifications();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: addComment,
    onSuccess: () => {
      notifications.success({
        message: intl.formatMessage({ id: 'recipes.creator.comment.success' }),
      });
    },
    onError: () => {
      notifications.error({ message: intl.formatMessage({ id: 'error.general.text' }) });
    },
  });

  return {
    addComment: mutateAsync,
    isPending,
  };
};
