import { useMutation, useQueryClient } from '@tanstack/react-query';
import { confirmSignIn } from 'aws-amplify/auth';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries';
import { useNotifications } from 'services/snackbar';

interface Variables {
  newPassword: string;
  attributes: {
    given_name: string;
    family_name: string;
  };
}

export const useCreateCompleteNewPassword = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isPending, error } = useMutation({
    mutationFn: (data: Variables) =>
      confirmSignIn({
        challengeResponse: data.newPassword,
        options: {
          userAttributes: data.attributes,
        },
      }),
    onSuccess: (data) => {
      queryClient.setQueryData(QueryKeys.auth.currentUser(), data);
    },
    onError: () => {
      notifications.error({ message: intl.formatMessage({ id: 'common.error_messages.error' }) });
    },
  });

  return {
    completeNewPassword: mutate,
    isPending,
    error,
  };
};
