import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Chip, chipClasses, MenuItem } from '@mui/material';
import { noop } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ActiveFilters, FilterOptions, ToggleFilterFn } from 'types/common.types';

import Popper from 'components/@common/Popper';

type Props<T extends string> = {
  activeFilters: ActiveFilters<T>;
  label: ReactNode;
  filter: T;
  options: FilterOptions;
  amountSelectedKey?: I18nKey;
  removable?: boolean;
  updateFilters: ToggleFilterFn<T>;
  onRemove?: () => void;
};

const FilterSelect = <T extends string>({
  activeFilters,
  label,
  filter,
  options,
  amountSelectedKey = 'general.amount_selected',
  removable,
  updateFilters,
  onRemove,
}: Props<T>) => {
  const chipLabel = useMemo(() => {
    if (activeFilters[filter].length === 0) return label;
    if (activeFilters[filter].length === 1) {
      return options[activeFilters[filter][0]];
    }
    return (
      <FormattedMessage id={amountSelectedKey} values={{ amount: activeFilters[filter].length }} />
    );
  }, [activeFilters, amountSelectedKey, filter, label, options]);

  const bgcolor = useMemo(() => {
    if (!activeFilters[filter].length) return 'transparent';
    return !!activeFilters[filter].length ? 'primary.lighter' : 'primary.100';
  }, [activeFilters, filter]);

  const border = useMemo(() => {
    if (!activeFilters[filter].length) return '1px solid';
    return 'none';
  }, [activeFilters, filter]);

  return (
    <Box alignItems="center" flexDirection="row" gap={1}>
      <Popper
        trigger={
          <Chip
            label={chipLabel}
            icon={<KeyboardArrowDownIcon />}
            onDelete={removable ? onRemove : noop}
            sx={{
              flexDirection: 'row-reverse', // place delete icon at the start
              bgcolor,
              border,
              borderColor: 'black.60',
              px: 1.25,
              [`& .${chipClasses.label}`]: {
                px: removable ? 0 : 0.25,
              },
              [`& .${chipClasses.deleteIcon}`]: {
                display: removable ? undefined : 'none',
              },
            }}
          />
        }
      >
        {Object.keys(options).map((option) => (
          <MenuItem
            key={option}
            selected={activeFilters[filter].includes(option)}
            onClick={() => updateFilters(filter, option)}
          >
            {options[option]}
          </MenuItem>
        ))}
      </Popper>
    </Box>
  );
};

export default FilterSelect;
