import { Box, BoxProps, Chip, Stack, Tooltip, TooltipProps, Typography, Zoom } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { useCountryName } from 'utils/recipe.utils';

import { COUNTRIES_WITH_ALL, Country } from 'constants/countries.constants';
import { COUNTRIES_ALL } from 'constants/recipes.constants';

interface Props extends BoxProps {
  country: Country;
  size?: number;
  tooltipPlacement?: TooltipProps['placement'];
  overrideTooltip?: React.ReactNode;
}

const CountryFlag = ({
  country,
  size = 20,
  tooltipPlacement = 'bottom',
  overrideTooltip,
  ...props
}: Props) => {
  const getCountryName = useCountryName();

  return (
    <Tooltip
      arrow
      placement={tooltipPlacement}
      TransitionComponent={Zoom}
      title={
        overrideTooltip ??
        (country === COUNTRIES_ALL ? (
          <FormattedMessage id="recipes.requirements.core_recipe" />
        ) : (
          getCountryName(country)
        ))
      }
      componentsProps={{
        popper: { modifiers: [{ name: 'offset', options: { offset: [0, -4] } }] },
        tooltip: {
          sx: {
            color: 'text.secondary',
            px: 1,
            py: 0.5,
            textAlign: 'start',
          },
        },
      }}
    >
      {country === COUNTRIES_ALL ? (
        <Chip variant="labelDark" label={<FormattedMessage id="countries.ALL" />} />
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Box
            className={`fib fi-${COUNTRIES_WITH_ALL[country]} fis`}
            width={size}
            height={size}
            borderRadius={(theme) => theme.mixins.borderRadius.lg}
            {...props}
          />
          <Typography className="print-only">{getCountryName(country)}</Typography>
        </Stack>
      )}
    </Tooltip>
  );
};

export default CountryFlag;
