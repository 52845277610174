import { Skeleton, Stack } from '@mui/material';

const RecipeSkeleton = () => {
  return (
    <Stack gap={1}>
      {/* Main info */}
      <Skeleton height={28} width={90} />
      <Skeleton height={24} width={72} sx={{ mt: 3 }} />
      <Stack direction="row" justifyContent="space-between">
        <Skeleton height={42} width={320} />
        <Stack direction="row" gap={1}>
          <Skeleton height={42} width={42} />
          <Skeleton height={42} width={42} />
          <Skeleton height={42} width={220} />
        </Stack>
      </Stack>
      <Stack direction="row" gap={1} sx={{ mt: 2 }}>
        <Skeleton height={36} width={120} variant="circular" />
        <Skeleton height={36} width={120} variant="circular" />
        <Skeleton height={36} width={120} variant="circular" />
      </Stack>
      {/* Ingredients */}
      <Stack gap={2} mt={6}>
        <Skeleton width={132} height={24} />
        <Stack gap={0.5}>
          <Stack direction="row" gap={0.5}>
            <Skeleton height={32} sx={{ flex: 1 }} />
            <Skeleton height={32} sx={{ flex: 1 }} />
            <Skeleton height={32} sx={{ flex: 1 }} />
          </Stack>
          <Stack direction="row" gap={0.5}>
            <Skeleton height={40} sx={{ flex: 1 }} />
            <Skeleton height={40} sx={{ flex: 1 }} />
            <Skeleton height={40} sx={{ flex: 1 }} />
          </Stack>
        </Stack>
      </Stack>
      {/* MEP - Plating */}
      <Stack direction="row" gap={3} mt={3}>
        <Stack gap={1} flex={1}>
          <Skeleton height={24} width={132} />
          <Skeleton height={340} sx={{ width: '100%' }} />
        </Stack>
        <Stack gap={1} flex={1}>
          <Skeleton height={24} width={132} />
          <Skeleton height={340} sx={{ width: '100%' }} />
        </Stack>
      </Stack>
      {/* Preparation */}
      <Stack gap={1} mt={3}>
        <Skeleton height={24} width={132} />
        <Stack direction="row" gap={3}>
          <Skeleton height={120} sx={{ flex: 1 }} />
          <Skeleton height={120} sx={{ flex: 1 }} />
          <Skeleton height={120} sx={{ flex: 1 }} />
        </Stack>
      </Stack>
      {/* Remarks */}
      <Stack gap={1} mt={3}>
        <Skeleton width={132} height={24} />
        <Stack gap={0.5}>
          <Skeleton height={40} sx={{ width: '100%' }} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RecipeSkeleton;
