import { Components, Theme } from '@mui/material';

export const MuiSkeleton: Components<Theme>['MuiSkeleton'] = {
  defaultProps: {
    animation: 'wave',
    variant: 'rounded',
  },
  variants: [
    {
      props: { variant: 'circular' },
      style: { borderRadius: 99 },
    },
  ],
};
