import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import MenuDetail from './MenuDetail';

export default createRoute({
  element: <MenuDetail />,
  path: Routes.MenuDetail,
  authenticated: true,
});
