import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { PostMeasurementParams } from 'types/recipes.types';

const postMeasurement = async (options: PostMeasurementParams) => {
  const { data: measurement } = await apiService.postMeasurement(options);
  return measurement;
};

export const useCreateMeasurement = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const notifications = useNotifications();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (options: PostMeasurementParams) => postMeasurement(options),
    onSuccess: () => {
      // Can be improved with optimistic update instead of invalidate
      queryClient.invalidateQueries({ queryKey: QueryKeys.measurements.all() });

      notifications.success({
        message: intl.formatMessage({ id: 'recipes.measurements.added' }),
      });
    },
    onError: (error) => {
      console.error(error);
      notifications.error({
        message: intl.formatMessage({ id: 'common.error_messages.error' }),
      });
    },
  });

  return {
    createMeasurement: mutateAsync,
    isPending,
  };
};
