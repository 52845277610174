import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  inputBaseClasses,
  outlinedInputClasses,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { RelativeTime } from 'utils/datetime.utils';

import { useIsHQ } from 'hooks/useIsHQ';
import { RecipeFormValues } from 'types/recipes.types';

type Props = {
  title: I18nKey;
  open: boolean;
  isLoading: boolean;
  addComment: (comment: string) => void;
  onClose: () => void;
};

const RecipeCommentDialog = ({ title, open, isLoading, addComment, onClose }: Props) => {
  const isHQ = useIsHQ();
  const intl = useIntl();
  const comments = useWatch<RecipeFormValues>({ name: 'comments' }) as RecipeFormValues['comments'];
  const [comment, setComment] = useState('');

  useEffect(() => {
    setComment('');
  }, [open]);

  const submitButton = (
    <LoadingButton
      loading={isLoading}
      variant="contained"
      disabled={!comment.length}
      onClick={() => comment.length && addComment(comment)}
      autoFocus
      sx={{ ml: 'auto' }}
    >
      <FormattedMessage id="recipes.creator.comment.button" />
    </LoadingButton>
  );

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id={title} />
      </DialogTitle>

      <DialogContent>
        {isHQ && (
          <Stack gap={1} mb={1}>
            <TextField
              value={comment}
              multiline
              placeholder={intl.formatMessage({ id: 'recipes.creator.reject.dialog.placeholder' })}
              onChange={(e) => setComment(e.target.value)}
              rows={5}
              inputProps={{ maxLength: 1024 }}
              sx={(theme) => ({
                [`& .${outlinedInputClasses.notchedOutline}`]: {
                  borderColor: theme.palette.black[20],
                },
                [`& .${inputBaseClasses.input}`]: {
                  padding: theme.spacing(1.5),
                  ...theme.typography.body1,
                },
              })}
            />

            {!!comments.length && submitButton}
          </Stack>
        )}

        {!!comments.length && (
          <Stack gap={3} mt={2}>
            {comments.map(
              ({ message, id, createdAt }) =>
                message && (
                  <Stack gap={0.5} key={id}>
                    <Typography
                      variant="body1"
                      sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', lineBreak: 'loose' }}
                    >
                      {message}
                    </Typography>

                    <Typography fontSize="12px" sx={{ color: (theme) => theme.palette.black[60] }}>
                      <RelativeTime>{createdAt}</RelativeTime>
                    </Typography>
                  </Stack>
                ),
            )}
          </Stack>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="inherit" onClick={onClose}>
          <FormattedMessage id="common.close" />
        </Button>
        {!comments.length && submitButton}
      </DialogActions>
    </Dialog>
  );
};

export default RecipeCommentDialog;
