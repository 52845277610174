import { ReactNode } from 'react';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useCognitoUser } from 'queries';

import { LoadingState } from 'components/@states';

interface Props {
  redirectTo?: string;
  enabled?: boolean;
  children: ReactNode;
}

const CheckAuth = ({ children, enabled = true, redirectTo = Routes.Login }: Props) => {
  const { isLoggedIn, isLoading: fetchingCurrentUser } = useCognitoUser();

  const location = useLocation();
  const shouldRedirect = !isLoggedIn && enabled;

  if (fetchingCurrentUser) {
    return <LoadingState />;
  }
  if (shouldRedirect) {
    // should not be happening because of the useEffect hook
    return <Navigate to={redirectTo} state={{ from: location }} replace />;
  }
  return <>{children}</>;
};

export default CheckAuth;
