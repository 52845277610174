import { Accept } from 'react-dropzone';

export const DROPZONE_ACCEPTED_FILETYPES: Accept = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/gif': ['.gif'],
};
export const DROPZONE_MAX_FILESIZE = 5 * 1024 * 1024; // 5MB

export const MAX_PDF_FILESIZE = 20 * 1024 * 1024; // 20MB
