import {
  ArrowForwardRounded as ArrowForwardRoundedIcon,
  LocalDrinkRounded as DrinkIcon,
} from '@mui/icons-material';
import { Box } from '@mui/material';

import { ActionButton } from 'components/@common';

interface Props {
  disabled?: boolean;
  loading?: boolean;
  wide?: boolean;
  onClick: () => void;
}

const PurchasedItemButton = ({ loading, disabled, wide, onClick }: Props) => {
  return (
    <ActionButton
      icon={
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          position="relative"
          height="40px"
        >
          <ArrowForwardRoundedIcon
            sx={{ height: 16, width: 16, position: 'absolute', left: -12 }}
          />
          <DrinkIcon sx={{ height: 36, width: 36, marginLeft: '2px' }} />
        </Box>
      }
      text="menus.matcher.no_results.purchased_item"
      loading={loading}
      disabled={disabled}
      wide={wide}
      onClick={onClick}
    />
  );
};

export default PurchasedItemButton;
