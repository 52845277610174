import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { NOTIFICATION_DURATION } from 'constants/common.constants';
import { NotificationPayload, NotificationTypes } from 'services/snackbar/snackbar.types';

import Notification from './Notification';

export const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();

  const add = useCallback(
    (notification: NotificationPayload, duration: number | null = NOTIFICATION_DURATION) => {
      const key = notification.message;

      return enqueueSnackbar(<Notification {...notification} notificationKey={key} />, {
        key,
        autoHideDuration: duration,
      });
    },
    [enqueueSnackbar],
  );

  const success = useCallback(
    (
      notification: Omit<NotificationPayload, 'type'>,
      duration: number | null = NOTIFICATION_DURATION,
    ) => add({ ...notification, type: NotificationTypes.Success }, duration),
    [add],
  );

  const info = useCallback(
    (
      notification: Omit<NotificationPayload, 'type'>,
      duration: number | null = NOTIFICATION_DURATION,
    ) => add({ ...notification, type: NotificationTypes.Info }, duration),
    [add],
  );

  const error = useCallback(
    (
      notification: Omit<NotificationPayload, 'type'>,
      duration: number | null = NOTIFICATION_DURATION,
    ) => add({ ...notification, type: NotificationTypes.Error }, duration),
    [add],
  );

  const warning = useCallback(
    (
      notification: Omit<NotificationPayload, 'type'>,
      duration: number | null = NOTIFICATION_DURATION,
    ) => add({ ...notification, type: NotificationTypes.Warning }, duration),
    [add],
  );

  return {
    success,
    info,
    error,
    warning,
  };
};
