import { Box, BoxProps, Collapse, CollapseProps, Fade, SxProps } from '@mui/material';
import { ReactNode } from 'react';

type Props = CollapseProps & {
  in: CollapseProps['in'];
  children: ReactNode;
  sx?: SxProps;
  boxProps?: BoxProps;
};

const CollapseFade = ({
  in: isIn,
  children,
  sx = { bgcolor: 'bg.paper', m: 1 },
  boxProps,
  ...collapseProps
}: Props) => (
  <Collapse
    in={isIn}
    exit
    {...collapseProps}
    timeout={200}
    sx={sx}
    easing={{ enter: 'ease-out', exit: 'ease-in' }}
    collapsedSize={0}
  >
    <Fade in={isIn} timeout={200} easing={{ enter: 'ease-out', exit: 'ease-in' }}>
      <Box height="100%" display="flex" flexDirection="column" {...boxProps}>
        {isIn && children}
      </Box>
    </Fade>
  </Collapse>
);

export default CollapseFade;
