'use client';

import {
  ArrowUpwardRounded as ArrowUpwardRoundedIcon,
  Delete as DeleteIcon,
  DeleteForever as ConfirmDeleteIcon,
  StopRounded as StopRoundedIcon,
} from '@mui/icons-material';
import { Collapse, Stack, Typography } from '@mui/material';
import { noop } from 'lodash';
import { FormEvent, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useChatHandler, useEnterSubmit } from 'hooks';
import { ChatbotUIContext } from 'services/ai-chat';

import { MessageForm, MessageInputField } from './Chat.styles';
import ChatTooltipButton from './ChatTooltipButton';
import ExamplePrompts from './ExamplePrompts';

const PromptForm = () => {
  const intl = useIntl();
  const [isConfirmingClear, setIsConfirmingClear] = useState(false);
  const { formRef, onKeyDown } = useEnterSubmit();
  const { chatInputRef, handleSendMessage, handleStopMessage, handleFocusChatInput } =
    useChatHandler();

  const { chatMessages, isGenerating, firstTokenReceived, isLoading, resetSession } =
    useContext(ChatbotUIContext);

  const [currentInput, setCurrentInput] = useState<string>('');

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    setIsConfirmingClear(false);

    e.preventDefault();
    if (!currentInput.trim() || isGenerating) return;
    setCurrentInput('');
    // Blur focus on mobile
    if (window.innerWidth < 600) {
      (e.target as HTMLFormElement)['message']?.blur();
    }
    handleSendMessage(currentInput, chatMessages);
    handleFocusChatInput();
  };

  const handleSetUserInput = (message: string) => {
    setCurrentInput(message);
    handleFocusChatInput();
    setTimeout(() => {
      const firstVariableIndexOpen = message.indexOf('{');
      const firstVariableIndexClose = message.indexOf('}') + 1;
      chatInputRef.current?.setSelectionRange(firstVariableIndexOpen, firstVariableIndexClose);
    });
  };

  const handleClear = () => {
    // first click will ask to confirm, second click will clear
    if (isConfirmingClear) {
      resetSession();
    } else {
      setIsConfirmingClear(true);
    }
  };

  return (
    <Stack
      width="100%"
      gap={1.5}
      px={{ xs: 1.5, sm: 3 }}
      pb={2}
      alignItems="center"
      bgcolor="bluegrey.800"
      sx={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}
    >
      <MessageForm ref={formRef} onSubmit={handleSubmit}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          width="100%"
          position="relative"
        >
          <MessageInputField
            ref={chatInputRef}
            tabIndex={0}
            onKeyDown={onKeyDown}
            placeholder={intl.formatMessage({ id: 'chat.input.placeholder' })}
            autoFocus
            spellCheck={false}
            autoComplete="off"
            autoCorrect="off"
            name="message"
            minRows={1}
            maxRows={8}
            value={currentInput}
            onChange={
              isLoading
                ? undefined
                : (e) => {
                    setIsConfirmingClear(false);
                    setCurrentInput(e.target.value);
                  }
            }
          />
          <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={1}>
            <Collapse in={isGenerating && firstTokenReceived} orientation="horizontal">
              <ChatTooltipButton
                tooltip={<FormattedMessage id="chat.message.stop" />}
                icon={<StopRoundedIcon />}
                onClick={() => handleStopMessage()}
              />
            </Collapse>
            <ChatTooltipButton
              type="submit"
              loading={isGenerating}
              disabled={!currentInput.trim() || isGenerating}
              tooltip={<FormattedMessage id="chat.message.send" />}
              icon={!isGenerating && <ArrowUpwardRoundedIcon />}
            />
            <Collapse in={!!chatMessages.length} orientation="horizontal">
              <ChatTooltipButton
                tooltip={
                  <FormattedMessage
                    id={isConfirmingClear ? 'chat.message.clear.confirm' : 'chat.message.clear'}
                  />
                }
                icon={isConfirmingClear ? <ConfirmDeleteIcon /> : <DeleteIcon />}
                onClick={handleClear}
              />
            </Collapse>
          </Stack>
        </Stack>
        <ExamplePrompts onSetUserInput={isLoading ? noop : handleSetUserInput} />
      </MessageForm>
      <Typography
        variant="chip"
        sx={{ color: (theme) => theme.palette.text.secondary }}
        width="100%"
        align="center"
      >
        <FormattedMessage id="chat.ai.disclaimer" />
      </Typography>
    </Stack>
  );
};

export default PromptForm;
