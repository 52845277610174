import { SignInOutput } from 'aws-amplify/auth';

export type CognitoUserAttributes = {
  email: string;
  given_name?: string;
  family_name?: string;
  phone_number?: string;
  email_verified: boolean;
  locale?: string;
  sub: string;
};

export type CognitoUser = {
  username: string;
  userDataKey: string;
  attributes: CognitoUserAttributes;
  client: {
    endpoint: string;
  };
  challengeName?: string;
  cognitoGroups: string[];
  keyPrefix: string[];
  signInUserSession: {
    accessToken: {
      jwtToken: string;
      payload: {
        auth_time: number;
        client_id: string;
        device_key: string;
        exp: number;
        iat: number;
        iss: string;
        jti: string;
        scope: string;
        sub: string;
        token_use: string;
        username: string;
      };
    };
  };
};

export type CognitoUserParams = {
  username: string;
  password: string;
  attributes: CognitoAttributeParams;
};

export type CognitoAttributeParams = Partial<{
  name: string;
  email: string;
  given_name: string;
  family_name: string;
  phone_number: string;
}>;

export enum CognitoErrors {
  UserNotFoundException = 'UserNotFoundException',
  NotAuthorizedException = 'NotAuthorizedException',
  ResourceConflictException = 'ResourceConflictException',
  CodeMismatchException = 'CodeMismatchException',
  InvalidParameterException = 'InvalidParameterException',
  EnableSoftwareTokenMFAException = 'EnableSoftwareTokenMFAException',
  ExpiredCodeException = 'ExpiredCodeException',
}

export type CognitoError = {
  message: string;
  name: keyof typeof CognitoErrors;
};

export enum LoginState {
  Login = 'Login',
  CompleteSignup = 'CompleteSignup',
  MFAStetup = 'MFASetup',
  MFA = 'MFA',
  Success = 'Success',
  ForgotPassword = 'ForgotPassword',
  ResetPassword = 'ResetPassword',
}

export type CompleteSignupFormValues = {
  givenName: string;
  familyName: string;
  newPassword: string;
  newPasswordRepeat: string;
};

export type LoginFormValues = {
  email: string;
  password: string;
  keepSignedIn?: boolean;
};
export type ResetPasswordFormValues = {
  email: string;
};

export const COGNITO_CHALLENGE_STATE_MAP: Partial<
  Record<SignInOutput['nextStep']['signInStep'], LoginState>
> = {
  CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED: LoginState.CompleteSignup,
  CONTINUE_SIGN_IN_WITH_TOTP_SETUP: LoginState.MFAStetup,
  CONFIRM_SIGN_IN_WITH_TOTP_CODE: LoginState.MFA,
};

export type MFASetupValues = {
  code: string;
};

export type ResetPasswordParams = {
  email: string;
  newPassword: string;
  confirmationCode: string;
};
