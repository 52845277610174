import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { useCheckForDuplicateRecipeName } from 'queries/recipes/hooks';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';

const submitRecipe = (recipeId: number) => {
  return apiService.submitRecipe(recipeId);
};

export const useSubmitRecipe = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const notifications = useNotifications();
  const checkError = useCheckForDuplicateRecipeName();

  const { mutate, isPending } = useMutation({
    mutationFn: submitRecipe,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.recipes.all() });
      notifications.success({
        message: intl.formatMessage({ id: 'recipes.creator.submit_for_approval.success' }),
      });
    },
    onError: (error) => {
      const isDuplicate = checkError(error);

      if (!isDuplicate) {
        notifications.error({
          message: intl.formatMessage({ id: 'recipes.creator.submit_for_approval.error' }),
        });
      }
    },
  });

  return {
    submitRecipe: mutate,
    isPending,
  };
};
