import { Button, Checkbox, Chip, chipClasses, Stack, Typography } from '@mui/material';
import { memo, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { useMedia } from 'hooks';
import { MenuItemFilterOptions } from 'types/menus.types';

import { CoverageValues } from 'components/@menu-form/MenuForm/types';

type Props = {
  coverageValues: CoverageValues;
  menuItemFilters: { [key in MenuItemFilterOptions]: boolean };
  onToggleFilter: (filter: MenuItemFilterOptions) => void;
};

const MenuItemFilters = ({ coverageValues, menuItemFilters, onToggleFilter }: Props) => {
  const { matchedAmount, unmatchedAmount, purchasedAmount } = coverageValues;
  const { sm } = useMedia();

  return (
    <Stack direction={sm ? 'column' : 'row'}>
      <MenuItemFilter
        amount={matchedAmount}
        checked={menuItemFilters.matched}
        onChange={() => onToggleFilter(MenuItemFilterOptions.Matched)}
        amountColor="green.500"
        label={<FormattedMessage id="menus.items.matched" />}
      />
      <MenuItemFilter
        amount={unmatchedAmount}
        checked={menuItemFilters.unmatched}
        onChange={() => onToggleFilter(MenuItemFilterOptions.Unmatched)}
        amountColor="warning.main"
        label={<FormattedMessage id="menus.items.unmatched" />}
      />
      <MenuItemFilter
        amount={purchasedAmount}
        checked={menuItemFilters.purchased}
        onChange={() => onToggleFilter(MenuItemFilterOptions.Purchased)}
        amountColor="bluegrey.500"
        label={<FormattedMessage id="menus.items.purchased.single" />}
      />
    </Stack>
  );
};

export default memo(MenuItemFilters);

type MenuItemFilterProps = {
  label: ReactNode;
  amount: number;
  amountColor: string;
  checked: boolean;
  onChange: () => void;
};
const MenuItemFilter = ({ label, amount, amountColor, checked, onChange }: MenuItemFilterProps) => {
  const { sm, md } = useMedia();

  return (
    <Button
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'fit-content',
        height: '100%',
        py: 2,
        minWidth: sm || md ? 'fit-content' : 192,
        px: sm ? 2 : md ? 1 : 2,
        gap: sm ? 2 : md ? 1 : 3.25,
        borderRight: sm ? undefined : '2px solid',
        borderRightColor: 'black.10',
        color: 'black.90',
        borderRadius: 0,
        backgroundColor: checked ? `${theme.palette.primary.light} !important` : undefined,
        '&:hover': { backgroundColor: 'primary.lighter' },
      })}
      onClick={onChange}
    >
      <Stack direction="row" alignItems="center">
        <Checkbox
          disableRipple
          size="small"
          checked={checked}
          color="default"
          sx={(theme) => ({ color: `${theme.palette.black[60]} !important` })}
        />
        <Typography variant="tableHeader" whiteSpace="nowrap">
          {label}
        </Typography>
      </Stack>
      <Chip
        label={amount}
        sx={(theme) => ({
          ...theme.typography.caption,
          fontWeight: 500,
          bgcolor: amountColor,
          height: 20,
          [`& .${chipClasses.label}`]: {
            px: 1,
            color: 'text.secondary',
          },
        })}
      />
    </Button>
  );
};
