import {
  Chip,
  chipClasses,
  styled,
  svgIconClasses,
  TableSortLabel,
  Typography,
} from '@mui/material';

import { useGetOnBrandnessColor, useGetRecipeStatusColor } from 'utils/recipe.utils';

import { RecipeStatus } from 'types/recipes.types';

export const SortingHeader = styled(TableSortLabel)(({ theme }) => ({
  height: '100%',
  width: '100%',
  color: theme.palette.text.primary,
  '&:hover, &:focus': { color: theme.palette.text.primary },
  [`& .${svgIconClasses.root}`]: {
    width: 18,
    color: theme.palette.black[60],
    marginLeft: theme.spacing(0.5),
  },
}));

export const OnBrandnessBar = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'score',
})<{ score: number }>(({ score, theme }) => {
  const scorePercentage = Math.round(score * 100);
  const getOnBrandnessColor = useGetOnBrandnessColor();
  return {
    width: '100%',
    height: theme.spacing(4),
    borderRadius: theme.mixins.borderRadius.full,
    background: `linear-gradient(90deg, ${getOnBrandnessColor(score)} ${scorePercentage}%, ${theme.palette.grey[100]} ${scorePercentage}%)`,
    position: 'relative',
    '&:before': {
      content: `'${scorePercentage}%'`,
      position: 'absolute',
      left: theme.spacing(1.5),
      top: '50%',
      transform: 'translateY(-50%)',
      ...theme.typography.buttonSmall,
      textTransform: 'none',
      fontWeight: 400,
    },
  };
});

export const StatusChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'status',
})<{ status: RecipeStatus }>(({ status, theme }) => {
  const getRecipeStatusColor = useGetRecipeStatusColor();
  const { bgColor, textColor } = getRecipeStatusColor(status);
  return {
    borderRadius: theme.mixins.borderRadius.full,
    backgroundColor: bgColor,
    color: textColor,
    border: `none`,
    ...theme.typography.buttonSmall,
    [`& .${chipClasses.label}`]: {
      paddingInline: theme.spacing(2.5),
    },
  };
});
