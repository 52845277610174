import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useIsHQ } from 'hooks/useIsHQ';
import { useMenuById, useUser } from 'queries';

import { MenuForm, MenuSkeleton } from 'components/@menu-form';

const MenuDetail = () => {
  const intl = useIntl();
  const { menuId } = useParams();
  const navigate = useNavigate();
  const {
    isLoading,
    data: menu,
    isError,
  } = useMenuById(Number(menuId), { refetchOnMount: 'always' });
  const isHQ = useIsHQ();
  const { user } = useUser();

  const disabled = !isHQ && menu?.country !== user?.group.country;

  useEffect(() => {
    if (isError) {
      navigate(Routes.MenuOverview);
    }
  }, [isError, navigate]);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'alain_ai.label' })} -{' '}
          {intl.formatMessage({ id: 'menus.overview.label' })}
        </title>
      </Helmet>
      {isLoading && <MenuSkeleton />}
      {!isLoading && !!menu && <MenuForm menu={menu} disabled={disabled} />}
    </>
  );
};

export default MenuDetail;
