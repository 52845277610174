import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import ExperimentalKitchen from './ExperimentalKitchen';

export default createRoute({
  element: <ExperimentalKitchen />,
  path: Routes.ExperimentalKitchen,
  authenticated: true,
});
