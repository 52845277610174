import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { seasonsMap } from 'constants/common.constants';
import { Season } from 'types/common.types';
import { RecipeFormValues } from 'types/recipes.types';

import { ChipSelect } from 'components/@form';

type Props = {
  isReadOnly: boolean;
};

const RecipeSeasons = ({ isReadOnly }: Props) => {
  const { control } = useFormContext<RecipeFormValues>();
  const intl = useIntl();

  return (
    <Controller
      control={control}
      name="seasons"
      render={({ field: { onChange, value } }) => (
        <ChipSelect
          testId="recipe-seasons"
          label={<FormattedMessage id="general.seasons.label" />}
          readOnly={isReadOnly}
          options={Object.values(Season).map((value) => ({
            value: value,
            label: intl.formatMessage({ id: seasonsMap[value] || value }),
          }))}
          selected={value ?? ([] as Season[])}
          keyMap={seasonsMap}
          onChange={onChange}
        />
      )}
    />
  );
};

export default RecipeSeasons;
