import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import RecipeDetail from './RecipeDetail';

export default createRoute({
  element: <RecipeDetail />,
  path: Routes.RecipeDetail,
  authenticated: true,
});
