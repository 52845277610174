import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';

const publishRecipe = (recipeId: number) => {
  return apiService.publishRecipe(recipeId);
};

export const usePublishRecipe = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const notifications = useNotifications();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ recipeId }: { silent?: boolean; recipeId: number }) => publishRecipe(recipeId),
    onSuccess: (_, { silent }) => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.recipes.all() });

      if (!silent) {
        notifications.success({
          message: intl.formatMessage({ id: 'recipes.creator.published.success' }),
        });
      }
    },
    onError: () => {
      notifications.error({ message: intl.formatMessage({ id: 'error.general.text' }) });
    },
  });

  return {
    publishRecipe: mutate,
    isPending,
  };
};
