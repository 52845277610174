import AddIcon from '@mui/icons-material/Add';
import { Chip, Collapse, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TransitionGroup } from 'react-transition-group';

import { recipeCategoriesMap } from 'constants/recipes.constants';
import { useTableFilters } from 'hooks/useTableFilters';
import { ActiveFilters, FilterOptions, FiltersObject, Season } from 'types/common.types';
import {
  BaseRecipeFilters,
  OptionalRecipeFilters,
  RecipeFilters,
  RecipeRequirement,
  RecipeStatus,
  RecipeStatusFilterOptions,
  RecipeType,
} from 'types/recipes.types';

import Popper from 'components/@common/Popper';
import { FilterSelect } from 'components/@form';
import { useCountryFilterOptions } from 'components/@form/FilterSelect/hooks';

type Props = {
  name: string;
  filters: ActiveFilters<RecipeFilters>;
  statusFilter: RecipeStatusFilterOptions;
  enableCountryFilter?: boolean;
  setFilters: (newFilters: ActiveFilters<RecipeFilters>) => void;
};

const RecipeFiltersList = ({
  name,
  filters,
  setFilters,
  statusFilter,
  enableCountryFilter,
}: Props) => {
  const countryFilterOptions = useCountryFilterOptions({ withAllCountries: true });
  const [selectedOptionalFilters, setSelectedOptionalFilters] = useState<RecipeFilters[]>([]);

  useEffect(() => {
    if (
      filters.status.length > 0 &&
      !selectedOptionalFilters.includes(OptionalRecipeFilters.status)
    ) {
      setSelectedOptionalFilters([...selectedOptionalFilters, OptionalRecipeFilters.status]);
    }
  }, [selectedOptionalFilters, filters]);

  const {
    activeFilters,
    activeOptionalFilters,
    removeOptionalFilter,
    toggleFilter,
    toggleOptionalFilter,
  } = useTableFilters({
    name,
    filters,
    setFilters,
    selectedOptionalFilters,
    setSelectedOptionalFilters,
    save: true,
  });

  const baseFilters: FiltersObject<BaseRecipeFilters> = {
    [BaseRecipeFilters.requirement]: {
      label: <FormattedMessage id="recipes.props.requirement" />,
      amountSelectedLabel: 'recipes.requirements.amount_selected',
      options: {
        [RecipeRequirement.Core]: <FormattedMessage id="recipes.requirements.core" />,
        [RecipeRequirement.Required]: <FormattedMessage id="recipes.requirements.required" />,
        [RecipeRequirement.Local]: <FormattedMessage id="recipes.requirements.local" />,
      },
    },
    [BaseRecipeFilters.category]: {
      label: <FormattedMessage id="recipes.props.category" />,
      amountSelectedLabel: 'recipes.categories.amount_selected',
      options: Object.entries(recipeCategoriesMap).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: <FormattedMessage id={value} /> }),
        {},
      ),
    },
    [BaseRecipeFilters.type]: {
      label: <FormattedMessage id="recipes.props.type" />,
      amountSelectedLabel: 'recipes.types.amount_selected',
      options: {
        [RecipeType.Recipe]: <FormattedMessage id="recipes.types.recipe" />,
        [RecipeType.MiseEnPlace]: <FormattedMessage id="recipes.types.mep" />,
        [RecipeType.Formula]: <FormattedMessage id="recipes.types.formula" />,
        [RecipeType.PurchasedItem]: <FormattedMessage id="recipes.types.purchased_item" />,
      },
    },
  };

  let optionalFilters: FiltersObject<OptionalRecipeFilters> = {
    [OptionalRecipeFilters.season]: {
      label: <FormattedMessage id="general.seasons.label" />,
      amountSelectedLabel: 'general.seasons.amount_selected',
      options: {
        [Season.Winter]: <FormattedMessage id="general.seasons.winter" />,
        [Season.Spring]: <FormattedMessage id="general.seasons.spring" />,
        [Season.Summer]: <FormattedMessage id="general.seasons.summer" />,
        [Season.Autumn]: <FormattedMessage id="general.seasons.autumn" />,
      },
    },
  };

  if (enableCountryFilter) {
    optionalFilters = {
      [OptionalRecipeFilters.country]: {
        label: <FormattedMessage id="general.country.label" />,
        amountSelectedLabel: 'countries.amount_selected',
        options: countryFilterOptions,
      },
      ...optionalFilters,
    };
  }

  if (statusFilter !== RecipeStatusFilterOptions.Published) {
    let statusFilterOptions: FilterOptions = {
      [RecipeStatus.Draft]: <FormattedMessage id="recipes.status.draft" />,
      [RecipeStatus.Submitted]: <FormattedMessage id="recipes.status.submitted" />,
      [RecipeStatus.Rejected]: <FormattedMessage id="recipes.status.rejected" />,
      [RecipeStatus.Archived]: <FormattedMessage id="recipes.status.archived" />,
      [RecipeStatus.Approved]: <FormattedMessage id="recipes.status.approved" />,
    };
    if (statusFilter === RecipeStatusFilterOptions.All) {
      statusFilterOptions = {
        ...statusFilterOptions,
        [RecipeStatus.Published]: <FormattedMessage id="recipes.status.published" />,
      };
    }

    optionalFilters = {
      ...optionalFilters,
      [OptionalRecipeFilters.status]: {
        label: <FormattedMessage id="general.status.label" />,
        amountSelectedLabel: 'general.status.amount_selected',
        options: statusFilterOptions,
      },
    };
  }

  return (
    <TransitionGroup style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
      {Object.keys(baseFilters).map((filterKey) => {
        const filter = baseFilters[filterKey as BaseRecipeFilters];
        return (
          <FilterSelect
            activeFilters={activeFilters}
            filter={filterKey as BaseRecipeFilters}
            key={filterKey}
            label={filter?.label}
            options={filter?.options || {}}
            amountSelectedKey={filter?.amountSelectedLabel}
            updateFilters={toggleFilter}
          />
        );
      })}

      {activeOptionalFilters?.map((filter) => (
        <Collapse key={filter} in orientation="horizontal">
          <FilterSelect
            activeFilters={activeFilters}
            filter={filter as OptionalRecipeFilters}
            label={optionalFilters[filter as OptionalRecipeFilters]?.label}
            options={optionalFilters[filter as OptionalRecipeFilters]?.options || {}}
            amountSelectedKey={
              optionalFilters[filter as OptionalRecipeFilters]?.amountSelectedLabel
            }
            updateFilters={toggleFilter}
            removable
            onRemove={() => removeOptionalFilter(filter)}
          />
        </Collapse>
      ))}

      <Popper
        closeOnClick
        trigger={
          <Chip
            icon={<AddIcon sx={{ width: 18 }} />}
            label={<FormattedMessage id="common.filters.add" />}
            variant="filled"
          />
        }
      >
        {Object.keys(optionalFilters).map((filter) => {
          const recipeFilter = filter as OptionalRecipeFilters;

          return (
            <MenuItem
              key={filter}
              selected={activeOptionalFilters?.includes(recipeFilter)}
              onClick={() => toggleOptionalFilter(OptionalRecipeFilters[recipeFilter])}
            >
              {optionalFilters[recipeFilter]?.label}
            </MenuItem>
          );
        })}
      </Popper>
    </TransitionGroup>
  );
};

export default RecipeFiltersList;
