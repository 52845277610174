export enum NotificationTypes {
  Info = 'Info',
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
}

export type NotificationPayload = {
  type: NotificationTypes;
  message: string;
  cta?: string;
  onClick?: () => void;
};
