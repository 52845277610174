import { LocalDrinkRounded as DrinkIcon } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { PickRecipeButton, UnlinkButton } from 'components/@menu-form/MenuItemDrawer/ActionButtons';

interface Props {
  setIsPickingRecipe: (value: boolean) => void;
  handleUnlink: () => void;
}

const MenuItemPurchased = ({ setIsPickingRecipe, handleUnlink }: Props) => {
  return (
    <>
      <DrinkIcon sx={{ height: 56, width: 56, color: 'bluegrey.200' }} />
      <Typography variant="body1" mt={3} mb={8}>
        <FormattedMessage id="menus.matcher.purchased_item.title" />
      </Typography>

      <Stack direction="row" gap={2}>
        <PickRecipeButton variant onClick={() => setIsPickingRecipe(true)} />
        <UnlinkButton onClick={handleUnlink} />
      </Stack>
    </>
  );
};

export default MenuItemPurchased;
