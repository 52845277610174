import { Warning as WarningIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import message from 'react-intl/src/components/message';

interface Props {
  title: I18nKey;
  open: boolean;
  validationErrors: string[];
  onClose: () => void;
}

const ValidationDialog = ({ title, validationErrors, open, onClose }: Props) => {
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle gap={2} display="flex">
        <WarningIcon color="warning" />
        <FormattedMessage id={title} />
      </DialogTitle>

      {message && (
        <DialogContent>
          <DialogContentText sx={{ color: 'text.primary' }}>
            <List sx={{ listStyleType: 'disc', color: 'grey.500', pt: 0 }}>
              {validationErrors.map((error) => (
                <ListItem
                  key={error}
                  sx={{
                    display: 'list-item',
                    listStylePosition: 'inside',
                    paddingBottom: 0,
                  }}
                >
                  <Typography variant="body2" component="span">
                    {error}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onClose}>
          <FormattedMessage id="common.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValidationDialog;
