import { Stack } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { ActionButton } from './Chat.styles';

type Props = {
  onSetUserInput: (message: string) => void;
};

const ExamplePrompts = ({ onSetUserInput }: Props) => {
  const intl = useIntl();

  return (
    <Stack direction="row" alignItems="flex-start" justifyContent="space-between" mt={1}>
      <Stack
        direction="row"
        gap={1}
        p={0.5}
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: { flexWrap: 'wrap', gap: 0.5 },
        })}
      >
        <ActionButton
          onClick={() =>
            onSetUserInput(intl.formatMessage({ id: 'chat.actions.create_recipe.message' }))
          }
        >
          <FormattedMessage id="chat.actions.create_recipe.title" />
        </ActionButton>
        <ActionButton
          onClick={() =>
            onSetUserInput(intl.formatMessage({ id: 'chat.actions.modify_recipe.message' }))
          }
        >
          <FormattedMessage id="chat.actions.modify_recipe.title" />
        </ActionButton>
        <ActionButton
          onClick={() =>
            onSetUserInput(intl.formatMessage({ id: 'chat.actions.search_recipe.message' }))
          }
        >
          <FormattedMessage id="chat.actions.search_recipe.title" />
        </ActionButton>
      </Stack>
    </Stack>
  );
};

export default ExamplePrompts;
