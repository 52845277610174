import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { recipeTypesMap } from 'constants/recipes.constants';
import { RecipeFormValues, RecipeType as RecipeTypeEnum } from 'types/recipes.types';

import { ChipSelect } from 'components/@form';

type Props = {
  isReadOnly: boolean;
};

const RecipeType = ({ isReadOnly }: Props) => {
  const { control } = useFormContext<RecipeFormValues>();
  const intl = useIntl();

  return (
    <Controller
      control={control}
      name="type"
      render={({ field: { onChange, value } }) => (
        <ChipSelect
          testId="recipe-type"
          label={<FormattedMessage id="recipes.props.type" />}
          readOnly={isReadOnly}
          options={Object.values(RecipeTypeEnum).map((value) => ({
            value,
            label: intl.formatMessage({ id: recipeTypesMap[value] || value }),
          }))}
          selected={value}
          onChange={onChange}
        />
      )}
    />
  );
};

export default RecipeType;
