import { useMemo } from 'react';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { FilterParams } from 'types/common.types';

interface SearchFilterState {
  filters: FilterParams;
  setFilters: (newFilters: FilterParams) => void;
}

export const useSearchFilterStore = (name: string) => {
  return useMemo(
    () =>
      create<SearchFilterState>()(
        devtools(
          persist(
            (set) => ({
              filters: {},
              setFilters: (newFilters) => set({ filters: newFilters }),
            }),
            {
              name,
              storage: createJSONStorage(() => sessionStorage),
            },
          ),
        ),
      ),
    [name],
  );
};
