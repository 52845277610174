import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { useLocale } from 'store/useLocale';

import { intl } from './config';

interface Props {
  children: ReactNode;
}

const IntlProvider = ({ children }: Props) => {
  const locale = useLocale((state) => state.locale);

  return (
    <ReactIntlProvider
      key={locale}
      locale={locale}
      messages={intl[locale].messages}
      onError={(error) => Sentry.captureEvent(error)}
    >
      {children}
    </ReactIntlProvider>
  );
};

export default IntlProvider;
