import { Box, BoxProps } from '@mui/material';

import { FALLBACK_IMAGE } from 'constants/media.constants';

interface Props extends BoxProps {
  src: string | null;
  alt?: string;
  height?: BoxProps['height'];
  width?: BoxProps['width'];
  eager?: boolean;
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
}

const Image = ({
  src,
  alt,
  width = '100%',
  height = '100%',
  eager,
  objectFit = 'contain',
  ...props
}: Props) => {
  return (
    <Box
      component="img"
      src={!!src && src !== 'string' ? src : FALLBACK_IMAGE}
      alt={alt || ''}
      loading={eager ? 'eager' : 'lazy'}
      style={{ transition: 'all 0.3s ease-out', objectFit }}
      height={height}
      width={width}
      overflow="hidden"
      borderRadius={(theme) => theme.mixins.borderRadius.sm}
      {...props}
    />
  );
};

export default Image;
