import { useQuery } from '@tanstack/react-query';

import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { CommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { ApiResponse } from 'services/api/api.types';
import { GetMenuItemRecommendedRecipeParams } from 'types/menus.types';
import { Recipe } from 'types/recipes.types';

const fetchRecommendedRecipes = async (params: GetMenuItemRecommendedRecipeParams) => {
  const { data: recipes } = await apiService.getRecommendedRecipes(params);
  return recipes;
};

export const useRecommendedRecipes = (
  { menuId, menuItemId }: GetMenuItemRecommendedRecipeParams,
  config: CommonQueryOptions<
    ApiResponse<Recipe[]>,
    ReturnType<typeof QueryKeys.menus.recommendedRecipes>
  > = {},
) => {
  const { data, status, isFetching, error } = useQuery({
    queryKey: QueryKeys.menus.recommendedRecipes(menuId, menuItemId),
    queryFn: () => fetchRecommendedRecipes({ menuId, menuItemId }),
    ...config,
    staleTime: config?.staleTime ?? Duration.FIVE_MIN,
  });

  return {
    data: data?.data || [],
    status,
    isFetching,
    error,
  };
};
