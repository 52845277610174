import { ComponentProps } from 'react';
import { FormattedRelativeTime } from 'react-intl';

export const RelativeTime = ({ children }: { children: string | Date }) => {
  try {
    const now = new Date();
    const commentDateObj = new Date(children);
    const diff = commentDateObj.getTime() - now.getTime();
    let timeSince = diff / 1000;

    const isNegative = timeSince < 0;
    timeSince = Math.abs(timeSince);

    let unit: ComponentProps<typeof FormattedRelativeTime>['unit'] = 'second';
    if (timeSince < 60) {
      unit = 'second';
    } else if (timeSince < 3600) {
      timeSince = timeSince / 60;
      unit = 'minute';
    } else if (timeSince < 86400) {
      timeSince = timeSince / 3600;
      unit = 'hour';
    } else {
      timeSince = timeSince / 86400;
      unit = 'day';
    }

    return (
      <FormattedRelativeTime
        value={Math.floor(isNegative ? -timeSince : timeSince)}
        style="long"
        unit={unit}
      />
    );
  } catch (error) {
    return '';
  }
};
