import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';

import { FallbackProps } from './boundary.types';

// Since nothing is loaded at this point the fallback can only return basic HTML
// Do not attempt to translate this error or add Mui styles, they are not loaded yet!
const Fallback = ({ error, componentStack }: FallbackProps) => {
  const message = error?.message;
  return (
    <div style={{ padding: 16, fontFamily: 'sans-serif' }}>
      <h1>Sorry, something went wrong</h1>
      <h2>
        Please send a screenshot of this error and what you were doing to support or the LPQ HQ
      </h2>
      {message && <p style={{ marginTop: 32, marginLeft: 32 }}>{message}</p>}
      <pre>{componentStack}</pre>
    </div>
  );
};

interface Props {
  children: ReactNode;
}

// This is the everything-else-failed fallback and is normally never shown to the user
// If there is an issue with the connectors then this will make sure we see it in sentry
const RootBoundary = ({ children }: Props) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => scope.setTag('boundary', 'root')}
    fallback={(props) => <Fallback {...props} />}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default RootBoundary;
