import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import config from 'config';

interface BearState {
  locale: string;
  setLocale: (newLocale: string) => void;
}

export const useLocale = create<BearState>()(
  devtools(
    persist(
      (set) => ({
        locale: config.DEFAULT_LOCALE,
        setLocale: (newLocale) => set(() => ({ locale: newLocale })),
      }),
      {
        name: 'bear-storage',
      },
    ),
  ),
);
