import {
  FoodBank as FoodbankIcon,
  Help as HelpIcon,
  Logout as LogoutIcon,
  MenuBook as MenuBookIcon,
  SvgIconComponent,
  Timeline as TimelineIcon,
} from '@mui/icons-material';
import {
  Box,
  Drawer,
  drawerClasses,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useIsHQ } from 'hooks/useIsHQ';
import { useDeleteLogin } from 'queries';

import HelpDialog from 'components/@navigations/Sidebar/HelpDialog';
import { LoadingState } from 'components/@states';

import UserCountry from './UserCountry';

const Sidebar = () => {
  const { signOut, isPending } = useDeleteLogin();
  const navigate = useNavigate();
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
  const isHQ = useIsHQ();

  const navItems = useMemo<
    {
      icon: SvgIconComponent;
      title: I18nKey;
      route: Routes;
    }[]
  >(
    () => [
      { icon: FoodbankIcon, title: 'recipes.label', route: Routes.Root },
      { icon: MenuBookIcon, title: 'menus.overview.label', route: Routes.MenuOverview },
      ...(isHQ
        ? [{ icon: TimelineIcon, title: 'insights.title' as I18nKey, route: Routes.Insights }]
        : []),
    ],
    [isHQ],
  );

  const handleLogout = () => {
    signOut(undefined, {
      onSuccess: () => {
        navigate(Routes.Login, { replace: true });
      },
    });
  };

  if (isPending) {
    return <LoadingState />;
  }

  return (
    <Drawer
      sx={(theme) => ({
        flex: 0,
        width: theme.mixins.sidebarWidth,
        [`& .${drawerClasses.paper}`]: {
          width: theme.mixins.sidebarWidth,
          boxSizing: 'border-box',
        },
      })}
      variant="permanent"
      anchor="left"
      className="no-print"
    >
      <Box
        bgcolor="global01.main"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        overflow="hidden"
      >
        <List disablePadding>
          {navItems.map(({ title, icon, route }) => (
            <NavLink to={route} key={title} state={{ resetScroll: true }}>
              {({ isActive }) => <NavItem isActive={isActive} icon={icon} title={title} />}
            </NavLink>
          ))}
        </List>

        <List disablePadding>
          <NavItem icon={HelpIcon} onClick={() => setIsHelpDialogOpen(true)} />
          <HelpDialog isOpen={isHelpDialogOpen} onClose={() => setIsHelpDialogOpen(false)} />

          <UserCountry />

          <NavItem icon={LogoutIcon} onClick={handleLogout} />
        </List>
      </Box>
    </Drawer>
  );
};

type NavItemProps = {
  isActive?: boolean;
  icon: SvgIconComponent;
  title?: I18nKey;
  onClick?: () => void;
};

const NavItem = ({ isActive, icon, title, onClick }: NavItemProps) => {
  const Icon = icon;

  return (
    <ListItem disablePadding onClick={onClick} sx={{ px: 0.5, my: 0.5 }}>
      <ListItemButton
        selected={isActive}
        sx={(theme) => ({
          height: '60px',
          width: '56px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '2px',
          py: 0.25,
          bgcolor: isActive ? 'global01.light' : undefined,
          borderRadius: theme.mixins.borderRadius.sm,
          '&:hover, &.Mui-selected': {
            color: 'white',
          },
        })}
      >
        <Icon sx={{ width: 24, color: isActive ? 'primary.main' : 'text.secondary' }} />
        {title && (
          <Typography
            fontSize="10px"
            lineHeight="14px"
            fontWeight={800}
            sx={{
              color: (theme) =>
                isActive ? theme.palette.primary.main : theme.palette.text.secondary,
            }}
          >
            <FormattedMessage id={title} />
          </Typography>
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default Sidebar;
