import { ArrowDownward, ArrowUpward, SwapVert } from '@mui/icons-material';
import { SortDirection } from '@mui/material';

export const getOrderIcon = ({
  id,
  sortBy,
  sortDirection,
}: {
  sortBy: string;
  id: string;
  sortDirection: SortDirection;
}) => {
  if (sortBy === id) {
    if (sortDirection === 'asc') return <ArrowUpward />;
    else return <ArrowDownward />;
  } else return <SwapVert />;
};
