import {
  SortDirection,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatRelative } from 'date-fns';
import { cloneDeep } from 'lodash';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useCountryName } from 'utils/recipe.utils';
import { getOrderIcon } from 'utils/table.utils';

import { COUNTRIES_ALL } from 'constants/recipes.constants';
import { useTableSort } from 'hooks';
import { useTableFilters } from 'hooks/useTableFilters';
import { ActiveFilters } from 'types/common.types';
import { CountryInsights } from 'types/insights.types';

import { CountryFlag } from 'components/@common';
import { FilterSelect } from 'components/@form';
import { useCountryFilterOptions } from 'components/@form/FilterSelect/hooks';
import ActiveMenuCoverage from 'components/@insights/ActiveMenuCoverage/ActiveMenuCoverage';
import ExperimentalRecipesCell from 'components/@insights/FranchiseePerformance/ExperimentalRecipesCell';
import { getTotalExperimental } from 'components/@insights/utils';
import { SortingHeader } from 'components/@recipe-overview/RecipeTable/RecipeTable.style';

interface Props {
  insights: CountryInsights[];
}

enum COLUMN_KEYS {
  'Country' = 'Country',
  'Recipes' = 'Recipes',
  'CookbookRecipes' = 'CookbookRecipes',
  'ExperimentalRecipes' = 'ExperimentalRecipes',
  'MenuCoverage' = 'MenuCoverage',
  'ActiveMenus' = 'ActiveMenus',
  'LastUpdated' = 'LastUpdated',
}

const COLUMNS: {
  id: COLUMN_KEYS;
  label: I18nKey;
  sortable: boolean;
  initialSort: SortDirection;
}[] = [
  { id: COLUMN_KEYS.Country, label: 'general.country.label', sortable: true, initialSort: 'asc' },
  { id: COLUMN_KEYS.Recipes, label: 'recipes.label', sortable: true, initialSort: 'desc' },
  {
    id: COLUMN_KEYS.CookbookRecipes,
    label: 'insights.franchisee_performance.cookbook_recipes',
    sortable: true,
    initialSort: 'desc',
  },
  {
    id: COLUMN_KEYS.ExperimentalRecipes,
    label: 'insights.franchisee_performance.experimental_recipes',
    sortable: true,
    initialSort: 'desc',
  },
  {
    id: COLUMN_KEYS.MenuCoverage,
    label: 'insights.franchisee_performance.coverage',
    sortable: true,
    initialSort: 'desc',
  },
  {
    id: COLUMN_KEYS.ActiveMenus,
    label: 'menus.active.label.plural',
    sortable: true,
    initialSort: 'desc',
  },
  {
    id: COLUMN_KEYS.LastUpdated,
    label: 'insights.franchisee_performance.last_updated',
    sortable: true,
    initialSort: 'desc',
  },
];

const FranchiseePerformance = ({ insights }: Props) => {
  const getCountryName = useCountryName();
  const countryFilterOptions = useCountryFilterOptions({
    withAllCountries: true,
    allCountryKey: 'insights.franchisee_performance.HQ',
  });
  const [countryFilter, setCountryFilter] = useState<ActiveFilters<'country'>>({ country: [] });
  const { activeFilters, toggleFilter } = useTableFilters({
    name: 'insights',
    filters: countryFilter,
    setFilters: setCountryFilter,
    save: false,
  });

  const { sortBy, sortDirection, handleTableSort } = useTableSort({
    initialSortBy: COLUMN_KEYS.LastUpdated,
    initialSortDirection: 'desc',
  });

  const filteredItems = useMemo(() => {
    if (activeFilters.country.length === 0) return insights;
    return insights.filter((item) => activeFilters.country.includes(item.country));
  }, [insights, activeFilters.country]);

  const sortedItems = useMemo(() => {
    const items: CountryInsights[] = cloneDeep(filteredItems);

    switch (sortBy) {
      case COLUMN_KEYS.Country:
        items.sort((a, b) => {
          if (a.country === COUNTRIES_ALL) return -1;
          return getCountryName(a.country).localeCompare(getCountryName(b.country));
        });
        break;
      case COLUMN_KEYS.Recipes:
        items.sort((a, b) => a.recipes.total - b.recipes.total);
        break;
      case COLUMN_KEYS.CookbookRecipes:
        items.sort((a, b) => a.recipes.published - b.recipes.published);
        break;
      case COLUMN_KEYS.ExperimentalRecipes:
        items.sort((a, b) => getTotalExperimental(a.recipes) - getTotalExperimental(b.recipes));
        break;
      case COLUMN_KEYS.MenuCoverage:
        items.sort((a, b) => a.menus.activeCoveragePercentage - b.menus.activeCoveragePercentage);
        break;
      case COLUMN_KEYS.ActiveMenus:
        items.sort((a, b) => a.menus.active - b.menus.active);
        break;
      case COLUMN_KEYS.LastUpdated:
        items.sort((a, b) => {
          if (!a.menus.lastUpdatedAt) return -1;
          if (!b.menus.lastUpdatedAt) return 0;
          return (
            new Date(a.menus.lastUpdatedAt).getTime() - new Date(b.menus.lastUpdatedAt).getTime()
          );
        });
        break;
    }

    if (sortDirection === 'desc') {
      items.reverse();
    }

    return items;
  }, [filteredItems, sortBy, sortDirection, getCountryName]);

  return (
    <Stack gap={3}>
      <Stack direction="row" alignItems="center" gap={1} justifyContent="space-between">
        <Typography variant="h3">
          <FormattedMessage id="insights.franchisee_performance" />
        </Typography>

        <FilterSelect
          activeFilters={activeFilters}
          filter="country"
          label={<FormattedMessage id="general.country.label" />}
          options={countryFilterOptions}
          amountSelectedKey="countries.amount_selected"
          updateFilters={toggleFilter}
        />
      </Stack>

      <TableContainer sx={{ overflowX: 'scroll' }}>
        <Table stickyHeader>
          <TableHead sx={{ [`& .${tableCellClasses.root}`]: { fontSize: 13 } }}>
            <TableRow>
              {COLUMNS.map(({ id, label, sortable, initialSort }) => (
                <TableCell
                  key={id}
                  sx={{
                    [`&:not(:last-child)`]: { borderRight: '1px solid', borderColor: 'divider' },
                  }}
                >
                  {sortable ? (
                    <SortingHeader
                      active={sortBy === id}
                      direction={sortBy === id ? sortDirection || undefined : 'desc'}
                      onClick={() => handleTableSort(id, initialSort)}
                      IconComponent={() => getOrderIcon({ id, sortBy, sortDirection })}
                    >
                      <FormattedMessage id={label} />
                    </SortingHeader>
                  ) : (
                    <FormattedMessage id={label} />
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ bgcolor: 'bg.paper' }}>
            {sortedItems.map((row) => (
              <TableRow key={row.country}>
                <TableCell>
                  {row.country === COUNTRIES_ALL ? (
                    <FormattedMessage id="insights.franchisee_performance.HQ" />
                  ) : (
                    <Stack direction="row" gap={1}>
                      <CountryFlag country={row.country} />
                      {getCountryName(row.country)}
                    </Stack>
                  )}
                </TableCell>
                <TableCell>{row.recipes.total}</TableCell>
                <TableCell>{row.recipes.published}</TableCell>
                <TableCell>
                  <ExperimentalRecipesCell country={row} />
                </TableCell>
                <TableCell>
                  <ActiveMenuCoverage value={row.menus.activeCoveragePercentage} />
                </TableCell>
                <TableCell>{row.menus.active}</TableCell>
                <TableCell>
                  {row.menus.lastUpdatedAt
                    ? formatRelative(row.menus.lastUpdatedAt, new Date())
                    : '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default FranchiseePerformance;
