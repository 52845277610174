import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';

import { RecipeOnBrandnessRuleSet } from 'types/recipes.types';

import OnBrandnessExplanationDialog from './OnBrandnessExplanationDialog';
import RecipeOnBrandness from './RecipeOnBrandness';
import RecipePerformanceAlerts from './RecipePerformanceAlerts';
import RecipePerformanceData from './RecipePerformanceData';

type Props = {
  isCalculating: boolean;
  onBrandnessErrors: RecipeOnBrandnessRuleSet[];
  disableActions: boolean | undefined;
  isReadOnly: boolean;
  onCalculate: () => void;
};

const RecipeFormPerformance = ({
  isCalculating,
  disableActions,
  onBrandnessErrors,
  isReadOnly,
  onCalculate,
}: Props) => {
  const theme = useTheme();
  const [showExplanation, setShowExplanation] = useState(false);

  const isSmallResolution = useMediaQuery(theme.breakpoints.down('md'));

  const PerformanceAlerts = !isReadOnly && (
    <RecipePerformanceAlerts
      onBrandnessErrors={onBrandnessErrors}
      setShowExplanation={setShowExplanation}
    />
  );

  return (
    <>
      <Stack gap={isSmallResolution ? 1 : 2}>
        <Stack
          gap={disableActions ? 0 : 2}
          direction={disableActions ? 'column' : 'row'}
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: { flexDirection: 'column' },
            '@media print': { flexDirection: 'row' },
          })}
        >
          <Stack flex={1} gap={2}>
            <RecipeOnBrandness
              isCalculating={isCalculating}
              disableActions={disableActions}
              isReadOnly={isReadOnly}
              setShowExplanation={setShowExplanation}
              onCalculate={onCalculate}
            />
            {isSmallResolution && PerformanceAlerts}
          </Stack>
          <Box flex={2} sx={(theme) => ({ [theme.breakpoints.down('md')]: { flex: 1 } })}>
            <RecipePerformanceData disableActions={disableActions} />
          </Box>
        </Stack>

        {!isSmallResolution && PerformanceAlerts}
      </Stack>

      <OnBrandnessExplanationDialog
        setShowExplanation={setShowExplanation}
        showExplanation={showExplanation}
      />
    </>
  );
};

export default RecipeFormPerformance;
