import {
  Components,
  dialogActionsClasses,
  dialogContentClasses,
  dialogContentTextClasses,
  dialogTitleClasses,
  Theme,
} from '@mui/material';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [`& .${dialogTitleClasses.root}`]: {
        padding: theme.spacing(3),
        ...theme.typography.h6,
      },
      [`& .${dialogContentClasses.root}`]: {
        paddingInline: theme.spacing(3),
        paddingBlock: 0,
      },
      [`& .${dialogContentTextClasses.root}`]: {
        color: theme.palette.text.primary,
      },
      [`& .${dialogActionsClasses.root}`]: {
        padding: theme.spacing(3),
        gap: theme.spacing(1),
      },
    }),
  },
};
