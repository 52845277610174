import {
  Check as CheckIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  Publish as PublishIcon,
  RemoveCircle as RemoveCircleIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useIsHQ } from 'hooks/useIsHQ';
import {
  useApproveRecipe,
  usePublishRecipe,
  useRejectRecipe,
  useSubmitRecipe,
} from 'queries/recipes';
import { useNotifications } from 'services/snackbar';
import { RecipeFormValues, RecipeStatus } from 'types/recipes.types';

import { ButtonWithMenu } from 'components/@common/ButtonWithMenu';

import RecipeCommentDialog from './RecipeCommentDialog';

interface Props {
  isSavingRecipe: boolean;
  setIsReadOnly: (isReadOnly: boolean) => void;
  handleStatusChange: (options: {
    successCallback?: () => void;
    skipValidation?: boolean;
  }) => Promise<void>;
}

const RecipeApprovalState = ({ isSavingRecipe, setIsReadOnly, handleStatusChange }: Props) => {
  const posthog = usePostHog();
  const isHQ = useIsHQ();
  const { getValues, setValue } = useFormContext<RecipeFormValues>();
  const navigate = useNavigate();
  const intl = useIntl();
  const notifications = useNotifications();
  const status = useWatch<RecipeFormValues>({ name: 'status' }) as RecipeFormValues['status'];
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);

  const { submitRecipe, isPending: isSubmittingRecipe } = useSubmitRecipe();
  const { rejectRecipe, isPending: isRejectingRecipe } = useRejectRecipe();
  const { approveRecipe, isPending: isApprovingRecipe } = useApproveRecipe();
  const { publishRecipe, isPending: isPublishingRecipe } = usePublishRecipe();

  // we remove empty notes, ingredients, and preparation steps before changing status
  const cleanRecipe = () => {
    setValue(
      'notes',
      getValues('notes').filter((note) => !!note.note),
    );
    setValue(
      'ingredients',
      getValues('ingredients').filter((ingredient) => !!ingredient.name),
    );
    setValue(
      'preparationSteps',
      getValues('preparationSteps').filter((step) => step.image || step.description),
    );
  };

  const changeStatus = ({
    nextStatus,
    message = '',
    isUnpublish,
  }: {
    nextStatus: RecipeStatus;
    message?: string;
    isUnpublish?: boolean;
  }) => {
    const recipeId = getValues('id');

    cleanRecipe();

    switch (nextStatus) {
      case RecipeStatus.Draft:
      case RecipeStatus.Rejected:
        return rejectRecipe(
          { recipeId, message },
          {
            onSuccess: () => {
              return handleStatusChange({
                skipValidation: true,
                successCallback: () => {
                  posthog.capture(`recipe_status_change`, {
                    recipe_id: recipeId,
                    name: getValues().name,
                    status: nextStatus,
                  });
                  setValue('status', nextStatus, { shouldDirty: false });
                  setIsReadOnly(false);
                  setIsRejectDialogOpen(false);
                },
              });
            },
          },
        );

      case RecipeStatus.Submitted:
        return handleStatusChange({
          successCallback: () => {
            submitRecipe(recipeId, {
              onSuccess: () => {
                posthog.capture(`recipe_status_change`, {
                  recipe_id: recipeId,
                  name: getValues().name,
                  status: nextStatus,
                });
                setValue('status', nextStatus, { shouldDirty: false });
                setIsReadOnly(true);
              },
              onError: () => setIsReadOnly(false),
            });
          },
        });
      case RecipeStatus.Approved:
        if (isUnpublish) {
          approveRecipe(
            { recipeId, isUnpublish },
            {
              onSuccess: () => {
                posthog.capture(`recipe_status_change`, {
                  recipe_id: recipeId,
                  name: getValues().name,
                  status: nextStatus,
                });
                setValue('status', nextStatus, { shouldDirty: false });
              },
            },
          );
        } else {
          handleStatusChange({
            successCallback: () => {
              approveRecipe(
                { recipeId, isUnpublish },
                {
                  onSuccess: () => {
                    setIsReadOnly(true);
                    posthog.capture(`recipe_status_change`, {
                      recipe_id: recipeId,
                      name: getValues().name,
                      status: nextStatus,
                    });
                    setValue('status', nextStatus, { shouldDirty: false });
                  },
                },
              );
            },
          });
        }

        return;
      case RecipeStatus.Published:
        return handleStatusChange({
          successCallback: () => {
            setIsReadOnly(true);

            publishRecipe(
              { silent: true, recipeId },
              {
                onSuccess: () => {
                  // the user will be redirected back to the experimental kitchen here
                  posthog.capture(`recipe_status_change`, {
                    recipe_id: recipeId,
                    name: getValues().name,
                    status: nextStatus,
                  });
                  setValue('status', nextStatus, { shouldDirty: false });
                  navigate(Routes.ExperimentalKitchen);
                  notifications.success({
                    message: intl.formatMessage({ id: 'recipes.creator.published.success' }),
                    cta: intl.formatMessage({ id: 'recipes.creator.published.success.cta' }),
                    onClick: () => navigate(Routes.Cookbook),
                  });
                },
              },
            );
          },
        });
      case RecipeStatus.Archived:
      // not implemented yet
      default:
        return;
    }
  };

  const isLoadingAny =
    isSavingRecipe ||
    isSubmittingRecipe ||
    isApprovingRecipe ||
    isRejectingRecipe ||
    isPublishingRecipe;

  if (status === RecipeStatus.Archived) return;

  if ([RecipeStatus.Draft, RecipeStatus.Rejected].includes(status)) {
    return (
      <LoadingButton
        data-testid="submit-recipe-button"
        loading={isSubmittingRecipe}
        disabled={isSavingRecipe}
        variant="contained"
        color="info"
        sx={{ px: 3 }}
        onClick={() => changeStatus({ nextStatus: RecipeStatus.Submitted })}
      >
        <PublishIcon fontSize="small" />
        <FormattedMessage
          id={
            status === RecipeStatus.Rejected
              ? 'recipes.creator.button.franchisee.rejected'
              : 'recipes.creator.button.franchisee.draft'
          }
        />
      </LoadingButton>
    );
  }

  const commentDialog = (
    <RecipeCommentDialog
      title="recipes.creator.reject.dialog.title"
      isLoading={isLoadingAny}
      addComment={(message) => changeStatus({ nextStatus: RecipeStatus.Rejected, message })}
      open={isRejectDialogOpen}
      onClose={() => setIsRejectDialogOpen(false)}
    />
  );

  if (status === RecipeStatus.Submitted) {
    if (isHQ) {
      return (
        <>
          <ButtonWithMenu
            buttonText={<FormattedMessage id="recipes.creator.button.hq.submitted" />}
            testId="submitted-recipe-menu-button"
            color="secondary"
            onClick={() => changeStatus({ nextStatus: RecipeStatus.Approved })}
            loading={isLoadingAny}
            startIcon={<CheckIcon />}
            menuItems={[
              {
                label: <FormattedMessage id="recipes.creator.button.hq.approve_publish" />,
                icon: <PlaylistAddCheckIcon />,
                onClick: () => changeStatus({ nextStatus: RecipeStatus.Published }),
                action: 'approve-publish',
              },
              {
                label: <FormattedMessage id="recipes.creator.button.hq.request_changes" />,
                icon: <RemoveCircleIcon />,
                onClick: () => setIsRejectDialogOpen(true),
                action: 'request-changes',
              },
            ]}
          />

          {commentDialog}
        </>
      );
    }
    return (
      <Button variant="contained" disabled data-testid="submitted-recipe-button">
        <FormattedMessage id="recipes.creator.button.franchisee.submitted" />
      </Button>
    );
  }

  if (status === RecipeStatus.Approved) {
    if (isHQ) {
      return (
        <>
          <ButtonWithMenu
            buttonText={<FormattedMessage id="recipes.creator.button.hq.approved" />}
            testId="approved-recipe-menu-button"
            color="secondary"
            onClick={() => changeStatus({ nextStatus: RecipeStatus.Published })}
            loading={isLoadingAny}
            startIcon={<CheckIcon />}
            menuItems={[
              {
                label: <FormattedMessage id="recipes.creator.button.hq.reject" />,
                icon: <RemoveCircleIcon />,
                onClick: () => setIsRejectDialogOpen(true),
                action: 'reject',
              },
            ]}
          />

          {commentDialog}
        </>
      );
    }
    return (
      <Button variant="contained" disabled data-testid="approve-recipe-button">
        <FormattedMessage id="recipes.creator.button.franchisee.approved" />
      </Button>
    );
  }

  if (status === RecipeStatus.Published) {
    if (isHQ) {
      return (
        <ButtonWithMenu
          variant="outlined"
          testId="published-recipe-menu-button"
          buttonText={<FormattedMessage id="recipes.creator.button.franchisee.published" />}
          color="secondary"
          loading={isLoadingAny}
          startIcon={<CheckIcon />}
          menuItems={[
            {
              label: <FormattedMessage id="recipes.creator.button.hq.unpublish" />,
              icon: <VisibilityOffIcon />,
              onClick: () => changeStatus({ nextStatus: RecipeStatus.Approved, isUnpublish: true }),
              action: 'unpublish',
            },
          ]}
        />
      );
    }
    return (
      <Button
        startIcon={<CheckIcon />}
        data-testid="published-recipe-button"
        variant="outlined"
        color="secondary"
        sx={{ cursor: 'default' }}
      >
        <FormattedMessage id="recipes.creator.button.franchisee.published" />
      </Button>
    );
  }
};

export default RecipeApprovalState;
