import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { PostMenuParams } from 'types/menus.types';

const postMenu = async (options: PostMenuParams) => {
  const { data: menu } = await apiService.postMenu(options);
  return menu;
};

export const useCreateMenu = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: postMenu,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.menus.all() });
    },
    onError: (error) => {
      console.error(error);
      notifications.error({ message: intl.formatMessage({ id: 'common.error_messages.error' }) });
    },
  });

  return {
    createMenu: mutateAsync,
    isPending,
  };
};
