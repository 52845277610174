import { chipClasses, Components, Theme } from '@mui/material';

export const MuiChip: Components<Theme>['MuiChip'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body2,
      width: 'fit-content',
      borderRadius: theme.mixins.borderRadius.full,
    }),
  },
  variants: [
    {
      props: { variant: 'filled' },
      style: {
        backgroundColor: 'transparent',
      },
    },
    {
      props: { variant: 'label' },
      style: {
        backgroundColor: 'transparent',
        border: `none`,
        [`& .${chipClasses.label}`]: {
          paddingInline: 0,
        },
      },
    },
    {
      props: { variant: 'labelDark' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.global01.main,
        color: theme.palette.text.secondary,
        border: `none`,
        height: '1.5rem',
        ...theme.typography.buttonSmall,
        [`& .${chipClasses.label}`]: {
          paddingInline: theme.spacing(1),
        },
      }),
    },
    {
      props: { variant: 'soft' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.global01.main + theme.palette.tonalOffset,
        color: theme.palette.text.primary,
        height: '2rem',
        ...theme.typography.chip,
        [`& .${chipClasses.label}`]: {
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1),
        },
        [`& .${chipClasses.deleteIcon}`]: {
          color: theme.palette.text.primary,
        },
      }),
    },
  ],
};
