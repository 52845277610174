'use client';

import { Box, CircularProgress, Stack, StackProps } from '@mui/material';
import { useContext } from 'react';

import { useChatScrollAnchor } from 'hooks';
import { ChatbotUIContext } from 'services/ai-chat';

import ErrorBoundary from 'components/@boundaries/ErrorBoundary';

import { ChatList, PromptForm } from './components';

const Chat = ({ ...props }: StackProps) => {
  const { scrollRef, visibilityRef } = useChatScrollAnchor();
  const { isLoading } = useContext(ChatbotUIContext);

  return (
    <ErrorBoundary boundary="ai-chat">
      <Stack minHeight="100%" maxHeight={100} flex={1} position="relative" {...props}>
        <Stack
          ref={scrollRef}
          bgcolor="bluegrey.800"
          position="relative"
          direction="column-reverse"
          flex={1}
          overflow="auto"
          mb="-1px"
          sx={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
        >
          <ChatList visibilityRef={visibilityRef} />
        </Stack>
        <PromptForm />

        {isLoading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="black.50"
            position="absolute"
            sx={{ inset: 0, cursor: 'not-allowed' }}
          >
            <CircularProgress />
          </Box>
        )}
      </Stack>
    </ErrorBoundary>
  );
};

export default Chat;
