import { NoMeals as NoMealsIcon } from '@mui/icons-material';
import { Stack, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { RecipeTableHeader } from 'types/recipes.types';

type Props = {
  label?: ReactNode;
  subtitle?: ReactNode;
  tableHeaders: RecipeTableHeader[];
};

const NoRecipesRow = ({ tableHeaders, label = <FormattedMessage id="recipes.none" /> }: Props) => {
  return (
    <TableBody>
      <TableRow sx={{ backgroundColor: 'bg.paper' }}>
        <TableCell colSpan={tableHeaders.length}>
          <Stack alignItems="center" justifyContent="center" gap={1.25}>
            <NoMealsIcon
              sx={{ color: 'bluegrey.200', width: 56, height: 56, marginBottom: 1.75 }}
            />
            <Typography align="center">{label}</Typography>
          </Stack>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default NoRecipesRow;
