import { Chip } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { seasonsMap } from 'constants/common.constants';
import { Season } from 'types/common.types';

type Props = {
  seasons: Season[];
};

const RecipeSeasonsChips = ({ seasons }: Props) => {
  const intl = useIntl();
  if (seasons.length === 4) {
    return <Chip variant="label" label={<FormattedMessage id="general.seasons.all" />} />;
  }

  return (
    <Chip
      variant="label"
      label={seasons.map((season) => intl.formatMessage({ id: seasonsMap[season] })).join(', ')}
    />
  );
};

export default RecipeSeasonsChips;
