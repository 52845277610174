import { useMutation } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { SendMessagePayload } from 'types/chat.types';

const sendChatMessage = async (payload: SendMessagePayload) => {
  const { body } = await apiService.sendChatMessage(payload);
  return body as ReadableStream<Uint8Array>;
};

export const useSendChatMessage = () => {
  const intl = useIntl();
  const notifications = useNotifications();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: sendChatMessage,
    onError: () => {
      notifications.error({ message: intl.formatMessage({ id: 'error.general.text' }) });
    },
  });

  return {
    sendChatMessage: mutateAsync,
    isPending,
  };
};
