import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Link, LinkProps } from 'react-router-dom';

interface Props {
  to: LinkProps['to'];
  children?: React.ReactNode;
}

const BackButton = ({ to, children = <FormattedMessage id="overview.label" /> }: Props) => {
  return (
    <Link to={to} className="no-print">
      <Button variant="text" color="inherit">
        <ArrowBackIcon sx={(theme) => ({ width: theme.spacing(2) })} />
        <Typography variant="buttonSmall">{children}</Typography>
      </Button>
    </Link>
  );
};

export default BackButton;
