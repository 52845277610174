import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Country } from 'constants/countries.constants';
import { recipeRequirementsMap } from 'constants/recipes.constants';
import { useIsHQ } from 'hooks/useIsHQ';
import { useUser } from 'queries';
import { RecipeFormValues, RecipeRequirement } from 'types/recipes.types';

import { ConfirmationDialog } from 'components/@common';
import { ChipSelect } from 'components/@form';

type Props = {
  isReadOnly: boolean;
};

const RecipeRequirementType = ({ isReadOnly }: Props) => {
  const isHQ = useIsHQ();
  const { control, setValue } = useFormContext<RecipeFormValues>();
  const intl = useIntl();
  const { user } = useUser();

  const [openSetToCoreConfirmation, setOpenSetToCoreConfirmation] = useState(false);
  const requirementType = useWatch<RecipeFormValues>({
    name: 'requirementType',
  }) as RecipeFormValues['requirementType'];

  if (isReadOnly) {
    const selectedOption = requirementType
      ? intl.formatMessage({ id: recipeRequirementsMap[requirementType] || requirementType })
      : '';

    return (
      <Stack direction="row" gap={1} height="38.5px">
        <Typography
          variant="subtitle1"
          sx={{ color: (theme) => theme.palette.grey[500] }}
          className="print-only"
        >
          <FormattedMessage id="recipes.props.requirement" />:
        </Typography>
        <Typography variant="subtitle1" sx={{ color: (theme) => theme.palette.grey[500] }}>
          {selectedOption}
        </Typography>
      </Stack>
    );
  }

  const options = Object.values(RecipeRequirement)
    .map((value) => ({
      label: intl.formatMessage({ id: recipeRequirementsMap[value] || value }),
      value,
    }))
    .filter(
      (option) =>
        isHQ || [RecipeRequirement.Required, RecipeRequirement.Local].includes(option.value),
    );

  return (
    <Box mb="5px">
      <Controller
        control={control}
        name="requirementType"
        render={({ field: { onChange, value: val } }) => {
          const handleChange = (newValue: RecipeRequirement) => {
            if (newValue === RecipeRequirement.Core) {
              setOpenSetToCoreConfirmation(true);
              return;
            }
            if (val === RecipeRequirement.Core) {
              onChange(newValue);
              setValue('country', user?.group.country as Country);
              return;
            }
            onChange(newValue);
          };
          return (
            <>
              <ChipSelect
                testId="recipe-requirement-type"
                color="neutral"
                label={<FormattedMessage id="recipes.props.requirement" />}
                options={options}
                selected={val}
                onChange={handleChange}
              />
              <ConfirmationDialog
                onConfirm={() => {
                  onChange(RecipeRequirement.Core);
                  setOpenSetToCoreConfirmation(false);
                }}
                open={openSetToCoreConfirmation}
                onClose={() => setOpenSetToCoreConfirmation(false)}
                title={<FormattedMessage id="recipes.creator.confirmation.change_to_core.title" />}
                message={<FormattedMessage id="recipes.creator.confirmation.change_to_core.text" />}
                confirmText={
                  <FormattedMessage id="recipes.creator.confirmation.change_to_core.confirm" />
                }
              />
            </>
          );
        }}
      />
    </Box>
  );
};

export default RecipeRequirementType;
